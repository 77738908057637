export default {
  methods: {
    setupDummyData (err) {
      if (!err) {
        return {}
      }

      return {
        title: err.module.locales[this.lang] ? err.module.locales[this.lang].name : err.module.name,
        trial: err.module.trial_period,
        price: +err.module.price,
        perUser: +err.module.user_price,
        isActive: err.module.is_active,
        id: +err.module.id,
        description: err.module.locales[this.lang] ? err.module.locales[this.lang].description : err.module.description,
        short_description: err.module.locales[this.lang] ? err.module.locales[this.lang].short_description : err.module.short_description,
        helpCenter: err.module.help_center,
        features: err.module.features,
        images: err.module.images
      }
    }
  }
}
