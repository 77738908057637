<template>
  <div>
    <div v-if="view === 'resourceTimeMonth'" class="w-100 mt-1">
      <div class="text-center">
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('Users')" placement="top" effect="light">
            <i class="icon-users text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('Shifts')" placement="top" effect="light">
            <i class="icon-calendar text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('Hours total')" placement="top" effect="light">
            <i class="icon-clock text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('Break, hours')" placement="top" effect="light">
            <i class="icon-coffee text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div v-if="view === 'resourceTimelineDay' && statType === 'text'" class="w-100 mt-1">
      <div class="text-center">
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('USERS_PRESENCE_STAT_LABEL')" placement="top" effect="light">
            <i class="icon-users text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
        <div class="text-danger font-weight-bold" style="margin-bottom: 2px">
          <el-tooltip :content="$t('BREAKS_PRESENCE_STAT_LABEL')" placement="top" effect="light">
            <i class="icon-coffee text-secondary" style="font-size: 1.1em"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticIcons',
  props: {
    translate: Object,
    statType: String,
    view: String
  }
}
</script>

<style scoped>

</style>
