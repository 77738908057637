<template>
  <div class="container h-100" :class="!isModal ? 'dummy-popup card' : ''">
    <div class="row dummy-row h-100" :class="!isModal ? 'card-body' : ''">
      <div v-if="!compactMode" class="col-sm-6 d-none d-sm-block left-section">
        <el-carousel height="100%" width="100%">
          <el-carousel-item v-for="image, index in imagesList" :key="'carousel-' + index">
            <div class="h-100 carousel-item-image">
              <el-image class="dummy-img" :fit="'cover'" :src="image" :preview-src-list="imagesList" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="col-xs-12 right-section vertical-scrollable" :class="compactMode ? 'col-sm-12' : 'col-sm-6'">
        <div class="row pt-2 pb-2" v-if="isModal">
          <div class="col-12 text-right">
            <i class="icon-x btn-icon text-muted mx-1" type="button" :id="'modalCloseButton'" data-dismiss="modal"
              data-cy="dismiss-modal" aria-label="Close" @click="closeModal"></i>
          </div>
        </div>
        <div class="scroll_container" :class="isModal ? 'scroll-short' : 'scroll-full'">
          <div>
            <div>
              <div v-if="isEmployeeHasPermission('enable-modules')" class="row h-100">
                <div class="col-lg-12" v-if="dummyInfo.isActive">
                  <div class="h5 font-weight-bold mb-2">
                    {{ $t(dummyInfo.title).toUpperCase() }}
                  </div>
                  <div class="font-weight-bold text-dark pb-2">{{ $t('Connect this module to get access to this functionality') }}</div>
                  <div v-if="dummyInfo.features && dummyInfo.features.length">
                    <ul class="dummy-module-ul">
                      <li v-for="(feature, index) in dummyInfo.features" v-bind:key="'feature' + index">
                        <span class="font-weight-bold pl-2">{{ $t('feature') }}</span>
                      </li>
                    </ul>
                    <template v-if="dummyInfo.helpCenter">
                      <a :href="link" target="_blank">{{ $t('Read more') }}...</a>
                    </template>
                    <template v-else>
                      <div data-dismiss="modal" aria-label="Close">
                        <router-link :to="link">
                          {{ $t('Read more') }}...
                        </router-link>
                      </div>
                    </template>
                  </div>
                  <div v-else v-html="dummyInfo.description"></div>
                  <div class="py-1">
                    <button type="submit" name="submit" class="btn btn-block btn-success shadow-sm"
                      :data-dismiss="dataDismiss" aria-label="Close" @click="submitModule">
                      {{ $t('Activate') }}
                    </button>
                  </div>
                  <div>
                    <small v-if="dummyInfo.trial">
                      {{ $t('key_dummy_trial', { trial: dummyInfo.trial }) }}
                    </small>
                    <small>${{ dummyInfo.price }} / {{ $t('per month') }} +{{ dummyInfo.perUser ? dummyInfo.perUser : 0 }} $ {{ $t('per user') }}</small>
                  </div>
                </div>
                <div class="col-lg-12" v-else>
                  {{ $t('This feature is temporarily disabled. If you have questions, please contact support.') }}
                </div>
              </div>
              <div v-else class="row h-100">
                <div class="col-lg-12">
                  <div class="title text-danger font-weight-bold mb-2">
                    {{ $t('This module is not activated') }}
                  </div>
                  <div>
                    {{ $t('To activate it, contact the owner of the company account') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DummyPaymentRequired',
  data () {
    return {
      dataDismiss: 'modal'
    }
  },
  props: {
    dummyInfo: Object,
    isModal: { type: Boolean, default: false },
    preventDismiss: Boolean,
    compactMode: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      companyModulesIds: 'companyModulesIds'
    }),
    link () {
      if (this.dummyInfo.helpCenter) {
        if (this.locale === 'ru' && this.dummyInfo.helpCenter.ru) {
          return this.dummyInfo.helpCenter.ru
        } else if (this.dummyInfo.helpCenter.en) {
          return this.dummyInfo.helpCenter.en
        }
      }
      return '/c/' + this.$store.getters.companyId + '/modules'
    },
    imagesList () {
      if (this.dummyInfo.images && Array.isArray(this.dummyInfo.images[this.locale]) && this.dummyInfo.images[this.locale].length) {
        return this.dummyInfo.images[this.locale]
      }
      return ['https://landing-shifton.s3.us-west-2.amazonaws.com/landing/G3.png']
    }
  },
  created () {
    if (this.preventDismiss) this.dataDismiss = false
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    submitModule () {
      const data = {
        add: [
          { id: this.dummyInfo.id, count: this.dummyInfo.count ? +this.dummyInfo.count : 1 }
        ]
      }
      this.$store.dispatch('setModulesByCompany', data).then(() => {
        this.$emit('activate')
        this.$store.dispatch('getCompany', this.companyId)
        this.$store.dispatch('getModulesByCompany')
      }).catch((error) => {
        const errorMessage = error.response.data.message
        if (error.response.status === 403 && errorMessage) {
          this.toastError(this.$i18n?.t(errorMessage))
        }
      })
    }
  }
}
</script>

<style scoped>
  .dummy-module-ul {
    text-align: left;
    list-style: none;
    padding-left: 0px;
  }

  .right-section li {
    font-size: .9rem;
    padding-left: 10px;
    margin: 0 auto 10px;
    line-height: 1.5;
  }

  .right-section li:before {
    content: '✓';
  }

  .el-carousel--horizontal {
    height: 100% !important;
  }

  .vertical-scrollable > .scroll_container {
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: wrap;
  }
  .scroll-short {
    height: 400px;
  }
  .scroll-full {
    height: 450px;
  }
  .left-section {
    background-color: #e2e4e5;
    padding: 0px;
    border-radius: 3px 0 0 3px;
    height: 450px;
  }

  .right-section {
    border-radius: 0 3px 3px 0;
    height: 450px;
  }

  .dummy-img {
    position: absolute;
    width: 100%;
    height: 100%;
    height: 450px;
    object-fit: contain;
  }

  .dummy-row {
    height: 450px;
  }

  .carousel-item-image {
    align-items: center !important;
    display: flex
  }
</style>
