<template>
  <div>
    <buy-project-modal :projectsCount="projects.length" />
    <div class="row mb-2 mx-auto">
      <div class="col mt-auto">
        <span class="title-text">
        {{ $t("Projects") }}
        <ui-hint :content="$t('PROJECT_HINTS')" />
      </span>
      </div>
      <div  v-if="hasProjects" class="col-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          :is-search="true"
        />
      </div>
      <div  v-if="hasProjects" class="col-auto">
        <button v-if="isEmployeeHasPermission('create project')"
                class="btn btn-primary btn-sm rounded-pill ml-4 mr-2"
                type="button"
                :data-toggle="modal" data-target="#BuyProjectModal"
                @click="addProject">
          {{ $t('Add project') }}
        </button>
      </div>
    </div>
    <div v-if="hasProjects" class="card card-small">
      <div class="card-body table-responsive">
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th class="text-left">{{ $t("Title") }}</th>
            <th class="text-left">{{ $t("Managers") }}</th>
            <th class="text-left">{{ $t("EMPLOYEES_COUNT") }}</th>
            <th class="text-left">{{ $t("Project color") }}</th>
            <th style="min-width: 80px"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(project, key) in filteredProjects">
            <tr v-if="isEmployeeHasPermission('create project') || rolesByProjects[project.id] === 'manager'" v-bind:key="key">
              <td>{{ project.name }}</td>
              <td>
                {{ project.managers ? buildUsersList(project.managers) : ''}}
              </td>
              <td>
                {{ project.users.length}}
              </td>
              <td>
                <span class="project-color" v-bind:style="{ backgroundColor: project.color }"></span>
              </td>
              <td>
                <div class="row justify-content-center">
                  <div>
                    <router-link :to="`/c/${companyId}/project/${project.id}`">
                      <i class="icon-edit header-icon mr-2"></i>
                    </router-link>
                  </div>
                  <div class="btn-group dropleft">
                    <a id="dropdownMenuIcon" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style="cursor: pointer">
                      <i class="icon-more-horizontal header-icon text-primary"></i>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuIcon" style="z-index: 99999">
                      <router-link v-if="doubleAccountAllowed" class="dropdown-item" :to="`/c/${companyId}/project/${project.id}/double_accounts`">
                        <i class="icon-users" style="color: #449fdb"></i>
                        <span class="menu-color ml-2">{{ $t('Doubles Account')}}</span>
                      </router-link>
                      <router-link class="dropdown-item" :to="`/c/${companyId}/marks/${project.id}`">
                        <i class="icon-alert-circle"></i>
                        <span class="menu-color ml-2">{{ $t('EMPLOYEE_POSITIONS')}}</span>
                      </router-link>
                      <router-link
                        v-if="isEmployeeHasPermission('get related projects full data') &&
                                               !isEmployeeHasPermission('get projects by company')"
                        class="dropdown-item"
                        :to="`/c/${companyId}/project/${project.id}/users`">
                        <i class="icon-users"></i>
                        <span class="menu-color ml-2">{{ $t('Users')}}</span>
                      </router-link>
                      <div v-if="isEmployeeHasPermission('delete project')" class="dropdown-item" @click="deleteProject(project.id)" style="cursor: pointer">
                        <i class="icon-trash"></i>
                        <span class="menu-color ml-2">{{ $t('Delete')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr class="text-center" v-if="filteredProjects.length < 1">
            <td colspan="6">{{ $t('No projects found') }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body text-center">
        <img class="img-fluid" style="max-height: 350px" src="../../../public/static/images/App/Splash_1_01.png"/>
        <h5 class="m-3">
          {{$t('You have no projects!')}}
        </h5>
        <div v-if="isEmployeeHasPermission('create project')">
          <button class="btn btn-primary btn-md rounded-pill shadow-sm" type="button"  :data-toggle="modal" data-target="#BuyProjectModal" @click="addProject">
            {{ $t('Add project') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuyProjectModal from '@/components/Project/Modals/BuyProjectModal'
import { mapGetters } from 'vuex'

export default {
  name: 'Projects',
  components: {
    BuyProjectModal: BuyProjectModal
  },

  data () {
    return {
      search: '',
      modal: '' // '#BuyProjectModal'
    }
  },

  created () {
    if (this.companyId) {
      this.init()
    }
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      projects: 'projects',
      preloadUsers: 'employeesIndexed'
    }),
    projectsLimit () {
      return this.$store.state.company.projectsLimit
    },
    filteredProjects () {
      const projects = this.projects.filter(project => {
        return project.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
      projects.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })

      return projects
    },
    hasProjects () {
      return this.projects.length > 0
    }
  },

  watch: {
    companyId () {
      this.init()
    },
    projectsLimit (value) {
      // console.log(value)
      this.modal = parseInt(value) > 0 ? '' : 'modal'
    }
  },

  methods: {
    init () {
      if (this.isEmployeeHasPermission('get projects by company') ||
        this.isEmployeeHasPermission('get related projects full data')) {
        this.modal = +this.$store.getters.projectsLimit > 0 ? '' : 'modal'
        this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then(() => {
          this.$store.dispatch('getProjectsByCompany', this.$store.getters.companyId).then(() => {
            //
          })
        })
      } else {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
    },

    deleteProject (projectId) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteProject', projectId).then(response => {
          this.$store.dispatch('updateProjectsLimit', +this.$store.getters.projectsLimit + 1)
          // console.log(response)
          let index = null
          this.projects.forEach((value, key) => {
            if (value.id === projectId) index = key
          })
          if (index !== null) this.projects.splice(index, 1)
          this.$eventBus.emit('checkSteps')
        }).catch(() => {})
      }).catch(() => {})
    },

    buildUsersList (users) {
      return users.map((userId) => this.getFirstLastName(userId)).join(', ')
    },

    getFirstLastName (id) {
      if (!this.preloadUsers[id]) {
        return this.$i18n?.t('Deleted Employee')
      }
      return this.preloadUsers[id].full_name
    },

    addProject () {
      if (+this.$store.getters.projectsLimit > 0) {
        this.$router.push(`/c/${this.companyId}/project`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .header-icon {
    width: 48px;
    font-size: 24px;
    // color: white;
  }
</style>
