<template>
  <template v-if="showTaskDropdown">
    <!-- List -->
    <li class="nav-item no-md-border my-auto">
      <div class="btn-group nav-link px-0" :class="{ 'py-0' : isMobile, 'border-top': isMobile }">
        <router-link
          id="demo_tasks"
          :to="`/c/${companyId}/tasks#active`"
          style="width:100%"
          class="bg-white"
        >
          <span class="d-md-none p-0 mx-2">
            <i class="icon-clipboard mobile-menu-icon"></i>
          </span>
          <button
            type="button"
            id="demo_tasks"
            class="btn btn-white menu-color">
            {{ $t('TASKS_LINK_LIST') }}
          </button>
        </router-link>
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split" id="tasksDropdown"
                data-toggle="dropdown" aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link v-if="isEmployeeHasPermission('create-checklists')"
                       id="" class="dropdown-item"
                       :to="`/c/${companyId}/checklists`">
            <i class="icon-playlist_add_check btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('Checklists') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-task-service-area')"
                       id="" class="dropdown-item"
                       :to="`/c/${companyId}/tasks-service-areas`">
            <i class="icon-library btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('TASK_SERVICE_AREAS_LINK') }}</span>
          </router-link>
          <router-link id="demo_user_tasks" class="dropdown-item" :to="`/c/${companyId}/user-tasks`">
            <i class="icon-how_to_reg btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('USER_TASKS_LINK') }}</span>
          </router-link>
          <div v-if="isEmployeeHasPermission('create-task-statuses')"
               class="dropdown-divider" />
          <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                       :to="`/c/${companyId}/client-fields`">
            <i class="icon-list btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('CLIENT_FIELDS') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                       :to="`/c/${companyId}/task-statuses`">
            <i class="icon-bar-chart btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('Statuses') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item" :to="`/c/${companyId}/task-marks`">
            <i class="icon-award btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('TASK_MARKS_LINK') }}</span>
          </router-link>
        </div>
      </div>
    </li>
    <!-- Inventory -->
    <li class="nav-item no-md-border my-auto" v-if="
      !isProduction && (isEmployeeHasPermission('create-inventory-category') ||
        isEmployeeHasPermission('create-inventory-facility') ||
        isEmployeeHasPermission('create-inventory-bundle') ||
        isEmployeeHasPermission('create-inventory-unit-of-measurement'))">
      <div class="btn-group nav-link px-0" :class="{ 'py-0' : isMobile, 'border-top': isMobile }">
        <router-link
          id="demo_tasks"
          :to="`/c/${companyId}/inventory/items`"
          style="width:100%"
          class="bg-white"
        >
          <span class="d-md-none p-0 mx-2">
            <i class="icon-clipboard mobile-menu-icon"></i>
          </span>
          <button
            type="button"
            id="demo_tasks"
            class="btn btn-white menu-color">
            {{ $t('INVENTORY_LINK') }}
          </button>
        </router-link>
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split" id="tasksDropdown"
                data-toggle="dropdown" aria-expanded="false">
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link v-if="isEmployeeHasPermission('create-inventory-category')"
                       id="" class="dropdown-item"
                       :to="`/c/${companyId}/inventory/categories`">
            <i class="icon-grid btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('INVENTORY_CATEGORIES_LINK') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-inventory-facility')"
                       id="" class="dropdown-item"
                       :to="`/c/${companyId}/inventory/facilities`">
            <i class="icon-home btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('INVENTORY_FACILITIES_LINK') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-inventory-bundle')"
            id="demo_user_tasks" class="dropdown-item"
            :to="`/c/${companyId}/inventory/bundles`">
            <i class="icon-package btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('INVENTORY_BUNDLES_LINK') }}</span>
          </router-link>
          <router-link v-if="isEmployeeHasPermission('create-inventory-unit-of-measurement')"
                       id="" class="dropdown-item"
                       :to="`/c/${companyId}/inventory/units-of-measurement`">
            <i class="icon-calculator btn-icon text-secondary"></i>
            <span class="menu-color ml-2">{{ $t('INVENTORY_UNITS_OF_MEASUREMENT_LINK') }}</span>
          </router-link>
        </div>
      </div>
    </li>
    <!-- Calendar -->
    <li class="nav-item d-none d-md-block">
      <router-link
        id="task_calendar"
        :to="`/c/${companyId}/user-tasks/calendar`"
        style="width:100%"
        class="nav-link d-none d-md-block my-auto px-0"
      >
        <button type="button"
                class="btn btn-white menu-color">
          {{ $t('FIELD_SERVICE_CALENDAR') }}
        </button>
      </router-link>
    </li>
    <li class="nav-item d-md-none border-top py-2">
      <router-link
        id="task_calendar"
        :to="`/c/${companyId}/user-tasks/calendar`"
        style="width:100%"
      >
        <i class="icon-calendar mobile-menu-icon mx-2"></i>
        <span class="menu-color">{{ $t('FIELD_SERVICE_CALENDAR') }}</span>
      </router-link>
    </li>
    <!-- Clients -->
    <template v-if="isEmployeeHasPermission('get-clients')">
      <li class="nav-item d-none d-md-block">
        <router-link
          id="tasks_clients"
          :to="`/c/${companyId}/clients`"
          style="width:100%"
          class="nav-link d-none d-md-block my-auto px-0"
        >
          <button type="button"
                  class="btn btn-white menu-color">
            {{ $t('Clients') }}
          </button>
        </router-link>
      </li>
      <li class="nav-item d-md-none border-top py-2">
        <router-link
          id="tasks_clients"
          :to="`/c/${companyId}/clients`"
          style="width:100%"
        >
          <i class="icon-smile mobile-menu-icon mx-2"></i>
          <span class="menu-color">{{ $t('Clients') }}</span>
        </router-link>
      </li>
    </template>
    <!-- Map -->
    <template v-if="isEmployeeHasPermission('create-checklists')">
      <li class="nav-item d-none d-md-block">
        <router-link
          id="tasks_map"
          :to="`/c/${companyId}/tasks-map`"
          style="width:100%"
          class="nav-link d-none d-md-block my-auto px-0"
        >
          <button type="button"
                  class="btn btn-white menu-color">
            {{ $t('Map') }}
          </button>
        </router-link>
      </li>
      <li class="nav-item d-md-none border-top py-2">
        <router-link
          id="tasks_map"
          :to="`/c/${companyId}/tasks-map`"
          style="width:100%"
        >
          <i class="icon-map-pin mobile-menu-icon mx-2"></i>
          <span class="menu-color">{{ $t('Map') }}</span>
        </router-link>
      </li>
    </template>
  </template>
  <li class="nav-item border-top no-md-border my-auto" v-if="showTaskDropdown">
    <div class="btn-group nav-link px-0" :class="{ 'py-0' : isMobile }">
      <div class="dropdown-menu dropdown-menu-right">

        <router-link v-if="isEmployeeHasPermission('create-checklists')"
                     id="" class="dropdown-item"
                     :to="`/c/${companyId}/checklists`">
          <i class="icon-playlist_add_check btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('Checklists') }}</span>
        </router-link>

        <router-link v-if="isEmployeeHasPermission('create-checklists')"
                     id="" class="dropdown-item" :to="`/c/${companyId}/tasks-map`">
          <i class="icon-map-pin btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('Map') }}</span>
        </router-link>

        <router-link id="demo_user_tasks" class="dropdown-item" :to="`/c/${companyId}/user-tasks`">
          <i class="icon-how_to_reg btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('USER_TASKS_LINK') }}</span>
        </router-link>

        <div v-if="isEmployeeHasPermission('create-task-statuses')"
             class="dropdown-divider" />
        <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                     :to="`/c/${companyId}/client-fields`">
          <i class="icon-list btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('CLIENT_FIELDS') }}</span>
        </router-link>

        <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                     :to="`/c/${companyId}/task-statuses`">
          <i class="icon-bar-chart btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('Statuses') }}</span>
        </router-link>

        <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item" :to="`/c/${companyId}/task-marks`">
          <i class="icon-award btn-icon text-secondary"></i>
          <span class="menu-color ml-2">{{ $t('TASK_MARKS_LINK') }}</span>
        </router-link>
      </div>
    </div>
  </li>
  <template v-else>
    <li class="nav-item my-auto">
      <router-link
        :to="`/c/${companyId}/user-tasks`"
        class="nav-link d-none d-md-block my-auto px-0 btn btn-white"
      >
        <span class="menu-color">{{ $t('USER_TASKS_LINK') }}</span>
      </router-link>
      <router-link
        class="d-md-none dropdown-item p-2 nav-link bg-white"
        :to="`/c/${companyId}/user-tasks`"
      >
        <i class="icon-clipboard"></i>
        <span class="menu-color ml-2" :class="{ mobileItemStyle: isMobile }">
        {{$t('USER_TASKS_LINK') }}
      </span>
      </router-link>
    </li>
  </template>
  <!-- Todos -->
  <li class="nav-item my-auto">
    <router-link
      :to="`/c/${companyId}/todos-list`"
      style="width:100%"
      class="nav-link d-none d-md-block my-auto px-0"
    >
      <button type="button"
              class="btn btn-white menu-color">
        {{ $t('TODOS_LIST') }}
      </button>
    </router-link>
    <router-link
      :to="`/c/${companyId}/todos-list`"
      style="width:100%"
      class="d-md-none dropdown-item p-2 nav-link btn btn-white"
    >
      <i class="icon-clipboard"></i>
      <span class="menu-color ml-2" :class="{ mobileItemStyle: isMobile }">
          {{ $t('TODOS_LIST') }}
        </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: 'HeaderTasksComponent',
  data () {
    return {
      isProduction: process.env.NODE_ENV === 'production'
    }
  },
  props: {
    isMobile: Boolean,
    employeePositions: Object
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    showTaskDropdown () {
      if (this.isEmployeeHasPermission('get-tasks-admin')) {
        return true
      }
      if (this.isEmployeeHasPermission('get-tasks-manager') &&
        this.taskSettings &&
        ['gg_user', 'gg_manager'].includes(this.taskSettings.min_role)) {
        return true
      }
      return !!(this.isEmployeeHasPermission('get-tasks-user') &&
        this.taskSettings &&
        this.taskSettings.min_role === 'gg_user' &&
        (this.taskSettings.positions_list.length === 0 ||
          this.taskSettings.positions_list.filter(item => {
            return this.employeePositions[item]
          }).length > 0))
    }
  }
}
</script>
<style scoped lang="scss">

.menu-color {
  color: #4a4a4a !important;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle {
  border-radius: 0px 7px 7px 0px !important;
}

#demo_tasks {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
  border: none;
}

#demo_tasks:hover {
  background-color: #eceeef;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

#tasksDropdown:hover {
  background-color: #e9ecef;
}

div#tasksDropdown .btn,
li#demo_tasks_button .btn {
  border-radius: 7px !important;
  background-color: transparent;
  border: none;
}

.mobile-menu-icon {
  color: rgba(0, 0, 0, 0.5);
}

.mobileItemStyle {
  padding-left: 1.25rem;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
.cha-cha:hover {
  background-color: #4a4a4a !important;
  color: #e9ecef;
}
</style>
