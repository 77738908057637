import { HTTP } from '@/api/http_utils'

const UPDATE_FACILITIES = 'UPDATE_FACILITIES'
const UPDATE_FACILITY = 'UPDATE_FACILITY'
const DELETE_FACILITY = 'DELETE_FACILITY'
const UPDATE_UNITS_OF_MEASUREMENT = 'UPDATE_UNITS_OF_MEASUREMENT'
const UPDATE_UNIT_OF_MEASUREMENT = 'UPDATE_UNIT_OF_MEASUREMENT'
const DELETE_UNIT_OF_MEASUREMENT = 'DELETE_UNIT_OF_MEASUREMENT'
const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES'
const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
const DELETE_CATEGORY = 'DELETE_CATEGORY'
const UPDATE_ITEMS = 'UPDATE_ITEMS'
const UPDATE_ITEM = 'UPDATE_ITEM'
const DELETE_ITEM = 'DELETE_ITEM'
const UPDATE_BUNDLES = 'UPDATE_BUNDLES'
const UPDATE_BUNDLE = 'UPDATE_BUNDLE'
const DELETE_BUNDLE = 'DELETE_BUNDLE'

const state = {
  itemsIndexed: {},
  facilitiesIndexed: {},
  categoriesIndexed: {},
  bundlesIndexed: {},
  unitsOfMeasurementIndexed: {}
}

let promises = {}

const getters = {
  items: state => Object.values(state.itemsIndexed),
  itemsIndexed: state => state.itemsIndexed,
  facilities: state => Object.values(state.facilitiesIndexed),
  facilitiesIndexed: state => state.facilitiesIndexed,
  unitsOfMeasurement: state => Object.values(state.unitsOfMeasurementIndexed),
  unitsOfMeasurementIndexed: state => state.unitsOfMeasurementIndexed,
  categories: state => Object.values(state.categoriesIndexed),
  categoriesIndexed: state => state.categoriesIndexed,
  bundles: state => Object.values(state.bundlesIndexed),
  bundlesIndexed: state => state.bundlesIndexed
}

const mutations = {
  [UPDATE_FACILITIES] (state, data) {
    const facilitiesById = {}
    data.forEach(item => {
      facilitiesById[item.id] = item
    })
    state.facilitiesIndexed = facilitiesById
  },
  [UPDATE_FACILITY] (state, payload) {
    state.facilitiesIndexed[payload.id] = payload
  },
  [DELETE_FACILITY] (state, facilityId) {
    delete state.facilitiesIndexed[facilityId]
  },
  [UPDATE_UNITS_OF_MEASUREMENT] (state, data) {
    const unitsOfMeasurementIndexedById = {}
    data.forEach(item => {
      unitsOfMeasurementIndexedById[item.id] = item
    })
    state.unitsOfMeasurementIndexed = unitsOfMeasurementIndexedById
  },
  [UPDATE_UNIT_OF_MEASUREMENT] (state, payload) {
    state.unitsOfMeasurementIndexed[payload.id] = payload
  },
  [DELETE_UNIT_OF_MEASUREMENT] (state, facilityId) {
    delete state.unitsOfMeasurementIndexed[facilityId]
  },
  [UPDATE_CATEGORIES] (state, data) {
    const categoriesById = {}
    data.forEach(item => {
      categoriesById[item.id] = item
    })
    state.categoriesIndexed = categoriesById
  },
  [UPDATE_CATEGORY] (state, payload) {
    state.categoriesIndexed[payload.id] = payload
  },
  [DELETE_CATEGORY] (state, facilityId) {
    delete state.categoriesIndexed[facilityId]
  },
  [UPDATE_ITEMS] (state, response) {
    const itemsById = {}
    let items = response

    if (response.current_page) {
      items = response.data
    }

    items.forEach(item => {
      itemsById[item.id] = item
    })
    state.itemsIndexed = itemsById
  },
  [UPDATE_ITEM] (state, payload) {
    state.itemsIndexed[payload.id] = payload
  },
  [DELETE_ITEM] (state, itemId) {
    delete state.itemsIndexed[itemId]
  },
  [UPDATE_BUNDLES] (state, response) {
    const bundlesById = {}
    response.forEach(item => {
      bundlesById[item.id] = item
    })
    state.bundlesIndexed = bundlesById
  },
  [UPDATE_BUNDLE] (state, payload) {
    state.bundlesIndexed[payload.id] = payload
  },
  [DELETE_ITEM] (state, buundleId) {
    delete state.bundlesIndexed[buundleId]
  }
}

const actions = {
  clearInventoryCache () {
    promises = {}
  },
  getFacilities ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/inventory/facilities`

    if (promises[promiseKey]) {
      console.error('repeating requests facilities')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/facilities`).then(response => {
        commit(UPDATE_FACILITIES, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getFacility ({ commit }, [companyId, facilityId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/facilities/${facilityId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createFacility ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/facilities`, payload).then(response => {
        commit(UPDATE_FACILITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateFacility ({ commit }, [companyId, facilityId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/inventory/facilities/${facilityId}`, payload).then(response => {
        commit(UPDATE_FACILITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteFacility ({ commit }, [companyId, facilityId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/facilities/${facilityId}`).then(response => {
        commit(DELETE_FACILITY, facilityId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUnitsOfMeasurement ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/inventory/units-of-measurement`

    if (promises[promiseKey]) {
      console.error('repeating requests units of measurement')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/units-of-measurement`).then(response => {
        commit(UPDATE_UNITS_OF_MEASUREMENT, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getUnitOfMeasurement ({ commit }, [companyId, unitOfMeasurementId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/units-of-measurement/${unitOfMeasurementId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createUnitOfMeasurement ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/units-of-measurement`, payload).then(response => {
        commit(UPDATE_UNIT_OF_MEASUREMENT, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateUnitOfMeasurement ({ commit }, [companyId, unitOfMeasurementId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/inventory/units-of-measurement/${unitOfMeasurementId}`, payload).then(response => {
        commit(UPDATE_UNIT_OF_MEASUREMENT, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteUnitOfMeasurement ({ commit }, [companyId, unitOfMeasurementId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/units-of-measurement/${unitOfMeasurementId}`).then(response => {
        commit(DELETE_UNIT_OF_MEASUREMENT, unitOfMeasurementId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCategories ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/inventory/categories`

    if (promises[promiseKey]) {
      console.error('repeating requests categories')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/categories`).then(response => {
        commit(UPDATE_CATEGORIES, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getCategory ({ commit }, [companyId, categoryId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/categories/${categoryId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createCategory ({ commit }, [companyId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/categories`, payload, config).then(response => {
        commit(UPDATE_CATEGORY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateCategory ({ commit }, [companyId, categoryId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/categories/${categoryId}`, payload, config).then(response => {
        commit(UPDATE_CATEGORY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteCategory ({ commit }, [companyId, categoryId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/categories/${categoryId}`).then(response => {
        commit(DELETE_CATEGORY, categoryId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getItems ({ commit }, [companyId, payload = '']) {
    const promiseKey = `/companies/${companyId}/inventory/items`

    if (promises[promiseKey]) {
      console.error('repeating requests items')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/items` + payload).then(response => {
        commit(UPDATE_ITEMS, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getItem ({ commit }, [companyId, itemId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/items/${itemId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createItem ({ commit }, [companyId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/items`, payload, config).then(response => {
        commit(UPDATE_ITEM, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateItem ({ commit }, [companyId, itemId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/items/${itemId}`, payload, config).then(response => {
        commit(UPDATE_ITEM, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteItem ({ commit }, [companyId, itemId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/items/${itemId}`).then(response => {
        commit(DELETE_ITEM, itemId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBundles ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/inventory/bundles`

    if (promises[promiseKey]) {
      console.error('repeating requests bundles')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/bundles`).then(response => {
        commit(UPDATE_BUNDLES, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getBundle ({ commit }, [companyId, bundleId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/bundles/${bundleId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createBundle ({ commit }, [companyId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/bundles`, payload, config).then(response => {
        commit(UPDATE_BUNDLE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateBundle ({ commit }, [companyId, bundleId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/bundles/${bundleId}`, payload, config).then(response => {
        commit(UPDATE_BUNDLE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteBundle ({ commit }, [companyId, bundleId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/bundles/${bundleId}`).then(response => {
        commit(DELETE_BUNDLE, bundleId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBundleItems ({ commit }, [companyId, bundleId]) {
    const promiseKey = `/companies/${companyId}/inventory/bundles/${bundleId}/items`

    if (promises[promiseKey]) {
      console.error('repeating requests bundles')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/bundles/${bundleId}/items`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getBundleItem ({ commit }, [companyId, bundleId, itemId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/inventory/bundles/${bundleId}/items/${itemId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createBundleItem ({ commit }, [companyId, bundleId, payload]) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/inventory/bundles/${bundleId}/items`, payload, config).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateBundleItem ({ commit }, [companyId, bundleId, itemId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/inventory/bundles/${bundleId}/items/${itemId}`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteBundleItem ({ commit }, [companyId, bundleId, itemId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/inventory/bundles/${bundleId}/items/${itemId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
