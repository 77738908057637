<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("UNITS_OF_MEASUREMENT") }}
          <ui-hint :content="$t('UNITS_OF_MEASUREMENT_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row py-2">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill mx-3 m-1 col-auto col-auto" type="button" @click="addUnitOfMeasurement">
                  {{ $t('Create unit of measurement') }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <el-table
              :data="list"
              style="width: 100%">
              <el-table-column
                prop="name"
                :label="$t('Title')"
                >
              </el-table-column>
              <el-table-column
                prop="min_step"
                :label="$t('Min step')">
              </el-table-column>
              <el-table-column
                width="150"
                :label="$t('Actions')">
                <template #default="scope">
                  <router-link :to="`/c/${companyId}/inventory/units-of-measurement/${scope.row.id}`">
                    <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                  </router-link>
                  <i v-if="!scope.row.is_first" class="icon-trash btn-icon text-danger"
                    @click="deleteUnitOfMeasurement(scope.row.id)"
                    role="button">
                  </i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'UnitsOfMeasurement',
  components: { DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAndInitialize()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAndInitialize()
    },
    period () {
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    checkAndInitialize () {
      this.$store.dispatch('isModuleActive', [this.companyId, 32]).then((e) => {
        this.init()
      }).catch(err => {
        this.$store.dispatch('getModulesByCompany')
        this.dummy = true
        if (!err.response.data.module) {
          this.dummyInfo = {}
        } else {
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    init () {
      this.$store.dispatch('getUnitsOfMeasurement', this.companyId).then((response) => {
        if (response) {
          this.list = response
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addUnitOfMeasurement () {
      this.$router.push(`/c/${this.companyId}/inventory/units-of-measurement/create`)
    },
    deleteUnitOfMeasurement (unitOfMeasurementId) {
      this.$confirm(this.$t('This will permanently delete the unit of measurement. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteUnitOfMeasurement', [this.companyId, unitOfMeasurementId]).then((response) => {
          this.init()
          this.toastSuccess(this.$t('Successfully deleted'))
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
        }).catch((error) => {
          const errorMessage = error.response.data.message
          if (error.response.status === 400 && errorMessage === 'Unable to delete unit of measurement') {
            this.toastError(this.$i18n?.t(errorMessage))
          }
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>

<style scoped>
</style>
