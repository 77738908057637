<template>
  <AttendanceChoiceComponent
    v-if="nextAttendance"
    @proceedAttendance="(data) => {
        proceedAttendance(...data)
      }"
    @proceedCloseAttendance="() => this.dialogAttendanceVisible = false"
    :dialogAttendanceVisible="dialogAttendanceVisible"
    :next="nextAttendance"/>
  <li class="nav-item dropdown no-md-border my-auto">
    <div @click="getAttendance"
         :class="{
           'border-top': isMobile
         }"
         data-toggle="dropdown"
         class="head-link nav-link px-0"
         style="cursor: pointer"
         id="attendanceDropdown"
         aria-haspopup="true"
         aria-expanded="false">
      <button class="btn btn-light dropdown-toggle d-none d-md-block menu-color" type="button"
              aria-expanded="false">{{ $t('ATTENDANCE') }}
      </button>
      <span class="d-md-none p-0 mx-2">
                    <i class="icon-clock"></i>
                  </span>
      <span class="d-md-none menu-color" :class="{ mobileItemStyle: isMobile }">{{ $t('ATTENDANCE_DROPDOWN') }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-right py-0"
         aria-labelledby="attendanceDropdown" :style="dummy ? 'min-width: 450px;' : null">
      <template
        v-if="!dummy && attendance && attendance.next_events && attendanceSettings && attendanceSettings.interfaces.web.enabled">
        <div v-for="(event, index) in attendance.next_events" :key="index" class="p-1">
          <button
            type="submit"
            @click="updateAttendance(event)"
            data-cy="switch-view"
            :class="buttonAttendanceClass(event)"
            class="btn rounded-pill shadow-sm w-100">
            <template v-if="event.type === 'shift_end'">
              {{ $t('END_SHIFT_ATTENDANCE') }}
            </template>
            <template v-if="event.type === 'break_end'">
              {{ $t('END_BREAK_ATTENDANCE') }}
            </template>
            <template v-if="event.type === 'shift_start'">
              {{ $t('START_SHIFT_ATTENDANCE') }}
            </template>
            <template v-if="event.type === 'break_start'">
              {{ $t('START_BREAK_ATTENDANCE') }}
            </template>
          </button>
        </div>
      </template>
      <template v-if="dummy">
        <dummy-payment-required
          @activate="activate"
          :dummyInfo="dummyInfo"
          :compactMode="true">
        </dummy-payment-required>
      </template>
      <template v-if="isInterfacesDisabled">
        <div class="p-5 text-danger">
          {{ $t(interfaceError) }}
        </div>
      </template>
    </div>
  </li>
</template>
<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import AttendanceChoiceComponent from '@/components/CommonComponents/Attendance/AttendanceChoiceComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  components: { AttendanceChoiceComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  name: 'HeaderAttendanceComponent',
  data () {
    return {
      dummy: false,
      isInterfacesDisabled: false,
      interfaceError: '',
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: {}
      },
      attendance: null,
      attendanceSettings: null,
      dialogAttendanceVisible: false,
      nextAttendance: null
    }
  },
  emits: ['activate'],
  props: {
    isMobile: Boolean
  },
  mounted () {
    this.getModulesByCompany()
    this.getAttendance()
  },
  computed: {
    isAttendanceShiftModal () {
      if (!this.attendance) {
        return false
      }
      let value = false
      this.attendance.next_events.forEach(next => {
        if ((next.type === 'shift_start') && next.data.length > 1) {
          value = true
        }
      })
      return value
    },
    isAttendanceBreakModal () {
      if (!this.attendance) {
        return false
      }
      let value = false
      this.attendance.next_events.forEach(next => {
        if ((next.type === 'break_start') && next.data.length > 1) {
          value = true
        }
      })
      return value
    }
  },
  methods: {
    activate () {
      this.$emit('activate')
    },
    getAttendance () {
      this.isInterfacesDisabled = false
      this.dummy = false
      this.$store.dispatch('getAttendance').then(response => {
        this.dummy = false
        this.attendance = response
        if (!this.attendanceSettings) {
          this.getModulesByCompany()
        }
      }).catch((error) => {
        if (error.response.data.errors.interfaces_disabled) {
          this.isInterfacesDisabled = true
          this.interfaceError = 'ALL_INTERFACES_ARE_DISABLED'
        }
        if (error.response.data.errors.web_interface_disabled) {
          this.isInterfacesDisabled = true
          this.interfaceError = 'WEB_INTERFACE_IS_DISABLED'
        }
        if (error.response.data.errors.mobile_interface_disabled) {
          this.isInterfacesDisabled = true
          this.interfaceError = 'MOBILE_INTERFACE_IS_DISABLED'
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          error.response.data.module_id = error.response.data.id
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    updateAttendance (event) {
      this.dialogAttendanceVisible = false
      if ((this.isAttendanceShiftModal && event.type === 'shift_start') ||
        (this.isAttendanceBreakModal && event.type === 'break_start')) {
        this.nextAttendance = event
        this.dialogAttendanceVisible = true
      } else {
        const scheduleId = event.data && event.data.length > 0 && !event.data[0].id ? event.data[0].schedule_id : null
        this.proceedAttendance(event.type, null, scheduleId)
      }
    },
    proceedAttendance (type, objectId = null, scheduleId = null) {
      const payload = {
        type
      }
      if (objectId) {
        payload.object_id = objectId
      }

      if (scheduleId) {
        payload.schedule_id = scheduleId
      }

      this.$store.dispatch('updateAttendance', payload).then(response => {
        this.getAttendance()
        this.$eventBus.emit('getAttendance')
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    },
    getModulesByCompany () {
      this.$store.dispatch('getModulesByCompany').then(response => {
        this.companyModules = response.forEach((module) => {
          if (+module.module_id === 24) {
            this.module = module
            if (!Array.isArray(this.module.settings)) {
              this.attendanceSettings = this.module.settings
            }
          }
          if (+module.module_id === 8) { // базовый модуль
            if (!Array.isArray(module.settings)) {
              const warningInPast = !!module.settings.should_warning_shift_in_past
              this.$store.commit('warningInPast', warningInPast)
              this.$store.commit('working_hours_display_type', module.settings.working_hours_display_type)
              this.$store.commit('require_user_confirm', module.settings.require_user_confirm)
            }
          }
        })
      })
    },
    buttonAttendanceClass (event) {
      if (event.type === 'shift_start') {
        return 'btn-outline-success'
      }
      if (event.type === 'shift_end') {
        return 'btn-outline-danger'
      }
      if (event.type === 'break_start' || event.type === 'break_end') {
        return 'btn-outline-primary'
      }
      return 'btn-outline-primary'
    }
  }
}
</script>
<style scoped lang="scss">

.menu-color {
  color: #4a4a4a !important;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle,
div#attendanceDropdown .btn {
  border-radius: 0px 7px 7px 0px !important;
}

div#attendanceDropdown .btn:hover {
  background-color: #eceeef;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

div#attendanceDropdown .btn {
  border-radius: 7px !important;
  background-color: transparent;
  border: none;
}

.badge-pos {
  position: absolute;
  top: 0;
  right: -5px;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>
