<template>
  <modal-component :id="'BundleItemModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="BundleItemModalTitle">{{ $t(item && item.id ? 'Update bundle item' : 'Create bundle item')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer">
          <div class="row">
            <div class="col-12">
              <ui-autocomplete
                :label="$t('Select item')"
                v-model="localItem.name"
                :containerClass="'w-100'"
                :disabled="item && item.id"
                :name="'client'"
                :validation="{required: false, min: 3}"
                :querySearchAsync="querySearchClientAsync"
                :placeholder="$t('Please input')"
                @select="handleSelectItem"
              />
            </div>
            <div class="col-12">
              <ui-number-input
                name="item_quantity"
                :label="$t('BUND_ITEM_QUANTITY')"
                :hint="$t('BUNDLE_DISCOUNT_VALUE_HINT')"
                v-model="localItem.quantity"
                :validation="{ required: true, integer: true, min_value: 1,}"
                :step="1"
                :min="1"
              />
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'BundleItemModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [errorMixin],
  data () {
    return {
      timeout: null,
      localItem: {
        id: null,
        item_id: null,
        name: '',
        quantity: 1
      }
    }
  },
  props: {
    item: Object,
    bundleId: Number
  },
  created () {
    console.log('this.item', this.item)
    if (this.item) {
      this.localItem = JSON.parse(JSON.stringify(this.item))
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },
  watch: {
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('closeBundleItemModal')
    },
    querySearchClientAsync (queryString, cb) {
      const values = []
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('getItems', [this.companyId, '?search=' + queryString]).then((response) => {
          response.forEach((item) => {
            values.push({
              name: item.name,
              value: item.name,
              id: item.id
            })
          })
          cb(values)
        }).catch({})
      }, 1500 * Math.random())
    },
    isSearch (queryString) {
      if (this.itemName) {
        return queryString && queryString !== this.itemName && queryString.length > 2
      } else {
        return queryString && queryString.length > 2
      }
    },
    handleSelectItem (item) {
      this.localItem.item_id = item.id
      this.localItem.name = item.name
    },
    save () {
      let payload = {}

      if (this.item && this.item.id) {
        payload = {
          quantity: this.localItem.quantity
        }

        this.$store.dispatch('updateBundleItem', [this.companyId, this.bundleId, this.item.id, payload]).then(response => {
          this.toastSuccess(this.$i18n.t('Update completed!'))
          this.$emit('updateBundleItems')
          this.closeModal()
        }).catch(e => {
          this.toastError(this.$i18n.t(e.message))
        })
      } else {
        payload = {
          item_id: this.localItem.item_id,
          quantity: this.localItem.quantity
        }
        this.$store.dispatch('createBundleItem', [this.companyId, this.bundleId, payload]).then(response => {
          this.toastSuccess(this.$i18n.t('Created completed!'))
          this.$emit('updateBundleItems')
          this.closeModal()
        }).catch(e => {
          const errorMessage = e.response.data.message
          if (errorMessage) {
            this.toastError(this.$i18n?.t(errorMessage))
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
