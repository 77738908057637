<template>
  <div
    data-toggle="modal"
    :data-target="isClickable ? '#ClickEventModal' : ''"
  >
      <div>
        <!--   бонусы/штрафы    -->
        <el-tooltip
          v-if="isClickable && (viewType !== 'resourceTimeMonth' || monthDisplay !== 'compact')"
          :show-after="openDelay"
          :hide-after="hideDelay"
          effect="light"
          placement="top"
        >
          <template #content>
            <div style="max-width: 350px">
              <div class="row">
                <div class="col-auto d-flex align-items-center">
                  <i class="icon-info text-primary" style="font-size: 15px;" />
                </div>
                <div class="col">
                  <div>{{ tooltip }}<br/>{{ tooltipDAC }}</div>
                </div>
              </div>
              <div class="row" v-if="isOverhead && !isUserStatus" >
                <div class="col-auto d-flex align-items-center">
                  <span class="red-circle-big" />
                </div>
                <div class="col">
                  {{warningQuotas}}
                </div>
              </div>
              <div class="row" v-if="require_user_confirm && eventData.extendedProps.confirmed_by_employee">
                <div class="col-auto d-flex align-items-center">
                  <i class="icon-check-circle text-success" style="font-size: 15px;" />
                </div>
                <div class="col">
                  {{confirmedMessage}}
                </div>
              </div>
              <el-divider v-if="event.extendedProps.location" class="my-1" />
              <div v-if="event.extendedProps.location" class="row">
                <div class="col-auto d-flex align-items-center">
                  <i class="icon-map-pin" style="font-size: 15px;" />
                </div>
                <div class="col">
                  <div>{{event.extendedProps.location.title}}</div>
                  <div>{{event.extendedProps.location.address}}</div>
                </div>
              </div>
              <el-divider
                v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                class="my-1"
              />
              <div class="row" v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)">
                <div class="col-auto d-flex align-items-center">
                  <el-icon
                    v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                    :size="15"
                  >
                    <Memo />
                  </el-icon>
                </div>
                <div class="col">
                  {{eventData.extendedProps.note}}
                </div>
              </div>
              <el-divider v-if="tags.length > 0" class="my-1" />
              <template v-if="tags.length > 0">
                <div
                  v-for="tag in tags"
                  :key="tag.id + '_tooltip'"
                  class="row"
                >
                  <div class="col-auto d-flex align-items-center">
                    <span :style="{background: tag.color}" style="width: 15px; height: 15px; display: inline-block; border-radius: 15px; vertical-align: middle;" />
                  </div>
                  <div class="col">
                    {{tag.name}}
                  </div>
                </div>
              </template>
              <el-divider
                class="my-1"
                v-if="isClickable && (event.extendedProps.isAdvance || event.extendedProps.isPenalty || event.extendedProps.isBonus)"
              />
              <template v-if="isClickable">
                <div v-if="event.extendedProps.isAdvance" class="row">
                  <div class="col-auto d-flex align-items-center">
                    <i class='icon-coin-dollar bg-success rounded text-white' style="font-size: 15px;" />
                  </div>
                  <div class="col">
                    {{$t('advance') + ': ' + event.extendedProps.advanceAmount}}
                  </div>
                </div>
                <div v-if="event.extendedProps.isPenalty" class="row">
                  <div class="col-auto d-flex align-items-center">
                    <i class='icon-coin-dollar bg-danger rounded text-white' style="font-size: 15px;" />
                  </div>
                  <div class="col">
                    {{$t('penalty') + ': ' + event.extendedProps.penaltyAmount}}
                  </div>
                </div>
                <div v-if="event.extendedProps.isBonus" class="row">
                  <div class="col-auto d-flex align-items-center">
                    <i class='icon-coin-dollar bg-warning rounded text-white' style="font-size: 15px;" />
                  </div>
                  <div class="col">
                    {{$t('bonus') + ': ' + event.extendedProps.bonusAmount}}
                  </div>
                </div>
              </template>
            </div>
          </template>
          <div
            class="row justify-content-end pr-2"
          >
            <div v-if="event.extendedProps.isAdvance"
                 class="col-auto px-0"
                 style="margin-top: -8px!important;">
              <i class='icon-coin-dollar bg-success rounded' style="font-size: 20px;" />
            </div>
            <div v-if="event.extendedProps.isPenalty"
                 class="col-auto px-0"
                 style="margin-top: -8px!important;">
              <i class='icon-coin-dollar bg-danger rounded' style="font-size: 20px;" />
            </div>
            <div v-if="event.extendedProps.isBonus"
                 class="col-auto px-0"
                 style="margin-top: -8px!important;">
              <i class='icon-coin-dollar bg-warning rounded' style="font-size: 20px;" />
            </div>
          </div>
        </el-tooltip>
        <!-- конец  бонусов/штрафов   -->
        <!-- основной блок   -->
        <div class="row" :class="{'px-2': borderColor.length > 0}">
          <!-- вертикальные полоски   -->
          <div v-if="borderColor.length > 0" class="ml-1" />
          <div
            class="col-auto p-0 m-0 rounded-lg"
            v-for="color in borderColor"
            :key="color"
            :style="{
                'background-color': color,
                'width': '4px'
             }"
          />
          <div class="ml-1" />
          <!-- конец вертикальных полосок   -->
          <div class="col overflow-hidden mx-0 pr-0" :class="{'px-0': borderColor.length > 0}">
            <!-- месячный компактный просмотр   -->
            <template v-if="viewType === 'resourceTimeMonth' && monthDisplay === 'compact'">
              <el-tooltip
                :show-after="openDelay"
                :hide-after="hideDelay"
                effect="light"
                placement="top"
              >
                <template #content>
                  <div style="max-width: 350px">
                    <div class="row">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-info text-primary" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        <div>{{ tooltip }}<br/>{{ tooltipDAC }}</div>
                      </div>
                    </div>
                    <div class="row" v-if="isOverhead && !isUserStatus" >
                      <div class="col-auto d-flex align-items-center">
                        <span class="red-circle-big" />
                      </div>
                      <div class="col">
                        {{warningQuotas}}
                      </div>
                    </div>
                    <div class="row" v-if="require_user_confirm && eventData.extendedProps.confirmed_by_employee">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-check-circle text-success" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        {{confirmedMessage}}
                      </div>
                    </div>
                    <el-divider v-if="event.extendedProps.location" class="my-1" />
                    <div v-if="event.extendedProps.location" class="row">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-map-pin" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        <div>{{event.extendedProps.location.title}}</div>
                        <div>{{event.extendedProps.location.address}}</div>
                      </div>
                    </div>
                    <el-divider
                      v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                      class="my-1"
                    />
                    <div class="row" v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)">
                      <div class="col-auto d-flex align-items-center">
                        <el-icon
                          v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                          :size="15"
                        >
                          <Memo />
                        </el-icon>
                      </div>
                      <div class="col">
                        {{eventData.extendedProps.note}}
                      </div>
                    </div>
                    <el-divider v-if="tags.length > 0" class="my-1" />
                    <template v-if="tags.length > 0">
                      <div
                        v-for="tag in tags"
                        :key="tag.id + '_tooltip'"
                        class="row"
                      >
                        <div class="col-auto d-flex align-items-center">
                          <span :style="{background: tag.color}" style="width: 15px; height: 15px; display: inline-block; border-radius: 15px; vertical-align: middle;" />
                        </div>
                        <div class="col">
                          {{tag.name}}
                        </div>
                      </div>
                    </template>
                    <el-divider
                      class="my-1"
                      v-if="isClickable && (event.extendedProps.isAdvance || event.extendedProps.isPenalty || event.extendedProps.isBonus)"
                    />
                    <template v-if="isClickable">
                      <div v-if="event.extendedProps.isAdvance" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-success rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('advance') + ': ' + event.extendedProps.advanceAmount}}
                        </div>
                      </div>
                      <div v-if="event.extendedProps.isPenalty" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-danger rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('penalty') + ': ' + event.extendedProps.penaltyAmount}}
                        </div>
                      </div>
                      <div v-if="event.extendedProps.isBonus" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-warning rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('bonus') + ': ' + event.extendedProps.bonusAmount}}
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <div class="fc-content text-truncate text-center" :class="{'py-1': tags.length === 0}">
                  <span class="fc-time text-nowrap text-truncate">{{ durationHrs }} </span>
                  <span class="" v-if="eventData.extendedProps.counter > 1">({{ eventData.extendedProps.counter }})</span>
                </div>
              </el-tooltip>
              <!-- дропдаун на месячном компактном просмотре  -->
              <div>
                <el-tooltip
                  effect="light"
                  placement="bottom"
                  :disabled="isMenuDisabled"
                >
                  <template #content>
                    <div>
                      <el-button
                        style="display: flex; justify-content: flex-start;"
                        class="w-100 p-1"
                        @click="handleEdit"
                        data-toggle="modal"
                        data-target="#ClickEventModal"
                        text
                        type="primary"
                      >
                        <div class="el-link">
                          <i class="icon-edit mx-2" /> {{ $t('Edit') }}
                        </div>
                      </el-button>
                    </div>
                    <div>
                      <el-button
                        style="display: flex; justify-content: flex-start;"
                        class="w-100 p-1"
                        @click="handleMoveToFreeboard"
                        v-if="eventData.extendedProps.employee_id !== 0"
                        text
                        type="primary"
                      >
                        <div class="el-link">
                          <i class="icon-repeat mx-2" /> {{$t('SHIFT_TO_FREE_BOARD')}}
                        </div>
                      </el-button>
                    </div>
                    <el-divider class="my-1" />
                    <div>
                      <el-button
                        style="display: flex; justify-content: flex-start;"
                        class="w-100 p-1"
                        @click="handleDeleteShift"
                        text
                        type="primary"
                      >
                        <div class="el-link">
                          <i class="icon-trash text-danger mx-2" /> {{ $t('Delete') }}
                        </div>
                      </el-button>
                    </div>
                  </template>
                  <i class="icon-more-horizontal text-white" style="font-size: 25px" />
                </el-tooltip>
<!--                <el-dropdown>-->
<!--                  <span class="el-dropdown-link">-->
<!--                    <i class="icon-more-horizontal text-white" style="font-size: 25px" />-->
<!--                  </span>-->
<!--                  <template #dropdown>-->
<!--                    <el-dropdown-menu>-->
<!--                      <el-dropdown-item-->
<!--                        @click="handleEdit"-->
<!--                        data-toggle="modal"-->
<!--                        data-target="#ClickEventModal"-->
<!--                      >-->
<!--                        <i class="icon-edit" /> {{ $t('Edit') }}-->
<!--                      </el-dropdown-item>-->
<!--                      <el-dropdown-item-->
<!--                        v-if="eventData.extendedProps.employee_id !== 0"-->
<!--                        @click="handleMoveToFreeboard"-->
<!--                      >-->
<!--                        <i class="icon-repeat" />-->
<!--                        {{$t('SHIFT_TO_FREE_BOARD')}}-->
<!--                      </el-dropdown-item>-->
<!--                      <el-divider class="my-1" />-->
<!--                      <el-dropdown-item-->
<!--                        @click="handleDeleteShift"-->
<!--                      >-->
<!--                        <i class="icon-trash text-danger" /> {{ $t('Delete') }}-->
<!--                      </el-dropdown-item>-->
<!--                    </el-dropdown-menu>-->
<!--                  </template>-->
<!--                </el-dropdown>-->
              </div>
            </template>
            <!-- все виды просмотров, кроме месячного компактного  -->
            <template v-else>
              <el-tooltip
                :show-after="openDelay"
                :hide-after="hideDelay"
                effect="light"
                placement="top"
              >
                <template #content>
                  <div style="max-width: 350px">
                    <div class="row">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-info text-primary" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        <div>{{ tooltip }}<br/>{{ tooltipDAC }}</div>
                      </div>
                    </div>
                    <div class="row" v-if="isOverhead && !isUserStatus" >
                      <div class="col-auto d-flex align-items-center">
                        <span class="red-circle-big" />
                      </div>
                      <div class="col">
                        {{warningQuotas}}
                      </div>
                    </div>
                    <div class="row" v-if="require_user_confirm && eventData.extendedProps.confirmed_by_employee">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-check-circle text-success" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        {{confirmedMessage}}
                      </div>
                    </div>
                    <el-divider v-if="event.extendedProps.location" class="my-1" />
                    <div v-if="event.extendedProps.location" class="row">
                      <div class="col-auto d-flex align-items-center">
                        <i class="icon-map-pin" style="font-size: 15px;" />
                      </div>
                      <div class="col">
                        <div>{{event.extendedProps.location.title}}</div>
                        <div>{{event.extendedProps.location.address}}</div>
                      </div>
                    </div>
                    <el-divider
                      v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                      class="my-1"
                    />
                    <div class="row" v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)">
                      <div class="col-auto d-flex align-items-center">
                        <el-icon
                          v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                          :size="15"
                        >
                          <Memo />
                        </el-icon>
                      </div>
                      <div class="col">
                        {{eventData.extendedProps.note}}
                      </div>
                    </div>
                    <el-divider v-if="tags.length > 0" class="my-1" />
                    <template v-if="tags.length > 0">
                      <div
                        v-for="tag in tags"
                        :key="tag.id + '_tooltip'"
                        class="row"
                      >
                        <div class="col-auto d-flex align-items-center">
                          <span :style="{background: tag.color}" style="width: 15px; height: 15px; display: inline-block; border-radius: 15px; vertical-align: middle;" />
                        </div>
                        <div class="col">
                          {{tag.name}}
                        </div>
                      </div>
                    </template>
                    <el-divider
                      class="my-1"
                      v-if="isClickable && (event.extendedProps.isAdvance || event.extendedProps.isPenalty || event.extendedProps.isBonus)"
                    />
                    <template v-if="isClickable">
                      <div v-if="event.extendedProps.isAdvance" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-success rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('advance') + ': ' + event.extendedProps.advanceAmount}}
                        </div>
                      </div>
                      <div v-if="event.extendedProps.isPenalty" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-danger rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('penalty') + ': ' + event.extendedProps.penaltyAmount}}
                        </div>
                      </div>
                      <div v-if="event.extendedProps.isBonus" class="row">
                        <div class="col-auto d-flex align-items-center">
                          <i class='icon-coin-dollar bg-warning rounded text-white' style="font-size: 15px;" />
                        </div>
                        <div class="col">
                          {{$t('bonus') + ': ' + event.extendedProps.bonusAmount}}
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <div class="fc-content py-1 text-truncate">
                <!--      <i v-if="isArrowLeft" class="icon-arrow-left"></i>-->
                <div>
                  <div>
                    <span v-if="isOverhead && !isUserStatus" class="red-circle"></span>
                    <span class="ml-1" v-if="require_user_confirm && eventData.extendedProps.confirmed_by_employee">
                      <i class="icon-check-circle" />
                    </span>
                    <span v-if="eventData.extendedProps.showTime" class="fc-time ml-1 text-nowrap text-truncate">
                      {{ start.format(timeFormat) }} - {{ end.format(timeFormat) }}
                    </span>
                    <span class="ml-1" v-if="eventData.extendedProps.counter > 1">({{ eventData.extendedProps.counter }})</span>
                    <span class="ml-1 overflow-hidden">{{ eventData.title }}</span>
                    <el-icon
                      v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                      :size="12"
                      class="mr-1">
                      <Memo/>
                    </el-icon>
                    <template v-if="event.extendedProps.location">
                      <i class="icon-map-pin" />
                      <span class="mx-1">{{event.extendedProps.location.title}}</span>
                    </template>
                  </div>
                  <div v-if="isTwoDays && lang === 'he'"
                       style="position: absolute; left: 0px; top: 3px;"
                       :style="{'background-color': eventData.backgroundColor}"
                  >
                    <i class="icon-arrow-left"></i>
                  </div>
                  <div v-if="isTwoDays && lang !== 'he'"
                       style="position: absolute; right: 0px; top: 3px;"
                       :style="{'background-color': eventData.backgroundColor}"
                  >
                    <i class="icon-arrow-right"></i>
                  </div>
                </div>
                <div class="ml-1" v-if="tags.length > 0">
                  <span v-for="tag in tags"
                        :key="tag.id"
                        class="mr-1"
                        :style="{background: tag.color}"
                        style="width: 15px; height: 15px; display: inline-block; border-radius: 25px; vertical-align: middle;" />
                </div>
                <!--      <i v-if="isArrowRight" class="icon-arrow-left"></i>-->
              </div>
              </el-tooltip>
            </template>
          </div>
          <!-- допдаун меню, кроме месячного компактного просмотра   -->
          <div class="col-auto d-flex align-items-center ml-0 pl-0" v-if="isClickable && (viewType !== 'resourceTimeMonth' || monthDisplay !== 'compact')">
            <el-tooltip
              effect="light"
              placement="bottom"
              :disabled="isMenuDisabled"
            >
              <template #content>
                <div>
                  <el-button
                    style="display: flex; justify-content: flex-start;"
                    class="w-100 p-1"
                    @click="handleEdit"
                    data-toggle="modal"
                    data-target="#ClickEventModal"
                    text
                    type="primary"
                  >
                    <div class="el-link">
                      <i class="icon-edit mx-2" /> {{ $t('Edit') }}
                    </div>
                  </el-button>
                </div>
                <div>
                  <el-button
                    style="display: flex; justify-content: flex-start;"
                    class="w-100 p-1"
                    @click="handleMoveToFreeboard"
                    v-if="eventData.extendedProps.employee_id !== 0"
                    text
                    type="primary"
                  >
                    <div class="el-link">
                      <i class="icon-repeat mx-2" /> {{$t('SHIFT_TO_FREE_BOARD')}}
                    </div>
                  </el-button>
                </div>
                <el-divider class="my-1" />
                <div>
                  <el-button
                    style="display: flex; justify-content: flex-start;"
                    class="w-100 p-1"
                    @click="handleDeleteShift"
                    text
                    type="primary"
                  >
                    <div class="el-link">
                      <i class="icon-trash text-danger mx-2" /> {{ $t('Delete') }}
                    </div>
                  </el-button>
                </div>
              </template>
              <i class="icon-more-vertical text-white" style="font-size: 25px" />
            </el-tooltip>
<!--            <el-dropdown>-->
<!--              <div class="el-dropdown-link">-->
<!--                <i class="icon-more-vertical text-white" style="font-size: 25px" />-->
<!--              </div>-->
<!--              <template #dropdown>-->
<!--                <el-dropdown-menu>-->
<!--                  <el-dropdown-item-->
<!--                    @click="handleEdit"-->
<!--                    data-toggle="modal"-->
<!--                    data-target="#ClickEventModal"-->
<!--                  >-->
<!--                    <i class="icon-edit" /> {{ $t('Edit') }}-->
<!--                  </el-dropdown-item>-->
<!--                  <el-dropdown-item-->
<!--                    @click="handleMoveToFreeboard"-->
<!--                    v-if="eventData.extendedProps.employee_id !== 0"-->
<!--                  >-->
<!--                    <i class="icon-repeat" /> {{$t('SHIFT_TO_FREE_BOARD')}}-->
<!--                  </el-dropdown-item>-->
<!--                  <el-divider class="my-1" />-->
<!--                  <el-dropdown-item-->
<!--                    @click="handleDeleteShift"-->
<!--                  >-->
<!--                    <i class="icon-trash text-danger" /> {{ $t('Delete') }}-->
<!--                  </el-dropdown-item>-->
<!--                </el-dropdown-menu>-->
<!--              </template>-->
<!--            </el-dropdown>-->
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ShiftComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 1000,
      hideDelay: 0,
      tags: [],
      markTemplate: '',
      tooltipEnable: true,
      isMenuDisabled: false
    }
  },
  props: {
    event: Object,
    marks: Array,
    activeStart: Date,
    activeEnd: Date,
    dayQuotas: String,
    warningQuotas: String,
    isUserStatus: Boolean,
    view: Object,
    require_user_confirm: Boolean,
    confirmedMessage: String,
    unconfirmedMessage: String,
    monthDisplay: String,
    currentEmployeeId: Number,
    isManager: Boolean,
    isAdmin: Boolean
  },
  created () {
    this.updateTags(this.event.extendedProps.marks)
  },
  computed: {
    borderColor () {
      let statuses = []
      let statusColors = {
        // 'new': '#065535',
        'late': '#ffa500',
        'early': '#8c1888',
        'absent': '#7D7A79',
        'started': '#ffff00',
        'finished': '#00ff00'
      }
      if (this.event.extendedProps.isShift && !this.event.extendedProps.freeBoardKey) {
        if (this.event.extendedProps.presence_info) {
          if (this.event.extendedProps.presence_info.lateness && this.event.extendedProps.presence_info.lateness > 0) {
            statuses.push(statusColors['late'])
          }
          if (this.event.extendedProps.presence_info.early && this.event.extendedProps.presence_info.early > 0) {
            statuses.push(statusColors['early'])
          }
          if (this.event.extendedProps.presence_info.absence) {
            statuses.push(statusColors['absent'])
          }
        }
        // console.log(12, this.event.extendedProps)
        if (this.event.extendedProps.status_info) {
          if (this.event.extendedProps.status_info.type === 'shift_start') {
            statuses.push(statusColors['started'])
          }
          if (this.event.extendedProps.status_info.type === 'shift_end') {
            statuses.push(statusColors['finished'])
          }
        }
      }
      return statuses
    },
    isClickable () {
      if (this.isAdmin || this.isManager) {
        return true
      }
      if (this.event.extendedProps.employee_id === 0) {
        return true
      }
      if (+this.currentEmployeeId === +this.event.extendedProps.employee_id) {
        return true
      }
      return false
    },
    timeFormat () {
      return this.localeTimeFormat
    },
    viewType () {
      return this.view.type
    },
    eventData () {
      return this.event
    },
    start () {
      return moment(this.eventData.start)
    },
    end () {
      return moment(this.event.end)
    },
    durationHrs () {
      const duration = this.eventData.extendedProps.shiftDuration / 60
      return Math.round(duration * 100) / 100
    },
    isOverhead () {
      // console.log(this.event)
      if (!+this.dayQuotas) return false
      const diff = moment.duration(this.end.diff(this.start)).asHours() - +this.event.extendedProps.break_time / 60
      return diff > +this.dayQuotas
    },
    tooltip () {
      // console.log(123, this.eventData.extendedProps.template_name)
      return this.eventData.title + ' ' + this.eventData.extendedProps.template_name[0] + ' ' + this.eventData.extendedProps.template_name[1] + ' ' + this.start.format(this.timeFormat) + ' - ' + this.end.format(this.timeFormat)
    },
    tooltipDAC () {
      return this.eventData.extendedProps.is_double_account ? 'double account: ' + this.eventData.title : ''
    },
    /** Проверяем, есть ли у шифта переход между датами (00:00 - 00:00 считается одним днем, если длительность шифта = 1 день) */
    isSameDay () {
      return moment(this.start.format(this.backendDateFormat), this.backendDateFormat).isSame(moment(this.end.format(this.backendDateFormat), this.backendDateFormat), 'day') ||
        (this.start.format(this.backendTimeFormat) === this.end.format(this.backendTimeFormat) &&
          this.start.format(this.backendTimeFormat) === '00:00' &&
          this.start.diff(this.end, 'days') === -1)
    },
    isTwoDays () {
      // console.log(123, this.lang)
      return (this.view.type === 'resourceTimeMonth' || this.view.type === 'resourceTimelineWeek') &&
        !this.isSameDay
    }
  },
  methods: {
    handleEdit () {
      this.isMenuDisabled = true
      setTimeout(_ => {
        this.isMenuDisabled = false
      }, 200)
      const payload = {
        event: this.event
      }
      this.$emit('clickEventModal', payload)
    },
    handleDeleteShift () {
      this.isMenuDisabled = true
      setTimeout(_ => {
        this.isMenuDisabled = false
      }, 200)
      this.$emit('deleteShift', this.eventData.extendedProps.shift_id)
    },
    handleMoveToFreeboard () {
      this.isMenuDisabled = true
      setTimeout(_ => {
        this.isMenuDisabled = false
      }, 200)
      this.$emit('moveToFreeboard', this.eventData.extendedProps.shift_id)
    },
    setView (view) {
      // eslint-disable-next-line vue/no-mutating-props
      this.view = view
    },
    mark () {
      let mark = ''
      this.tags.forEach(tag => {
        mark += `<div class="mr-1" style="background: ${tag.color}; width: 15px; height: 15px; display: inline-block; border-radius: 25px; vertical-align: middle;"></div>${tag.name}<br>`
      })
      this.markTemplate = mark
    },
    updateTags (markIds) {
      this.tags = []
      if (Array.isArray(markIds)) {
        for (const value of markIds) {
          for (const mark of this.marks) {
            if (mark.id === value) this.tags.push(mark)
          }
        }
      }
      this.mark()
    }
  }
}
</script>

<style scoped>
  .confirmed {
    z-index: 100;
    position: absolute; /* Абсолютное позиционирование */
    top: -8px; /* Положение от верхнего края */
    right: 6px; /* Положение от правого края */
  }
  .red-circle {
    background: red;
    border: 1px solid white;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .red-circle-big {
    background: red;
    border: 1px solid white;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  /*.project-color {*/
    /*width: 20px;*/
    /*height: 20px;*/
    /*display: inline-block;*/
    /*border-radius: 25px;*/
    /*vertical-align: middle;*/
  /*}*/
  .popper {
    max-width: 150px;
  }
</style>
