<template>
  <div>
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t('USER_SALARY_TITLE') }}</span>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 d-flex flex-column form-group">
            <div class="mt-auto">
              <ui-input-label>{{ $t('Select a date range') }}</ui-input-label>
              <div class="row py-0">
                <div class="col-lg-6">
                  <ui-date-picker
                      name="from_date"
                      v-model="period[0]"
                      :validation="{required: true}"
                      :clearable="false"
                      @change="changeRange('from')"
                  />
                </div>
                <div class="col-lg-6">
                  <ui-date-picker
                      name="to_date"
                      v-model="period[1]"
                      :validation="{required: true}"
                      :clearable="false"
                      @change="changeRange('to')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column form-group">
          </div>
        </div>
        <div v-if="loadedData" class="row">
          <div class="col-12 mt-1">
            <div class="card flex-fill shadow-none">
              <div class="card-header font-weight-bold">
                {{ $t("SALARY_TOTAL").toUpperCase() }}
              </div>
              <div class="card-body p-1 table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th class="text-center">{{$t('TOTAL_TIME_SPEND')}}</th>
                    <th class="text-center">{{$t('PAY_RATE')}}</th>
                    <th class="text-center">{{$t('TOTAL_MONEY_EARN')}}</th>
                    <th class="text-center">{{$t('BONUS')}}</th>
                    <th class="text-center">{{$t('PENALTY')}}</th>
                    <th class="text-center">{{$t('TOTAL')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="w-100" style="height: 67px">
                    <td class="text-center">
                      {{ roundValue(loadedData.total_time / 60) }} <br> ({{ hrsForHuman(loadedData.total_time / 60.0 + Number.EPSILON) }})
                    </td>
                    <td class="text-center">
                      {{rateFormatted(loadedData.shift_rates)}}
                    </td>
                    <td class="text-center">{{roundValue(loadedData.total_cost_without_bonuses)}}</td>
                    <td class="text-center">{{roundValue(loadedData.bonus)}}</td>
                    <td class="text-center">{{roundValue(loadedData.penalty)}}</td>
                    <td class="text-center">{{roundValue(loadedData.total_cost_without_bonuses + loadedData.bonus - loadedData.penalty)}}</td>
                  </tr>
                  </tbody>
                </table>
                <el-divider />
                <div class="row">
                  <div class="col-md-6">
                    <div class="card flex-fill shadow-none">
                      <div class="card-header font-weight-bold">
                        {{ $t("INCLUDING_DAY_SHIFTS").toUpperCase() }}
                      </div>
                      <div class="card-body p-1 table-responsive">
                        <table class="table table-hover">
                          <thead>
                          <tr>
                            <th class="text-center">{{$t('RATE_MULTIPLAYER')}}</th>
                            <th class="text-center">{{$t('DAY_TIME')}}</th>
                            <th class="text-center">{{$t('DAY_AMOUNT')}}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(chunk, key) in loadedData.day.chunks" :key="'day_' + key" class="w-100" style="height: 67px">
                            <td class="text-center">
                              {{ key }}
                            </td>
                            <td class="text-center">
                              {{ roundValue(chunk.time / 60) }} <br> ({{ hrsForHuman(chunk.time / 60.0 + Number.EPSILON) }})
                            </td>
                            <td class="text-center">{{roundValue(chunk.cost)}}</td>
                          </tr>
                          <tr>
                            <td class="text-center font-weight-bold">
                              {{$t('Total')}}
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ roundValue(loadedData.day.total_time / 60) }} <br> ({{ hrsForHuman(loadedData.day.total_time / 60.0 + Number.EPSILON) }})
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ roundValue(loadedData.day.total_cost) }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card flex-fill shadow-none">
                      <div class="card-header font-weight-bold">
                        {{ $t("INCLUDING_NIGHT_SHIFTS").toUpperCase() }}
                      </div>
                      <div class="card-body p-1 table-responsive">
                        <table class="table table-hover">
                          <thead>
                          <tr>
                            <th class="text-center">{{$t('RATE_MULTIPLAYER')}}</th>
                            <th class="text-center">{{$t('NIGHT_TIME')}}</th>
                            <th class="text-center">{{$t('NIGHT_AMOUNT')}}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(chunk, key) in loadedData.night.chunks" :key="'night_' + key" class="w-100" style="height: 67px">
                            <td class="text-center">
                              {{ key }}
                            </td>
                            <td class="text-center">
                              {{ roundValue(chunk.time / 60) }} <br> ({{ hrsForHuman(chunk.time / 60.0 + Number.EPSILON) }})
                            </td>
                            <td class="text-center">{{roundValue(chunk.cost)}}</td>
                          </tr>
                          <tr>
                            <td class="text-center font-weight-bold">
                              {{$t('Total')}}
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ roundValue(loadedData.night.total_time / 60) }} <br> ({{ hrsForHuman(loadedData.night.total_time / 60.0 + Number.EPSILON) }})
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ roundValue(loadedData.night.total_cost) }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-1">
            <div class="card flex-fill shadow-none">
              <div class="card-header font-weight-bold">
                {{ $t("BONUS_PENALTY_DETAILS").toUpperCase() }}
              </div>
              <div class="card-body p-1 table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th class="text-center">{{$t('BONUS_TYPE')}}</th>
                    <th class="text-center">{{$t('BONUS_AMOUNT')}}</th>
                    <th class="text-center">{{$t('BONUS_COMMENT')}}</th>
                    <th class="text-center">{{$t('BONUS_DATE')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="loadedData.bonuses_info.length > 0">
                    <tr v-for="bonus in loadedData.bonuses_info" :key="bonus.id" class="w-100" style="height: 67px">
                      <td class="text-center">
                        {{$t(bonus.type)}}
                      </td>
                      <td class="text-center">
                        {{roundValue(bonus.amount)}}
                      </td>
                      <td class="text-left">
                        {{bonus.comment}}
                      </td>
                      <td class="text-center">
                        {{datetimeToDate(bonus.shift_date)}}
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <template v-if="loadedData.bonuses_info.length === 0">
                  <div class="text-center font-weight-bold w-100 mb-2">
                    {{$t('NO_BONUSES_AVAILABLE')}}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import paginationMixin from '@/components/CommonComponents/Pagination/mixinPagination'

export default {
  mixins: [errorMixin, momentMixin, paginationMixin],
  name: 'Salary',
  data () {
    return {
      period: [moment().add(-7, 'Days').format(this.backendDateFormat), moment().format(this.backendDateFormat)],
      loadedData: null
    }
  },
  created () {
    if (!this.showSalary) {
      this.accessErrorRedirect('You don\'t have permission for this action')
    }
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      company: 'company'
    }),
    showSalary () {
      if (this.company) {
        return this.company.salary.show_salary_to_users &&
          !this.company.is_blocked
          // this.company.modules.filter(module => module.modules.includes('4')).length > 0
      }
      return false
    },
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    changeRange (val) {
      // console.log(val)
      if (val === 'from') {
        if (moment(this.period[0]).isAfter(moment(this.period[1])) || moment.utc(this.period[1]).diff(this.period[0], 'days') > 93) {
          this.period[1] = this.period[0]
        }
      }
      if (val === 'to') {
        if (moment(this.period[1]).isBefore(moment(this.period[0]))) {
          this.period[0] = this.period[1]
        }
      }
      this.getTableData()
    },
    getTableData () {
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      const data = '?date_from=' + dateFrom + '&date_to=' + dateTo
      this.$store.dispatch('getUserPayRates', [this.$store.getters.companyId, data]).then((response) => {
        // console.log(response)
        this.loadedData = response
      }).catch(() => {
      })
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    rateFormatted (val) {
      const v = val.map(item => Math.round(item * 100) / 100)
      return v.join('; ')
    },
    datetimeToDate (val) {
      return this.fromZoneToZone(val, 'UTC', this.tz).format(this.localeDateFormat)
    }
  },
  watch: {}
}
</script>
<style></style>
