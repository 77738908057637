<template>
  <div>
    <bonus-penalty-modal
      v-if="importBonusPenaltyShow"
      @closeBonusPenaltyModal="() => this.importBonusPenaltyShow = false"
    />

    <clone-shifts-modal
      v-if="copyEventModalShow"
      :schedules="currentProject.schedulesByProject"
      :templates="selectedTemplates"
      :projectId="currentProjectId"
      :resources="filteredResources"
      :filter="filter"
      :hideEmpty="!userSwitcher.showAllUsers"
      :start="viewStart"
      :end="viewEnd"
      @copyShifts="() => this.copyEventModalShow = false"
    >
    </clone-shifts-modal>

    <user-edit-modal-component
      v-if="editUserModalShow && editUserId"
      :userId="editUserId"
      @hideUserEditModal="hideUserEditModal"
    />

    <distribute-free-board-modal
      v-if="distributeFreeBoardModalShow"
      :schedules="schedulesById"
      :start="viewStart"
      :end="viewEnd"
      @closeModal="() => this.distributeFreeBoardModalShow = false"
      @closeDistributeFreeBoardModal="() => this.distributeFreeBoardModalShow = false"
      @distributeFreeBoard="distributeFreeBoard"
    />

    <add-user-modal
      v-if="addUserModalShow"
      @refetchResources="refetchResources"
      :projectId="currentProjectId"
      @AddUser="() => this.addUserModalShow = false"
    />

    <click-emergency-shifts-notification-modal
        v-if="emergencyShiftsModalShow"
        :projectId="currentProjectId"
        @closeModal="() => this.emergencyShiftsModalShow = false"
        @hideEmergencyShiftsModalShow = "() => this.emergencyShiftsModalShow = false"
    />

    <add-template-modal
      v-if="addTemplateModalShow"
      :projectId="currentProjectId"
      :schedules="schedulesById"
      @AddTemplate="() => this.addTemplateModalShow = false"
    />

    <mass-action-shift-modal
      v-if="massShiftActionsShow"
      :users="usersBySchedule"
      :filteredResources="filteredResources"
      :filteredTemplates="selectedTemplates"
      :projectId="currentProjectId"
      :filter="filter"
      :templatesByProject="currentProject.templatesByProject"
      :start="viewStart"
      :end="viewEnd"
      @ShiftMassAction="() => this.massShiftActionsShow = false"
    />

    <div class="row mb-2" v-show="hideCalendar === false && isPreview">
      <div class="col text-center">
        <button type="submit" @click="rewertDistribute" class="btn btn-primary btn-sm mx-2">{{$t('REWERT_DISTRIBUTE')}}</button>
        <button type="submit" @click="confirmDistribute" class="btn btn-success btn-sm mx-2">{{$t('CONFIRM_DISTRIBUTE')}}</button>
      </div>
    </div>
    <div class="row mb-2" v-show="hideCalendar === false && !isPreview">
      <div class="col-12 col-lg-8">
        <div class="d-flex">
          <i v-if="lang !== 'he'" class="icon-arrow-right-circle btn-icon text-primary mx-1 align-bottom"
             v-show="!isOpen"
             @click="toggleMenu"></i>
          <i v-if="lang === 'he'" class="icon-arrow-left-circle btn-icon text-primary mx-1 align-bottom"
             v-show="!isOpen"
             @click="toggleMenu">
          </i>
          <el-tooltip
            v-if="isEmployeeHasPermission('create-shift-admin') ||
           rolesByProjects[currentProjectId] === 'manager'"
            class="item" effect="light" :content="$t('Copy shifts')" placement="top">
            <i
               class="icon-copy btn-icon text-primary mx-1 align-bottom" data-toggle="modal" data-target="#CopyShifts"
               @click="toggleCopyEventModalShow"></i>
          </el-tooltip>
          <el-tooltip
            v-if="(isEmployeeHasPermission('get-company-schedules-admin') ||
            rolesByProjects[currentProjectId] === 'manager')"
            class="item" effect="light" :content="$t('Export schedule')" placement="top">
            <div class="dropdown">
              <i class="icon-download btn-icon text-primary mx-1 align-bottom" type="button" id="dropdownMenuButton"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a v-if="viewType !== 'resourceTimelineMonth'" class="dropdown-item" type="button"
                   @click="exportSchedule('xlsx', view.type)">{{ $t('Xlsx') }}</a>
                <a class="dropdown-item" type="button" @click="exportSchedule('xlsx', 'month')">{{ $t('Xlsx Month') }}</a>
                <a class="dropdown-item" type="button" v-if="viewSwitcher.showByUsers" @click="exportSchedule('xlsx_without_template_names', 'month')">{{ $t('xlsx_month_without_template_names') }}</a>
                <a v-if="['resourceTimelineDay', 'resourceTimeline3days', 'resourceTimelineWeek'].includes(viewType)"
                   class="dropdown-item" type="button" id="" @click="exportSchedule('pdf', view.type)">{{ $t('Pdf') }}</a>
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="(isEmployeeHasPermission('get-bonuses-by-company-admin') || rolesByProjects[currentProjectId] === 'manager')"
            class="item" effect="light" :content="$t('Import')" placement="top">
            <div>
              <i class="icon-upload btn-icon text-primary mx-1 align-bottom" type="button" data-toggle="modal" data-target="#bonusPenaltyModal" @click="toggleImportBonusPenaltyShow"></i>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="isEmployeeHasPermission('get-employee-admin') || rolesByProjects[currentProjectId] === 'manager'"
            class="item" effect="light" :content="$t('Add new Employee')" placement="top">
            <i class="icon-user-plus btn-icon text-primary mx-1 align-bottom"
               data-cy="add-employees-fc"
               data-toggle="modal"
               data-target="#AddUser"
               @click="toggleAddUserModalShow" />
          </el-tooltip>
          <el-tooltip
            v-if="isEmployeeHasPermission('get-company-schedules-admin') || rolesByProjects[currentProjectId] === 'manager'"
            class="item" effect="light" :content="$t('ADD_TEMPLATE_MODAL')" placement="top">
            <i class="icon-calendar1 btn-icon text-primary mx-1 font-weight-bold align-bottom"
               style="font-size: 18px;"
               data-toggle="modal"
               data-target="#AddTemplate"
               @click="toggleAddTemplateModalShow" />
          </el-tooltip>
          <el-tooltip
            v-if="isEmployeeHasPermission('update-shift-admin') || rolesByProjects[currentProjectId] === 'manager'"
            class="item" effect="light" :content="$t('SHIFT_MASS_ACTION_MODAL')" placement="top">
            <i class="icon-edit-3 btn-icon text-primary mx-1 align-bottom"
               data-toggle="modal"
               data-target="#ShiftMassActionModal"
               @click="toggleMassShiftActionsShow" />
          </el-tooltip>
          <div class="dropdown">
            <i class="icon-repeat btn-icon text-primary mx-1 align-bottom" type="button" id="dropdownMenuButton1"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <a class="dropdown-item" type="button"
                 @click="() => {
                this.viewSwitcher.showByUsers = true
                this.$eventBus.emit('changeViewMode')
              }">
                <span :style="{color: this.viewSwitcher.showByUsers === true ? '#409EFF' : '#303133'}">
                   {{this.viewSwitcher.inactiveText}}
                </span>
              </a>
              <a class="dropdown-item" type="button"
                 @click="() => {
                this.viewSwitcher.showByUsers = false
                this.$eventBus.emit('changeViewMode')
              }">
                <span :style="{color: this.viewSwitcher.showByUsers === false ? '#409EFF' : '#303133'}">
                  {{this.viewSwitcher.activeText}}
                </span>
              </a>
            </div>
          </div>
          <template v-if="showTZModeSelect">
            <div class="dropdown">
              <span class="text-primary align-bottom" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{this.actualTZMode === 'employeeTZ' ?
                this.$i18n?.t('EMPLOYEE_TZ') + ' (' + this.$store.getters.profileTimeZone + ')' :
                this.$i18n?.t('PROJECT_TZ') + ' (' + (this.$store.getters.projectById(this.$store.getters.currentProjectId) ? this.$store.getters.projectById(this.$store.getters.currentProjectId).time_zone : this.$i18n?.t('none')) + ')'}}
                  <ui-hint :placement="'top'" :content="$t('TZ_CALENDAR_HINT')" />
              </span>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <a class="dropdown-item" type="button"
                   @click="() => {this.actualTZMode = 'employeeTZ'}">
                <span :style="{color: this.actualTZMode === 'employeeTZ' ? '#409EFF' : '#303133'}">
                  {{this.$i18n?.t('EMPLOYEE_TZ') + ' (' + this.$store.getters.profileTimeZone + ')'}}
                </span>
                </a>
                <a class="dropdown-item" type="button"
                   @click="() => {this.actualTZMode = 'projectTZ'}">
                <span :style="{color: this.actualTZMode === 'projectTZ' ? '#409EFF' : '#303133'}">
                  {{this.$i18n?.t('PROJECT_TZ') + ' (' + (this.$store.getters.projectById(this.$store.getters.currentProjectId) ? this.$store.getters.projectById(this.$store.getters.currentProjectId).time_zone : this.$i18n?.t('none')) + ')'}}
                </span>
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 col-lg-4 d-flex justify-content-end align-items-center">
        <div class="d-flex">
          <div class="mx-2">
            <div v-if="viewSwitch" class="dropdown">
              <span class="text-primary align-bottom" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <el-tooltip class="item" effect="light" placement="top" :content="$t('CALENDAR_USER_MODE_SWITCHER_HINT')">
                    <i class="icon-users btn-icon text-primary mx-1 align-top" />
                </el-tooltip>
              </span>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton3">
                <a class="dropdown-item" type="button" @click="() => {this.userSwitcher.showAllUsers = true}">
                 <span :style="{color: this.userSwitcher.showAllUsers === true ? '#409EFF' : '#303133'}">
                  {{this.userSwitcher.activeText}}
                  </span>
                </a>
                <a class="dropdown-item" type="button" @click="() => {this.userSwitcher.showAllUsers = false}">
                <span :style="{color: this.userSwitcher.showAllUsers === false ? '#409EFF' : '#303133'}">
                  {{this.userSwitcher.inactiveText}}
                </span>
                </a>
              </div>
            </div>
          </div>
          <div id="viewSettingsDropdown" class="dropdown">
            <i id="viewSettings" class="icon-settings btn-icon text-primary mx-1 align-bottom" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div id="viewSettingsDropdownMenu"
                 class="dropdown-menu dropdown-menu-right"
                 aria-labelledby="viewSettings"
                 style="z-index: 1000;overflow-y:auto; min-width: 600px">
              <form class="px-2" style="min-width: 600px">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="py-1 text-primary">{{$t('DISPLAY_MODE')}}</div>
                      <div>
                        <ui-radio
                          name="displayMode"
                          :label="$t('FULL_MODE')"
                          v-model="monthDisplay"
                          :set-value="'full'"
                        />
                      </div>
                      <div>
                        <ui-radio
                          name="displayMode"
                          :label="$t('COMPACT_MODE')"
                          v-model="monthDisplay"
                          :set-value="'compact'"
                        />
                      </div>
                      <div class="py-1 text-primary">{{$t('DISPLAY_MODE_DAY')}}</div>
                      <div>
                        <ui-radio
                          name="displayModeBreaks"
                          :label="$t('FULL_MODE_BREAKS')"
                          v-model="dayDisplay"
                          :set-value="'extended'"
                        />
                      </div>
                      <div>
                        <ui-radio
                          name="displayModeBreaks"
                          :label="$t('COMPACT_MODE_BREAKS')"
                          v-model="dayDisplay"
                          :set-value="'normal'"
                        />
                      </div>
                      <div class="py-1 text-primary">{{$t('CALENDAR_MAX_MIN_TIME_DAY')}}</div>
                      <div class="px-3">
                        <el-slider
                          v-model="maxMinTime"
                          range
                          show-stops
                          show-tooltip
                          :format-tooltip="formatTooltip"
                          :marks="marksCalendarTimeRange"
                          :min="0"
                          :max="24">
                        </el-slider>
                      </div>
                      <div class="py-1 text-primary mt-2">{{$t('TEMPLATE_SHOW_ORDER')}}</div>
                      <div class="py-1">
                        <ui-select
                          id="templateSortOrder"
                          v-model="templateSortOrder"
                          name="templateSortOrder"
                          :clearable="false"
                          :filterable="false"
                          class="w-100"
                          :options="sortTemplateChoices"
                          :key-name="'value'"
                          :label-name="'label'"
                          :value-name="'value'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <template v-if="isEmployeeHasPermission('get-dashboard-project-users')">
                        <div class="py-1 text-primary">{{$t('DISPLAY_DAY_STAT_MODE')}}</div>
                        <div>
                          <ui-radio
                            name="displayStatMode"
                            :label="$t('GRAPH_MODE')"
                            v-model="presenceStatMode"
                            :set-value="'graph'"
                          />
                        </div>
                        <div>
                          <ui-radio
                            name="displayStatMode"
                            :label="$t('TEXT_MODE')"
                            v-model="presenceStatMode"
                            :set-value="'text'"
                          />
                        </div>
                      </template>
                      <div class="py-1 text-primary">{{$t('RESOURCE_SHOW_MODE')}}</div>
                      <div class="py-2">
                        <ui-checkbox
                          name="SHOW_POSITION_LABEL"
                          v-model="showPosition"
                        >
                          <span class="text-wrap">{{ $t('SHOW_POSITION_LABEL') }}</span>
                        </ui-checkbox>
                        <ui-checkbox
                          name="SHOW_IDENTIFIER_LABEL"
                          v-model="showIdentifier"
                        >
                          <span class="text-wrap">{{ $t('SHOW_IDENTIFIER_LABEL') }}</span>
                        </ui-checkbox>
                        <ui-select
                          id="primary_sort_order"
                          v-model="primarySortOrder"
                          name="primary_sort_order"
                          :clearable="false"
                          :filterable="false"
                          class="w-100"
                          :options="sortChoices"
                          :key-name="'value'"
                          :label-name="'label'"
                          :value-name="'value'"
                        />
                        <div class="py-1 text-primary">{{$t('NAME_FORMAT')}}</div>
                        <div class="py-1">
                          <div>
                            <ui-radio
                              name="NAME_FORMAT"
                              :label="$t('LAST_NAME_FIRST_NAME_FORMAT')"
                              v-model="nameFormat"
                              :set-value="'last_first'"
                            />
                          </div>
                          <div>
                            <ui-radio
                              name="NAME_FORMAT"
                              :label="$t('FIRST_NAME_LAST_NAME_FORMAT')"
                              v-model="nameFormat"
                              :set-value="'first_last'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" @click="updateDisplayMode" class="btn btn-outline-primary btn-sm">{{$t('Apply')}}</button>
              </form>
              <div class="dropdown-divider"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2" v-show="hideCalendar === true">
      <div class="col">
        <h5 class="text-center">
          <span class="text-uppercase">{{ $t('You don’t currently have any schedules.') }}</span>
        </h5>
      </div>
    </div>
    <div class="row" v-show="hideCalendar === false">
      <div ref="sizing" class="col" v-loading.fullscreen.lock="loading">
        <resize-observer @notify="handleResize"/>
        <full-calendar v-if="calendarOptions" ref="scheduleCalendar" :options="calendarOptions">
          <template v-slot:resourceLabelContent='arg'>
            <!-- Лоадер при подгрузке юзерво -->
            <div v-if="arg.resource.id === 'loaderResource'">
              <div
                class="h-100 p-3 btn-icon"
                element-loading-spinner="el-icon-loading"
                v-loading="true">
              </div>
              <visibility-observer/>
            </div>
            <!-- Тайтл ресурса -->
            <span v-if="arg.resource.id !== 'info_event'" class="h-auto">
                <template v-if="viewSwitch
                 && arg.resource.id !== 'break_events'
                 && arg.resource.id !== 'info_event'
                 && arg.resource.id !== 'holidays'
                 && +arg.resource.id !== 0">
                  <div v-if="showIdentifier" class="small">
                    {{arg.resource.extendedProps.identifier}}
                  </div>
                  <div v-if="showPosition" class="small">
                    {{arg.resource.extendedProps.position}}
                  </div>
                </template>
                <span
                  class="font-weight-bold mx-1"
                  :style="{ 'color': arg.resource.extendedProps.color ? arg.resource.extendedProps.color : false }">
                  {{arg.resource.title}}
                </span>
                <div
                  v-if="!viewSwitch
                  && arg.resource.id !== 'custom_events'
                  && arg.resource.id !== 'holidays'
                  && +arg.resource.id !== 0"
                  class="font-weight-bold">
                  {{ setTimeFormat(arg.resource.extendedProps.time_from) }} - {{ setTimeFormat(arg.resource.extendedProps.time_to)}}
                </div>
              <template v-if="viewSwitch
                 && (isEmployeeHasPermission('get-shifts-admin') || rolesByProjects[this.currentProjectId] === 'manager')
                 && arg.resource.id !== 'break_events'
                 && arg.resource.id !== 'info_event'
                 && arg.resource.id !== 'holidays'
                 && +arg.resource.id !== 0">
                <ResourceInfoIcon :schedules="userSchedule[+arg.resource.id]" />
              </template>
                <UserNotesComponent
                  v-if="arg.resource.id !== 'break_events'
                   && arg.resource.id !== 'info_event'
                   && arg.resource.id !== 'holidays'
                   && +arg.resource.id !== 0"
                  :userId="+arg.resource.id" />
            </span>
            <template v-if="isEmployeeHasPermission('get-project-schedules-admin') ||
               rolesByProjects[currentProjectId] === 'manager'">
              <!-- Инфоресурс, отображается на любом типе просмотра -->
              <template v-if="arg.resource.id === 'info_event'">
                <div class="row m-0 p-0">
                  <div class="col m-0 p-0">
                    <info-resource-component
                      class="h-auto"
                      :translate="$i18n"
                      :basicRate="parseFloat(basicRate)"
                      :payBreaks="0">
                    </info-resource-component>
                  </div>
                  <div class="col-auto m-0 p-0">
                    <StatisticIcons
                      :stat-type="presenceStatMode"
                      :view="viewType"
                    />
                  </div>
                </div>
              </template>
              <!-- Админы менеджеры -->
              <template v-if="isEmployeeHasPermission('get-shifts-admin') || rolesByProjects[currentProjectId] === 'manager'">
                <!-- Фриборд -->
                <el-tooltip
                  effect="light"
                  placement="bottom"
                  v-if="+arg.resource.id === 0 && !arg.resource.extendedProps.deleted"
                  :disabled="resourceDropdownMenuDisabled"
                >
                  <template #content>
                    <div
                      data-toggle="modal"
                      data-target="#EmergencyShiftsModal"
                      @click="toggleEmergencyShiftsModalShow()"
                      class="w-100 p-1"
                    >
                      <div class="el-link">
                        <span>
                          <i class="icon-rocket text-primary font-weight-bold"
                             style="font-size: 1.3em; cursor: pointer"
                          /> {{$t('EMERGENCY_SHIFTS_NOTIFICATIONS')}}
                        </span>
                      </div>
                    </div>
                    <div class="w-100 p-1">
                      <div class="el-link">
                        <PlusShiftIcon
                          @clicked="resourceDropdownMenuDisable"
                          :isFreeBoard="true"
                          :userId="+arg.resource.id"
                          :userMarks="userMarks"
                        />
                      </div>
                    </div>
                    <div class="w-100 p-1">
                      <div class="el-link">
                        <MinusShiftIcon
                          @clicked="resourceDropdownMenuDisable"
                          :title="$i18n?.t('Are you sure?')"
                          :text="$i18n?.t('Delete shifts from free board')"
                          :accept="$i18n?.t('Yes, I am sure!!')"
                          :decline="$i18n?.t('No, cancel it!!')"
                          :warning="$i18n?.t('Warning!')"
                        />
                      </div>
                    </div>
                    <div class="w-100 p-1">
                      <div class="el-link">
                        <!--                        tooltip: this.$i18n?.t('DISTRIBUTE_SHIFTS_FROM_FREEBOARD')-->
                        <DistributeShiftsIcon
                          @clicked="resourceDropdownMenuDisable"
                        />
                      </div>
                    </div>
                  </template>
                  <i class="icon-more-vertical btn-icon ml-1 float-right" />
                </el-tooltip>
                <!-- Ресурсы юзеров -->
                <el-tooltip
                  :disabled="resourceDropdownMenuDisabled"
                  effect="light"
                  placement="bottom"
                  v-if="viewSwitch
                  && arg.resource.id !== 'break_events'
                  && arg.resource.id !== 'info_event'
                  && arg.resource.id !== 'holidays'
                  && +arg.resource.id !== 0
                  && !arg.resource.extendedProps.deleted"
                >
                  <template #content>
                    <div class="w-100 p-1">
                        <div
                          class="el-link"
                          data-toggle="modal"
                          data-target="#EditUserModal"
                          @click="toggleEditUserModalShow(+arg.resource.id)"
                        >
                          <i class="icon-eye text-primary font-weight-bold mr-1"
                             style="cursor: pointer; font-size: 1.3em;"
                          />
                          {{$t('EDIT_USER_MODAL_TITLE')}}
                        </div>
                    </div>
                    <div class="w-100 p-1">
                      <div class="el-link">
                        <PlusShiftIcon
                          @clicked="resourceDropdownMenuDisable"
                          :isFreeBoard="false"
                          :userId="+arg.resource.id"
                          :userMarks="userMarks"
                        />
                      </div>
                    </div>
                  </template>
                  <i class="icon-more-vertical btn-icon ml-1 float-right" />
                </el-tooltip>
                <!-- Праздники -->
                <el-tooltip
                  :disabled="resourceDropdownMenuDisabled"
                  effect="light"
                  placement="bottom"
                  v-if="arg.resource.id === 'holidays'"
                >
                  <i class="icon-more-vertical btn-icon ml-1 float-right" />
                  <template #content>
                    <div class="w-100 p-1">
                      <div class="el-link">
                        <StarHolidayIcon
                          @clicked="resourceDropdownMenuDisable"
                        />
                      </div>
                    </div>
                  </template>
                </el-tooltip>
              </template>
              <!-- Пометки -->
              <template v-if="viewSwitch
                 && arg.resource.id !== 'break_events'
                 && arg.resource.id !== 'info_event'
                 && arg.resource.id !== 'holidays'
                 && +arg.resource.id !== 0">
                  <span class="my-auto">
                    <template v-for="(mark, key) in userMarks[arg.resource.id]" :key="key">
                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :content="mark.name">
                        <span class="user-mark float-right" :style="{background: mark.color}"></span>
                      </el-tooltip>
                    </template>
                  </span>
              </template>
            </template>
            <!-- Юзеры -->
            <template v-if="viewSwitch && isRoleUser && currentEmployeeId && arg.resource.id === currentEmployeeId.toString()">
              <el-tooltip
                effect="light"
                placement="bottom"
                :disabled="resourceDropdownMenuDisabled"
              >
                <i class="icon-more-vertical btn-icon ml-1 float-right" />
                <template #content>
                  <div class="w-100 p-1">
                    <div class="el-link">
                      <RequestCreateShiftIcon
                        @clicked="resourceDropdownMenuDisable"
                        :userId="+arg.resource.id"
                        :userMarks="userMarks"
                      />
                    </div>
                  </div>
                </template>
              </el-tooltip>
            </template>
            <!-- Статистика по недели, только на просмотре по юзерам -->
            <div v-if="arg.resource.id !== 'info_event' &&
                 arg.resource.id !== 'loaderResource'"
                 class="h-auto">
              <div v-if="viewSwitcher.showByUsers === true &&
               arg.resource.id !== '0' &&
               arg.resource.id !== 'holidays'" class="h-auto">
                <employee-week-statistic-component
                  class="h-auto"
                  :viewType="viewType"
                  :show="showWeekStat"
                  :companyWeekHrsLabel="$t('COMPANY_WEEK_HRS_LABEL')"
                  :userWeekHrsLabel="$t('USER_WEEK_HRS_LABEL')"
                  :weekHrsCompanySetting="weekHrsCompanySetting"
                  :resourceId="arg.resource.id">
                </employee-week-statistic-component>
              </div>
            </div>
          </template>
          <template v-slot:eventContent='arg'>
            <template v-if="arg.event.extendedProps.isShift">
              <shift-component
                class="font-weight-bold"
                :event="arg.event"
                :dayQuotas="dayQuotas"
                :warningQuotas="$t('DAY_QUOTAS_WARNING_HINT')"
                :activeStart="arg.view.currentStart"
                :activeEnd="arg.view.currentEnd"
                :isUserStatus="isRoleUser"
                :view="view"
                :monthDisplay="monthDisplay"
                :lang="lang"
                :confirmedMessage="$t('CONFIRMED_MESSAGE_HINT')"
                :unconfirmedMessage="$t('UNCONFIRMED_MESSAGE_HINT')"
                :require_user_confirm="(isEmployeeHasPermission('get-dashboard-project-users') ||
                 currentEmployeeId === arg.event.extendedProps.employee_id) &&
                  requireUserConfirm"
                :marks="projectMarks"
                :current-employee-id="currentEmployeeId"
                :isAdmin="isAdmin"
                :isManager="isManager"
                @clickEventModal="eventClickAction"
                @deleteShift="deleteShift"
                @moveToFreeboard="putOnFreeBoard"
              />
            </template>
            <template v-if="arg.event.extendedProps.isInfoEvent">
              <statistic-shift-component
                class="h-100 d-flex align-items-center"
                @disableLoader="() => {loading === false}"
                :statistic="arg.event.extendedProps.statistic"
                :basicRate="parseFloat(basicRate)"
                :translate="$i18n"
                :presence="presenceStat"
                :direction="direction"
                :presenceStatMode="presenceStatMode"
                :view="view"
                :hrs="new Array(calendarTimeRange.end - calendarTimeRange.start)"
                :monthDisplay="monthDisplay"
                :payBreaks="0">
              </statistic-shift-component>
            </template>
            <template v-if="arg.event.extendedProps.isHoliday">
              <holiday-component :event="arg.event"/>
            </template>
            <template v-if="arg.event.extendedProps.isUserRequest">
              <user-request-component
                :event="arg.event"
                :view="view"
                :monthDisplay="monthDisplay"
                :tz="$store.getters.profileTimeZone"
                :translate="$i18n">
              </user-request-component>
            </template>
            <template v-if="arg.event.extendedProps.isUnavailability || arg.event.extendedProps.isAvailability">
              <AvailabilityFcComponent :event="arg.event" :monthDisplay="monthDisplay" :view="view" />
            </template>
            <template v-if="arg.event.extendedProps.isBreak && !arg.event.extendedProps.isFreeBreak">
              <break-component :event="arg.event" />
            </template>
            <template v-if="arg.event.extendedProps.isActivity">
              <ShiftActivityItem
                :event="arg.event"
                :locale="lang"
              />
            </template>
          </template>
        </full-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue3'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interaction from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import ruLocale from '@fullcalendar/core/locales/ru'
import heLocale from '@fullcalendar/core/locales/he'
import ukLocale from '@fullcalendar/core/locales/uk'
import deLocale from '@fullcalendar/core/locales/de'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import 'vue-slider-component/theme/default.css'
import ShiftComponent from '@/components/CommonComponents/Fullcalendar/ShiftComponent'
import StatisticShiftComponent from '@/components/CommonComponents/Fullcalendar/StatisticShiftComponent'
import InfoResourceComponent from '@/components/CommonComponents/Fullcalendar/InfoResourceComponent'
import HolidayComponent from '@/components/CommonComponents/Fullcalendar/HolidayComponent'
import UserRequestComponent from '@/components/CommonComponents/Fullcalendar/UserRequestComponent'
import BreakComponent from '@/components/CommonComponents/Fullcalendar/BreakComponent'
import InfoClass from '@/components/Schedule/Classes/Info'
import FullCalendarFilters from '@/components/Schedule/Classes/FullCalendarFilters'
import CloneShiftsModal from '@/components/Schedule/CompanyScheduleComponents/Modals/CloneShiftsModal'
import AddUserModal from '@/components/Schedule/CompanyScheduleComponents/Modals/AddUserModal'
import MassActionShiftModal from '@/components/Schedule/CompanyScheduleComponents/Modals/MassActionShiftModal'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import FullCalendarPrepareData from '@/components/Schedule/CompanyScheduleComponents/Mixins/FullCalendarPrepareData'
import ResourceInfoIcon from '@/components/CommonComponents/Fullcalendar/ResourceInfoIcon'
import PlusShiftIcon from '@/components/CommonComponents/Fullcalendar/PlusShiftIcon'
import RequestCreateShiftIcon from '@/components/CommonComponents/Fullcalendar/RequestCreateShiftIcon'
import StarHolidayIcon from '@/components/CommonComponents/Fullcalendar/StarHolidayIcon'
import StatisticIcons from '@/components/CommonComponents/Fullcalendar/StatisticIcons'
import MinusShiftIcon from '@/components/CommonComponents/Fullcalendar/MinusShiftIcon'
import DatePickerComponent from '@/components/CommonComponents/Fullcalendar/DatePickerComponent'
import DistributeShiftsIcon from '@/components/CommonComponents/Fullcalendar/DistributeShiftsIcon.vue'
import VisibilityObserver from '@/components/CommonComponents/Fullcalendar/VisibilityObserver'
import EmployeeWeekStatisticComponent from '@/components/CommonComponents/Fullcalendar/EmployeeWeekStatisticComponent'
import AddTemplateModal from '@/components/Schedule/CompanyScheduleComponents/Modals/AddTemplateModal'
import BonusPenaltyModal from '@/components/Schedule/CompanyScheduleComponents/Modals/BonusPenaltyModal'
import UserEditModalComponent from '@/components/CommonComponents/UserEditModalComponent'
import TimeLineStatistic from '@/components/Schedule/Classes/TimeLineStatistic'
import UserNotesComponent from '@/components/Schedule/CompanyScheduleComponents/Components/UserNotesComponent'
import SideMenuFilters from '@/components/Schedule/CompanyScheduleComponents/Mixins/SideMenuFilters'
import rrulePlugin from '@fullcalendar/rrule'
import AvailabilityFcComponent from '@/components/CommonComponents/Availability/AvailabilityFcComponent'
import ClickEmergencyShiftsNotificationModal from '@/components/Schedule/CompanyScheduleComponents/Modals/ClickEmergencyShiftsNotificationModal.vue'
import DistributeFreeBoardModal from '@/components/Schedule/CompanyScheduleComponents/Modals/DistributeFreeBoardModal.vue'
import { mount } from '@/utils/mount.js'
import { app } from '@/main'
import ShiftActivityItem from '@/components/ShiftActivity/Components/ShiftActivityItem.vue'

const FREE_BOARD_RESOURCE_ID = 0
// const INFO_EVENT_RESOURCE_ID = 'info_event'
const CUSTOM_RESOURCE_ID = 'custom_events'

const moment = extendMoment(Moment)

export default {
  name: 'CompanyScheduleCalendar',
  mixins: [
    ToastMixin,
    momentMixin,
    mixinWindowWidthWatch,
    mixinFCWeek,
    FullCalendarPrepareData,
    SideMenuFilters
  ],

  components: {
    ShiftActivityItem,
    DistributeFreeBoardModal,
    ClickEmergencyShiftsNotificationModal,
    AvailabilityFcComponent,
    MassActionShiftModal,
    UserNotesComponent,
    UserEditModalComponent,
    AddTemplateModal,
    EmployeeWeekStatisticComponent,
    VisibilityObserver,
    AddUserModal,
    CloneShiftsModal,
    FullCalendar,
    InfoResourceComponent,
    ShiftComponent,
    StatisticShiftComponent,
    HolidayComponent,
    UserRequestComponent,
    BreakComponent,
    BonusPenaltyModal,
    PlusShiftIcon,
    RequestCreateShiftIcon,
    MinusShiftIcon,
    DistributeShiftsIcon,
    StarHolidayIcon,
    StatisticIcons,
    ResourceInfoIcon
  },
  data () {
    return {
      calendar: Object,
      shouldReload: false,
      onlyRerender: false,
      resourceColumnWidth: 300,
      userSchedule: {}, // информация по шедулям, для треугольничка на ресурсе пользователя
      usersBySchedule: {}, // id пользователей, сгрупированные по id расписания, передается в модалки // todo подумать, может выпилить это напрямую в модалки
      doubleAccounts: {},
      slotWidth3days: 10,
      slotWidth1day: 10,
      breaksAction: null,
      statistic: {
        data: {},
        showStat: false
      },
      initFC: false,
      doNotLoadShifts: false,
      filteredEvents: [],
      userSwitcher: {
        showAllUsers: true,
        activeText: this.$i18n?.t('Show all users'),
        inactiveText: this.$i18n?.t('Show users with shifts')
      },
      viewSwitcher: {
        showByUsers: true,
        activeText: this.$i18n?.t('TOGGLE_VIEW_MODE_TO_TEMPLATES_VIEW'),
        inactiveText: this.$i18n?.t('TOGGLE_VIEW_MODE_TO_USERS_VIEW')
      },
      modalSetup: {
      },
      importBonusPenaltyShow: false,
      copyEventModalShow: false,
      editUserModalShow: false,
      addUserModalShow: false,
      addTemplateModalShow: false,
      massShiftActionsShow: false,
      distributeFreeBoardModalShow: false,
      basicRate: 0,
      statisticEvents: null,
      hideCalendar: false,
      filterUsersWithShifts: false,
      calendarOptions: null,
      calendarApi: null,
      loadedResources: {},
      lastScrollTop: 0,
      scrollDirection: null,
      // templates: [],
      workingTimeStatistic: [],
      updateSwitch: false, // флаг указывающий на необходимость перегрузки фильтра все юзеры/юзеры со сменами (свич на календаре)
      timeOutId: null, // id таймаута, который задается перед обновлением недельной статистики по юзерам
      editUserId: null,
      clearStyle: false,
      monthDisplay: 'full',
      dayDisplay: 'extended',
      presenceStat: {
        shifts: [],
        breaks: [],
        labels: []
      },
      presenceStatMode: 'text',
      timeLineStatClass: null,
      timeLineStatSetup: {
        segments: 288,
        segmentStep: 5,
        segmentType: 'minutes'
      },
      loading: false,
      maxMinTime: [0, 24],
      marksCalendarTimeRange: {
        0: '00:00',
        24: '24:00'
      },
      calendarTimeRange: {
        start: 0,
        end: 24
      },
      showPosition: true,
      showIdentifier: true,
      primarySortOrder: 'name',
      templateSortOrder: 'date',
      sortChoices: [
        {
          label: this.$t('SORT_BY_NAME'),
          value: 'name'
        },
        {
          label: this.$t('SORT_BY_POSITION'),
          value: 'position'
        },
        {
          label: this.$t('SORT_BY_IDENTIFIER'),
          value: 'identifier'
        },
        {
          label: this.$t('SORT_BY_DATE_ASC'),
          value: 'id_asc'
        },
        {
          label: this.$t('SORT_BY_DATE_DESC'),
          value: 'id_desc'
        }
      ],
      sortTemplateChoices: [
        {
          label: this.$t('SORT_BY_NAME'),
          value: 'name'
        },
        {
          label: this.$t('SORT_BY_TEMPLATE_DATE'),
          value: 'date'
        }
      ],
      nameFormat: 'last_first',
      warningInPast: true,
      toHideDeleted: [],
      isDropOrResize: null,
      filteredBreaks: [],
      fcEventsSelectedDates: null,
      eventsSuccessCallback: null,
      resourcesSuccessCallback: null,
      fcResourcesSelectedDates: null,
      userMarks: {},
      actualTZMode: 'employeeTZ',
      emergencyShiftsModalShow: false,
      isPreview: false,
      distributeFreeBoardPayload: {},
      previewShiftsId: [],
      schedulesMultiplySelectPrevValue: [],
      resourceDropdownMenuDisabled: false
    }
  },
  props: {
    currentProject: Object,
    isOpen: Boolean
  },
  created () {
    // у экрана активностей и компани шедуля обший стор активностей, поэтому нужно его почистить на случай
    // если снята галочка "отображать активности"
    this.$store.dispatch('updateShiftActivitiesList', [])
    this.statisticEvents = new InfoClass(null, null, this.actualTZ)
    this.updateWeekWorkingHoursStatistic()
    this.modulesByCompany.forEach((module) => {
      if (+module.module_id === 8) { // базовый модуль
        if (!Array.isArray(module.settings)) {
          this.warningInPast = !!module.settings.should_warning_shift_in_past
          this.$store.commit('warningInPast', this.warningInPast)
          this.$store.commit('working_hours_display_type', module.settings.working_hours_display_type)
          this.$store.commit('require_user_confirm', module.settings.require_user_confirm)
        }
      }
    })
    // this.$on('CopyShifts', () => {
    //   this.copyEventModalShow = false
    // })
    // this.$on('closeBonusPenaltyModal', () => {
    //   this.importBonusPenaltyShow = false
    // })
    // this.$on('hideUserEditModal', () => {
    //   this.editUserModalShow = false
    //   this.calendarApi.refetchResources()
    // })
    // this.$on('hideEmergencyShiftsModalShow', () => {
    //   this.emergencyShiftsModalShow = false
    // })
    // this.$on('AddUser', () => {
    //   this.addUserModalShow = false
    // })
    // this.$on('AddTemplate', () => {
    //   this.addTemplateModalShow = false
    // })
    // this.$on('ShiftMassAction', () => {
    //   this.massShiftActionsShow = false
    // })
    const monthDisplayType = localStorage.getItem('monthDisplayType')
    const presenceStatMode = localStorage.getItem('presenceStatMode')
    const dayDisplayType = localStorage.getItem('dayDisplayType')
    const minTimeCalendar = +localStorage.getItem('minTimeCalendar')
    const maxTimeCalendar = +localStorage.getItem('maxTimeCalendar')
    const showPosition = localStorage.getItem('showPosition')
    const showIdentifier = localStorage.getItem('showIdentifier')
    const primarySortOrder = localStorage.getItem('primarySortOrder')
    const templateSortOrder = localStorage.getItem('templateSortOrder')
    const nameFormat = localStorage.getItem('nameFormat')
    if (showPosition) {
      if (showPosition === 'true') {
        this.showPosition = true
      } else {
        this.showPosition = false
      }
    } else {
      localStorage.setItem('showPosition', this.showPosition)
    }
    if (showIdentifier) {
      if (showIdentifier === 'true') {
        this.showIdentifier = true
      } else {
        this.showIdentifier = false
      }
    } else {
      localStorage.setItem('showIdentifier', this.showIdentifier)
    }
    if (primarySortOrder) {
      this.primarySortOrder = primarySortOrder
    } else {
      localStorage.setItem('primarySortOrder', this.primarySortOrder)
    }
    if (templateSortOrder) {
      this.templateSortOrder = templateSortOrder
    } else {
      localStorage.setItem('templateSortOrder', this.templateSortOrder)
    }
    if (nameFormat) {
      this.nameFormat = nameFormat
    } else {
      localStorage.setItem('nameFormat', this.nameFormat)
    }
    if (monthDisplayType) {
      this.monthDisplay = monthDisplayType
    } else {
      localStorage.setItem('monthDisplayType', this.monthDisplay)
    }
    if (minTimeCalendar) {
      this.maxMinTime[0] = minTimeCalendar
    } else {
      localStorage.setItem('minTimeCalendar', this.maxMinTime[0])
    }
    if (maxTimeCalendar) {
      this.maxMinTime[1] = maxTimeCalendar
    } else {
      localStorage.setItem('maxTimeCalendar', this.maxMinTime[1])
    }
    this.calendarTimeRange = {
      start: this.maxMinTime[0],
      end: this.maxMinTime[1]
    }
    const segments = (this.calendarTimeRange.end - this.calendarTimeRange.start) * 60 / 5
    this.timeLineStatSetup = {
      segments: segments,
      segmentStep: 5,
      segmentType: 'minutes'
    }
    if (dayDisplayType) {
      this.dayDisplay = dayDisplayType
    } else {
      localStorage.setItem('dayDisplayType', this.dayDisplay)
    }
    if (presenceStatMode) {
      this.presenceStatMode = presenceStatMode
    } else {
      localStorage.setItem('presenceStatMode', this.presenceStatMode)
    }
    // window.addEventListener('scroll', this.handleScroll)
  },
  beforeMount () {
    const actualTZMode = localStorage.getItem('actualTZMode')
    if (!actualTZMode) {
      localStorage.setItem('actualTZMode', 'employeeTZ')
    }
    this.actualTZMode = actualTZMode || 'employeeTZ'
    this.$store.dispatch('updateActualTZ')
  },
  mounted () {
    this.$eventBus.on('addShiftsToUsers', this.addShiftsFromModal)
    this.$eventBus.on('deleteShift', this.deleteShiftFromModal)
    this.$eventBus.on('refetchEvents', this.refetch)
    this.$eventBus.on('refetchEventsLazy', this.refetchEventsLazy)
    this.$eventBus.on('refetchUser', this.refetchUser)
    this.$eventBus.on('deleteFreeBoardShift', this.deleteFreeBoardShift)
    this.$eventBus.on('assignFreeBoardShift', this.assignFreeBoardShift)
    this.$eventBus.on('assignShiftToFreeBoard', this.assignShiftToFreeBoard)
    this.$eventBus.on('clearFreeBoard', this.clearFreeBoard)
    this.$eventBus.on('updateHolidays', this.updateHolidays)
    this.$eventBus.on('loadNextResource', this.loadNextResource)
    this.$eventBus.on('goToDate', this.goToDate)
    this.$eventBus.on('reloadAllUsersSwitch', this.markUserSwitch)
    this.$eventBus.on('getAttendance', this.refetch)
    this.$eventBus.on('enableLoader', () => { this.loading = true })
    this.$eventBus.on('distributeFreeBoard', this.toggleDistributeFreeBoardModalShow)
    this.timeSlotWidth()
    this.setCalendarOptions()
    this.$nextTick(() => {
      this.init().then(() => {
        this.calendarApi.setOption('height', 'auto')
        if (this.viewType === 'resourceTimelineDay') {
          this.calendarApi.setOption('slotMinTime', this.slotMinTime)
          this.calendarApi.setOption('slotMaxTime', this.slotMaxTime)
        }
        this.basicRate = this.$store.getters.basicRate
        if (this.defaultViewName === 'resourceTimelineDay') {
          this.reloadDayStatistic()
          this.calendarApi.setOption('nowIndicator', true)
        }
        // костыль, чтобы отображались тултипы на слайдере в меню настроек
        const el = document.getElementById('viewSettingsDropdownMenu')
        el.style = null
        el.style.zIndex = 1000
        setTimeout(() => {
          this.calendarApi.render()
        }, 3000)
        this.$store.dispatch('updateCurrentProjectId', localStorage.getItem('currentProject'))
      })
    })
  },

  beforeUnmount () {
    try {
      // this.clearStore()
      this.$store.dispatch('dropFCState')
      this.calendarApi.destroy()
    } catch (e) {
      // do nothing
    }
    this.$eventBus.off('addShiftsToUsers')
    this.$eventBus.off('changeFilterFC')
    this.$eventBus.off('refetchEvents')
    this.$eventBus.off('refetchEventsLazy')
    this.$eventBus.off('deleteFreeBoardShift')
    this.$eventBus.off('assignFreeBoardShift')
    this.$eventBus.off('assignShiftToFreeBoard')
    this.$eventBus.off('clearFreeBoard')
    this.$eventBus.off('updateHolidays')
    this.$eventBus.off('closeLatenessSetupModal')
    this.$eventBus.off('goToDate')
    this.$eventBus.off('markUserSwitch')
    // window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    projectsWhereUserIsManager () {
      const projects = []
      this.$store.getters.projectsWhereUserIsManager.forEach(item => {
        projects.push(item.id)
      })
      return projects
    },
    isAdmin () {
      return this.isEmployeeHasPermission('get-shifts-admin')
    },
    isManager () {
      return !!(this.isEmployeeHasPermission('get-shifts-manager') &&
        this.projectsWhereUserIsManager.includes(this.currentProjectId))
    },
    modulesByCompany () {
      return this.$store.getters.ModulesByCompany
    },
    slotMinTime () {
      if (this.calendarTimeRange.start === 24) {
        return '24:00:00'
      }
      return moment().startOf('day').add(this.calendarTimeRange.start, 'hours').format(this.backendTimeFormatExtended)
    },
    slotMaxTime () {
      if (this.calendarTimeRange.end === 24) {
        return '24:00:00'
      }
      return moment().startOf('day').add(this.calendarTimeRange.end, 'hours').format(this.backendTimeFormatExtended)
    },
    overlapLevel () {
      return this.$store.getters.company.block_overlap_level
    },
    currentEmployeeId  () {
      return this.$store.getters.company.employee_id
    },
    requireUserConfirm () {
      return this.$store.getters.require_user_confirm
    },
    workingTypeDisplayType () {
      return this.$store.getters.working_hours_display_type
    },
    showWeekStat () {
      return this.view.type === 'resourceTimelineWeek' || this.view.type === 'resourceTimeMonth'
    },
    weekHrsCompanySetting () {
      if (this.$store.getters.company.salary && this.$store.getters.company.salary.quotas_per_week) {
        return this.$store.getters.company.salary.quotas_per_week.toString()
      }
      return '0'
    },
    isRoleUser () {
      return !this.isEmployeeHasPermission('get-company-admin') &&
        !(this.rolesByProjects[this.currentProjectId] === 'manager') &&
        !(this.rolesByProjects[this.currentProjectId] === 'viewer')
    },
    dayQuotas () {
      return this.$store.getters.company &&
      this.$store.getters.company.salary &&
      this.$store.getters.company.salary.quotas_per_day
        ? this.$store.getters.company.salary.quotas_per_day.toString()
        : '0'
    },
    handleResourceColumnWidth () {
      if (this.windowWidth >= 576) {
        return '350px'
      }
      return '60%'
    },
    viewSwitch () {
      return this.viewSwitcher.showByUsers
    },
    userSwitch () {
      return this.userSwitcher.showAllUsers
    },
    firstDay () {
      return this.fcWeek[this.apiWeek[this.$store.getters.company.salary.week_start]]
    },
    lang () {
      return this.$store.getters.locale ? this.$store.getters.locale : 'en'
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    view () {
      return this.$store.getters.view
    },
    viewType () {
      return this.view.type
    },
    viewStart () {
      return this.view.currentStart
    },
    viewEnd () {
      return this.view.currentEnd
    },
    defaultViewName () {
      if (this.windowWidth >= 576) {
        const viewType = localStorage.getItem('viewType')
        if (!viewType) {
          localStorage.setItem('viewType', 'resourceTimelineWeek')
          return 'resourceTimelineWeek'
        }
        return viewType
      }
      return 'resourceTimelineDay'
    },
    header () {
      if (this.windowWidth >= 576) {
        return {
          start: 'title',
          center: 'prev,calendarButton,next',
          end: 'today resourceTimelineDay,resourceTimeline3days,resourceTimelineWeek,resourceTimeMonth'
        }
      }
      return {
        start: 'title',
        center: 'prev,calendarButton,next',
        end: 'today'
      }
    },
    views () {
      if (this.windowWidth >= 576) {
        return {
          resourceTimelineDay: {
            slotLabelFormat: { // формат времени лейбла временной сетки
              hour: 'numeric',
              hour12: this.is12hourFormat,
              omitZeroMinute: false,
              meridiem: 'short'
            },
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            slotDuration: '00:10:00',
            slotMinWidth: 5,
            displayEventTime: true,
            displayEventEnd: true,
            titleFormat: { // will produce something like "Tuesday, September 18, 2018"
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          },
          resourceTimeline3days: {
            type: 'resourceTimelineMonth',
            slotLabelInterval: '24:00',
            buttonText: this.$i18n?.t('3 days'),
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            // dateIncrement: '24:00',
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { days: 3 },
            slotDuration: { hours: 1 },
            slotMinWidth: this.slotWidth3days
          },
          resourceTimelineWeek: {
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { week: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00'
            // slotWidth: this.slotWidth
          },
          resourceTimeMonth: {
            type: 'resourceTimelineMonth',
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              // weekday: 'narrow',
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { month: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00',
            slotMinWidth: this.monthDisplay === 'full' ? 105 : 1
          }
        }
      }
      return {
        resourceTimelineDay: {
          slotLabelFormat: [{ // формат времени лейбла временной сетки
            weekday: 'short',
            day: 'numeric'
          }],
          eventTimeFormat: { // формат времени, которое отображается на событии
            hour: 'numeric',
            hour12: this.is12hourFormat,
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          },
          editable: true,
          displayEventTime: true,
          displayEventEnd: true,
          duration: { day: 1 },
          slotDuration: { days: 1 },
          snapDuration: '24:00',
          titleFormat: { // will produce something like "Tuesday, September 18, 2018"
            month: 'numeric',
            year: 'numeric',
            day: 'numeric',
            weekday: 'short'
          }
        }
      }
    },
    ...mapGetters({
      currentProjectId: 'currentProjectId',
      preloadUsers: 'employeesIndexed',
      employeesCount: 'employeesCount',
      projectMarks: 'projectMarks'
    }),
    schedules () {
      const schedules = this.$store.getters.schedules
      return schedules.filter(schedule => {
        return +schedule.project_id === +this.currentProjectId
      })
    },
    schedulesById () {
      const schedules = {}
      this.schedules.forEach(schedule => {
        schedules[schedule.id] = schedule
      })

      return schedules
    },
    templates () {
      const templates = this.$store.getters.templates
      return templates.filter(template => {
        return this.currentProject.schedulesByProject.includes(+template.schedule_id)
      })
    },
    fcEvents () {
      return this.$store.getters.fc_events
    },
    shifts () {
      return this.$store.getters.shifts
    },
    deletedUsers () {
      return this.$store.getters.deletedUsers
    },
    breaks () {
      return this.$store.getters.breaks
    },
    breaksById () {
      return this.$store.getters.breaksIndexed
    },
    holidays () {
      return this.$store.getters.holidays
    },
    fcRequests () {
      return this.$store.getters.fcRequests
    },
    companyAvailability () {
      let filtered = [...this.$store.getters.companyAvailability]
      if (!this.loadUnavailability) {
        filtered = filtered.filter(item => item.event_type !== 'unavailability')
      }
      if (!this.loadAvailability) {
        filtered = filtered.filter(item => item.event_type !== 'availability')
      }
      return filtered
    },
    filter () {
      return this.$store.getters.fc_filters
    },
    shiftsById () {
      return this.$store.getters.shiftsIndexed
    },
    filteredResources () {
      return this.$store.getters.fc_filtered_resources
    },
    holidaysById () {
      return this.$store.getters.holidaysIndexed
    },
    isEventsLoaded () {
      return this.$store.getters.isEventsLoaded
    },
    shouldUpdateWeekWorkingHoursStatistic () {
      return this.$store.getters.shouldUpdateWeekWorkingHoursStatistic
    },
    filteredShifts () {
      return this.$store.getters.filteredShifts
    },
    deletedTemplatesToHide () {
      return this.$store.getters.deletedTemplatesToHide
    },
    shouldRefetch () {
      return this.$store.getters.shouldRefetch
    },
    freeBoardEvents () {
      return this.$store.getters.fc_free_board_events
    },
    actualTZ () {
      return this.$store.getters.actualTZ
    },
    showTZModeSelect () {
      const profileTZ = this.$store.getters.profileTimeZone
      const projectTZ = this.$store.getters.projectById(this.$store.getters.currentProjectId) ? this.$store.getters.projectById(this.$store.getters.currentProjectId).time_zone : null
      return profileTZ !== projectTZ
    },
    activities () {
      return this.$store.getters.activities
    },
    indexedMarks () {
      const indexedMarks = {}
      this.projectMarks.forEach(mark => {
        indexedMarks[mark.id] = mark
      })
      return indexedMarks
    },
    locationsIndexed () {
      return this.$store.getters.locationsIndexed
    },
    positionsIndexed () {
      return this.$store.getters.positionsIndexed
    },
    projects () {
      return this.$store.getters.projectsIndexed
    }
  },
  watch: {
    actualTZMode () {
      localStorage.setItem('actualTZMode', this.actualTZMode)
      this.$store.dispatch('updateActualTZ')
      this.calendarApi && this.calendarApi.refetchEvents()
      this.setupTimeLineStatistic()
    },
    schedules () {
      this.setUserMarks()
    },
    filter () {
      if (this.calendarApi) {
        if (this.isPreview) {
          this.rewertDistribute()
        } else {
          this.doNotLoadShifts = true
          this.calendarApi.refetchEvents()
          this.calendarApi.refetchResources()
        }
      }
    },
    fcResourcesSelectedDates () {
      this.loadedResources = {}
      if (this.viewSwitch) {
        this.createResources().then(resources => {
          resources = this.applyFilter(resources)
          this.applyFilterUsersWithShifts(resources).then(resources => {
            const loadedResources = resources.slice(0, 25)
            loadedResources.forEach(resource => {
              this.loadedResources[resource.id] = true
            })
            if (loadedResources.length < resources.length) {
              loadedResources.push({
                id: 'loaderResource',
                title: '',
                control: null,
                type: 666
              })
            }
            this.resourcesSuccessCallback(loadedResources)
          })
        })
      } else {
        this.createResourcesByTemplate().then(resources => {
          const loadedResources = resources.slice(0, 25)
          loadedResources.forEach(resource => {
            this.loadedResources[resource.id] = true
          })
          if (loadedResources.length < resources.length) {
            loadedResources.push({
              id: 'loaderResource',
              title: '',
              control: null,
              type: 666
            })
          }
          this.resourcesSuccessCallback(loadedResources)
        })
      }
    },
    fcEventsSelectedDates (value) {
      if (value && this.isFiltersLoaded) {
        let events = []
        if (this.doNotLoadShifts) { // возвращаем эвенты без запроса в базу при изменении наобора темплейтов
          this.doNotLoadShifts = false
          this.shouldReload = false
          events = this.makeShiftsTransformations()
          events = this.applyEventsTemplateFilter(events)
          this.$store.dispatch('updateFcEvents', events)
        } else {
          this.shouldReload = false
          this.clearStore()
          this.getEvents(this.fcEventsSelectedDates.startStr, this.fcEventsSelectedDates.endStr)
        }
      }
    },
    isEventsLoaded (val) {
      if (val) {
        const usersMarks = {}
        for (const [key, value] of Object.entries(this.deletedUsers)) {
          value.schedule_user.forEach(element => {
            if (!(element.schedule_id in usersMarks)) {
              usersMarks[element.schedule_id] = {}
            }
            // usersMarks[element.schedule_id] = {}
            usersMarks[element.schedule_id][key] = element.marks
          })
        }
        this.setDeletedUserMarks(usersMarks).then(() => {
          this.shouldReload = false
          let events
          events = this.makeShiftsTransformations()
          events = this.applyEventsTemplateFilter(events)
          this.$store.dispatch('updateFcEvents', events)
          if (this.calendarApi) {
            this.calendarApi.refetchResources()
          }
        })
      }
    },
    fcEvents (fcEvents) {
      if (fcEvents && this.fcEventsSelectedDates && this.shouldRefetch) {
        fcEvents = this.applyShiftFilter(fcEvents)
        if (this.isEmployeeHasPermission('get-company-admin') ||
          this.rolesByProjects[this.currentProjectId] === 'manager') {
          const infoEvents = this.statisticEvents.setRange(this.fcEventsSelectedDates.startStr, this.fcEventsSelectedDates.endStr)
            .setInfoEvents()
            .getInfoEvents()
          fcEvents = fcEvents.concat(infoEvents)
          this.eventsSuccessCallback(fcEvents)
        } else {
          this.eventsSuccessCallback(fcEvents)
        }
      } else {
        this.$store.dispatch('updateShouldRefetch', true)
      }
      this.updateStatistic()
      if (this.view.type === 'resourceTimelineDay') {
        this.sendStatistic(this.fcEventsSelectedDates.start, this.fcEventsSelectedDates.end)
      }
    },
    shouldUpdateWeekWorkingHoursStatistic (val) {
      if (val) {
        this.updateWeekWorkingHoursStatistic()
        this.$store.dispatch('updateShouldUpdateWeekStatistic', false)
      }
    },
    slotMinTime (val) {
      if (this.viewType === 'resourceTimelineDay' && this.calendarApi) {
        this.calendarApi.setOption('slotMinTime', val)
      }
    },
    slotMaxTime (val) {
      if (this.viewType === 'resourceTimelineDay' && this.calendarApi) {
        this.calendarApi.setOption('slotMaxTime', val)
      }
    },
    viewSwitch () {
      this.calendarApi.refetchResources()
      this.calendarApi.refetchEvents()
    },
    isMobile (value) {
      this.calendarApi.destroy()
      this.userSwitcher.showAllUsers = true
      this.init().then(() => {
        this.calendarApi.render()
        if (value) {
          this.userSwitcher.showAllUsers = false
        }
      })
    },
    deletedUsers: {
      handler (newValue, oldValue) {
        if (this.calendarApi && (JSON.stringify(Object.keys(newValue)) !== JSON.stringify(Object.keys(oldValue)))) {
          this.calendarApi.refetchResources()
        }
        // this.calendarApi.rerenderEvents()
      },
      deep: true
    },
    employeesCount () {
      this.calendarApi.refetchResources()
    },
    userSwitch (value) {
      this.toggleUsersSwitch(!value)
      this.updateStatistic()
    },
    // переключение между проектами
    currentProjectId () {
      this.$store.dispatch('updateActualTZ')
      this.clearStore()
      this.doNotLoadShifts = false
      this.userSchedule = {}
      this.usersBySchedule = {}
      this.loadedResources = {}
      this.filterUsersWithShifts = false
      this.userSwitcher.showAllUsers = true
      this.previewShiftsId.forEach(id => {
        const event = this.calendarApi.getEventById(id)
        if (event) {
          event.remove()
        }
      })
      this.isPreview = false
      this.previewShiftsId = []
      this.calendarApi.refetchResources()
      setTimeout(() => {
        this.calendarApi.refetchEvents()
      }, 1000)
      this.$store.commit('PROJECT_IS_CHANGING', false)
    },
    /** обновление шифтов при смене при смене типа вьюхи календаря (разные надписи для разных типов) */
    viewType () {
      if (this.calendarApi) {
        // this.shouldReload = true
        localStorage.setItem('viewType', this.viewType)
        if (this.viewType !== 'resourceTimelineDay') {
          this.calendarApi.setOption('nowIndicator', false)
          this.calendarApi.setOption('slotMinTime', '00:00:00')
          this.calendarApi.setOption('slotMaxTime', '24:00:00')
          this.statistic.showStat = false
          this.statistic.data = {}
          this.$eventBus.emit('showStat', this.statistic)
        } else { // day view
          this.calendarApi.setOption('nowIndicator', true)
          this.calendarApi.setOption('slotMinTime', this.slotMinTime)
          this.calendarApi.setOption('slotMaxTime', this.slotMaxTime)
          this.reloadDayStatistic()
        }
        if (this.viewType === 'resourceTimelineWeek' || this.viewType === 'resourceTimeMonth') {
          this.calendarApi.setOption('eventDurationEditable', false)
        } else {
          this.calendarApi.setOption('eventDurationEditable', true)
        }
      }
    },
    viewStart (val) {
      this.$emit('viewDateChange', moment(this.viewStart), moment(this.viewEnd))
    },
    presenceStatMode (val) {
      this.reloadDayStatistic()
    },
    filteredShifts (val) {
      this.updateTimelineStatistic('shifts')
      this.updateStatistic()
    },
    filteredResources () {
      this.updateStatistic()
      if (this.view.type === 'resourceTimelineDay') {
        this.sendStatistic(this.fcEventsSelectedDates.start, this.fcEventsSelectedDates.end)
        this.updateTimelineStatistic('shifts')
        this.updateTimelineStatistic('breaks')
      }
    },
    filteredBreaks () {
      this.updateTimelineStatistic('breaks')
    },
    projectMarks () {
      this.setUserMarks()
    },
    userMarks () {
      this.calendarApi.refetchResources()
    }
  },
  methods: {
    hideUserEditModal () {
      this.editUserModalShow = false
      this.calendarApi.refetchResources()
    },
    distributeFreeBoard (payload) {
      this.distributeFreeBoardPayload = payload
      this.distributeFreeBoardPayload.preview = true
      this.previewShiftsId = []
      this.$store.dispatch('distributeFreeBoard', [this.companyId, this.distributeFreeBoardPayload]).then(response => {
        if (response && response.length > 0) {
          this.isPreview = true
          this.calendarApi.setOption('headerToolbar', {
            start: 'title',
            center: '',
            end: ''
          })
          const freeBoardResource = this.calendarApi.getResourceById('0')
          let freeboardEvents = []
          if (freeBoardResource) {
            freeboardEvents = freeBoardResource.getEvents()
          }
          response.forEach(item => {
            const event = this.setEvent(item)
            // event.color = 'red'
            event.isPreview = true
            this.previewShiftsId.push(event.id)
            this.calendarApi.addEvent(event)

            let freeBoardKey = item.schedule_id + '__' + item.template_id + '__' + item.time_from + '__' + item.time_to
            const groupedBonuses = {}
            if (item.bonuses) {
              item.bonuses.forEach(bonus => {
                if (!groupedBonuses[bonus.type]) {
                  groupedBonuses[bonus.type] = 0
                }
                groupedBonuses[bonus.type] = groupedBonuses[bonus.type] + bonus.amount
              })
              Object.keys(groupedBonuses).forEach(type => {
                freeBoardKey = event.freeBoardKey + '__' + type + '__' + groupedBonuses[type]
              })
            }
            if (item.location_id) {
              freeBoardKey = freeBoardKey + '__' + item.location_id
            }
            if (item.marks && item.marks.length > 0) {
              freeBoardKey = freeBoardKey + '__marks__' + item.marks.sort((a, b) => {
                if (a > b) {
                  return 1
                }
                if (a < b) {
                  return -1
                }
                return 0
              }).join('__')
            }
            freeboardEvents.forEach(item => {
              if (item.id === freeBoardKey) {
                if (item.extendedProps.counter > 1) {
                  item.setExtendedProp('counter', item.extendedProps.counter - 1)
                } else {
                  item.remove()
                }
              }
            })
          })
        } else {
          this.toastWarning(this.$i18n?.t('NO_SHIFTS_DISTRIBUTED'))
        }
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          this.toastError(this.$i18n?.t(err.response.data.message))
        }
      })
    },
    rewertDistribute () {
      this.distributeFreeBoardPayload = {}
      this.isPreview = false
      this.calendarApi.destroy()
      this.calendarApi.setOption('headerToolbar', this.header)
      this.calendarApi.refetchResources()
      this.calendarApi.refetchEvents()
      this.previewShiftsId.forEach(id => {
        const event = this.calendarApi.getEventById(id)
        if (event) {
          event.remove()
        }
      })
      this.previewShiftsId = []
      this.calendarApi.render()
    },
    confirmDistribute () {
      this.distributeFreeBoardPayload.preview = false
      this.$store.dispatch('distributeFreeBoard', [this.companyId, this.distributeFreeBoardPayload]).then(response => {
        this.rewertDistribute()
      }).catch(err => {
        if (err.response.data && err.response.data.message) {
          this.toastError(this.$i18n?.t(err.response.data.message))
        }
        this.rewertDistribute()
      })
    },
    formatTooltip (val) {
      return val
    },
    setTimeFormat (timeData) {
      return Moment(timeData, 'HH:mm').format(this.localeTimeFormat)
    },
    getEvents (currentStart, currentEnd) {
      let dateStart, dateEnd
      dateStart = this.fromZoneToZone(currentStart, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      dateEnd = this.fromZoneToZone(currentEnd, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
      let queryShifts = 'start=' + dateStart + '&end=' + dateEnd + '&include_free_board=True'
      if (!(this.isEmployeeHasPermission('get-shifts-admin') ||
        this.rolesByProjects[this.currentProject.id] === 'manager')) {
        queryShifts += '&employee_id=' + this.$store.getters.profileId
      }
      const queryHolidays = '?start=' + dateStart + '&end=' + dateEnd
      const queryUnavailability = '?start=' + dateStart + '&' + 'end=' + dateEnd
      const queryBreaks = '?time_from=' + dateStart + '&time_to=' + this.fromZoneToZone(currentEnd, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const activityQuery = `?date_from=${dateStart}&date_to=${dateEnd}`
      this.loading = true
      this.$store.dispatch('getEvents', {
        queryShifts: queryShifts,
        queryHolidays: queryHolidays,
        queryUnavailability: queryUnavailability,
        queryBreaks: queryBreaks,
        currentProjectId: this.currentProject.id,
        companyId: this.companyId,
        viewType: this.viewType,
        activityQuery: activityQuery
      })
    },
    clearStore () {
      this.$store.dispatch('updateShiftsList', [])
      this.$store.dispatch('updateBreaksList', [])
      this.$store.dispatch('updateFcRequests', [])
      this.$store.dispatch('updateFcEvents', null)
      this.$store.dispatch('updateFreeBoardEvents', {})
      this.$store.dispatch('updateDeletedUsers', [])
      this.$store.dispatch('updateFilteredShiftsList', [])
    },
    setUserMarks () {
      const temp = {}
      const projectMarks = this.projectMarks
      const projectMarksById = {}

      projectMarks.forEach(mark => {
        projectMarksById[mark.id] = mark
      })

      Object.keys(this.preloadUsers).forEach(userId => {
        temp[userId] = []
      })

      this.schedules.forEach(schedule => {
        schedule.users.forEach(scheduleUser => {
          const marks = [...new Set(scheduleUser.marks)]
          marks.forEach(markId => {
            if (projectMarksById[markId]) {
              if (!temp[scheduleUser.employee_id]) {
                temp[scheduleUser.employee_id] = []
              }
              temp[scheduleUser.employee_id].push({
                markId: markId,
                color: projectMarksById[markId].color,
                name: projectMarksById[markId].name,
                scheduleId: scheduleUser.schedule_id
              })
            }
          })
          // temp[scheduleUser.employee_id] = temp[scheduleUser.employee_id].filter(mark)
        })
      })
      // фильтруем повоторяющиеся пометки на ресурсе юзера
      Object.keys(temp).forEach(key => {
        const filtered = []
        const ids = []
        temp[key].forEach(mark => {
          if (!ids.includes(mark.markId)) {
            filtered.push(mark)
            ids.push(mark.markId)
          }
        })
        temp[key] = filtered
      })

      this.userMarks = { ...this.$store.getters.userMarksDeleted, ...temp }
    },
    setCalendarOptions () {
      this.calendarOptions = {
        schedulerLicenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
        plugins: [interaction, rrulePlugin, resourceTimelinePlugin, dayGridPlugin, bootstrapPlugin],
        aspectRatio: 1.5,
        themeSystem: 'bootstrap',
        eventResourceEditable: false,
        selectable: true,
        // slotMinTime: '09:00:00',
        // slotMaxTime: '18:00:00',
        // height: 'auto',
        headerToolbar: this.header,
        stickyHeaderDates: true,
        stickyFooterScrollbar: true,
        firstDay: this.firstDay,
        // nowIndicator: true,
        refetchResourcesOnNavigate: false,
        nowIndicatorDidMount: () => {
          console.log('mount')
        },
        customButtons: {
          calendarButton: {
            text: ' '
          }
        },
        buttonText: {
          prev: '<',
          next: '>'
        },
        initialView: this.defaultViewName,
        locales: [ruLocale, heLocale, ukLocale, deLocale, plLocale, esLocale, elLocale],
        locale: this.lang,
        direction: this.direction,
        dayMaxEventRows: false,
        lazyFetching: false,
        views: this.views,
        viewClassNames: (arg) => {
          if (arg.view.type === 'resourceTimeMonth' && this.monthDisplay === 'compact') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        slotLabelClassNames: () => {
          if (this.view.type === 'resourceTimeMonth' && this.monthDisplay === 'compact') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        scrollTime: '00:00:00',
        nextDayThreshold: '24:00:00',
        resourceAreaWidth: this.handleResourceColumnWidth,
        resourceAreaColumns: [
          {
            labelText: '',
            field: 'title'
          }
        ],
        loading: (isLoading) => {
          this.loading = isLoading
        },
        resourceOrder: 'type,sortOrder,title',
        resourceLaneClassNames: (el) => {
          if (el.resource.id === '0' || +el.resource.id === this.currentEmployeeId) {
            return 'bg-resource-color'
          }
        },
        resourceLabelClassNames: (el) => {
          if (el.resource.id === '0' || +el.resource.id === this.currentEmployeeId) {
            return 'bg-resource-color'
          }
        },
        resources: (fetchInfo, successCallback, failureCallback) => {
          this.resourcesSuccessCallback = successCallback
          this.fcResourcesSelectedDates = {
            startStr: fetchInfo.startStr,
            endStr: fetchInfo.endStr,
            start: fetchInfo.start,
            end: fetchInfo.end
          }
        },
        eventOrder: (item1, item2) => {
          if (!this.viewSwitch) {
            // если вьюха по темплейтам, сначала сортируем по id расписания
            if (item1.schedule_id > item2.schedule_id) return 1
            // сортируем по времени начала шифта
            if (moment(item1.start).isAfter(moment(item2.start))) return 1
            // сортируем по тайтлу
            if (moment(item1.start).isSame(moment(item2.start)) &&
              item1.title.toLowerCase() > item2.title.toLowerCase()) return 1
          } else {
            if (moment(item1.start).isAfter(moment(item2.start))) return 1
          }
          return -1
        },
        // use events, till we find any reason to use sources below
        events: (fetchInfo, successCallback, failureCallback) => {
          this.eventsSuccessCallback = successCallback
          this.fcEventsSelectedDates = {
            startStr: fetchInfo.startStr,
            endStr: fetchInfo.endStr,
            start: fetchInfo.start,
            end: fetchInfo.end
          }
        },
        eventOverlap: (stillEvent, movingEvent) => {
          // Исключения из проверки на пересечение событий
          // we use internal event property here, as there is no other option to get reosurceId
          if (this.viewSwitch) {
            if (+stillEvent._def.resourceIds[0] === FREE_BOARD_RESOURCE_ID ||
              stillEvent.extendedProps.is_double_account ||
              stillEvent.extendedProps.isUnavailability ||
              stillEvent.extendedProps.isAvailability ||
              stillEvent.extendedProps.isBreak ||
              movingEvent.extendedProps.is_double_account) {
              return true
            }
            if (stillEvent.extendedProps.isBackground && stillEvent.extendedProps.allowShifts) {
              return true
            }
            // Проверка на пересечение событий
            if (this.overlapLevel !== 'no_control' && this.isOverlap(stillEvent.start, stillEvent.end, movingEvent.start, movingEvent.end)) {
              return false
            }
            return true
          } else {
            return true
          }
        },
        viewDidMount: (info) => {
          // this.view = info.view
          this.statistic.showStat = false
          this.statistic.data = {}
          if (this.isMobile) {
            this.mobileStyle()
          }
          // монтируем датапикер на кнопку в хедере календаря
          const { el } = mount(DatePickerComponent,
            {
              props: {
                locale: this.$store.getters.locale
              },
              app: app
            }
          )
          // console.log(el)
          const btn = document.querySelector('.fc-calendarButton-button')
          if (btn) {
            btn.replaceWith(el)
          }
        },
        datesSet: (info) => {
          this.$store.dispatch('updateView', info.view)
          this.updateSwitch = true
          this.$eventBus.emit('updateCurrentDate', this.view.currentStart)
        },
        eventClassNames: (arg) => {
          if (arg.event.extendedProps.isPreview) {
            return ['fade_in_black', 'rounded']
            // const style = `background-color:${arg.event.color}; opacity:0.2`
          }
          if (arg.event.extendedProps.isHoliday) {
            const style = `background-color:${arg.backgroundColor}; opacity:0.2`
            this.updateHolidayBackground(arg.event, style)
          }
          return arg.event.extendedProps.isBackground ? [] : ['rounded']
        },
        dateClick: (dateClickInfo) => {
          if (this.currentProject.block_change_in_past_for_manager &&
              dateClickInfo.resource.id !== 'holidays' &&
              !this.isEmployeeHasPermission('update-schedule-admin')) {
            if (moment(dateClickInfo.date).isBefore(moment().startOf('day'))) {
              return
            }
            // return !(project.block_change_in_past_for_manager === true && moment(this.shift.start).isBefore(moment()))
          }
          if (this.isEmployeeHasPermission('create-shift-admin') ||
            this.rolesByProjects[this.currentProjectId] === 'manager') {
            if (dateClickInfo.resource.id === 'holidays') {
              this.$eventBus.emit('addHoliday', {
                start: dateClickInfo.date,
                end: dateClickInfo.date
              })
            }
            if (this.viewSwitch || +dateClickInfo.resource.id === 0) {
              if (!dateClickInfo.resource.extendedProps.deleted && dateClickInfo.resource.id !== 'info_event' && dateClickInfo.resource.id !== 'holidays') {
                this.$eventBus.emit('addShiftShow', {
                  addShiftUserId: +dateClickInfo.resource.id,
                  userMarks: this.userMarks,
                  shiftAddDate: dateClickInfo.date
                })
              }
            }
            if (!this.viewSwitch) {
              if (dateClickInfo.resource.id !== 'info_event' && dateClickInfo.resource.id !== 'holidays' && +dateClickInfo.resource.id !== 0) {
                this.$eventBus.emit('addShiftShowTemplateView', {
                  templateId: +dateClickInfo.resource.id,
                  userMarks: this.userMarks,
                  shiftAddDate: dateClickInfo.date
                })
              }
            }
          } else if (this.isRoleUser && this.currentEmployeeId && dateClickInfo.resource.id === this.currentEmployeeId.toString()) {
            this.$eventBus.emit('requestCreateShiftShow', {
              addShiftUserId: +dateClickInfo.resource.id,
              userMarks: this.userMarks,
              shiftAddDate: dateClickInfo.date
            })
          }
        },
        eventClick: (eventClickInfo) => {
          this.eventClickAction(eventClickInfo)
        },
        eventResize: (info) => {
          this.isDropOrResize = 'resize'
          // prevent resize of events on free board
          if (info.event.extendedProps.employee_id === FREE_BOARD_RESOURCE_ID) {
            info.revert()
            return false
          }
          const eventId = info.event.id
          const from = this.fromZoneToZone(info.event.start, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          const to = this.fromZoneToZone(info.event.end, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          const query = `employee_id=${info.event.extendedProps.employee_id}&time_from=${from}&time_to=${to}&shift_id=${+eventId}&break_time=${+info.event.extendedProps.break_time}`
          this.$store.dispatch('isWeekOvertime', [info.event.extendedProps.schedule_id, query]).then(result => {
            if (!result.is_overtime || result.week_overtime_control_type === 'allow_overtime') {
              this.isShiftHasRequest(info, eventId, info.event.extendedProps.employee_id, result.is_overtime)
              return true
            }

            if (result.is_overtime && result.week_overtime_control_type === 'block_overtime') {
              info.revert()
              this.toastError(this.$i18n?.t('BLOCKED_BY_WEEK_OVERTIME'))
              return false
            }

            if (result.is_overtime && result.week_overtime_control_type === 'confirm_overtime') {
              this.getSwalWeekOvertime().then((result) => {
                this.isShiftHasRequest(info, eventId, info.event.extendedProps.employee_id)
                return true
              }).catch(() => {
                info.revert()
                return false
              })
              return false
            }
          }).catch((err) => {
            this.toastError(this.$i18n?.t(err.response.data.message))
            info.revert()
            return false
          })
        },
        eventDrop: (info) => {
          if (!(this.isEmployeeHasPermission('create-shift-admin') ||
            this.rolesByProjects[this.currentProjectId] === 'manager')) {
            info.revert()
            return false
          }
          // <-start-- если праздник заблокирован для добавления эвентов
          let counter = 0
          const holidays = this.holidays
          holidays.forEach(holiday => {
            if (!holiday.allow_shifts) {
              const startTime = this.fromZoneToZone(holiday.start, 'UTC', this.actualTZ)
              const endTime = this.fromZoneToZone(holiday.end, 'UTC', this.actualTZ)
              if (moment(info.event.start).isBetween(startTime, endTime)) {
                info.revert()
                counter++
                // return false
              }
            }
          })
          if (counter > 0) return false
          // <-end--
          let userId = info.event.extendedProps.employee_id
          let eventId = info.event.id
          if (info.newResource) {
            if (this.viewSwitch || (+info.newResource.id === FREE_BOARD_RESOURCE_ID)) {
              userId = +info.newResource.id
              if (info.oldResource && +info.oldResource.id === FREE_BOARD_RESOURCE_ID) {
                const eventsOnFreeBoard = this.freeBoardEvents[info.event.id].length
                eventId = this.freeBoardEvents[info.event.id][eventsOnFreeBoard - 1].id
              }
            }
          }
          // if moving inside free board
          if (info.event.extendedProps.employee_id === FREE_BOARD_RESOURCE_ID && !info.oldResource) {
            const eventsOnFreeBoard = this.freeBoardEvents[info.event.id].length
            eventId = this.freeBoardEvents[info.event.id][eventsOnFreeBoard - 1].id
          }
          const from = this.fromZoneToZone(info.event.start, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          const to = this.fromZoneToZone(info.event.end, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
          const query = `employee_id=${+userId}&time_from=${from}&time_to=${to}&shift_id=${+eventId}&break_time=${+info.event.extendedProps.break_time}`
          this.$store.dispatch('isWeekOvertime', [info.event.extendedProps.schedule_id, query]).then(result => {
            if (!result.is_overtime || result.week_overtime_control_type === 'allow_overtime') {
              this.isShiftHasRequest(info, eventId, userId, result.is_overtime)
              return true
            }

            if (result.is_overtime && result.week_overtime_control_type === 'block_overtime') {
              info.revert()
              this.toastError(this.$i18n?.t('BLOCKED_BY_WEEK_OVERTIME'))
              return false
            }

            if (result.is_overtime && result.week_overtime_control_type === 'confirm_overtime') {
              this.getSwalWeekOvertime().then((result) => {
                this.isShiftHasRequest(info, eventId, userId)
                return true
              }).catch(() => {
                info.revert()
                return false
              })
              return false
            }
          }).catch((err) => {
            this.toastError(this.$i18n?.t(err.response.data.message))
            info.revert()
            return false
          })
        }
      }
    },
    isShiftHasRequest (info, eventId, userId, showWarning = false) {
      this.isDropOrResize = 'drop'
      if (info.event.extendedProps.request_id) {
        this.$confirm(this.$i18n?.t('DECLINE_REQUEST_WARNING'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Confirm'),
          cancelButtonText: this.$i18n?.t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          this.declineRequest(info.event.extendedProps.request_id, this.$i18n?.t('Canceled')).then(() => {
            this.isShiftInPast(info, eventId, userId, showWarning)
          }).catch(() => {
            info.revert()
          })
        }).catch(() => {
          info.revert()
        })
      } else {
        this.isShiftInPast(info, eventId, userId, showWarning)
      }
    },
    isShiftInPast (info, eventId, userId, showWarning = false) {
      if (moment.utc(info.event.start) < moment.utc() && this.warningInPast) {
        this.getSwal().then((result) => {
          this.onDropConfirm(eventId, info, userId, showWarning)
        }).catch(() => {
          info.revert()
        })
      } else {
        this.onDropConfirm(eventId, info, userId, showWarning)
      }
    },
    onDropConfirm (eventId, info, userId, showWarning) {
      this.saveOnDrop(eventId, info, userId).then((shift) => {
        if (shift && showWarning) {
          this.toastWarning(this.$i18n?.t('WARNING_WEEK_OVERTIME'))
        }
        if (this.isDropOrResize === 'drop') {
          this.afterDrop(shift, info)
        }
        if (this.isDropOrResize === 'resize') {
          this.afterResize(shift, info)
        }
      }).catch((err) => {
        this.onShiftPatchError(err, eventId, info, userId, showWarning)
      })
    },
    saveOnDrop (shiftId, info, userId) {
      // const oldEvent = (info.oldEvent) ? info.oldEvent : info.prevEvent
      // const eventsInteraction = new FullCalendarEventsInteractionClass(info.event, oldEvent)
      let data = {}
      // для стандартной вьюхи или при перетаскивании внутри ресурса или на фриборд или в кастомные смены
      if (this.viewSwitch ||
        !info.newResource ||
        info.newResource.id === CUSTOM_RESOURCE_ID ||
        info.newResource.id === FREE_BOARD_RESOURCE_ID.toString()) {
        const from = this.fromZoneToZone(info.event.start, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const to = this.fromZoneToZone(info.event.end, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        data = {
          // employee_id: this.viewSwitch || (info.newResource.id === FREE_BOARD_RESOURCE_ID.toString()) ? FREE_BOARD_RESOURCE_ID : userId,
          employee_id: userId,
          time_from: from,
          time_to: to
        }
        if (info.newResource && info.newResource.id === CUSTOM_RESOURCE_ID) {
          data.template_id = 0
        }
      } else { // для вьюхи по темплейтам, при перетаскивании между темплейтами или с фриборда на темплейт
        const template = this.templates.filter(item => item.id === +info.newResource.id)[0]
        const templateTimeA = template.time_from.split(':')
        const templateTimeB = template.time_to.split(':')
        const templateStart = moment().set({ h: +templateTimeA[0], m: +templateTimeA[1] })
        const templateEnd = moment().set({ h: +templateTimeB[0], m: +templateTimeB[1] })
        const eventStart = moment(info.event.start).set({ h: +templateTimeA[0], m: +templateTimeA[1] })
        let dateA, dateB
        // если время темплейта в рамках одного дня
        if (templateStart.isBefore(templateEnd)) {
          dateA = eventStart.set({ h: +templateTimeA[0], m: +templateTimeA[1] }).format(this.backendDateTimeFormat)
          dateB = eventStart.set({ h: +templateTimeB[0], m: +templateTimeB[1] }).format(this.backendDateTimeFormat)
        } else { // если темплейт с переходящими сменами (время начала === времени окончания, или время окончания < времени начала)
          dateA = eventStart.set({ h: +templateTimeA[0], m: +templateTimeA[1] }).format(this.backendDateTimeFormat)
          dateB = eventStart.add(1, 'day').set({
            h: +templateTimeB[0],
            m: +templateTimeB[1]
          }).format(this.backendDateTimeFormat)
        }
        const from = this.fromZoneToZone(dateA, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const to = this.fromZoneToZone(dateB, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        data = {
          time_from: from,
          time_to: to,
          template_id: template.id,
          color: template.color
        }
      }

      if (this.breaksAction) {
        data.breaks_action = this.breaksAction
      }
      this.breaksAction = null

      return this.$store.dispatch('patchShift', [shiftId, data])
    },
    onShiftPatchError (err, shiftId, info, userId, showWarning) {
      const errorMessage = err.response.data.message
      if (errorMessage === 'Shift is out of schedule range') {
        const link = `/c/${this.$store.getters.companyId}/schedule/${info.event.extendedProps.schedule_id}#resizeSchedule`
        this.toastError(
          this.$i18n?.t(
            `<div>${this.$i18n?.t(errorMessage)}</div><div><a href="${link}">${this.$i18n?.t('LINK_TO_RESIZE_SCHEDULE')}</a></div>`
          ),
          true
        )
        info.revert()
      } else if (errorMessage === 'Breaks outside the shift') {
        this.$confirm(this.$i18n?.t('key_warning_breaks_outside_shift'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
          cancelButtonText: this.$i18n?.t('No, cancel it!!'),
          type: 'warning',
          center: true
        }).then(() => {
          this.breaksAction = 'skip'
          this.onDropConfirm(shiftId, info, userId, showWarning)
        }).catch(() => {
          this.breaksAction = null
          info.revert()
        })
      } else {
        this.toastError(this.$i18n?.t(errorMessage))
        info.revert()
      }
    },
    afterDrop (shift, info) {
      info.event.setExtendedProp('confirmed_by_employee', false)
      info.event.setExtendedProp('plannedStart', this.fromZoneToZone(shift.planned_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat))
      info.event.setExtendedProp('plannedEnd', this.fromZoneToZone(shift.planned_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat))
      if (this.view.type === 'resourceTimelineDay' && shift.breaks && shift.breaks.length > 0) {
        this.refetch()
      }

      if ((info.newResource && +info.newResource.id === FREE_BOARD_RESOURCE_ID) ||
        (info.oldResource && +info.oldResource.id === FREE_BOARD_RESOURCE_ID) ||
        (info.event.extendedProps.employee_id === FREE_BOARD_RESOURCE_ID && !info.oldResource)) {
        this.refetchEventsLazy()
      } else {
        if (!this.viewSwitch && info.newResource) {
          info.event.setExtendedProp('template_name', this.currentProject.templatesByProject[[shift.schedule_id, shift.template_id].join('__sp__')])
        }
        // перетаскивание внутри ресурса темлейта
        // if (!this.viewSwitch && !info.newResource) {
        //   info.event.setResources([CUSTOM_RESOURCE_ID])
        // }
        info.event.setExtendedProp('employee_id', shift.employee_id)
        // перетаскивание между ресурсами
        if (info.newResource) {
          if (this.viewSwitch) { // ресурсы пользователи
            info.event.setExtendedProp('user', info.newResource.title)
          } else { // ресурсы темлпейты
            const event = this.setEvent(shift)
            info.event.setStart(event.start)
            info.event.setEnd(event.end)
            // info.event.setProp('color', info.newResource.extendedProps.color)
          }
        }
      }
      this.$eventBus.emit('checkNotify')
    },
    afterResize (shift, info) {
      info.event.setExtendedProp('confirmed_by_employee', false)
      info.event.setExtendedProp('plannedStart', this.fromZoneToZone(shift.planned_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat))
      info.event.setExtendedProp('plannedEnd', this.fromZoneToZone(shift.planned_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat))
      if (!this.viewSwitch && info.newResource) {
        info.event.setExtendedProp('template_name', this.currentProject.templatesByProject[[shift.schedule_id, shift.template_id].join('__sp__')])
      }
      if (!this.viewSwitch) {
        info.event.setResources([CUSTOM_RESOURCE_ID])
      }
      this.$eventBus.emit('checkNotify')
    },
    updateDisplayMode (e) {
      e.preventDefault()
      const el1 = document.getElementById('viewSettingsDropdown')
      el1.classList.remove('show')
      const el2 = document.getElementById('viewSettings')
      el2.classList.remove('show')
      const el3 = document.getElementById('viewSettingsDropdownMenu')
      el3.style = null
      el3.style.zIndex = 15000
      el3.classList.remove('show')
      localStorage.setItem('monthDisplayType', this.monthDisplay)
      localStorage.setItem('presenceStatMode', this.presenceStatMode)
      localStorage.setItem('dayDisplayType', this.dayDisplay)
      localStorage.setItem('minTimeCalendar', this.maxMinTime[0])
      localStorage.setItem('maxTimeCalendar', this.maxMinTime[1])
      // showPosition: true,
      //   showIdentifier: true,
      //   primarySortOrder: 'name',
      localStorage.setItem('showPosition', this.showPosition)
      localStorage.setItem('showIdentifier', this.showIdentifier)
      localStorage.setItem('primarySortOrder', this.primarySortOrder)
      localStorage.setItem('templateSortOrder', this.templateSortOrder)
      localStorage.setItem('nameFormat', this.nameFormat)
      this.calendarTimeRange = {
        start: this.maxMinTime[0],
        end: this.maxMinTime[1]
      }
      if (this.presenceStatMode === 'text') {
        const segments = this.calendarTimeRange.end - this.calendarTimeRange.start
        this.timeLineStatSetup = {
          segments: segments,
          segmentStep: 1,
          segmentType: 'hours'
        }
      } else {
        const segments = (this.calendarTimeRange.end - this.calendarTimeRange.start) * 60 / 5
        this.timeLineStatSetup = {
          segments: segments,
          segmentStep: 5,
          segmentType: 'minutes'
        }
      }
      this.setupTimeLineStatistic()
      this.calendarApi.destroy()
      this.setCalendarOptions()
      this.$nextTick(() => {
        if (this.$refs.scheduleCalendar) {
          this.init().then(() => {
            this.calendarApi.setOption('height', 'auto')
            this.calendarApi.render()
          })
        }
      })
    },
    reloadDayStatistic () {
      if (this.presenceStatMode === 'text') {
        const segments = this.calendarTimeRange.end - this.calendarTimeRange.start
        this.timeLineStatSetup = {
          segments: segments,
          segmentStep: 1,
          segmentType: 'hours'
        }
      } else {
        const segments = (this.calendarTimeRange.end - this.calendarTimeRange.start) * 60 / 5
        this.timeLineStatSetup = {
          segments: segments,
          segmentStep: 5,
          segmentType: 'minutes'
        }
      }
      this.setupTimeLineStatistic()
    },
    updateHolidayBackground (event, style) {
      const elems = Array.from(document.getElementsByClassName('fc-timeline-slot-lane'))
      const start = moment(event.start)
      const end = moment(event.end)
      elems.forEach((elem, key) => {
        const elDateStart = moment(elem.getAttribute('data-date'))
        // костыль для вычисления end даты, т.к. в дата-аттрибутах хранится только дата начала
        const elDateEnd = elems[key + 1] ? moment(elems[key + 1].getAttribute('data-date')) : moment(elem.getAttribute('data-date'))
        if (elDateStart.isBefore(end) && elDateEnd.isAfter(start)) {
          elem.setAttribute('style', style)
        }
      })
    },
    markUserSwitch () {
      this.updateSwitch = true
    },
    updateWeekWorkingHoursStatistic () {
      if (this.view.currentStart && this.view.currentEnd && this.actualTZ) {
        this.$store.dispatch('getCompanyWorkingTime', [
          this.$store.getters.company.id,
          this.fromZoneToZone(this.view.currentStart, this.actualTZ, 'UTC').format(this.backendDateTimeFormat),
          this.fromZoneToZone(this.view.currentEnd, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        ]).catch(() => {})
      }
    },
    goToDate (value) {
      if (this.calendarApi && !moment(value).isSame(moment(this.view.currentStart))) this.calendarApi.gotoDate(value)
    },
    toggleViewMode () {
      this.viewSwitcher.showByUsers = !this.viewSwitcher.showByUsers
      this.$eventBus.emit('changeViewMode')
    },
    loadNextResource () {
      const notLoadedResources = this.filteredResources.filter(item => !this.loadedResources[item.id])
      if (notLoadedResources.length <= 10) {
        if (this.calendarApi) {
          const loaderResource = this.calendarApi.getResourceById('loaderResource')
          if (loaderResource) loaderResource.remove()
        }
      }
      const nextRes = notLoadedResources.slice(0, 10)
      nextRes.forEach(res => {
        this.loadedResources[res.id] = true
        this.calendarApi.addResource(res)
      })
    },
    unAvailabilityDate (date) {
      return moment(date).format(this.localeTimeFormat)
    },
    /** инициализация календаря */
    init () {
      return new Promise((resolve, reject) => {
        this.calendarApi = this.$refs.scheduleCalendar.getApi()
        this.initFC = true
        if (this.shouldUpdateWeekWorkingHoursStatistic === null) {
          this.$store.dispatch('updateShouldUpdateWeekStatistic', true)
        }
        resolve()
      })
    },
    isShift (info) {
      return info.event.id !== 'tmp_holiday' && info.event.id !== 'tmp_free_board' &&
          !info.event.extendedProps.isInfoEvent && !info.event.extendedProps.isBackground &&
          !info.event.extendedProps.isHoliday && !info.event.extendedProps.isUserRequest &&
          !info.event.extendedProps.isUnavailability
    },
    setupTimeLineStatistic () {
      const { segments, segmentStep, segmentType } = this.timeLineStatSetup
      this.timeLineStatClass = new TimeLineStatistic(this.viewType, this.actualTZ, segmentType, segments, segmentStep, this.localeTimeFormat)
      if (this.timeLineStatClass) {
        this.presenceStat.labels = this.timeLineStatClass.labels(moment(this.view.currentStart).add(this.calendarTimeRange.start, 'hours').toDate())
      }
    },
    updateTimelineStatistic (type) {
      if (this.view.type === 'resourceTimelineDay' && this.timeLineStatClass) {
        let events = type === 'shifts' ? this.filteredShifts : this.filteredBreaks
        const filteredResourcesId = []
        this.filteredResources.forEach(res => {
          filteredResourcesId.push(res.id)
        })
        events = events.filter(event => {
          return filteredResourcesId.includes(event.employee_id)
        })
        // this.$eventBus.emit('loading', true)
        this.timeLineStatClass.presence(moment(this.view.currentStart).add(this.calendarTimeRange.start, 'hours').toDate(), events, type).then((val) => {
          this.presenceStat[type] = val
          // this.$eventBus.emit('loading', false)
        }).catch(() => {
          // this.$eventBus.emit('loading', false)
        })
      } else {
        // this.$eventBus.emit('loading', false)
      }
    },
    mobileStyle () {
      let list = document.querySelectorAll('.fc-prev-button')
      list.forEach(elem => {
        elem.classList.add('btn-sm')
      })
      list = document.querySelectorAll('.fc-next-button')
      list.forEach(elem => {
        elem.classList.add('btn-sm')
      })
      list = document.querySelectorAll('.fc-today-button')
      list.forEach(elem => {
        elem.classList.add('btn-sm')
        elem.classList.add('ml-2')
      })
    },
    refetchResources (withEvents = false) {
      this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then((r) => {
        this.calendarApi.refetchResources()
        if (withEvents) {
          this.calendarApi.refetchEvents()
        }
      })
    },
    toggleMenu () {
      this.$emit('toggleMenu')
    },
    toggleImportBonusPenaltyShow () {
      this.importBonusPenaltyShow = !this.importBonusPenaltyShow
    },
    toggleCopyEventModalShow () {
      // let from = this.fromZoneToZone(moment(this.view.activeStart).format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      // let to = this.fromZoneToZone(moment(this.view.activeEnd).format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      this.copyEventModalShow = !this.copyEventModalShow
    },
    toggleDistributeFreeBoardModalShow () {
      this.distributeFreeBoardModalShow = !this.distributeFreeBoardModalShow
    },
    toggleEditUserModalShow (id) {
      this.resourceDropdownMenuDisable()
      this.editUserId = id
      this.editUserModalShow = !this.editUserModalShow
    },
    toggleEmergencyShiftsModalShow () {
      this.resourceDropdownMenuDisable()
      this.emergencyShiftsModalShow = !this.emergencyShiftsModalShow
    },
    resourceDropdownMenuDisable () {
      this.resourceDropdownMenuDisabled = true
      setTimeout(_ => {
        this.resourceDropdownMenuDisabled = false
      }, 200)
    },
    toggleAddUserModalShow () {
      this.addUserModalShow = !this.addUserModalShow
    },
    toggleAddTemplateModalShow () {
      this.addTemplateModalShow = !this.addTemplateModalShow
    },
    toggleMassShiftActionsShow () {
      if (this.massShiftActionsShow) {
        this.$eventBus.emit('initMassAction')
      }
      this.massShiftActionsShow = !this.massShiftActionsShow
    },
    updateHolidays (data) {
      let style = ''
      if (data.data) {
        const holiday = this.setHoliday(data.data)
        style = `background-color:${holiday.color}; opacity:0.2`
        this.updateHolidayBackground(holiday, style)
        this.doNotLoadShifts = true
        this.calendarApi.refetchEvents()
      } else {
        const holiday = this.calendarApi.getEventById('holiday__sp__' + data.id)
        style = 'background-color:white; opacity:0.5'
        this.updateHolidayBackground(holiday, style)
        holiday.remove()
      }
    },
    exportSchedule (exportType, viewType) {
      let period = 'month'
      if (viewType === 'resourceTimelineDay') {
        period = 'one_day'
      } else if (viewType === 'resourceTimeline3days') {
        period = 'three_day'
      } else if (viewType === 'resourceTimelineWeek') {
        period = 'week'
      }

      const mode = this.viewSwitcher.showByUsers ? 'by_users' : 'by_templates'

      let from = this.fromZoneToZone(moment(this.view.currentStart).format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      if (viewType === 'month') {
        from = this.fromZoneToZone(moment(this.view.currentStart).startOf('month').format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      }

      const displayUsers = this.userSwitcher.showAllUsers ? 'all' : 'with_shifts'
      this.$store.dispatch('exportSchedule', [this.currentProjectId, exportType, from, period, displayUsers, mode]).then(response => {
        const extension = exportType !== 'pdf' ? 'xlsx' : 'pdf'
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.$i18n?.t('export_schedule_')}_${moment(this.view.currentStart).format(this.backendDateFormat)}_${moment(this.view.currentEnd).format(this.backendDateFormat)}.${extension}`)
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
      })
    },
    clearFreeBoard () {
      const from = this.fromZoneToZone(moment(this.viewStart).format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(moment(this.viewEnd).format(this.backendDateTimeFormat), this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const schedules = []
      this.selectedTemplates.forEach(template => {
        const [scheduleId, templateId] = template.split('__sp__')
        schedules.push({
          schedule_id: scheduleId,
          template_id: templateId
        })
      })
      const data = {
        date_from: from,
        date_to: to,
        project_id: this.currentProjectId,
        schedules: schedules
      }
      this.$store.dispatch('deleteShiftsFromFreeBoard', [this.$store.getters.companyId, data]).then(response => {
        this.refetch()
      }).catch(() => {
      })
    },
    /** switcher all users / users with shifts */
    toggleUsersSwitch (value) {
      this.filterUsersWithShifts = value
      this.$nextTick(() => {
        this.calendarApi.refetchResources()
      })
    },
    deleteFreeBoardShift (shiftId) {
      this.$store.dispatch('deleteShiftFromList', shiftId)
      this.refetchEventsLazy()
      this.$eventBus.emit('checkNotify')
    },
    assignFreeBoardShift (shift) {
      if (shift.breaks && shift.breaks.length > 0) {
        this.refetch()
      } else {
        this.refetchEventsLazy()
      }
      this.$eventBus.emit('checkNotify')
    },
    assignShiftToFreeBoard (shift) {
      if (shift.breaks && shift.breaks.length > 0) {
        this.refetch()
      } else {
        this.refetchEventsLazy()
      }
      this.$eventBus.emit('checkNotify')
    },
    refetchEventsLazy () {
      this.doNotLoadShifts = true
      this.calendarApi.refetchEvents()
      if (this.$store.state.company.companyRole !== 'gg_user') {
        this.$eventBus.emit('checkNotify')
      }
    },
    refetchUser (withEvents = false) {
      this.refetchResources(withEvents)
    },
    refetch () {
      if (this.initFC) {
        this.calendarApi.refetchEvents()
        if (this.$store.state.company.companyRole !== 'gg_user') {
          this.$eventBus.emit('checkNotify')
        }
      }
    },
    handleResize () {
      this.timeSlotWidth()
      try {
        this.calendarApi.updateSize()
      } catch (e) {
        // do nothing
      }
    },
    applyFilter (resources) {
      const filteredResources = []
      // const statistic = resources.map((e) => e.id).indexOf('info_event')
      const holidayIndex = resources.map((e) => e.id).indexOf('holidays')
      const freeboardIndex = resources.map((e) => e.id).indexOf(0)
      if (this.isEmployeeHasPermission('get-dashboard-project-users')) {
        const statistic = resources.map((e) => e.id).indexOf('info_event')
        if (resources[statistic]) {
          filteredResources.push(resources[statistic])
        }
      }
      if (resources[freeboardIndex]) {
        filteredResources.push(resources[freeboardIndex])
      }
      if (resources[holidayIndex]) {
        filteredResources.push(resources[holidayIndex])
      }
      const filter = new FullCalendarFilters(resources, filteredResources)
      if (this.filter.values.usersMultiplySelect && this.filter.values.usersMultiplySelect.length > 0) {
        filter.filterByUsers(this.filter.values.usersMultiplySelect)
      }
      if (this.filter.values.usersPositions && this.filter.values.usersPositions.length > 0) {
        filter.filterByPositions(this.filter.values.usersPositions)
      }
      if (this.filter.values.byEmployeeMarks && this.filter.values.byEmployeeMarks.length > 0) {
        filter.filterByUserMarks(this.filter.values.byEmployeeMarks, this.userMarks)
      }
      if (this.filter.values.byShiftMarks && this.filter.values.byShiftMarks.length > 0) {
        filter.filterByShiftMarks(this.filter.values.byShiftMarks, this.shifts, this.view)
      }
      if (this.filter.values.byLocations && this.filter.values.byLocations.length > 0) {
        filter.filterByLocations(this.filter.values.byLocations, this.shifts, this.view)
      }
      if (this.filter.values.schedulesMultiplySelect && this.filter.values.schedulesMultiplySelect.length > 0) {
        filter.filterBySchedules(this.filter.values.schedulesMultiplySelect, this.$store.getters.schedulesIndexed)
      }
      return filter.getResources()
    },
    applyShiftFilter (fcEvents) {
      if (this.filter.values.byShiftMarks && this.filter.values.byShiftMarks.length > 0) {
        fcEvents = fcEvents.filter((item) => {
          if (!item.isShift) {
            return true
          }
          const filteredArray = this.filter.values.byShiftMarks.filter(value => item.marks.includes(value))
          return filteredArray.length > 0
        })
      }
      if (this.filter.values.byLocations && this.filter.values.byLocations.length > 0) {
        fcEvents = fcEvents.filter((item) => {
          if (!item.isShift) {
            return true
          }
          const filteredArray = this.filter.values.byLocations.filter(value => {
            if (!item.location) {
              return false
            }
            return value === item.location.id
          })
          return filteredArray.length > 0
        })
      }
      if (this.filter.values.schedulesMultiplySelect && this.filter.values.schedulesMultiplySelect.length > 0) {
        const templatesId = []
        this.filter.values.schedulesMultiplySelect.forEach(id => {
          const schedule = this.$store.getters.schedulesIndexed[id]
          if (schedule) {
            schedule.templates.forEach(template => {
              templatesId.push(+template.id)
            })
          }
        })
        fcEvents = fcEvents.filter(item => {
          return templatesId.includes(+item.template)
        })
      }
      if (this.filter.values.byStatuses && this.filter.values.byStatuses.length > 0) {
        fcEvents = fcEvents.filter(item => {
          if (this.filter.values.byStatuses.includes('late')) {
            if (item.presence_info?.lateness && item.presence_info.lateness > 0) {
              return true
            }
          }
          if (this.filter.values.byStatuses.includes('early')) {
            if (item.presence_info?.early && item.presence_info.early > 0) {
              return true
            }
          }
          if (this.filter.values.byStatuses.includes('absent')) {
            if (item.presence_info?.absence) {
              return true
            }
          }
          if (this.filter.values.byStatuses.includes('started')) {
            if (item.status_info && item.status_info.type === 'shift_start') {
              return true
            }
          }
          if (this.filter.values.byStatuses.includes('finished')) {
            if (item.status_info && item.status_info.type === 'shift_end') {
              return true
            }
          }
          return false
        })
      }
      return fcEvents
    },
    applyFilterUsersWithShifts (resources) {
      return new Promise((resolve, reject) => {
        if (this.filterUsersWithShifts) {
          const templatesIDs = {}
          this.selectedTemplates.forEach(template => {
            const id = +template.split('__sp__')[1]
            const scheduleId = +template.split('__sp__')[0]
            if (!templatesIDs[scheduleId]) {
              templatesIDs[scheduleId] = {}
            }
            templatesIDs[scheduleId][id] = true
          })
          const filter = new FullCalendarFilters(resources, resources)
          filter.filterByUsersWithShifts(this.shifts, templatesIDs).then((filteredResources) => {
            this.$store.dispatch('updateFilteredResources', filteredResources)
            resolve(filteredResources)
          })
        } else {
          this.$store.dispatch('updateFilteredResources', resources)
          resolve(resources)
        }
      })
    },
    /** расчет ширины слота временной сетки в зависимости от ширины контейнера */
    // todo убрать горизонтальный скролл, может не программно а с помощью стилей, тогда это можно будет выпилить
    timeSlotWidth () {
      const calendarEl = this.$refs.sizing
      const containerWidth = calendarEl.clientWidth
      this.slotWidth3days = Math.round(parseFloat((containerWidth - this.resourceColumnWidth) / 72))
      this.slotWidth1day = Math.round(parseFloat((containerWidth - this.resourceColumnWidth) / 96))

      // calculate more precise resource column width
      this.resourceColumnWidth = Math.floor(containerWidth - this.slotWidth1day * 96 - 22)
    },
    /** Отправка статистики по сменам для 1-дневной вьюхи */
    sendStatistic (activeStart, activeEnd) {
      const employeesId = []
      this.filteredResources.forEach(resource => {
        employeesId.push(+resource.id)
      })
      this.statistic.showStat = false
      this.statistic.data = {}
      this.shifts.forEach(event => {
        if (event.employee_id !== FREE_BOARD_RESOURCE_ID &&
          this.selectedTemplates.includes([event.schedule_id, event.template_id].join('__sp__')) &&
          employeesId.includes(event.employee_id) &&
            this.toTimeZone(event.time_from).isSameOrAfter(moment(activeStart)) &&
            this.toTimeZone(event.time_from).isBefore(activeEnd)) {
          const id = [moment(event.time_from).format(this.backendDateTimeFormat), moment(event.time_to).format(this.backendDateTimeFormat)].join('_')
          if (id in this.statistic.data) {
            this.statistic.data[id] += 1
          } else {
            this.statistic.data[id] = 1
          }
        }
      })
      this.statistic.showStat = true
      this.$eventBus.emit('showStat', this.statistic)
    },
    /** SweetAlert для смен из прошлого периода */
    getSwal () {
      return this.$confirm(this.$i18n?.t('You want to take action towards a shift that has already started or ended.'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      })
    },
    /** SweetAlert для смен из прошлого периода */
    getSwalWeekOvertime () {
      return this.$confirm(this.$i18n?.t('CONFIRM_WEEK_OVERTIME'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      })
    },
    /** добавление пользователям шифтов через модалки без перезагрузки ресурсов */
    addShiftsFromModal (shifts) {
      if (shifts.length > 1 || shifts[0].employee_id === 0) { // если добавляется больше 1 шифта
        this.doNotLoadShifts = true
        this.calendarApi.refetchEvents()
      } else { // если добавляется 1 шифт
        shifts.forEach(shift => {
          const event = this.setEvent(shift)
          // if (shift.employee_id === 0) {
          //   await this.$store.dispatch('addFreeBoardEvent', Object.assign({}, event))
          //   event.id = event.freeBoardKey
          //   event.counter = this.freeBoardEvents[event.freeBoardKey].length
          // }
          this.$store.dispatch('addFCEvent', event)
          this.calendarApi.addEvent(event, true)
          if (shift.breaks && this.viewType === 'resourceTimelineDay') {
            const viewMode = localStorage.getItem('dayDisplayType')
            shift.breaks.forEach(breakSlot => {
              const slot = this.setBreaks(breakSlot, viewMode)
              this.calendarApi.addEvent(slot, true)
            })
          }
        })
      }
    },
    deleteShiftFromModal (ids) {
      ids.forEach(id => {
        const event = this.calendarApi.getEventById(id)
        if (event) {
          event.remove()
        }
      })
    },
    declineRequest (requestId, reason) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getRequest', [this.$store.getters.companyId, requestId]).then(shiftRequest => {
          const data = {
            subtype: shiftRequest.subtype,
            status: 'declined',
            message: reason
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, requestId, data]).then(response => {
            resolve(response)
          }).catch((e) => {
            reject(e)
          })
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateStatistic () {
      const infoEvents = this.statisticEvents.updateStatistic(this.filteredShifts, this.filteredResources, this.viewSwitch).getInfoEvents()
      // todo this.statisticEvents это класс, который не является компонентом vue, я хз как в класс прокинуть стор, так чтобы диспатч оттуда дергать
      this.$store.dispatch('updateInfoEvents', infoEvents)
    },
    /** обработка кликов по ивентам на календаре */
    eventClickAction (eventClickInfo) {
      if (this.isPreview) {
        return false
      }
      const { isAvailability, isUnavailability, isActivity } = eventClickInfo.event.extendedProps
      if (isAvailability || isUnavailability || isActivity) return

      let isInPast = false
      if (moment.utc(eventClickInfo.event.start) < moment.utc()) {
        isInPast = true
      }
      if (eventClickInfo.event.extendedProps.employee_id === FREE_BOARD_RESOURCE_ID) { // handle free board click action
        // prevent event click if its in the past and user is not an admin or manager
        if (isInPast && !(this.isEmployeeHasPermission('create-shift-admin') ||
          this.rolesByProjects[this.currentProjectId] === 'manager')) {
          return false
        }
        let canUserTakeShift = true
        const freeBoardEvents = this.freeBoardEvents[eventClickInfo.event.id]
        let requestedShift = null
        let requestedShiftIndex = null
        freeBoardEvents.forEach((event, index) => {
          requestedShift = Object.assign({}, event)
          requestedShiftIndex = index
        })
        if (!requestedShift) {
          canUserTakeShift = false

          this.$eventBus.emit('canTakeShift', canUserTakeShift)
        } else {
          if (this.rolesByProjects[this.currentProjectId] === 'user' &&
            !(this.isEmployeeHasPermission('create-shift-admin') ||
              this.rolesByProjects[this.currentProjectId] === 'manager')) {
            canUserTakeShift = this.canUserTakeShift(eventClickInfo.event.extendedProps.schedule_id, eventClickInfo.event.start, eventClickInfo.event.end)
            this.$eventBus.emit('canTakeShift', canUserTakeShift)
          } else {
            this.createAssignUsers(eventClickInfo.event)
            this.$eventBus.emit('canTakeShift', canUserTakeShift)
          }
        }
        const event = eventClickInfo.event.extendedProps.request_id ? this.freeBoardEvents[eventClickInfo.event.id][0] : this.freeBoardEvents[eventClickInfo.event.id][requestedShiftIndex]
        this.$eventBus.emit('clickFreeBoardEventModalShow', {
          event: event ? event.id : null,
          isInPast: isInPast,
          freeBoardStackedEvents: eventClickInfo.event.extendedProps.request_id
            ? this.freeBoardEvents[eventClickInfo.event.id]
            : this.freeBoardEvents[eventClickInfo.event.id]
        })
      } else {
        if (!eventClickInfo.event.extendedProps.isInfoEvent &&
            !eventClickInfo.event.extendedProps.isHoliday &&
            !eventClickInfo.event.extendedProps.isUnavailability &&
            !eventClickInfo.event.extendedProps.isBreak &&
            !eventClickInfo.event.extendedProps.isUserRequest) { // если не инфоэвент
          if (this.currentEmployeeId !== eventClickInfo.event.extendedProps.employee_id &&
            this.rolesByProjects[this.currentProjectId] === 'user' &&
            !(this.isEmployeeHasPermission('create-shift-admin') ||
              this.rolesByProjects[this.currentProjectId] === 'manager')) {
            return false
          }

          const value = {
            actionShift: eventClickInfo.event,
            schedulesById: this.schedulesById,
            isInPast: isInPast
          }
          this.$eventBus.emit('clickEventModalShow', value)
        }
        if (eventClickInfo.event.extendedProps.isHoliday &&
          this.isEmployeeHasPermission('create-holiday')) {
          this.$eventBus.emit('addHoliday', this.holidaysById[eventClickInfo.event.id.split('__sp__')[1]])
        }
        if (eventClickInfo.event.extendedProps.isUserRequest) {
          this.$eventBus.emit('clickUserRequestModalShow', eventClickInfo.event.extendedProps)
        }
        if (eventClickInfo.event.extendedProps.isBreak) {
          if (this.isEmployeeHasPermission('get-dashboard-project-users')) {
            const shift = this.calendarApi.getEventById(eventClickInfo.event.extendedProps.shiftId.toString())
            this.$eventBus.emit('breakExchangeModalShow', {
              breakEvent: eventClickInfo.event,
              shift: shift
            })
          }
          if (!this.isEmployeeHasPermission('get-dashboard-project-users') &&
            this.$store.getters.currentCompanyUserId === eventClickInfo.event.extendedProps.employeeId) {
            const shift = this.calendarApi.getEventById(eventClickInfo.event.extendedProps.shiftId.toString())
            this.$eventBus.emit('breakExchangeModalShow', {
              breakEvent: eventClickInfo.event,
              shift: shift
            })
          }
        }
      }
    },
    /**
       * Проверка, может ли обычный пользователь взять смену с фриборда
       * берем все шифты с id текущего пользователя и проверяем на пересечение временного диапазона с шифтом с фриборда
       * аналогично проверяем на пересечение с подтвержденными тайм офф реквестами
       */
    canUserTakeShift (scheduleId, start, end) {
      /** Проверяем галочку разрешения обмена сменами, если обмен не разрешен - блокируем клик по фриборду */
      let result = false
      const allowTakeFromFreeboard = this.currentProject.shifts_take_freeboard || this.schedulesById[scheduleId].allow_take_from_freeboard
      if (this.schedulesById[scheduleId] && allowTakeFromFreeboard) {
        const currentUserId = this.$store.getters.currentCompanyUserId
        result = true
        this.shifts.forEach(shift => {
          if (shift.employee_id === currentUserId) {
            if (this.isOverlap(this.fromZoneToZone(shift.time_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat),
              this.fromZoneToZone(shift.time_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat),
              start,
              end)) {
              result = false
            }
          }
        })
        const requests = this.fcRequests
        requests.forEach(request => {
          if (request.created_by === currentUserId) {
            if (this.isOverlap(this.fromZoneToZone(request.date_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat),
              this.fromZoneToZone(request.date_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat),
              start,
              end)) {
              result = false
            }
          }
        })
      }
      return result
    },
    /**
       * Список пользователей, которым можно назначить смену с фриборда
       * если время начала одной смены совпадает со временем конца другой смены, считается что смены не пересекаются
       */
    createAssignUsers (event) {
      const AssignUsersObject = {}
      const overlapUsersId = []

      this.shifts.forEach(shift => {
        if (shift.employee_id !== FREE_BOARD_RESOURCE_ID) {
          // Пересечение диапазонов (StartA <= EndB) and (EndA >= StartB)
          if (this.isOverlap(this.fromZoneToZone(shift.time_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat), this.fromZoneToZone(shift.time_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat), event.start, event.end) && !shift.is_double_account) {
            overlapUsersId.push(+shift.employee_id)
          }
        }
      })

      this.schedulesById[event.extendedProps.schedule_id].users.forEach(user => {
        if (!overlapUsersId.includes(user.employee_id)) {
          const employee = this.preloadUsers[user.employee_id]
          if (employee) {
            AssignUsersObject[user.employee_id] = employee.full_name
          }
        }
      })
      this.$eventBus.emit('assignUsersObject', AssignUsersObject)
    },

    setDeletedUserMarks (usersMarks) {
      return new Promise((resolve, reject) => {
        const marksData = {}
        const projectMarks = this.projectMarks
        const projectMarksById = {}

        projectMarks.forEach(mark => {
          projectMarksById[mark.id] = mark
        })

        Object.keys(usersMarks).forEach(scheduleId => {
          Object.keys(usersMarks[scheduleId]).forEach(userId => {
            if (!marksData[userId]) {
              marksData[userId] = []
            }

            usersMarks[scheduleId][userId].forEach(markId => {
              if (projectMarksById[markId]) {
                marksData[userId].push({
                  markId: markId,
                  color: projectMarksById[markId].color,
                  name: projectMarksById[markId].name,
                  scheduleId: scheduleId
                })
              }
            })
            const uniqArr = [...new Set(marksData[userId])]
            marksData[userId] = uniqArr
          })
        })
        this.$store.commit('USER_MARKS_DELETED', marksData)
        resolve()
      })
    },
    /** Объект, содержащий списки расписаний, в которых состоят пользователи, ключем является id пользователя */
    userSchedules (userId, schedule) {
      if (this.userSchedule[userId]) {
        this.userSchedule[userId].push({
          title: schedule.name,
          color: schedule.color
        })
      } else {
        this.userSchedule[userId] = [{
          title: schedule.name,
          color: schedule.color
        }]
      }
    },
    deleteShift (id) {
      this.$store.dispatch('getShift', id).then(shift => {
        const message = shift.request_id ? this.$t('DELETE_WITH_REQUEST') : this.$t('Are you sure?')
        this.$confirm(message, this.$t('Warning'), {
          confirmButtonText: this.$t('Ok'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          if (shift.request_id) {
            this.$store.dispatch('getRequest', [this.$store.getters.companyId, shift.request_id]).then(response => {
              this.cancelRequest(this.$t('Canceled'), response)
            })
          }
          this.$store.dispatch('deleteShift', shift.id).then(() => {
            this.refetchEventsLazy()
            this.toastSuccess(this.$t('Shift successfully deleted'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }).catch(() => {})
      }).catch(() => {})
    },
    cancelRequest (reason, request) {
      return new Promise((resolve, reject) => {
        let data
        if (reason) {
          data = {
            subtype: request.subtype,
            status: 'declined',
            message: reason
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, request.id, data]).then(response => {
            resolve(response)
          }).catch((e) => {
            reject(e)
          })
        } else {
          this.toastWarning(this.$t('Enter reason'))
          resolve(false)
        }
      })
    },
    putOnFreeBoard (id) {
      if (this.isEmployeeHasPermission('create-shift-admin') ||
        this.rolesByProjects[this.currentProjectId] === 'manager') {
        this.$confirm(this.$t('MOVE_TO_FREE_BOARD_CONFIRM'), this.$t('Are you sure?'), {
          confirmButtonText: this.$t('Ok'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          this.assignShift(id)
        }).catch(() => {})
      }
    },
    assignShift (id) {
      this.$store.dispatch('getShift', id).then(shift => {
        if (shift.request_id) {
          this.$confirm(this.$t('DECLINE_REQUEST_WARNING'), this.$t('Warning'), {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
            center: true
          }).then(() => {
            return this.handleAssign(id)
          }).catch(() => {})
        } else {
          return this.handleAssign(id)
        }
      })
      // всегда админ или менеджер
    },
    handleAssign (id) {
      const data = {
        employee_id: 0
      }
      return this.$store.dispatch('patchShift', [id, data]).then((shift) => {
        this.assignShiftToFreeBoard(shift)
        this.toastSuccess(this.$t('Shift successfully put on the Free Board'))
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  }
}
</script>
<style lang="scss">
  .user-mark {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }

  .fc-event-main > span {
    width: 100%;
  }

  .fc-calendarButton-button {
    padding: 0 !important;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center !important;
    width: 0px !important;
    height: 0px !important;
    border: none !important;
    background: none !important;
    /*background:url('../../../../public/static/images/App/calendar-dark.png') center no-repeat!important;*/
  }
  /*.fc-timeline-bg-harness:hover {*/
  /*  background:url('../../../../public/static/images/App/calendar-dark.png') center no-repeat!important;*/
  /*}*/
  .bg-resource-color {
    background-color: rgb(140, 140, 140, 0.4)!important;
  }
  .el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    max-height: 100vh;
    overflow: auto;
  }
  .el-select-dropdown.el-popper {
    z-index: 20000!important;
  }
  .el-slider__button-wrapper .el-tooltip, .el-slider__button-wrapper::after {
    display: inline-block;
    vertical-align: middle;
    z-index: 21000!important;
  }
  .el-tooltip__popper{
    position:absolute;
    border-radius:4px;
    padding:10px;
    z-index:21000!important;
    font-size:12px;
    line-height:1.2;
    min-width:10px;
    word-wrap:break-word
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0!important;
  }
  .el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
    height: 20px!important;
    line-height: 20px!important;
    border-bottom: 0!important;
    color: #909399;
  }
  .el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
    border-bottom: 0!important;
  }
  .el-sub-menu.is-active .el-sub-menu__title {
    border-bottom: 0!important;
  }
  .el-sub-menu__title {
    padding: 0 2px!important;
  }
  .fade_in_black {
    opacity: 0.5!important;
  }
</style>
