<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'UserTasks', params: { companyId: companyId} }">
                <span class="breadcrumb-item">{{ $t('Tasks') }}</span>
              </router-link>
            </li>
            <li v-if="this.$route.params['taskId']" class="breadcrumb-item title-text active" aria-current="page">
              {{ this.$i18n?.t("EDIT_TASK") }}
              <ui-hint :content="$t('TASK_HINTS')"/>
            </li>
            <li v-if="!this.$route.params['taskId']" class="breadcrumb-item title-text active" aria-current="page">
              {{ this.$i18n?.t("CREATE_TASK") }}
              <ui-hint :content="$t('TASK_HINTS')"/>
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <div ref="form">
            <div class="row">
              <div class="col-lg-6">
                <div class="col-lg-12 pb-2">
                  <h3>{{ $t('main information') }}</h3>
                </div>
                <template v-if="allow_starting_without_shifts">
                  <div class="col-lg-12 pb-2">
                    {{ $t("Employee") }}: <span v-if="task.employee" class="ml-2 font-weight-bold">{{ task.employee.full_name }}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Employee") }}: <span v-if="task.shift && task.shift.employee" class="ml-2 font-weight-bold">{{ task.shift.employee.full_name }}</span>
                  </div>
                </template>
                <div v-if="task.children && task.children.length > 0" class="col-lg-12 pb-2">
                  <div v-for="child in task.children" :key="child.id">
                    {{ $t("SUB_EMPLOYEE") }}: <span class="ml-2 font-weight-bold">{{ child.shift.employee.full_name }}</span>
                  </div>
                </div>
                <div v-if="task.parent" class="col-lg-12 pb-2">
                  <div>
                    {{ $t("MAIN_EMPLOYEE") }}: <span class="ml-2 font-weight-bold">{{ task.parent.employee.full_name }}</span>
                  </div>
                </div>
                <div v-if="task.sisters && task.sisters.length > 0" class="col-lg-12 pb-2">
                  <div v-for="child in task.sisters" :key="child.id">
                    {{ $t("SUB_EMPLOYEE") }}: <span class="ml-2 font-weight-bold">{{ child.shift.employee.full_name }}</span>
                  </div>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Title") }}: <span class="ml-2 font-weight-bold">{{ task.title }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Description") }}: <span class="ml-2 font-weight-bold">{{ task.description }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Client") }}:
                  <span class="ml-2 font-weight-bold" v-if="task.client">{{ task.client['name'] }}</span>
                  <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                </div>
                <template v-if="task.client">
                  <div class="col-lg-12 pb-2">
                    {{ $t("Address") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.client_address">{{ task.client_address['address'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                </template>
                <template v-if="task.client && task.client['fields'].length > 0">
                  <template v-for="(c_field, index) in task.client['fields']" :key="index">
                    <div class="col-lg-12 pb-2">
                      <template v-if="c_field['field_type'] === 'text'">{{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] }}</span></template>
                      <template v-else-if="c_field['field_type'] === 'number'">{{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ Math.round(c_field['pivot']['value'] * 100) / 100 }}</span>
                      </template>
                      <template v-else-if="c_field['field_type'] === 'checkbox'">{{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] ? $t('Yes') : $t('No') }}</span>
                      </template>
                      <template v-else-if="c_field['field_type'] === 'select'">{{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] }}</span></template>
                      <template v-else-if="c_field['field_type'] === 'multi_select'">{{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ c_field['pivot']['options'].join(', ') }}</span></template>
                      <template v-else-if="c_field['field_type'] === 'date_range_picker'">
                        {{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{
                          formatDate(c_field['pivot']['options'][0])
                        }} - {{ formatDate(c_field['pivot']['options'][1]) }}</span>
                      </template>
                      <template v-else-if="c_field['field_type'] === 'date_picker'">
                        {{ c_field['title'] }}: <span
                        class="ml-2 font-weight-bold">{{ formatDate(c_field['pivot']['value']) }}</span>
                      </template>
                      <!-- {{ c_field }} -->
                    </div>
                  </template>
                </template>
                <div v-if="isEmployeeHasPermission('create-schedule')" class="col-lg-12 pb-2">
                  {{ $t("Lead time (in hrs)") }}: <span
                  class="ml-2 font-weight-bold">{{ Math.round(task.lead_time * 100) / 100 }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Scheduled time") }}:
                  <span class="ml-2 font-weight-bold" v-if="task.scheduled_time">{{
                      toTimeZone(task.scheduled_time).format(localeDateTimeFormat)
                    }}</span>
                  <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Schedule") }}:
                  <span class="ml-2 font-weight-bold" v-if="task.schedule">{{ task.schedule['name'] }}</span>
                  <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("Checklist") }}:
                  <span class="ml-2 font-weight-bold" v-if="task.checklist">{{ checklistTitle }}</span>
                  <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("CHECKIN_TIME") }}:
                  <span class="ml-2 font-weight-bold">{{
                      task.start_time ? toTimeZone(task.start_time).format(localeDateTimeFormat) : $t('NO_DATA')
                    }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  {{ $t("CHECKOUT_TIME") }}:
                  <span class="ml-2 font-weight-bold">{{
                      task.end_time ? toTimeZone(task.end_time).format(localeDateTimeFormat) : $t('NO_DATA')
                    }}</span>
                </div>
                <div class="col-lg-12 pb-2">
                  <shift-attach-component
                    @attachShift="(id) => {task.shift_id = id}"
                    :date="task.scheduled_time"
                    v-if="!allow_starting_without_shifts && !task.start_time && !task.shift"
                    :taskId="+taskId"
                    :leadTime="+task.lead_time"
                    :tz="tz"
                    :userTask="true"
                    :scheduleId="task.schedule_id">
                  </shift-attach-component>
                </div>
                <div class="col-lg-12 pb-2" v-if="!allow_starting_without_shifts && task.shift">
                  {{ shiftTimeFrom }} - {{ shiftTimeTo }} {{ task.shift.employee.full_name }}
                </div>
                <div class="col-lg-12 pb-2">
                  <el-tooltip
                    v-for="mark in task.marks"
                    :key="mark"
                    :content="taskMarksIndexed[mark].name"
                    placement="top" effect="light">
                        <span class="project-color mr-2"
                              :style="{ backgroundColor: taskMarksIndexed[mark].color }"
                        />
                  </el-tooltip>
                </div>
              </div>
              <Form ref="observer" as="div" class="col-lg-6">
                <div class="col-lg-12 pb-2">
                  <h3>{{ $t('Checklist') }}</h3>
                </div>
                <template v-if="(task.checklist && task.checklist['items'].length > 0) ||
                   (task.checklist_result && task.checklist_result.length > 0)">
                  <additional-fields-component
                    ref="additionalFields"
                    :title="null"
                    :disabled="!!(!task.start_time || task.end_time)"
                    :items="checklistForm"
                    :taskId="task.id"
                    :checklistId="task.checklist_id"
                    @proceedSave="handleCheckout"
                    @updateItems="handleUpdateItems"
                  />
                </template>
              </Form>
              <div class="col-lg-6 py-2">
                <template v-if="task.next_status">
                  <template v-for="next_status in task.next_status">
                    <template v-if="next_status && (allow_starting_without_shifts || (!allow_starting_without_shifts && shift_id))">
                      <button
                        :class="'btn btn-circle shadow-sm m-1'"
                        :key="next_status.id + '__' + taskId"
                        :style="{
                          backgroundColor: next_status.color
                        }"
                        size="small"
                        :disabled="!allow_starting_without_shifts && task.shift_id === null"
                        @click="changeStatus(next_status)"
                        type="button"><span style="color: white">{{ next_status.title }}</span>
                      </button>
                    </template>
                  </template>
                  <template v-if="!allow_starting_without_shifts && task.next_status.length > 0 && !shift_id">
                    <button
                      :class="'btn btn-success btn-circle shadow-sm m-1'"
                      :disabled="task.shift_id === null"
                      @click="changeStatus(null)"
                      type="button">{{ $t('TAKE_TASK') }}
                    </button>
                  </template>
                </template>
                <template v-if="task.is_final && !block_reopen_tasks && hoursDiff <= 24">
                  <button
                    class="btn btn-danger btn-circle shadow-sm m-1"
                    @click="changeStatus({ id: 'reopen' })"
                    type="button">{{ $t('Reopen') }}
                  </button>
                </template>
              </div>
              <div class="col-lg-6 py-2">
                <button
                  class="btn btn-success btn-circle shadow-sm m-1"
                  v-if="task.checklist"
                  :disabled="!task.start_time || task.end_time"
                  @click="handleSave"
                  type="submit">
                  {{ $t('Save checklist') }}
                </button>
              </div>
              <!-- </form> -->
              <div v-if="mapUrl" class="block pl-4">
                <el-image :src="mapUrl" lazy></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-12 pb-2">
            <h3>{{ $t('Action logs') }}</h3>
          </div>
          <div class="col-lg-6">
            <el-table
              :data="task.logs"
              style="width: 100%">
              <el-table-column
                :label="$t('Title')"
                width="220">
                <template v-slot="scope">
                  {{ scope.row.status.title }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Action time')"
                width="180">
                <template v-slot="scope">
                  {{ toTimeZone(scope.row.created_at).format(localeDateTimeFormat) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Status duration')"
                width="180">
                <template v-slot="scope">
                  {{ hrsForHuman(scope.row.duration / 60.0) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ShiftAttachComponent from '@/components/Tasks/Task/Components/ShiftAttachComponent'
import mixinDummy from '@/mixins/mixinDummy'
import moment from 'moment'
import AdditionalFieldsComponent from '@/components/Tasks/CommonComponents/AdditionalFieldsComponent.vue'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Task',
  mixins: [errorMixin, momentMixin, mixinDummy, ToastMixin],
  components: { AdditionalFieldsComponent, DummyPaymentRequired, ShiftAttachComponent },
  data () {
    return {
      task: {
        title: null,
        description: null,
        client_id: null,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        lead_time: 0.0,
        shift_id: null,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        checklist_name: null,
        logs: []
      },
      hoursDiff: 0,
      items: [],
      clients: [],
      mapUrl: '',
      timeout: null,
      taskId: this.$route.params.taskId,
      gApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      shift_id: null,
      nextStatus: null,
      allow_starting_without_shifts: false,
      block_reopen_tasks: false
    }
  },
  created () {
    if (this.companyId) {
      this.$store.dispatch('getTasksSettings', this.$store.getters.companyId).then((response) => {
        this.allow_starting_without_shifts = response.allow_starting_without_shifts
        this.block_reopen_tasks = response.block_reopen_tasks
      })
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    taskMarksIndexed () {
      return this.$store.getters.taskMarksIndexed
    },
    tz () {
      if (!this.task.schedule_id) {
        return 'UTC'
      }
      const scheduleId = this.task.schedule_id
      const schedule = this.$store.getters.scheduleById(scheduleId)
      const project = this.$store.getters.projectById(schedule.project_id)

      return project.time_zone
    },

    shiftTimeFrom () {
      let tz = 'UTC'
      if (this.tz) {
        tz = this.tz
      }
      return this.fromZoneToZone(this.task.shift.time_from, 'UTC', tz).format(this.localeDateTimeFormat)
    },

    shiftTimeTo (time) {
      let tz = 'UTC'
      if (this.tz) {
        tz = this.tz
      }
      return this.fromZoneToZone(this.task.shift.time_to, 'UTC', tz).format(this.localeDateTimeFormat)
    },

    checklistForm () {
      if (this.task.checklist_result) {
        return this.task.checklist_result
      }
      if (this.task.checklist) {
        return this.task.checklist.items
      }
      return []
    },
    checklistTitle () {
      // заголовок чеклиста сохраняется в таске при чекине, если его там нет, берем имя из шаблона чеклсита
      if (this.task.checklist) {
        if (this.task.checklist_name) {
          return this.task.checklist_name
        } else {
          return this.task.checklist.title
        }
      }
      return null
    }
  },

  watch: {
    tz: function (newTz, oldTz) {
      if (this.task.scheduled_time) {
        if (!oldTz) {
          oldTz = 'UTC'
        }
        if (!newTz) {
          newTz = 'UTC'
        }
        this.task.scheduled_time = this.fromZoneToZone(this.task.scheduled_time, oldTz, newTz).format(this.backendDateTimeFormat)
      }
    },
    companyId () {
      this.init()
    }
  },

  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.$store.dispatch('getTaskMarks', this.companyId)
      this.$store.dispatch('getTask', [this.companyId, this.taskId]).then((response) => {
        this.task = response
        if (!this.allow_starting_without_shifts) {
          this.shift_id = this.task.shift_id
        }
        this.items = response.checklist_result ? response.checklist_result : response.checklist ? response.checklist.items : []

        this.getSchedules()
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    changeStatus (nextStatus) {
      const payload = {}
      this.nextStatus = nextStatus

      if (nextStatus) {
        payload.update_status = true
        payload.next_status_id = nextStatus.id
      }

      if (nextStatus && nextStatus.check_in) {
        if (!this.allow_starting_without_shifts) {
          payload.shift_id = this.task.shift_id
        }
        // payload['checklist_name'] = this.checklistTitle
        this.editTask(payload)
      } else if (nextStatus && nextStatus.check_out) {
        if (this.task.checklist_id) {
          this.$eventBus.emit('validateClientFields')
        } else {
          this.$refs.observer.validate().then(result => {
            if (result.valid) {
              payload.items = this.items
              this.editTask(payload)
            }
          })
        }
      } else {
        if (!this.allow_starting_without_shifts) {
          payload.shift_id = this.task.shift_id
        }
        this.editTask(payload)
      }
    },
    handleSave () {
      const payload = {
        items: this.items
      }
      this.editTask(payload)
    },
    handleUpdateItems (data) {
      this.items = data
    },
    handleCheckout () {
      const payload = {}
      payload.update_status = true
      payload.next_status_id = this.nextStatus.id
      payload.items = this.items
      this.editTask(payload)
    },
    editTask (payload) {
      // наверное лучше дописать апи, чтобы при редактировании таска возвращался parent, ну или нет
      const parent = this.task.parent
      this.$store.dispatch('editTask', [this.companyId, this.$route.params.taskId, payload]).then((response) => {
        this.$refs.additionalFields?.resetValidation()
        this.items = response.checklist_result ? response.checklist_result : response.checklist && response.checklist.items ? response.checklist.items : []
        this.task = response
        this.task.parent = parent
        if (!this.allow_starting_without_shifts) {
          this.shift_id = this.task.shift_id
        }
        this.toastSuccess(this.$t('Task updated'))
      }).catch((err) => {
        if (err.response.status === 400) {
          this.toastError(this.$i18n?.t(err.response.data.message))
        } else if ('latitude' in err.response.data.errors || 'longitude' in err.response.data.errors) {
          this.toastError(this.$i18n?.t('Coordinates are required'))
        } else {
          this.toastError(this.$i18n?.t(this.$store.state.apiErrors.error))
        }
      })
    },
    getSchedules () {
      this.$store.dispatch('getSchedules', this.companyId)
      this.$store.dispatch('getProjects')
    },
    formatDate (val) {
      return moment(val).format(this.localeDateFormat)
    }
  }
}
</script>

<style>
</style>
