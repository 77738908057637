<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="isModuleActive && loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'InventoryCategories', params: { companyId: companyId} }">
                <span class="breadcrumb-item">{{ $t('Categories')}}</span>
              </router-link>
            </li>
            <li v-if="categoryId" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_CATEGORY")}}
              <ui-hint :content="$t('EDIT_CATEGORY_HINT')" />
            </li>
            <li v-else class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_CATEGORY")}}
              <ui-hint :content="$t('CREATE_CATEGORY_HINT')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <form @submit.prevent="handleSave">
            <Form ref="observer" as="div" class="row">
              <div class="col-lg-6">
                <div class="pb-2">
                  <ui-input-label :label="$t('Category image')" :required="false" />
                  <el-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :multiple="false"
                    :limit="1"
                    :on-change="handleFileSuccess"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-upload>
                  <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" :alt="$t('Preview Image')" />
                  </el-dialog>
                </div>
                <div class="pb-2">
                  <ui-text-input
                    id="title"
                    name="title"
                    :label="$t('Title')"
                    v-model="category.name"
                    :validation="{required: true, min: 3}"
                  />
                </div>
                <div class="pb-2">
                  <ui-textarea-input
                    :rows="3"
                    name="description"
                    v-model="category.description"
                    :placeholder="$t('description')"
                    :label="$t('Description')"
                  />
                </div>
                <div class="pb-2">
                  <color-picker
                    name="Color"
                    :label="$t('Color')"
                    v-model="category.color"
                    :colors="colorsChoices"
                  />
                </div>
              </div>
            </Form>
            <div class="row">
              <div class="col py-2 text-left">
                <button class="btn btn-success btn-circle shadow-sm" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import colors from '@/mixins/mixinColors'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import ColorPicker from '@/ui/color-picker.vue'

export default {
  name: 'InventoryCategory',
  mixins: [errorMixin, momentMixin, colors, mixinDummy, ToastMixin],
  components: { ColorPicker, DummyPaymentRequired },
  data () {
    return {
      category: {
        title: null,
        check_gps: false,
        color: '#958d19'
      },
      fileList: [],
      maxFileSize: 2048,
      upload: {},
      uploadRef: null,
      file: null,
      loaded: false,
      isModuleActive: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    categoryId () {
      return this.$route.params.categoryId
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.checkActiveModule()

      if (this.categoryId) {
        this.$store.dispatch('getCategory', [this.companyId, this.categoryId]).then((response) => {
          this.category = response
          this.loaded = true
          if (response.image) {
            this.fileList.push({ url: response.image.url })
          }
        }).catch(() => {})
      } else {
        this.loaded = true
      }
    },
    checkActiveModule () {
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 32]).then(response => {
        if (response.active === true) {
          this.dummy = false
          this.isModuleActive = true
        }
      }).catch(err => {
        if (!err.response.data.module) {
          this.dummy = true
          this.dummyInfo = {}
        } else {
          this.dummy = true
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    handleFileSuccess (file) {
      if (['image/jpeg', 'image/png'].indexOf(file.raw.type) === -1) {
        this.toastError(this.$i18n?.t('Category image must be JPG/PNG format!'))
        this.fileList = []
        return false
      } else if (file.raw.size / 1024 / 1024 > 2) {
        this.toastError(this.$i18n?.t('Category image size can not exceed 2MB!'))
        this.fileList = []
        return false
      }
      this.file = file.raw

      return true
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove (uploadFile, uploadFiles) {
      this.file = null
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const formData = new FormData()
          formData.append('name', this.category.name)
          formData.append('color', this.category.color)
          formData.append('description', this.category.description)

          if (this.file) {
            formData.append('image', this.file)
          }

          if (this.categoryId) {
            // Laravel can only accept files with the POST method, but it turns out that PATCH
            // can be called through POST by passing _method: PATCH in the formData
            formData.append('_method', 'PATCH')
            // Remove image
            if (this.category.image && this.fileList.length === 0) {
              formData.append('remove_image', 1)
            }

            this.$store.dispatch('updateCategory', [this.companyId, this.categoryId, formData]).then((response) => {
              this.category = response
              this.file = null
              this.toastSuccess(this.$t('Category updated'))
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          } else {
            this.$store.dispatch('createCategory', [this.companyId, formData]).then((response) => {
              this.toastSuccess(this.$t('Category created'))
              this.category = response
              this.file = null
              this.$router.push(`/c/${this.companyId}/inventory/categories/${response.id}`)
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
</style>
