<template>
  <div v-if="Object.keys(schedule.mainInfo).length > 0" class="card w-100">
    <h5 class="card-header text-left border-bottom text-muted p-2 text-center">
      {{ $t('Overview') }}
    </h5>
    <ul class="list-group list-group-flush">
      <li v-if="isMainInfoReady" class="list-group-item">
        <div>
          <span class="font-weight-bold">{{ $t('Schedule') }}:</span> <span data-cy="stat-schedule-name">{{ schedule.mainInfo.title }}</span>
        </div>
        <div>
          <span class="font-weight-bold">{{ $t('Project') }}:</span> <span data-cy="stat-project-name">{{ schedule.mainInfo.projectName }}</span>
        </div>
        <div>
          <span class="font-weight-bold">{{ $t('Period') }}:</span>
            {{ dateFrom }} - {{ dateTo }}
        </div>
        <div>
          <span class="font-weight-bold">{{ $t('WEEK_OVERTIME_CONTROL_TYPE') }}: </span>
          <span data-cy="stat-overtime-type">{{$t(schedule.mainInfo.shiftsControl.week_overtime_control_type.toUpperCase())}}</span>
        </div>
      </li>
      <li v-if="isTypeReady" class="list-group-item">
        <div>
          <span class="font-weight-bold">{{ $t('Schedule type') }}: </span>
          <span data-cy="stat-schedule-type">{{ scheduleType }}</span>
        </div>
        <div v-if="schedule.scheduleType === 'by_pattern' && isPatternReady">
          <span class="font-weight-bold">{{ $t('Pattern') }}:</span> {{ $t(patternType) }}
        </div>
      </li>
      <li v-if="isScheduleSettingsReady" class="list-group-item" data-cy="stat-templates">
        <div>
          <span class="font-weight-bold">{{ $t('Work days') }}:</span> {{ workingDays }}
        </div>
        <div>
          <span class="font-weight-bold">{{ $t('Templates number') }}: </span>
          <span data-cy="stat-template-count">{{ templatesNumber }}</span>
        </div>
        <div v-for="(template, index) in schedule.scheduleSettings.templates" :key="'new-' + index" class="mt-2">
          <span class="font-weight-bold">{{ template.name }}  {{ timeToLocale(template.time_from) }} - {{ timeToLocale(template.time_to) }}</span>
          <br>
          <template v-if="template.night_shift">
            <span class="font-weight-bold">{{ $t('NIGHT_SHIFT') }}</span>
            <br>
          </template>
          <template v-if="template.allow_break">
            <span class="font-weight-bold">{{ $t('ALLOW_BREAK_KEY') }}</span>
            <br>
          </template>
          <span v-if="schedule.scheduleType !== 'simple'">
            <span class="font-weight-bold">{{ $t('Workplaces') }}: </span>
            <template v-if="`by_days` in template && template.by_days">
              <div v-for="(value, index) in template.workplaces" :key="index">
                <span>{{ $t(daysLangSorted[index]) }} - {{value}}</span>
              </div>
            </template>
            <template v-else>
              {{ template.workplacesTotal }}
            </template>
          </span>
        </div>
        <!-- Step 3 -->
      <div v-for="(rule, index) in schedule.rules" :key="'rule-' + index" class="mt-2">
        <div>
          <span class="font-weight-bold">{{ $t('Rule') }}:</span>
        </div>
        <span class="font-weight-bold">{{ $t('RULE_ACTION_TIME') }}:</span> {{ getActionTime(rule.action_time) }}
        <br>
        <span class="font-weight-bold">{{ $t('RULE_ACTION_OBJECT') }}:</span> {{ getRuleObject(rule.action_objects) }}
        <br>
        <span class="font-weight-bold">{{ $t('RULE_TYPE') }}:</span> {{ getRuleTitle(rule.rule) }}
        <br>
        <span class="font-weight-bold">{{ $t('RULE_OBJECT') }}:</span> {{ getRuleObject(rule.objects) }}
      </div>
        <div v-for="(templateId, index) in schedule.scheduleSettings.preloadedTemplates" :key="'old-' + index" class="mt-2">
          <template v-if="schedule.scheduleSettings.preloadedTemplatesData[templateId]">
            <span class="font-weight-bold">{{ schedule.scheduleSettings.preloadedTemplatesData[templateId].name }}:</span>
            {{ timeToLocale(schedule.scheduleSettings.preloadedTemplatesData[templateId].time_from) }} - {{ timeToLocale(schedule.scheduleSettings.preloadedTemplatesData[templateId].time_to) }}
            <br>
            <span class="font-weight-bold">{{ $t('IS_NIGHT_TEMPLATE') }}: </span> {{$t(schedule.scheduleSettings.preloadedTemplatesData[templateId].night_shift.toString())}}
            <br>
            <span class="font-weight-bold">{{ $t('ALLOW_BREAK_KEY') }}: </span> {{$t(schedule.scheduleSettings.preloadedTemplatesData[templateId].allow_break.toString())}}
            <br>
            <template v-if="schedule.scheduleType !== 'simple'">
              <span class="font-weight-bold">{{ $t('Workplaces') }}: </span>
              <template v-if="schedule.scheduleSettings.preloadedTemplatesData[templateId]['by_days']">
                <div v-for="(value, index) in schedule.scheduleSettings.preloadedTemplatesData[templateId]['workplaces']" :key="index">
                  <span>{{ $t(daysLangSorted[index]) }} - {{value}}</span>
                </div>
              </template>
              <template v-else>
                {{schedule.scheduleSettings.preloadedTemplatesData[templateId]['workplaces'][0]}}
              </template>
            </template>
          </template>
        </div>
      </li>
      <!-- Step 4 -->
      <li v-if="isWorkingTimeReady && schedule.scheduleType !== 'simple' && schedule.scheduleType !== 'by_pattern'" class="list-group-item">
        <template v-if="schedule.mainInfo.shiftsControl.working_hours">
          <div class="font-weight-bold">{{ $t('COMPANY_WORKING_HOURS_SETTINGS') }}</div>
          <div>
            <span class="font-weight-bold">{{ $t('WORKING_HOURS_DAY') }}:</span> {{+salary.quotas_per_day}}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('WORKING_HOURS_WEEK') }}:</span> {{+salary.quotas_per_week}}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('WORKING_HOURS_MONTH') }}:</span> {{+salary.quotas_per_month}}
          </div>
        </template>
        <div class="mt-2">
          <div>
            <span class="font-weight-bold">{{ $t('Maximum shifts in a row') }}:</span> {{ schedule.mainInfo.shiftsControl.break_between_shifts }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('Minimum breaks in a row') }}:</span> {{ schedule.mainInfo.shiftsControl.minimum_breaks_in_row }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('Maximum breaks in a row') }}:</span> {{ schedule.mainInfo.shiftsControl.maximum_breaks_in_row }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('Days off') }}:</span> {{ schedule.workingTime.weekends }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('minimum_break_between_shifts') }}:</span> {{ schedule.mainInfo.shiftsControl.minimum_break_between_shifts }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('minimum_break_between_shifts_for_women') }}:</span> {{ schedule.mainInfo.shiftsControl.minimum_break_between_shifts_for_women }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('minimum_break_in_row_per_week') }}:</span> {{ schedule.mainInfo.shiftsControl.minimum_break_in_row_per_week }}
          </div>
        </div>
      </li>
      <li v-if="isEmployeesReady" class="list-group-item">
        <div>
          <span class="font-weight-bold">{{ $t('Employees selected') }}:</span> {{ schedule.employees.users.length }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'StatisticComponent',
  mixins: [momentMixin],
  props: {
    schedule: Object
  },
  data () {
    return {
      daysLang: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
    }
  },
  methods: {
    getActionTime (actionTime) {
      switch (actionTime) {
        case 'before':
          return this.$t('Before')
        case 'after':
          return this.$t('After')
        default:
          return ''
      }
    },
    getRuleTitle (rule) {
      switch (rule) {
        case 'forbidden':
          return this.$t('Forbidden')
        case 'can_only':
          return this.$t('Can only')
        case 'need_day_off':
          return this.$t('Need day off')
        case 'need_work_day':
          return this.$t('Need work day')
        default:
          return ''
      }
    },
    getRuleObject (objects) {
      const arr = []
      objects.forEach(obj => {
        if (obj === 'day_off') {
          arr.push(this.$t('Day off'))
        } else if (this.schedule.scheduleSettings.preloadedTemplatesData[obj]) {
          arr.push(this.schedule.scheduleSettings.preloadedTemplatesData[obj].name)
        } else if (this.schedule.scheduleSettings.templates[obj - this.schedule.scheduleSettings.preloadedTemplates.length]) {
          arr.push(this.schedule.scheduleSettings.templates[obj - this.schedule.scheduleSettings.preloadedTemplates.length].name)
        }
      })
      return arr.join(', ')
    }
  },
  computed: {
    dateFrom () {
      if (this.schedule.scheduleId) {
        return this.toSelectedTimeZone(this.schedule.mainInfo.period[0], this.schedule.mainInfo.projectTZ).format(this.localeDateFormat)
      }
      return moment(this.schedule.mainInfo.period[0]).format(this.localeDateFormat)
    },
    dateTo () {
      if (this.schedule.scheduleId) {
        return this.toSelectedTimeZone(this.schedule.mainInfo.period[1], this.schedule.mainInfo.projectTZ).format(this.localeDateFormat)
      }
      return moment(this.schedule.mainInfo.period[1]).format(this.localeDateFormat)
    },
    salary () {
      return this.$store.getters.company.salary || {}
    },
    isMainInfoReady () {
      // console.log(2, this.schedule)
      return Object.keys(this.schedule.mainInfo).length > 0
    },
    isTypeReady () {
      return this.schedule.scheduleType !== null
    },
    isPatternReady () {
      // console.log(this.schedule.patternType)
      return this.schedule.patternType !== null
    },
    isScheduleSettingsReady () {
      // console.log(2, this.schedule.scheduleSettings)
      return Object.keys(this.schedule.scheduleSettings).length > 0
    },
    isWorkingTimeReady () {
      return Object.keys(this.schedule.workingTime).length > 0
    },
    isEmployeesReady () {
      return this.schedule.employees.users.length > 0
    },
    daysLangSorted () {
      const daysLang = JSON.parse(JSON.stringify(this.daysLang))
      let week = []
      const weekPart = daysLang.splice(this.$store.getters.company.salary.week_start)
      week = weekPart.concat(daysLang)
      return week
    },
    workingDays () {
      let week = []
      Object.keys(this.schedule.scheduleSettings.days).forEach(key => {
        week.push(this.schedule.scheduleSettings.days[key])
      })
      if (this.$store.getters.company.salary) {
        const weekPart = week.splice(this.$store.getters.company.salary.week_start)
        week = weekPart.concat(week)
      }
      const days = []
      week.forEach(weekDay => {
        if (weekDay.selected) {
          days.push(this.$i18n?.t(weekDay.short))
        }
      })

      return days.join(', ')
    },
    scheduleType () {
      if (this.schedule.scheduleType === 'advanced') {
        return this.$i18n?.t('Advanced')
      }
      if (this.schedule.scheduleType === 'simple') {
        return this.$i18n?.t('Simple')
      }
      if (this.schedule.scheduleType === 'by_availability') {
        return this.$i18n?.t('By availability')
      }
      if (this.schedule.scheduleType === 'by_pattern') {
        return this.$i18n?.t('By pattern')
      }
      if (this.schedule.scheduleType === 'manually') {
        return this.$i18n?.t('Empty')
      }
      return ''
    },
    patternType () {
      if (this.schedule.patternType === '1/1') {
        return '1/1'
      } else if (this.schedule.patternType === '1/2') {
        return '1/2'
      } else if (this.schedule.patternType === '1/3') {
        return '1/3'
      } else if (this.schedule.patternType === '2/2') {
        return '2/2'
      } else if (this.schedule.patternType === '2/2/alternation') {
        return '2/2/alternation'
      } else if (this.schedule.patternType === '2/2/3') {
        return '2/2/3'
      } else if (this.schedule.patternType === '3/3') {
        return '3/3'
      } else if (this.schedule.patternType === '4/2') {
        return '4/2'
      } else if (this.schedule.patternType === '5/2') {
        return '5/2'
      } else if (this.schedule.patternType === '5/2/6/1') {
        return '5/2/6/1'
      } else if (this.schedule.patternType === 'day/night/48') {
        return 'day/night/48'
      }
      return ''
    },
    templatesNumber () {
      // console.log(this.schedule.scheduleSettings)
      return this.schedule.scheduleSettings.templates.length + this.schedule.scheduleSettings.preloadedTemplates.length
    },
    timeControlLabel () {
      if (this.schedule.workingTime.timeControl === 'project') {
        return this.$i18n?.t('By project')
      }
      if (this.schedule.workingTime.timeControl === 'company') {
        return this.$i18n?.t('By company')
      }
      if (this.schedule.workingTime.timeControl === 'schedule') {
        return this.$i18n?.t('By schedule')
      }
      if (this.schedule.workingTime.timeControl === 'daysOff') {
        return this.$i18n?.t('By days off')
      }
      return this.$i18n?.t('No control')
    }
  }
}
</script>

<style scoped>

</style>
