<template>
  <div>
    <import-employees-modal
      v-if="ImportEmployeesModal"
      :scheduleId="importForSchedule"
    />
    <div class="row mb-2 mx-auto">
      <div class="col mt-auto">
        <span class="title-text">
        {{ $t('Schedules') }}
        <ui-hint :content="$t('SCHEDULES_HINTS')" />
      </span>
      </div>
      <div v-if="schedules.length > 0" class="col-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          :is-search="true"
        />
      </div>
      <div v-if="schedules.length > 0" class="col-auto">
        <router-link
          id="demo_schedules_add_schedule"
          :to="`/c/${companyId}/schedule/master`"
          class="btn btn-primary btn-sm rounded-pill shadow-sm">
          {{ $t('Add schedule') }}
        </router-link>
      </div>
    </div>
    <div v-if="loaded" class="card card-small">
      <div v-if="schedules.length > 0" class="card-body table-responsive">
        <!--<div class="col-lg-12">-->
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="text-left">{{ $t('Title') }}</th>
            <th class="text-left">{{ $t('Time') }}</th>
            <th class="text-left">{{ $t('Number of users') }}</th>
            <th class="text-left">{{ $t('Project') }}</th>
            <th class="text-left">{{ $t('Period') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(schedule, key) in filteredSchedules">
            <tr v-if="schedule.role !== 'user'" v-bind:key="key">
              <td>
                <table class="table-borderless">
                  <tbody>
                  <tr style="background-color:rgba(0, 0, 0, 0)!important;">
                    <td>
                      <b v-bind:style="{ backgroundColor: schedule.color }" class="text-white p-1">{{ schedule.name.toUpperCase() }}</b>
                    </td>
                  </tr>
                  <tr v-for="(template, t_key) in templatesBySchedule[schedule.id]" :key="t_key" style="background-color:rgba(0, 0, 0, 0)!important;">
                    <template v-if="!template.is_hide">
                      <td>
                        <span class="project-color" v-bind:style="{ backgroundColor: template.color }"></span> {{template.name }}
                      </td>
                    </template>
                  </tr>
                  </tbody>
                </table>
              </td>
              <td style="vertical-align: bottom">
                <table class="table-borderless">
                  <tbody>
                  <tr style="background-color:rgba(0, 0, 0, 0)!important;">
                    <td></td>
                  </tr>
                  <tr v-for="(template, t_key) in templatesBySchedule[schedule.id]" :key="t_key" style="background-color:rgba(0, 0, 0, 0)!important;">
                    <template v-if="!template.is_hide">
                      <td> {{ timeToLocale(template.time_from) }} - {{ timeToLocale(template.time_to) }}</td>
                    </template>
                  </tr>
                  </tbody>
                </table>
              </td>
              <td style="vertical-align: middle">
                {{ schedule.users.length }}
              </td>
              <td style="vertical-align: middle">
                {{ getProjectName(schedule) }}
              </td>
              <td style="vertical-align: middle;">
                <div>{{ $t('From') }}: {{ getFormattedDateFrom(schedule) }}</div>
                <br>
                <div>
                  {{ $t('To') }}: {{ getFormattedDateTo(schedule) }}
                  <template v-if="!isOutdated(schedule) && isAlmostOutdated(schedule)">
                    <el-tooltip class="item" effect="light" :content="$t('SCHEDULE_IS_ALMOST_EXPIRED')" placement="top">
                      <i class="icon-warning btn-icon text-warning mx-1" />
                    </el-tooltip>
                  </template>
                  <template v-if="isOutdated(schedule)">
                    <el-tooltip class="item" effect="light" :content="$t('SCHEDULE_IS_EXPIRED')" placement="top">
                      <i class="icon-warning btn-icon text-danger mx-1" />
                    </el-tooltip>
                  </template>
                </div>
              </td>
              <td class="d-flex justify-content-end">
                <i v-if="[14184, 5886 ].includes(companyId)"
                  class="icon-upload btn-icon text-primary mx-1"
                  @click="importShifts(schedule.id)"
                  role="button"
                  data-toggle="modal"
                  data-target="#ImportEmployeesModal"
                />
                <router-link :to="`/c/${companyId}/schedule/${schedule.id}`">
                  <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                </router-link>
                <i :data-cy="`delete-schedule-${schedule.id}`" class="icon-trash btn-icon text-danger mx-1" @click="deleteSchedule(schedule.id)"></i>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div v-else class="card-body text-center">
        <img style="max-height: 350px" src="../../../public/static/images/App/Splash_1_02.png"/>
        <h5 class="m-3">
          {{$t('You have no schedules!')}}
        </h5>
        <div>
          <router-link
            :to="`/c/${companyId}/schedule/master`"
            class="btn btn-primary btn-md rounded-pill shadow-sm">
            {{ $t('Add schedule') }}
          </router-link>
        </div>
      </div>
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinMoment from '@/mixins/mixinMoment'
import moment from 'moment'
import ImportEmployeesModal from '@/components/Schedule/ImportEmployeesComponents/ImportEmployeesModal'

let checking
export default {
  name: 'Schedules',
  mixins: [errorMixin, mixinMoment],
  components: { ImportEmployeesModal },
  beforeRouteLeave (to, from, next) {
    clearInterval(checking)
    next()
  },

  data () {
    return {
      search: '',
      ImportEmployeesModal: false,
      importForSchedule: null,
      loaded: false
    }
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      projects: 'projects',
      schedules: 'schedules',
      templates: 'templates'
    }),
    templatesBySchedule () {
      const templatesBySchedule = {}
      this.templates.forEach((template) => {
        if (!templatesBySchedule[template.schedule_id]) {
          templatesBySchedule[template.schedule_id] = []
        }
        templatesBySchedule[template.schedule_id].push(template)
      })

      return templatesBySchedule
    },
    filteredSchedules () {
      const schedules = this.schedules
      // eslint-disable-next-line
      return schedules.filter((schedule, index) => {
        if (this.search === '') {
          return schedule
        }
        const filteredSchedules = schedule.name.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1
        if (filteredSchedules) {
          return filteredSchedules
        }
      }).sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    }
  },

  created () {
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    isOutdated (schedule) {
      return this.toSelectedTimeZone(schedule.date_to, this.$store.getters.profileTimeZone).isBefore(moment())
    },
    isAlmostOutdated (schedule) {
      return this.toSelectedTimeZone(schedule.date_to, this.$store.getters.profileTimeZone).isBefore(moment().add(1, 'day'))
    },
    init () {
      if (!this.isEmployeeHasPermission('update-schedule-user')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.$store.dispatch('getProjects').then(response => {
        this.$store.dispatch('getSchedules', this.$store.getters.companyId).then(response => {
          this.loaded = true
        })
      })
    },

    getProjectName (schedule) {
      const project = this.$store.getters.projectById(schedule.project_id)
      return project ? project.name : ''
    },

    getFormattedDateFrom (schedule) {
      const project = this.$store.getters.projectById(schedule.project_id)
      return this.toSelectedTimeZone(schedule.date_from, project.time_zone).format(this.localeDateFormat)
    },

    getFormattedDateTo (schedule) {
      const project = this.$store.getters.projectById(schedule.project_id)
      return this.toSelectedTimeZone(schedule.date_to, project.time_zone).add(-1, 'day').format(this.localeDateFormat)
    },
    importShifts (scheduleId) {
      this.importForSchedule = scheduleId
      this.ImportEmployeesModal = true
    },
    deleteSchedule: function (id) {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, delete it!'),
        cancelButtonText: this.$i18n?.t('Cancel'),
        confirmButtonClass: 'deleteButton',
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteSchedule', id).then((response) => {
          this.schedules.forEach((item, key) => {
            if (item.id === id) {
              this.schedules.splice(key, 1)
            }
          })
          this.toastSuccess(this.$i18n?.t('Your schedule has been deleted.'))
          this.$eventBus.emit('checkSteps')
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
  .table td, .table th {
    font-size: 14px;
  }

  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
