<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6 col-md-10 col-sm-12 col-xs-12"> <!-- form here -->
          <div class="row"> <!-- form header here -->
            <div class="col">
              <h5 class="text-center">{{ $t('Password recovery') }}</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto text-center">
              <label class="control-label font-weight-bold">{{ $t("Recovery by") }}</label>
              <br>
              <el-radio-group v-model="recoveryType">
                <el-radio-button :value="'byEmail'">{{ $t("Email") }}</el-radio-button>
                <el-radio-button :value="'byPhone'">{{ $t("Phone") }}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <template v-if="recoveryType === 'byEmail'">
            <Form id="from_recovery_by_email" ref="emailObserve" @submit="RecoveryEmail">
              <div class="card">
                <div class="card-body">
                  <ui-text-input
                    class="w-auto"
                    id="email"
                    :name="'email'"
                    v-model="email"
                    :validation="{required: true, email: true}"
                    :placeholder="$t('E-mail')"
                    :prepend-icon="'icon-mail'"
                  />
                  <div class="col-md-12 pt-4">
                    <div class="d-flex justify-content-center">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary rounded-pill shadow-sm">
                        {{ $t('Recovery') }}
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 pt-4 d-flex justify-content-center">
                    <div class="mx-auto">
                    <span>{{ $t('SignIn') }}
                      <router-link :to="`/login?lang=${pageLang}`" class="">{{ $t('login') }}</router-link>
                    </span>
                    </div>
                  </div>
                  <div class="col-md-12 pt-4 text-center">
                  <span>{{ $t("Don't have an account?") }}
                    <router-link :to="`/registration?lang=${pageLang}`" class="">{{ $t('Register') }}</router-link>
                  </span>
                  </div>
                </div>
              </div>
            </Form>
          </template>
          <template v-else-if="recoveryType === 'byPhone'">
            <div class="card">
              <div class="card-body">
                <Form ref="phoneObserve" @submit="sendCode" id="form_send_code">
                  <div class="input-group mt-3 ">
                    <div class="form-group col-lg-8 col-md-8 col-sm-12 px-0 ltr">
                      <ui-tel-input
                        v-model="number"
                        name="phone"
                        id="registerInputPhone"
                        :validation="{required: true, max: 20}"
                        @isPhoneValid="isPhoneValid"
                      />
                    </div>
                    <div class="mx-auto">
                      <button
                        type="submit"
                        id="registerSubmitAddPhoneFormButton"
                        :disabled="!validPhone || timer > 0"
                        class="btn btn-primary btn-circle btn-block"
                        :class="{'disabled': !validPhone || timer > 0}"
                      >{{ timer > 0 ? timer : $t('Send code') }}</button>
                    </div>
                  </div>
                </Form>
                <Form ref="codeObserver" @submit="confirmCode" id="form_confirm_code">
                  <div class="row">
                    <div class="form-group col-md">
                      <ui-text-input
                        class=""
                        id="registerInputConfirmCode"
                        :name="'confirm'"
                        v-model="confirm"
                        :validation="{required: true, numeric: true, min: 8, max: 8}"
                        :label="$t('Confirmation code')"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 pt-4">
                    <div class="d-flex justify-content-center">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary rounded-pill shadow-sm"
                      >
                        {{ $t('Recovery') }}
                      </button>
                    </div>
                  </div>
                </Form>
                  <div class="col-md-12 pt-4 d-flex justify-content-center">
                    <div class="mx-auto">
                      <span>{{ $t('SignIn') }}
                        <router-link :to="`/login?lang=${pageLang}`" class="">{{ $t('login') }}</router-link>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 pt-4 text-center">
                    <span>{{ $t("Don't have an account?") }}
                      <router-link :to="`/registration?lang=${pageLang}`" class="">{{ $t('Register') }}</router-link>
                    </span>
                  </div>
                </div>
            </div>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import { loadLanguageAsync } from '@/i18n'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'

export default {
  name: 'PasswordRecovery',
  mixins: [errorMixin, mixinWindowWidthWatch],
  data () {
    return {
      email: '',
      recoveryType: 'byEmail',
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      recapchaResponse: null,
      number: '',
      confirm: null,
      muteAddPhoneInput: false,
      timer: 0,
      languages: [],
      pageLang: 'en',
      captchaSize: 'normal',
      validPhone: true
    }
  },
  beforeMount () {
    if (this.isMobile) this.captchaSize = 'compact'
  },
  mounted () {
    this.muteAddPhoneInput = true
  },
  created () {
    this.$store.dispatch('getLanguages').then((resolve) => {
      this.languages = resolve.map((item) => item.code)
      const lang = this.$route.query.lang
      if (this.languages.includes(lang)) {
        this.pageLang = lang
        loadLanguageAsync(lang)
      }
    })
  },
  computed: {
    isFormConfirmPhoneValid () {
      if (this.fields.confirm) {
        return Object.keys(this.fields).some(key => this.fields.confirm.invalid)
      }
      return true
    }
  },
  watch: {
    number () {
      this.number = this.number.trim()
    },
    $route (to, from) {
      if (this.languages.includes(this.$route.query.lang)) {
        this.pageLang = this.$route.query.lang
      }
    }
  },
  methods: {
    isPhoneValid (val) {
      this.validPhone = val
    },
    parsePhoneNumber (number) {
      number = number.substr(1)
      number = number.replace(/\s/g, '')
      return number
    },
    counter () {
      this.timer = 30
      const downloadTimer = setInterval(() => {
        this.timer -= 1
        if (this.timer <= 0) {
          this.muteAddPhoneInput = false
          clearInterval(downloadTimer)
        }
      }, 1000)
    },
    sendCode () {
      this.$refs.phoneObserve.validate('phone').then(async result => {
        if (!this.recaptchaShow) {
          this.recapchaResponse = this.sitekey
        } else {
          this.recapchaResponse = await this.$recaptcha('PasswordRecoverByPhone')
        }

        if (result.valid && this.recapchaResponse) {
          const phone = this.parsePhoneNumber(this.number)
          this.$store.dispatch('passwordRecoveryPhone', [phone, { recaptcha_key: this.recapchaResponse }]).then(response => {
            this.toastSuccess(this.$t(response.message))
            this.counter()
          }).catch(e => {})
        }
      })
    },
    async confirmCode () {
      if (!this.recaptchaShow) {
        this.recapchaResponse = this.sitekey
      } else {
        this.recapchaResponse = await this.$recaptcha('PasswordRecoveryConfirmCode')
      }
      this.$refs.codeObserver.validate().then(r => {
        if (r.valid) {
          this.$refs.codeObserver.validate().then(result => {
            if (result && this.recapchaResponse) {
              const phone = this.parsePhoneNumber(this.number)
              this.$store.dispatch('passwordRecoveryPhoneSms', [phone, { confirm_code: this.confirm, recaptcha_key: this.recapchaResponse }]).then(response => {
                const hash = response.token
                this.$router.push(`/password/recovery/confirm/${hash}`)
              }).catch(e => {
                this.recapchaResponse = null
                this.toastError(this.$t('Wrong code'))
              })
            }
          })
        }
      })
    },
    async RecoveryEmail () {
      if (!this.recaptchaShow) {
        this.recapchaResponse = this.sitekey
      } else {
        this.recapchaResponse = await this.$recaptcha('PasswordRecoverByEmail')
      }

      this.$refs.emailObserve.validate().then(result => {
        if (result.valid && this.recapchaResponse) {
          this.$store.dispatch('passwordRecovery', { email: this.email, recaptcha_key: this.recapchaResponse }).then(response => {
            this.toastSuccess(this.$t(response.message))
            this.$router.push('/login')
          }).catch(e => {
            this.toastError(this.$t(e.response.data.message))
            this.recapchaResponse = null
          })
        }
      })
    }
  }
}
</script>
