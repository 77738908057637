<template>
  <div>
    <dummy-payment-required v-if="dummy" @activate="getTableDataActivated()" :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <bundle-item-modal
      v-if="showBundleItemModal"
      :item="itemData"
      :bundleId="+bundleId"
      @closeBundleItemModal="closeBundleItemModal"
      @updateBundleItems="getBundleItems"
    />

    <div v-if="isModuleActive && loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'InventoryBundles', params: { companyId: companyId } }">
                <span class="breadcrumb-item">{{ $t('Bundles') }}</span>
              </router-link>
            </li>
            <li v-if="bundleId" class="breadcrumb-item title-text active" aria-current="page">
              {{ this.$i18n?.t("EDIT_BUNDLE") }}
              <ui-hint :content="$t('EDIT_BUNDLE_HINT')" />
            </li>
            <li v-else class="breadcrumb-item title-text active" aria-current="page">
              {{ this.$i18n?.t("CREATE_BUNDLE") }}
              <ui-hint :content="$t('CREATE_BUNDLE_HINT')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <form @submit.prevent="handleSave">
            <Form ref="observer" as="div" class="row">
              <div class="col-lg-6">
                <div class="pb-2">
                  <ui-input-label :label="$t('Bundle image')" :required="false" />
                  <el-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :multiple="false"
                    :limit="1"
                    :on-change="handleFileSuccess"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-upload>
                  <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" :alt="$t('Preview Image')" />
                  </el-dialog>
                </div>
                <div class="pb-2">
                  <ui-text-input
                    id="title"
                    name="title"
                    :label="$t('Title')"
                    v-model="bundle.name"
                    :validation="{ required: true, min: 3 }"
                  />
                </div>
                <div class="pb-2">
                  <ui-textarea-input
                    :rows="3"
                    name="description"
                    v-model="bundle.description"
                    :placeholder="$t('description')"
                    :label="$t('Description')"
                  />
                </div>
                <div class="pb-2">
                  <ui-multi-select
                    name="item_categories"
                    :label="$t('ITEM_CATEGORIES')"
                    :hint="$t('ITEM_CATEGORIES_HINT')"
                    v-model="bundleCategoriesIds"
                    :options="categoriesChoices"
                    :key-name="'id'"
                    :label-name="'name'"
                    :value-name="'id'"
                  />
                </div>
                <div class="pb-2">
                  <label class="control-label font-weight-bold">
                    {{ $t('BUNDLE_DISCOUNT_TYPE') }}
                    <ui-hint :content="$t('BUNDLE_DISCOUNT_TYPE_HINT')" />
                  </label>
                  <div>
                    <ui-radio-group v-model="bundle.discount_type">
                      <ui-radio
                        name="bundle_discount_type_percentage"
                        :setValue="'percentage'">{{$t('BUNDLE_DISCOUNT_TYPE_PERCENTAGE') }}
                        <ui-hint :content="$t('BUNDLE_DISCOUNT_TYPE_PERCENTAGE_HINT')" />
                      </ui-radio>
                    </ui-radio-group>
                  </div>
                  <div>
                    <ui-radio-group v-model="bundle.discount_type">
                      <ui-radio
                        name="bundle_discount_type_amount"
                        :setValue="'amount'">
                        {{$t('BUNDLE_DISCOUNT_TYPE_AMOUNT') }}
                        <ui-hint :content="$t('BUNDLE_DISCOUNT_TYPE_AMOUNT_HINT')" />
                      </ui-radio>
                    </ui-radio-group>
                  </div>
                </div>
                <div class="pb-2">
                  <ui-number-input
                    name="bundle_discount_value"
                    :label="$t('BUND_DISCOUNT_VALUE')"
                    :hint="$t('BUNDLE_DISCOUNT_VALUE_HINT')"
                    :step="0.01"
                    :min="0"
                    v-model="bundle.discount_value" :validation="{
                      required: true,
                      integer: false,
                      min_value: 0,
                      ...(bundle.discount_type === 'percentage' ? { max_value: 100 } : {})
                    }"
                    />
                </div>
                <div class="pb-2">
                  <color-picker name="Color" :label="$t('Color')" v-model="bundle.color" :colors="colorsChoices" />
                </div>
              </div>
            </Form>
            <div class="row">
              <div class="col py-2 text-left">
                <button class="btn btn-success btn-circle shadow-sm" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </form>
          <div class="row">
            <div v-if="bundleId" class="col-lg-12 mt-2 p-3">
              <div class="row mb-2">
                <div class="col d-flex align-items-center">
                  <div class="text-primary text-uppercase font-weight-bold">
                    {{ $t("BUNDLE_ITEMS") }}
                  </div>
                </div>
                <div class="col-auto pl-1">
                  <button class="btn btn-primary btn-sm shadow-sm" data-toggle="modal" data-target="#BundleItemModal"
                    @click="addItem" type="submit">
                    {{ $t('ADD_ITEM') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <template v-if="items && items.length > 0">
                <el-table class="table" header-row-class-name="thead-light" :data="items" style="width: 100%">
                  <el-table-column :label="$t('Title')" prop="name">
                    <template #default="scope">
                      <div class="text-break">
                        {{ scope.row.item.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Description')" prop="description">
                    <template #default="scope">
                      <div class="text-break">
                        {{ scope.row.item.description }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Quantity')" >
                    <template #default="scope">
                      <div class="text-break">
                        {{ scope.row.quantity }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150" align="right">
                    <template #default="scope">
                      <i class="icon-pencil text-primary btn-icon mx-1" data-toggle="modal"
                        data-target="#BundleItemModal" @click="editItem(scope.row)" />
                      <i class="icon-trash text-danger btn-icon mx-1" @click="deleteItem(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else>
                <div class="card mb-1">
                  <div class="card-body text-center p-4">
                    {{ $t('NO_ADDED_ITEMS') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import BundleItemModal from './Components/BundleItemModal.vue'
import colors from '@/mixins/mixinColors'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import ColorPicker from '@/ui/color-picker.vue'

export default {
  name: 'InventoryBundle',
  mixins: [errorMixin, momentMixin, colors, mixinDummy, ToastMixin],
  components: { ColorPicker, DummyPaymentRequired, BundleItemModal },
  data () {
    return {
      bundle: {
        title: null,
        description: null,
        discount_type: 'percentage',
        discount_value: 0,
        color: '#958d19'
      },
      fileList: [],
      items: [],
      showBundleItemModal: false,
      itemData: null,
      isModuleActive: false,
      bundleCategoriesIds: [],
      maxFileSize: 2048,
      file: null,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    bundleId () {
      return this.$route.params.bundleId
    },
    categoriesChoices () {
      return this.$store.getters.categories
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.init()
    },
    init () {
      this.checkActiveModule()
      this.$store.dispatch('getCategories', this.companyId)

      if (this.bundleId) {
        this.$store.dispatch('getBundle', [this.companyId, this.bundleId]).then((response) => {
          this.getBundleItems()
          this.bundle = response
          this.loaded = true
          if (response.categories) {
            this.bundleCategoriesIds = response.categories.map(item => item.id)
          }
          if (response.image) {
            this.fileList.push({ url: response.image.url })
          }
        }).catch(() => { })
      } else {
        this.loaded = true
      }
    },
    getBundleItems () {
      this.$store.dispatch('getBundleItems', [this.companyId, this.bundleId]).then((response) => {
        this.items = response
      }).catch(() => { })
    },
    checkActiveModule () {
      this.$store.dispatch('isModuleActive', [this.companyId, 32]).then(response => {
        if (response.active === true) {
          this.dummy = false
          this.isModuleActive = true
        }
      }).catch(err => {
        if (!err.response.data.module) {
          this.dummy = true
          this.dummyInfo = {}
        } else {
          this.isModuleActive = false
          this.dummy = true
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove (uploadFile, uploadFiles) {
      this.file = null
    },
    handleFileSuccess (file) {
      if (['image/jpeg', 'image/png'].indexOf(file.raw.type) === -1) {
        this.toastError(this.$i18n?.t('Bundle image must be JPG/PNG format!'))
        this.fileList = []
        return false
      } else if (file.raw.size / 1024 / 1024 > 2) {
        this.toastError(this.$i18n?.t('Bundle image size can not exceed 2MB!'))
        this.fileList = []
        return false
      }

      this.file = file.raw
      return true
    },
    addItem () {
      this.itemData = null
      this.showBundleItemModal = true
    },
    editItem (item) {
      this.itemData = { ...{ id: item.id, item_id: item.item_id, name: item.item.name, quantity: item.quantity } }
      this.showBundleItemModal = true
    },
    deleteItem (itemId) {
      this.$confirm(this.$t('This will permanently delete the item. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteBundleItem', [this.companyId, this.bundleId, itemId]).then((response) => {
          this.toastSuccess(this.$t('Successfully deleted'))
          this.getBundleItems()
        }).catch((error) => {
          const errorMessage = error.response.data.message
          this.toastError(this.$i18n?.t(errorMessage))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    closeBundleItemModal () {
      this.showBundleItemModal = false
      this.itemData = null
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const formData = new FormData()
          formData.append('name', this.bundle.name)
          formData.append('color', this.bundle.color)
          formData.append('description', this.bundle.description)
          formData.append('discount_value', this.bundle.discount_value)
          formData.append('discount_type', this.bundle.discount_type)

          this.bundleCategoriesIds.forEach(categoryId => {
            formData.append('categories[]', categoryId)
          })

          if (this.file) {
            formData.append('image', this.file)
          }

          if (this.bundleId) {
            // Laravel can only accept files with the POST method, but it turns out that PATCH
            // can be called through POST by passing _method: PATCH in the formData
            formData.append('_method', 'PATCH')

            // Remove image
            if (this.bundle.image && this.fileList.length === 0) {
              formData.append('remove_image', 1)
            }

            this.$store.dispatch('updateBundle', [this.companyId, this.bundleId, formData]).then((response) => {
              this.bundle = response
              this.file = null
              this.toastSuccess(this.$t('Bundle updated'))
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          } else {
            this.$store.dispatch('createBundle', [this.companyId, formData]).then((response) => {
              this.toastSuccess(this.$t('Bundle created'))
              this.bundle = response
              this.file = null
              this.$router.push(`/c/${this.companyId}/inventory/bundles/${response.id}`)
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          }
        }
      }).catch(() => { })
    }
  }
}
</script>

<style scoped>
</style>
