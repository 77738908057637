<template>
  <div>
    <import-file-modal
      @closeImportFileModal="closeImportFileModal"
      v-if="modalSetup.ImportFileModal">
    </import-file-modal>
    <import-file-table
      @closeFileTable="closeFileTable"
      v-show="modalSetup.ImportFileTable"
      :projectDefaultId="modalProjectId"
      :file="file"
      :file_preload="file_preload">
    </import-file-table>
    <dismiss-user-modal
      @closeDismissUserModal="closeDismissUserModal"
      v-if="modalSetup.DismissUserModal"
      :dismissUser="dismissUser">
    </dismiss-user-modal>
    <mass-update-user-modal
      @clearSelection="clearSelection"
      @closeMassActionUserModal="closeMassActionUserModal"
      v-if="modalSetup.MassUpdateUserModal"
      :selectedEmployees="selectedEmployees"
    />
    <remove-dismiss-user-modal
      v-if="modalSetup.RemoveDismissUserModal"
      :dismissUser="dismissUser">
    </remove-dismiss-user-modal>
    <add-employee-component
      v-if="modalSetup.AddEmployeeComponent"
      :projectDefaultId="modalProjectId"
      @addEmployees="handleEmployees"
      @AddEmployeeModal="addEmployeeModal">
    </add-employee-component>
    <div class="row mb-2 mx-auto">
      <ol class="breadcrumb bg-transparent p-0 m-0">
        <li v-if="projectId" class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/projects/`">
            <span>{{ $t('Projects') }}</span>
          </router-link>
        </li>
        <li class="breadcrumb-item title-text active" aria-current="page">
          <span class="title-text">{{ $t('Employees') }}</span>
        </li>
      </ol>
      <div class="ml-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          autofocus
          :is-search="true"
        />
      </div>
    </div>
    <div class="mt-2">
      <div class="card card-small">
        <div class="card-body">
          <div class="row">
            <div class="col-auto" style="padding-top: 3px">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="paginationConfig.pageSize"
                :current-page="paginationConfig.currentPage"
                @current-change="updatePaginated"
                :total="paginationConfig.rows.length">
              </el-pagination>
            </div>
            <div v-if="companyUsers.length > 25">
              <ui-select
                name="pageSize"
                v-model="paginationConfig.pageSize"
                style="width: 100px;"
                :filterable="false"
                :options="pageSizeChoices"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
            <div class="col"></div>
              <template v-if="workMode !== 'field_service' && (isEmployeeHasPermission('get related projects full data') && !isEmployeeHasPermission('get-employee-admin'))">
                <div class="col-auto" style="min-width: 240px">
                  <ui-select
                    v-model="projectId"
                    name="selectedProject"
                    :filterable="false"
                    :options="projectsWhereUserIsManager"
                    :key-name="'id'"
                    :label-name="'name'"
                    :value-name="'id'"
                  />
                </div>
              </template>
            <div class="col-auto" style="min-width: 240px">
              <template v-if="workMode !== 'field_service' && isEmployeeHasPermission('get-employee-admin')">
                <ui-select
                  v-model="filterProjectId"
                  name="selectedProjectFilter"
                  :filterable="false"
                  :options="companyProjectsOptions"
                  :key-name="'id'"
                  :label-name="'name'"
                  :value-name="'id'"
                />
              </template>
            </div>
            <div v-if="isEmployeeHasPermission('get-employee-admin')" class="col-auto d-flex align-items-center">
              <div class="row">
                <div v-if="selectedUsers > 0" class="col-auto">
                  <ui-checkbox
                    name="selectAllEmployees1"
                    v-model="selectAllEmployees"
                  >
                    {{projectId ? $t('SELECT_ALL_PROJECT_EMPLOYEES') : $t('SELECT_ALL_COMPANY_EMPLOYEES')}}
                  </ui-checkbox>
                  <div v-if="selectedUsers > 0" class="mx-1 text-right small">
                    {{$t('NUMBER_OF_SELECTED_USERS')}} {{selectedUsers}}
                  </div>
                </div>
                <div class="col-auto">
                  <template v-if="selectedUsers > 0">
                    <div class="dropdown">
                      <button
                        class="btn btn-primary dropdown-toggle btn-md rounded-pill shadow-sm"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ $t('SELECT_ACTION') }}
                      </button>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <span
                          class="dropdown-item"
                          @click="handleBatchDelete"
                          style="cursor: pointer">
                          {{ $t('DELETE_SELECTED_EMPLOYEES') }}
                        </span>
                        <span
                          class="dropdown-item"
                          style="cursor: pointer"
                          @click="modalSetup.MassUpdateUserModal = true"
                          data-toggle="modal"
                          data-cy="mass-update-employee-modal"
                          data-target="#MassActionUserModal"
                        >
                          {{ $t('ADD_POSITION_EMPLOYEES') }}
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-auto d-flex align-items-center">
              <template v-if="selectedUsers < 1">
                <el-tooltip effect="light" :content="$t('Import employees from file')" placement="top">
                  <i
                    role="button"
                    class="icon-download btn-icon text-primary mx-2"
                    v-on:click="ImportFileModal"
                    data-toggle="modal"
                    data-target="#ImportFileModal"
                  />
                </el-tooltip>
                <button class="btn btn-primary btn-md rounded-pill shadow-sm ml-2"
                        role="button"
                        @click="modalSetup.AddEmployeeComponent = true"
                        data-toggle="modal"
                        data-cy="add-employee-modal"
                        data-target="#AddEmployeeModal">
                  {{ $t('Add employees') }}
                </button>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <span
                class="text-primary text-uppercase font-weight-bold"
              >
                {{ $t("Company employees") }} ({{ $t('total') }}: {{ companyUsers.length }})
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-sortable">
                  <thead>
                  <tr>
                    <th v-if="isEmployeeHasPermission('get-employee-admin')" class="align-middle">
                      <ui-checkbox
                        name="selectAllEmployeesOnPage"
                        v-model="selectAllEmployeesOnPage"
                      />
                    </th>
                    <th class="text-left">{{ $t("IDENTIFIER_TITLE") }}</th>
                    <th @click="sort('last_name')" class="text-left">
                      {{ $t("Full name") }}
                    </th>
                    <th
                      v-if="isEmployeeHasPermission('get-employee-admin')"
                      @click="sort('group')"
                      class="text-left"
                    >
                      {{ $t("Employee authority level") }}
                    </th>
                    <th v-if="workMode === 'work'" class="text-left">{{ $t("Position") }}</th>
                    <th class="text-left">{{ $t("Phone number") }}</th>
                    <th class="text-left">{{ $t("E-mail") }}</th>
                    <th @click="sort('time_zone')" class="text-left">{{ $t("Time zone") }}</th>
                    <th @click="sort('is_registered')" class="text-left">
                      {{ $t("Account status") }}
                      <ui-hint :content="$t('ACCOUNT_STATUS_HINTS')"/>
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user, key) in paginationConfig.paginated" :key="key" :class="user.dismiss ? 'dismiss' : ''">
                    <td v-if="isEmployeeHasPermission('get-employee-admin')" class="align-middle">
                      <template v-if="user.user_id !== +companyOwnerId">
                        <ui-checkbox
                          :name="key + '_checked'"
                          v-model="user.checked"
                          @update:modelValue="(val) => {handleSingleCheckboxChanged(user.id, val)}"
                        />
                      </template>
                    </td>
                    <td class="align-middle">{{user.identifier}}</td>
                    <td class="align-middle">
                      <span class="employee-color mx-1" v-bind:style="{ backgroundColor: user.color }" />
                      {{user.full_name}}
                    </td>
                    <td v-if="user.user_id === +companyOwnerId" class="align-middle"></td>
                    <td v-else-if="isEmployeeHasPermission('get-employee-admin')" class="form-group align-middle">
                      <ui-select
                        v-model="user.group"
                        v-bind:key="user.id"
                        :name="key+ '__group'"
                        :filterable="false"
                        @change="(group) => setGroup(group, user)"
                        :options="userGroups"
                        :key-name="'id'"
                        :label-name="'name'"
                        :value-name="'id'"
                      />
                    </td>
                    <td v-if="workMode === 'work'" class="align-middle">{{ user_positions_list(user) }}</td>
                    <td class="align-middle"><span v-if="user.phone">+{{user.phone}}</span></td>
                    <td class="align-middle" style="word-wrap: break-word">{{user.email}}</td>
                    <td class="align-middle">{{user.time_zone}}</td>
                    <td v-if="user.user_id === +companyOwnerId" class="align-middle"></td>
                    <td v-else-if="user.is_registered" class="align-middle text-success">
                      <el-tooltip class="item" effect="light" :content="$t('Account confirmed')" placement="left">
                        <span>{{ $t('Invite accepted') }}</span>
                      </el-tooltip>
                    </td>
                    <td v-else class="align-middle">
                      <template v-if="user.is_invite_send">
                        <div class="text-muted">
                          <el-tooltip
                            class="item"
                            effect="light"
                            :content="$t('Account confirmation sent via email or phone (if entered) but not confirmed by employee')"
                            placement="left"
                          >
                            <span>{{ $t('Invite pending') }}</span>
                          </el-tooltip>
                        </div>
                        <div v-if="(user.phone || user.email) && isConfirmed">
                          <span class="text-primary" style="cursor: pointer" @click="sendInvite(user)">{{ $t('Resend invite') }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-danger">
                          <el-tooltip
                            class="item"
                            effect="light"
                            :content="$t('Must add email or phone number for employee to be invited')"
                            placement="left"
                          >
                            <span>{{ $t('Not invited') }}</span>
                          </el-tooltip>
                        </div>
                        <div v-if="(user.phone || user.email) && isConfirmed">
                          <span class="text-primary" style="cursor: pointer" @click="sendInvite(user)">{{ $t('Invite now') }}</span>
                        </div>
                      </template>
                    </td>
                    <td class="d-flex w-5">
                      <template v-if="isEmployeeHasPermission('delete-employee')">
                        <router-link :to="`/c/${companyId}/users/${user.id}`">
                          <i role="button" class="icon-eye btn-icon text-primary mx-2"></i>
                        </router-link>
                      </template>
                      <template v-else-if="isEmployeeHasPermission('get-company-schedules-manager')">
                        <router-link :to="`/c/${companyId}/project/${projectId}/user/${user.id}`">
                          <i role="button" class="icon-eye btn-icon text-primary mx-2"></i>
                        </router-link>
                      </template>
                      <template v-if="(isEmployeeHasPermission('delete-employee')) && (user.user_id !== +companyOwnerId)">
                        <template v-if="!user.dismiss">
                          <i
                            class="icon-trash btn-icon mx-2"
                            :data-cy="user.id"
                            :class="dismissColor(user.user_id)"
                            :disabled="user.user_id === +companyOwnerId"
                            @click="user.user_id !== +companyOwnerId ? dismissModal(user, key) : null"
                            role="button"
                            data-toggle="modal"
                            data-target="#RemoveUserModal">
                          </i>
                        </template>
                        <template v-else>
                          <i
                            class="icon-rotate-ccw btn-icon mx-2"
                            :class="removeDismissColor(user.user_id)"
                            :disabled="user.user_id === +companyOwnerId"
                            @click="user.user_id !== +companyOwnerId ? removeDismissModal(user, key) : null"
                            role="button"
                            data-toggle="modal"
                            data-target="#RemoveUserModal">
                          </i>
                        </template>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
            </div>
          </div>
          <div class="row">
            <div class="col-lg">
              <div class="row">
                <div class="col-auto" style="padding-top: 3px">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-size="paginationConfig.pageSize"
                    :current-page="paginationConfig.currentPage"
                    @current-change="updatePaginated"
                    :total="paginationConfig.rows.length">
                  </el-pagination>
                </div>
                <div v-if="companyUsers.length > 25" class="col-auto">
                  <ui-select
                    name="pageSize"
                    v-model="paginationConfig.pageSize"
                    style="width: 100px;"
                    :filterable="false"
                    :options="pageSizeChoices"
                    :key-name="'id'"
                    :label-name="'name'"
                    :value-name="'id'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationMixin from '../CommonComponents/Pagination/mixinPagination'
import DismissUserModal from './UsersComponents/DismissUserModal'
import RemoveDismissUserModal from './UsersComponents/RemoveDismissUserModal'
import AddEmployeeComponent from '@/components/CommonComponents/AddEmployeeComponent'
import ImportFileModal from '@/components/Company/UsersComponents/ImportFileModal'
import ImportFileTable from '@/components/Company/UsersComponents/ImportFileTable'
import $ from 'jquery'
import MassUpdateUserModal from '@/components/Company/UsersComponents/MassUpdateUserModal'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'Users',
  components: {
    MassUpdateUserModal,
    ImportFileModal,
    DismissUserModal,
    RemoveDismissUserModal,
    AddEmployeeComponent,
    ImportFileTable
  },
  data () {
    return {
      companyOwnerId: '',
      search: '',
      dismissUser: null,
      dismissUserKey: null,
      userGroups: [],
      group_selected: '',
      currentSort: 'last_name',
      currentSortDir: 'asc',
      modalSetup: {
        DismissUserModal: false,
        RemoveDismissUserModal: false,
        ImportFileModal: false,
        ImportFileTable: false,
        AddEmployeeComponent: false,
        MassUpdateUserModal: false
      },
      projectId: null,
      file: '',
      file_preload: [],
      selectAllEmployees: false,
      selectAllEmployeesOnPage: false,
      selectedEmployees: [],
      handleCheckboxChange: true,
      filterProjectId: 'all'
    }
  },
  mixins: [paginationMixin],
  created () {
    this.$eventBus.on('LoadedFile', this.LoadedFile)
    this.$eventBus.on('DelUserResult', this.DelUserResult)
    this.$eventBus.on('dismissUserResult', this.dismissUserResult)
    this.$eventBus.on('removeDismissUserResult', this.removeDismissUserResult)
    this.$eventBus.on('refetchResources', this.updateList)
    this.projectId = this.$route.params.projectId ? +this.$route.params.projectId : null
    this.onCreated()
  },
  beforeUnmount () {
    this.$eventBus.off('LoadedFile')
  },
  watch: {
    'paginationConfig.pageSize': {
      handler () {
        this.updatePaginated(1)
      },
      deep: true
    },
    filterProjectId () {
      this.filteredUsers()
    },
    selectAllEmployees (val) {
      if (val) {
        this.handleCheckboxChange = true
        this.selectedEmployees = []
        const selectedEmployees = []
        this.companyUsers.forEach(user => {
          if (user.user_id !== this.companyOwnerId) {
            user.checked = true
            selectedEmployees.push(user.id)
          }
        })
        this.selectedEmployees = selectedEmployees
        this.handleCheckboxChange = false
        this.selectAllEmployeesOnPage = true
      } else {
        this.selectedEmployees = []
        this.selectAllEmployeesOnPage = false
        this.paginationConfig.paginated.forEach(user => {
          user.checked = false
        })
        this.companyUsers.forEach(user => {
          user.checked = false
        })
      }
    },
    selectAllEmployeesOnPage (val) {
      if (!val) {
        this.selectAllEmployees = false
        if (this.handleCheckboxChange) {
          this.handleEmployees()
        }
      } else {
        if (this.handleCheckboxChange) {
          this.selectedEmployees = []
          const selectedEmployees = []
          this.paginationConfig.paginated.forEach(user => {
            if (user.user_id !== this.companyOwnerId) {
              user.checked = true
              selectedEmployees.push(user.id)
            }
          })
          this.selectedEmployees = selectedEmployees
        }
      }
      this.handleCheckboxChange = true
    },
    projectsWhereUserIsManager () {
      this.onCreated()
    },
    projectId (val) {
      if (this.companyId && val) {
        this.filterProjectId = val
        window.history.pushState(
          {},
          null,
          `/c/${this.companyId}/project/${val}/users`
        )
        this.onCreated()
        this.currentSort = 'last_name'
        this.currentSortDir = 'asc'
      }
    },
    companyId () {
      this.onCreated()
    },
    search: function () {
      this.filteredUsers()
    },
    currentSort: function () {
      this.filteredUsers()
    },
    currentSortDir: function () {
      this.filteredUsers()
    }
  },

  computed: {
    companyUsers () {
      return this.$store.getters.employees
    },
    projectUsers () {
      return this.$store.getters.projectEmployees
    },
    modalProjectId () {
      let id = null
      if (this.filterProjectId !== 'all' && this.filterProjectId !== 'no_project') {
        id = this.filterProjectId
      }
      return id
    },
    selectedUsers () {
      return this.selectedEmployees.length
    },
    isConfirmed () {
      return this.$store.getters.Profile.phone !== null
    },
    projectsWhereUserIsManager () {
      return this.$store.getters.projectsWhereUserIsManager
    },
    companyProjects () {
      return this.$store.getters.projects
    },
    companyProjectsOptions () {
      const options = [
        {
          id: 'all',
          name: this.$t('ALL_USERS')
        },
        {
          id: 'no_project',
          name: this.$t('USERS_WITHOUT_PROJECTS')
        }
      ]
      this.companyProjects.forEach(project => {
        options.push({
          id: project.id,
          name: project.name
        })
      })
      return options
    },
    allProjectsUsers () {
      let users = []
      this.companyProjects.forEach(project => {
        users = users.concat(project.users)
      })
      return users
    }
  },

  methods: {
    updatePaginated (page) {
      this.paginationConfig.currentPage = page
      this.paginationConfig.paginated = this.pageData()
    },
    pageData () {
      return this.paginationConfig.rows.filter((row, index) => {
        const start = (this.paginationConfig.currentPage - 1) * this.paginationConfig.pageSize
        const end = this.paginationConfig.currentPage * this.paginationConfig.pageSize

        return (index >= start && index < end)
      })
    },
    user_positions_list (user) {
      if (user.positions) {
        return user.positions.map(item => item.title).join(', ')
      }

      return ''
    },
    handleBatchDelete () {
      this.$confirm(this.$i18n?.t('Confirm deletion'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('batchDeleteUsers', [
          this.$store.getters.companyId,
          { employees_id: this.selectedEmployees }
        ]).then(response => {
          this.handleEmployees()
          this.paginationConfig.currentPage = 1
          this.$notify({
            title: this.$i18n?.t('Success'),
            message: this.$i18n?.t('DELETE_SUCCESS'),
            type: 'success',
            position: this.notificationPosition
          })
          this.$eventBus.emit('checkSteps')
          this.onCreated()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleSingleCheckboxChanged (id, val) {
      if (val) {
        this.selectedEmployees.push(id)
      } else {
        this.selectedEmployees = this.selectedEmployees.filter(item => item !== id)
      }
    },
    handleEmployees () {
      this.selectedEmployees = []
      this.companyUsers.forEach(user => {
        user.checked = false
      })
      this.paginationConfig.paginated.forEach(user => {
        user.checked = false
      })
      this.paginationConfig.rows.forEach(user => {
        user.checked = false
      })
      this.selectAllEmployeesOnPage = false
      this.selectAllEmployees = false
    },
    addEmployeeModal () {
      this.modalSetup.AddEmployeeComponent = false
    },
    updateList () {
      this.filteredUsers()
    },
    onCreated () {
      if (this.companyId) {
        this.handleEmployees()
        if (!this.isEmployeeHasPermission('create-employee')) {
          this.accessErrorRedirect('You don\'t have permission for this action')
        }
        if (this.projectId && this.projectsWhereUserIsManager.length > 0) {
          // если юзер пытается перейти на сотрудников через проект, в котором он не являются менеджером, переключаем проект на первый, в котором он менеджер
          let isProjectRight = false
          this.projectsWhereUserIsManager.forEach(project => {
            if (project.id === this.projectId) {
              isProjectRight = true
            }
          })
          if (!isProjectRight) {
            this.projectId = this.projectsWhereUserIsManager[0].id
          }
          this.$store.dispatch('getEmployeesByProject', this.projectId).then(() => {
            this.filteredUsers()
          })
        } else {
          this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then(() => {
            this.filteredUsers()
          })
        }
        this.$store.dispatch('usersStatusFields').then(response => {
          this.userGroups = response.groups
          this.userGroups = this.userGroups.map(item => {
            item.name = this.$t(item.name)
            return item
          })
        })
        this.companyOwnerId = this.$store.getters.companyOwnerId
      }
    },

    trashColor (userId) {
      return !(userId === +this.companyOwnerId) ? 'text-danger' : 'text-muted'
    },
    dismissColor (userId) {
      return !(userId === +this.companyOwnerId) ? 'text-warning' : 'text-muted'
    },
    removeDismissColor (userId) {
      return !(userId === +this.companyOwnerId) ? 'text-success' : 'text-muted'
    },

    filteredUsers () {
      let filteredUsers = this.companyUsers

      if (this.projectId) {
        filteredUsers = this.projectUsers
      }
      filteredUsers.forEach(user => {
        user.checked = false
      })
      // first filter by projects
      if (this.filterProjectId === 'no_project') {
        filteredUsers = filteredUsers.filter((user) => {
          return !this.allProjectsUsers.includes(+user.id)
        })
        this.paginationConfig.rows = filteredUsers
      } else if (this.filterProjectId !== 'all') {
        const project = this.$store.getters.projectById(this.filterProjectId)

        filteredUsers = filteredUsers.filter((user) => {
          return project.users.includes(+user.id)
        })
      }
      // then by search
      filteredUsers = filteredUsers.filter((user, index) => {
        const needle = this.search.toString().toLowerCase()

        if (!user.first_name) {
          return false
        }

        if (user.phones && user.phones.length > 0 && user.phones[0]) {
          user.phone = user.phones[0].toString()
        }

        if (needle === '') {
          return true
        }
        let searchable = ''
        if (user.first_name) {
          searchable += ' ' + user.first_name.toLowerCase()
        }
        if (user.last_name) {
          searchable += ' ' + user.last_name.toLowerCase()
        }
        if (user.email) {
          searchable += ' ' + user.email.toLowerCase()
        }
        if (user.identifier) {
          searchable += ' ' + user.identifier.toLowerCase()
        }
        if (searchable.indexOf(needle) > -1) {
          return true
        }

        return !!(user.phone && user.phone.indexOf(needle) > -1)
      })
      // finally sort
      filteredUsers = filteredUsers.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') {
          modifier = -1
        }
        if (a[this.currentSort] === null && b[this.currentSort] !== null) {
          return 1
        }
        if (a[this.currentSort] !== null && b[this.currentSort] === null) {
          return -1
        }
        if (a[this.currentSort] === null && b[this.currentSort] === null) {
          return 0
        }
        if (this.currentSort === 'is_registered') {
          const aStatus = a.is_registered === true ? 'accepted' : a.is_invite_send === true ? 'pending' : 'waiting'
          const bStatus = b.is_registered === true ? 'accepted' : b.is_invite_send === true ? 'pending' : 'waiting'
          if (aStatus < bStatus) {
            return -1 * modifier
          }
          if (aStatus > bStatus) {
            return modifier
          }
        } else {
          if (a[this.currentSort].toString().trim().toLowerCase() < b[this.currentSort].toString().trim().toLowerCase()) {
            return -1 * modifier
          }
          if (a[this.currentSort].toString().trim().toLowerCase() > b[this.currentSort].toString().trim().toLowerCase()) {
            return modifier
          }
        }
        return 0
      })

      this.paginationConfig.rows = filteredUsers
      this.updatePaginated(this.paginationConfig.currentPage)
    },
    closeDismissUserModal () {
      this.modalSetup.DismissUserModal = false
      this.dismissUser = null
      this.dismissUserKey = null
    },
    closeMassActionUserModal () {
      this.modalSetup.MassUpdateUserModal = false
    },
    clearSelection () {
      this.selectedEmployees = []
      this.selectAllEmployeesOnPage = false
      this.paginationConfig.paginated.forEach(user => {
        user.checked = false
      })
      this.companyUsers.forEach(user => {
        user.checked = false
      })
      this.filteredUsers()
    },
    closeImportFileModal () {
      this.modalSetup.ImportFileModal = false
    },
    closeFileTable () {
      this.modalSetup.ImportFileTable = false
    },
    removeDismissModal (user, key) {
      this.modalSetup.RemoveDismissUserModal = true
      this.dismissUser = user
      this.dismissUserKey = key
    },
    dismissModal (user, key) {
      this.modalSetup.DismissUserModal = true
      this.dismissUser = user
      this.dismissUserKey = key
    },
    dismissUserResult ([userId, dismiss]) {
      this.modalSetup.DismissUserModal = false
      const dismissUser = this.paginationConfig.rows.filter(function (el) { return el.id === userId })

      const index = this.paginationConfig.rows.indexOf(dismissUser[0])
      if (index > -1) {
        this.paginationConfig.rows[index].dismiss = dismiss
      }
      this.dismissUserKey = null
    },
    removeDismissUserResult (userId) {
      this.modalSetup.RemoveDismissUserModal = false
      const dismissUser = this.paginationConfig.rows.filter(function (el) { return el.id === userId })
      const index = this.paginationConfig.rows.indexOf(dismissUser[0])
      if (index > -1) {
        this.paginationConfig.rows[index].dismiss = null
      }
      this.dismissUserKey = null
    },
    DelUserResult (userId) {
      this.paginationConfig.rows = this.paginationConfig.rows.filter(function (el) { return el.id !== userId })
      this.updatePaginated(this.paginationConfig.currentPage)
    },
    setGroup (group, user) {
      const permData = {
        group: group
      }
      this.$store.dispatch('changeUserPriorityAndPermission', [this.$store.getters.companyId, user.id, permData])
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.currentSort = s
        this.currentSortDir = 'asc'
      }
    },
    sendInvite (user) {
      this.$store.dispatch('inviteCompanyUsers', [user.id]).then(() => {
        user.is_invite_send = true
      }).catch((error) => {
        // console.log(error.response)
        if (error.response.data.message === 'Sending invites is not allowed') {
          ElMessageBox.alert('<div>' + this.$t('CREDIT_CARD_REQUIRED_MESSAGE') + "</div><div><a href='/c/" + this.$store.getters.company.id + "#payment'>" + this.$t('ADD_CREDIT_CARD_LINK') + '</a></div>', this.$t(error.response.data.message), {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            center: true,
            showClose: false
          })
        } else {
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    ImportFileModal () {
      this.modalSetup.ImportFileModal = true
    },
    LoadedFile (data) {
      $('#ImportFileTable').modal('toggle')
      this.file_preload = data.file_preload
      this.file = data.file
      this.modalSetup.ImportFileModal = false
      this.modalSetup.ImportFileTable = true
    }
  }
}
</script>

<style scoped>
.table-sortable > thead > tr > th {
  cursor: pointer;
  position: relative;
}

.employee-color {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  vertical-align: middle;
}
</style>
