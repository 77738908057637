<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Categories") }}
          <ui-hint :content="$t('INVENTORY_CATEGORIES_HINT')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row py-2">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill mx-3 m-1 col-auto col-auto" type="button" @click="addCategory">
                  {{ $t('Create Category') }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <el-table
              :data="list"
              style="width: 100%">
              <el-table-column
                prop="name"
                width="200">
                <template #default="scope">
                  <el-image
                    :key="scope.row.id + '_image'"
                    :src="scope.row.image ? scope.row.image.url : ''"
                    fit="contain"
                    lazy
                  >
                  <template #error>
                  </template>
                </el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('Title')"
                width="240">
              </el-table-column>
              <el-table-column
                :label="$t('Color')"
                >
                <template #default="scope">
                  <span class="category-color" v-bind:style="{ backgroundColor: scope.row.color }"></span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Created at')">
                <template #default="scope">
                  {{ fromZoneToZone(scope.row.created_at, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                </template>
              </el-table-column>
              <el-table-column
                width="150"
                :label="$t('Actions')">
                <template #default="scope">
                  <router-link :to="`/c/${companyId}/inventory/categories/${scope.row.id}`">
                    <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                  </router-link>
                  <i v-if="!scope.row.is_first" class="icon-trash btn-icon text-danger"
                    @click="deleteCategory(scope.row.id)"
                    role="button">
                  </i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'InventoryCategories',
  components: { DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAndInitialize()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAndInitialize()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    checkAndInitialize () {
      this.$store.dispatch('isModuleActive', [this.companyId, 32]).then((e) => {
        this.init()
      }).catch(err => {
        this.$store.dispatch('getModulesByCompany')
        this.dummy = true
        if (!err.response.data.module) {
          this.dummyInfo = {}
        } else {
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    init () {
      this.$store.dispatch('getCategories', this.companyId).then((response) => {
        if (response) {
          this.list = response
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addCategory () {
      this.$router.push(`/c/${this.companyId}/inventory/categories/create`)
    },
    deleteCategory (categoryId) {
      this.$confirm(this.$t('This will permanently delete the category. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteCategory', [this.companyId, categoryId]).then((response) => {
          this.init()
          this.toastSuccess(this.$t('Successfully deleted'))
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
        }).catch((error) => {
          const errorMessage = error.response.data.message
          if (error.response.status === 400 && errorMessage === 'Unable to delete category') {
            this.toastError(this.$i18n?.t(errorMessage))
          }
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>

<style scoped>
.category-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
.el-image {
  padding: 0 5px;
  /* max-width: 300px; */
  /* max-height: 300px; */
  width: 100%;
  height: 160px;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
.image-slot .el-icon {
  font-size: 30px;
}
</style>
