<template>
  <div class="row justify-content-center">
    <div class="col-6 text-center pt-4">
        <p>
          {{ $t('email_confirm_token') }}
        </p>
        <p>
          <router-link :to="`/login?lang=${lang}`" class="">{{ $t('Sign In') }}</router-link>
        </p>
    </div>
  </div>
</template>
<script>
import { loadLanguageAsync } from '@/i18n'

export default {
  name: 'Confirm',
  data () {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      recaptcha_key: null,
      languages: [],
      lang: 'en'
    }
  },
  watch: {
    $route (to, from) {
      if (this.languages.includes(this.$route.query.lang)) {
        this.lang = this.$route.query.lang
      }
    }
  },
  created () {
    this.$store.dispatch('getLanguages').then((resolve) => {
      this.languages = resolve.map((item) => item.code)
      const lang = this.$route.query.lang
      if (this.languages.includes(lang)) {
        this.lang = lang
        this.setLanguage()
      }
    })
  },
  async beforeMount () {
    await this.$recaptchaLoaded()

    let token = this.sitekey
    if (!this.recaptchaShow) {
      this.recaptcha_key = token
    } else {
      token = await this.$recaptcha('Email confirmation')
      this.recaptcha_key = token
    }

    this.$store.dispatch('confirmEmail', { hash: this.$route.params.hash, recaptcha_key: this.recaptcha_key }).then(() => {
      this.$store.dispatch('setConfirmedEmail')
      if (localStorage.getItem('api_key')) {
        console.log('Auth')
        this.$store.dispatch('dropProfile').then(() => {
          this.$store.dispatch('getProfile').then(() => {
            this.$store.dispatch('getUserInfo').then(() => {
            })
          })
        })
      }
    })
  },
  methods: {
    isAuthorized () {
      return !!this.$store.getters.profileId
    },
    setLanguage () {
      loadLanguageAsync(this.lang)
    }
  }
}
</script>

<style scoped>

</style>
