<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-else-if="loaded">
      <div class="table-responsive">
        <table class="table table-bordered" >
          <thead v-if="chunks.length > 0">
          <tr>
            <template v-for="(item, key) in columns" >
              <th class="align-middle text-center table-header text-wrap" v-bind:key="item.name + '_' + key" v-if="key === 4 || key === 5" :colspan="chunks.length">{{item.title}}</th>
              <th class="align-middle text-center table-header align-middle text-wrap" v-bind:key="item.name + '_c' + key" v-else :rowspan="2">{{item.title}}</th>
            </template>
          </tr>
          <tr>
            <th class="align-middle text-center table-header text-nowrap" v-for="(chunk, index) in chunks" v-bind:key="chunk + '_time_' + index">{{getChunkName(chunk)}}</th>
            <th class="align-middle text-center table-header text-nowrap" v-for="(chunk, index) in chunks" v-bind:key="chunk + '_cost_' + index">{{getChunkName(chunk)}}</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="chunks.length > 0">
            <template v-for="(row, key) in paginationConfig.paginated">
              <template v-for="(project, project_key, project_index) in row.projects">
                <template v-for="(schedule, schedule_id, schedule_index) in project.schedules">
                  <template v-for="(rate, rate_id, rate_index) in schedule.rates" :key="`${key}_${project_index}_${schedule_index}_${rate_index}`">
                    <tr>
                      <!-- мерж юзернейма -->
                      <template v-if="row.rows > 1">
                        <td v-if="project_index === 0 && schedule_index === 0 && rate_index === 0" :rowspan="row.rows" class="text-nowrap align-middle">
                          {{row.name}}
                          <div class="small">
                            {{positionsByUsers[row.employee_id] ? positionsByUsers[row.employee_id].positionName : ''}}
                          </div>
                        </td>
                      </template>
                      <template v-else>
                        <td class="text-nowrap align-middle">
                          {{row.name}}
                          <div class="small">
                            {{positionsByUsers[row.employee_id] ? positionsByUsers[row.employee_id].positionName : ''}}
                          </div>
                        </td>
                      </template>
                      <!-- мерж имени проекта -->
                      <template v-if="project.rows > 1">
                        <td v-if="schedule_index === 0 && rate_index === 0" :rowspan="project.rows" class="text-nowrap align-middle">{{project.name}}</td>
                      </template>
                      <template v-else>
                        <td class="text-nowrap align-middle">{{project.name}}</td>
                      </template>
                      <!-- мерж имени расписания -->
                      <template v-if="schedule.rows > 1">
                        <td v-if="Object.keys(project.schedules).indexOf(schedule_id) === schedule_index && rate_index === 0" :rowspan="schedule.rows" class="text-nowrap align-middle">{{ schedule.name }}</td>
                      </template>
                      <template v-else>
                        <td class="text-nowrap">{{ schedule.name }}</td>
                      </template>
                      <td class="text-center">{{ roundValue(rate_id) }}</td>
                      <td class="text-center" v-for="(chunk, chunk_index) in chunks" v-bind:key="key + '_' + project_key + '_' + schedule_id + '_time_' + chunk_index">{{ roundValue(rate.chunks.time[chunk] / 60.0 ) }} <br> ({{ hrsForHuman(rate.chunks.time[chunk] / 60.0 + Number.EPSILON) }})</td>
                      <td class="text-center" v-for="(chunk, chunk_index) in chunks" v-bind:key="key + '_' + project_key + '_' + schedule_id + '_cost_' + chunk_index">{{ roundValue(rate.chunks.cost[chunk]) }}</td>
                      <td class="text-center">{{ roundValue(rate.total_cost) }}</td>
                      <!-- мерж тоталов -->
                      <template v-if="row.rows > 1 && Object.keys(row.projects).indexOf(project_key) === 0 && schedule_index === 0 && rate_index === 0">
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.bonus) }}</td>
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.penalty) }}</td>
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.advance) }}</td>
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.total_cost_with_bonus) }}</td>
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.total_cost_with_bonus - row.advance) }}</td>
                        <td class="text-center align-middle" :rowspan="row.rows">{{ roundValue(row.total_shifts) }}</td>
                      </template>
                      <template v-else-if="row.rows === 1">
                        <td class="text-center align-middle">{{ roundValue(row.bonus) }}</td>
                        <td class="text-center align-middle">{{ roundValue(row.penalty) }}</td>
                        <td class="text-center align-middle">{{ roundValue(row.advance) }}</td>
                        <td class="text-center align-middle">{{ roundValue(row.total_cost_with_bonus) }}</td>
                        <td class="text-center align-middle">{{ roundValue(row.total_cost_with_bonus - row.advance) }}</td>
                        <td class="text-center align-middle">{{ roundValue(row.total_shifts) }}</td>
                      </template>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </template>
          </tbody>
        </table>
      </div>
      <div class="row">
        <pagination
          class="pl-1 pr-3"
          :rows="paginationConfig.rows"
          :pageSize="paginationConfig.pageSize"
          :currentPage="paginationConfig.currentPage"
          v-on:updatePaginated="updatePaginated"
          v-show="chunks.length > 0"
        />
        <div class="col-auto">
          <ui-select
            name="pageSize"
            v-model="paginationConfig.pageSize"
            :options="pageSizeChoices.map(i => {
              return {id: i, title: i}
            })"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import Pagination from '../../CommonComponents/Pagination/Pagination'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  mixins: [errorMixin, momentMixin, paginationMixin, mixinDummy],
  data () {
    return {
      columns: [
        { name: 'name', title: this.$i18n?.t('Employee\'s Name') },
        { name: 'project', title: this.$i18n?.t('Project') },
        { name: 'schedules', title: this.$i18n?.t('Schedules') },
        { name: 'pay_rate', title: this.$i18n?.t('Pay rate') },
        { name: 'time_worked', title: this.$i18n?.t('Time worked based on set rate') },
        { name: 'costs', title: this.$i18n?.t('Costs based on set rate') },
        { name: 'schedules_total', title: this.$i18n?.t('Total salary schedules') },
        { name: 'bonus', title: this.$i18n?.t('BONUS') },
        { name: 'penalty', title: this.$i18n?.t('PENALTY') },
        { name: 'advance', title: this.$i18n?.t('Advance') },
        { name: 'salary_total', title: this.$i18n?.t('Total salary') },
        { name: 'salary_total_without_advance', title: this.$i18n?.t('Total salary without advance') },
        { name: 'shifts_total', title: this.$i18n?.t('Shifts total') }
      ],
      rows: [],
      headRows: {},
      loaded: false,
      display: [],
      chunks: [],
      dummy: false,
      pageSizeChoices: [25, 50, 100, 200, 500],
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  name: 'PayRate',
  props: ['projects', 'period', 'search', 'selectedMarks', 'marksByUsers', 'selectedPositions', 'positionsByUsers'],
  components: { pagination: Pagination, DummyPaymentRequired: DummyPaymentRequired },
  created () {
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      employees: 'employees',
      projectsList: 'projects',
      schedules: 'schedules'
    })
  },
  beforeUnmount () {
    this.$eventBus.off('exportPayRateReport')
  },
  mounted () {
    this.$eventBus.on('exportPayRateReport', this.getTableData)
  },
  methods: {
    getChunkName (value) {
      const strArray = value.split('.')
      if (strArray.length > 1) {
        return this.$i18n?.t(strArray[0]) + ' ' + `(${strArray[1]}%)`
      }
      return strArray[0]
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    filteredData () {
      this.paginationConfig.rows = this.rows.filter((row, index) => {
        if (this.search === '') {
          return row
        }
        return (row.name.toLowerCase()).indexOf(this.search.toString().toLowerCase()) > -1
      })
    },
    filteredByPositions () {
      this.paginationConfig.rows = this.rows.filter((row, index) => {
        if (this.selectedPositions.length === 0) {
          return row
        }
        // console.log(row, this.positionsByUsers)
        return this.positionsByUsers[row.employee_id] && this.selectedPositions.includes(this.positionsByUsers[row.employee_id].positionId)
        // return this.selectedPositions.includes(row.position_id)
      })
    },
    filteredByMarks () {
      const selectedMarks = this.selectedMarks
      const marksByUsers = this.marksByUsers
      this.paginationConfig.rows = this.rows.filter((row, index) => {
        if (selectedMarks.length === 0 && marksByUsers) {
          return row
        }
        let find = 0
        const userId = row.employee_id
        selectedMarks.forEach(selectMark => {
          const splitData = selectMark.split(':')
          const scheduleId = parseInt(splitData[0])
          const markId = parseInt(splitData[1])
          if (marksByUsers[userId] && scheduleId in marksByUsers[userId] && marksByUsers[userId][scheduleId].indexOf(markId) !== -1) {
            find += 1
          }
        })
        return find > 0
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    getTableData (exportType = null) {
      if (moment(this.period[0], this.backendDateFormat).isSameOrBefore(moment(this.period[1], this.backendDateFormat))) {
        this.loaded = false
        const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
        const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)

        let data = '?projects[]=' + this.projects.join('&projects[]=')
        data += '&positions[]=' + this.selectedPositions.join('&positions[]=')
        data += '&date_from=' + dateFrom
        data += '&date_to=' + dateTo
        this.selectedMarks.forEach(element => {
          const markArr = element.split(':')
          data += `&marks[${markArr[0]}][]=${markArr[1]}`
        })

        if (exportType) {
          data += '&export_type=' + exportType
        }

        this.$store.dispatch('getPayRates', [this.$store.getters.companyId, data, exportType]).then((response) => {
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
          if (exportType) {
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.$i18n?.t('export_pay_report')}_${moment(this.toTimeZone(dateFrom)).format(this.backendDateFormat)}_${moment(this.toTimeZone(dateTo)).format(this.backendDateFormat)}.xlsx`)
            document.body.appendChild(link)
            link.click()
          } else {
            this.setTable(response)
          }
        }).catch((error) => {
          if (error.response.status === 500) {
            this.toastError(this.$t('500_ERROR_MESSAGE'))
            return
          }
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          } else {
            // this.$router.push('dashboard')
            this.toastError(this.$t(error.response.data.message))
          }
        })
      }
    },
    setTable (response) {
      this.rows = []
      const users = response.users_data
      this.chunks = response.chunks
      Object.keys(users).forEach(key => {
        const row = {
          name: users[key].username,
          employee_id: key,
          projects: users[key].projects,
          bonus: users[key].bonus,
          penalty: users[key].penalty,
          advance: users[key].advance,
          total_cost_with_bonus: users[key].total_cost + users[key].bonus - users[key].penalty,
          total_shifts: users[key].shifts,
          rows: users[key].rows
        }
        this.rows.push(row)
      })

      this.rows.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
      this.filteredData()
    }
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    },
    search: function () {
      this.filteredData()
    },
    selectedMarks: function () {
      this.filteredByMarks()
    },
    selectedPositions () {
      this.filteredByPositions()
    }
  }
}
</script>
<style></style>
