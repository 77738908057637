<template>
  <div>
    <!-- Нужно для тестов, закоментить после тестов -->
<!--     <button @click="test">Test</button>-->
    <button
      id="onboardWizardBtn"
      class="visually-hidden"
      data-toggle="modal"
      data-target="#OnboardWizard"
      type="button"
    >
    </button>

    <onboard-wizard v-if="showOnboardWizard"/>
    <click-event-modal
      v-if="modalSetup.clickEventModalShow"
      :shift="actionShift"
      :schedules-by-id="schedulesById"
      :currentProjectData="currentProject"
      @closeClickEventModal="() => this.modalSetup.clickEventModalShow = false"
    />
    <click-free-board-modal
      v-if="modalSetup.clickFreeBoardEventModalShow"
      :shift="actionShift"
      :assignUsers="assignUsersObject"
      :can-take-shift="canTakeShift"
      @closeClickFreeBoardEventModal="() => this.modalSetup.clickFreeBoardEventModalShow = false"
    />

    <click-break-modal
      v-if="modalSetup.breakExchangeModalShow"
      :breakEvent="actionBreak"
    />

    <add-shift-modal
      v-if="modalSetup.addShiftShow"
      :shift-add-date="addShiftModal.shiftAddDate"
      :shiftAddEndDate="addShiftModal.shiftAddEndDate"
      :userMarks="userMarks"
      :users="usersBySchedule"
      :userId="addShiftModal.addShiftUserId"
      :schedules="schedules"
      :templateId="addShiftModal.templateId"
      :shiftModalType="modalSetup.shiftModalType"
      @closeAddShiftModal="() => this.modalSetup.addShiftShow = false"
    />

    <request-create-shift
      v-if="modalSetup.requestCreateShiftShow"
      :shift-add-date="addShiftModal.shiftAddDate"
      :userMarks="userMarks"
      :users="usersBySchedule"
      :user-id="addShiftModal.addShiftUserId"
      :schedulesList="schedules"
      @closeRequestCreateShiftModal="() => this.modalSetup.requestCreateShiftShow = false"
    />

    <add-holiday-modal
      v-if="modalSetup.addHoliday"
      :viewStart="viewStart"
      :toggleModalHoliday="toggleModalHoliday"
      :holidayUpdated="holiday"
      @closeAddHolidayModal="() => this.holiday = {}"
    />

    <click-user-request-modal
      v-if="modalSetup.userRequestModal"
      :t="$i18n"
      :userRequest="userRequest"
      @closeUserRequestModal="() => {
        this.userRequest = {}
        this.modalSetup.userRequestModal = false
      }"
    />

    <add-shift-by-template-modal
      v-if="modalSetup.addShiftByTemplateShow"
      :schedule-template-str="actionScheduleTemplateStr"
      :schedules="actionSchedules"
      :schedulesList="schedules"
      :shift-add-date="viewStart"
      :shiftAddEndDate="viewEnd"
      :color="color"
      :project-tz="currentProject.projectTz"
      :users="usersBySchedule[actionScheduleId]"
      @closeAddShiftByTemplateModal="() => this.modalSetup.addShiftByTemplateShow = false"
    />

    <double-accounts-setup-modal
      v-if="modalSetup.doubleAccountsSetupShow"
      :shift-add-date="viewStart"
      :currentProject="currentProject.id"
      :preloadUsers="preloadUsers"
      @closeDoubleAccountsSetupModal="() => this.modalSetup.doubleAccountsSetupShow = false"
    />

    <div class="row mx-auto">
      <span class="title-text">{{ $t('Schedule') }}</span>
      <div class="ml-auto">
        <i class="icon-users btn-icon text-primary mx-1"
          v-if="doubleAccountAllowed && (isEmployeeHasPermission('get-company-schedules-admin') || rolesByProjects[currentProject.id] === 'manager')"
          data-toggle="modal" data-target="#DoubleAccountsSetupModal"
          @click="doubleAccountsSetup()"
        />
      </div>
    </div>
    <div class="row text-center" v-if="Object.keys(currentProject.templatesByProject).length === 0">
      <div class="col-lg-12">
        {{ this.$i18n?.t('Your company schedules will be displayed on this page.') }}
      </div>
    </div>
    <div class="row">
      <div v-show="isOpen" class="col p-1" style="max-width: 250px;" :style="isMobile ? {position: 'absolute', 'z-index': 1000} : null">
        <div class="card card-small">
          <div class="card-body p-1">
            <div class="text-right">
              <i v-if="lang !== 'he'" class="icon-arrow-left-circle btn-icon text-primary mx-1"
                 v-show="isOpen"
                 @click="toggleMenu"></i>
              <i v-if="lang === 'he'" class="icon-arrow-right-circle btn-icon text-primary mx-1"
                 v-show="isOpen"
                 @click="toggleMenu"></i>
            </div>
            <!-- Кнопка отправки уведомлений -->
            <publish
              v-show="currentProject.id !== 0"
              :currentProject="currentProject.id">
            </publish>
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-show="Object.keys(currentProject.templatesByProject).length > 0 &&
                  viewByUserMode"
                class="px-2"
                name="Filters"
              >
                <template #title>
                  <div class="font-weight-bold">{{ $t('Filters') }}</div>
                </template>
                <!-- Фильтры -->
                <full-calendar-filter
                  :users-show="usersShow"
                  v-show="Object.keys(currentProject.templatesByProject).length > 0"
                  :currentProject="currentProject.id"
                  :positions="positions">
                </full-calendar-filter>
              </el-collapse-item>
              <el-collapse-item
                class="px-2"
                name="Projects">
                <template #title>
                  <div class="font-weight-bold">{{ $t('Projects') }}</div>
                </template>
                <!-- Проект -->
                <projects />
              </el-collapse-item>
              <schedules
                @plus-click="addShiftByTemplate"
                :hideCalendar="hideCalendar"
                :currentProject="currentProject">
              </schedules>
              <el-collapse-item
                v-if="(isEmployeeHasPermission('get-company-schedules-admin') ||
                 rolesByProjects[currentProject.id] === 'manager') &&
                  Object.keys(statistic.data).length > 0"
                class="px-2"
                name="Statistic">
                <template #title>
                  <div class="font-weight-bold">{{ $t('Statistics') }}</div>
                </template>
                <!-- Статистика смен -->
                <statistic
                  :statistic="statistic.data"
                  :showStat="statistic.showStat">
                </statistic>
              </el-collapse-item>
              <el-collapse-item
                class="px-2"
                name="DisplaySettings">
                <template #title>
                  <div class="font-weight-bold">{{ $t('DISPLAY_SETTINGS') }}</div>
                </template>
                <!-- Настройки отображения событий на календаре -->
                <display-settings />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class="col w-100 p-1" v-if="Object.keys(currentProject.templatesByProject).length < 1">
        <i class="icon-arrow-right-circle btn-icon text-primary mx-1"
              v-show="!isOpen"
              @click="toggleMenu"></i>
      </div>
      <div class="col" v-if="Object.keys(currentProject.templatesByProject).length > 0">
        <div class="row">
          <div class="form-body col-lg-12 p-1">
            <div class="card card-small">
              <div class="card-body">
                <company-schedule-calendar
                  v-if="currentProjectId > 0"
                  @viewDateChange="viewDateChange"
                  @toggleMenu="toggleMenu"
                  :isOpen="isOpen"
                  :key="'company-schedule-calendar'"
                  :currentProject="currentProject">
                </company-schedule-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickEventModal from './CompanyScheduleComponents/Modals/ClickEventModal'
import FullCalendarFilter from './CompanyScheduleComponents/FullСalendarFilter'
import Publish from './CompanyScheduleComponents/ManagmentMenu/Publish'
import Projects from './CompanyScheduleComponents/ManagmentMenu/Projects'
import Schedules from './CompanyScheduleComponents/ManagmentMenu/Schedules'
import Statistic from './CompanyScheduleComponents/ManagmentMenu/Statistic'
import ClickFreeBoardEventModal from './CompanyScheduleComponents/Modals/ClickFreeBoardEventModal'
import AddShiftModal from './CompanyScheduleComponents/Modals/AddShiftModal'
import AddHolidayModal from './CompanyScheduleComponents/Modals/AddHolidayModal'
import RequestCreateShiftModal from './CompanyScheduleComponents/Modals/RequestCreateShiftModal'
import ClickUserRequestModal from './CompanyScheduleComponents/ClickUserRequestModal'
import AddShiftByTemplateModal from './CompanyScheduleComponents/Modals/AddShiftByTemplateModal'
import DoubleAccountsSetupModal from './CompanyScheduleComponents/DoubleAccountsSetupModal'
import CompanyScheduleCalendar from './CompanyScheduleComponents/CompanyScheduleCalendar'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment-timezone'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import DisplaySettings
  from '@/components/Schedule/CompanyScheduleComponents/ManagmentMenu/DisplaySettings'
import ClickBreakModal from '@/components/Schedule/CompanyScheduleComponents/Modals/ClickBreakModal'
import OnboardWizard from '@/components/OnboardWizard/OnboardWizard'
import store from '@/store'

export default {
  name: 'CompanySchedule',
  components: {
    OnboardWizard,
    ClickBreakModal,
    DisplaySettings,
    'request-create-shift': RequestCreateShiftModal,
    'full-calendar-filter': FullCalendarFilter,
    'click-event-modal': ClickEventModal,
    'click-free-board-modal': ClickFreeBoardEventModal,
    'add-shift-modal': AddShiftModal,
    AddHolidayModal,
    ClickUserRequestModal,
    'add-shift-by-template-modal': AddShiftByTemplateModal,
    'double-accounts-setup-modal': DoubleAccountsSetupModal,
    publish: Publish,
    projects: Projects,
    schedules: Schedules,
    statistic: Statistic,
    companyScheduleCalendar: CompanyScheduleCalendar
  },
  mixins: [errorMixin, momentMixin, mixinWindowWidthWatch],
  beforeRouteEnter (to, from, next) {
    if (to.name === 'ProjectSchedule') {
      const currentProjectId = localStorage.getItem('currentProject')
      if (+to.params.projectId !== +currentProjectId) {
        localStorage.setItem('currentProject', to.params.projectId)
        store.commit('PROJECT_IS_CHANGING', true)
      }
    }
    next(vm => {
      vm.tick++ // used to force render while getting to another route and back
    })
  },

  beforeRouteLeave (to, from, next) {
    if (localStorage.getItem('scheduleNotify') === 'true' &&
      (this.isEmployeeHasPermission('get-company-schedules-admin') ||
        this.rolesByProjects[this.currentProject.id] === 'manager')) {
      this.$confirm(this.$i18n?.t('You’ve made changes to the schedule. Do you wish to publish them and notify users affected by these changes?'),
        this.$i18n?.t('Are you sure?'),
        {
          confirmButtonText: this.$i18n?.t('Publish'),
          cancelButtonText: this.$i18n?.t('Cancel'),
          type: 'warning',
          center: true,
          customClass: 'text-center'
        }).then(() => {
        this.$eventBus.emit('sendNotify')
        this.toastSuccess(this.$i18n?.t('Published'))
        next()
      }).catch(() => {
        next()
      })
    } else {
      next()
    }
  },

  data () {
    return {
      showOnboardWizard: false,
      tick: 0,
      companyName: this.$store.getters.company.name,
      usersShow: 50,
      schedulesById: {},
      usersBySchedule: {},
      addShiftModal: {
        addShiftUserId: null,
        templateId: null,
        shiftAddDate: {},
        shiftAddEndDate: {}
      },
      actionShift: {},
      actionBreak: {},
      assignUsersObject: {},
      userRequest: {},
      canTakeShift: true,
      actionScheduleTemplateStr: '',
      actionScheduleId: '',
      actionSchedules: [],
      statistic: {
        data: {},
        showStat: false
      },
      doubleAccounts: [],
      color: '#0000FF',
      userMarks: {},
      modalSetup: {
        clickEventModalShow: false,
        clickFreeBoardEventModalShow: false,
        breakExchangeModalShow: false,
        addShiftShow: false,
        addShiftShowTemplateView: false,
        requestCreateShiftShow: false,
        addHoliday: false,
        userRequestModal: false,
        addShiftByTemplateShow: false,
        doubleAccountsSetupShow: false,
        shiftModalType: null
      },
      viewStart: {},
      viewEnd: {},
      isOpen: true,
      activeNames: [],
      hideCalendar: false,
      holiday: {},
      viewByUserMode: true,
      isProjectUpdated: false,
      toggleModalHoliday: false,
      positions: [],
      currentProject: {}
    }
  },

  computed: {
    currentProjectId () {
      return +this.$store.getters.currentProjectId
    },
    ...mapGetters({
      rolesByProjects: 'rolesByProjects',
      preloadUsers: 'employeesIndexed',
      projectsIndexed: 'projectsIndexed',
      projects: 'projects',
      projectMarks: 'projectMarks',
      schedules: 'schedules',
      templates: 'templates',
      companyId: 'companyId',
      company: 'company',
      profile: 'Profile'
    }),
    lang () {
      return this.$store.getters.locale
    }
  },

  created () {
    this.$eventBus.on('hideCalendar', (value) => {
      this.hideCalendar = value
    })
    this.$eventBus.on('doubleAccounts', this.doubleAccountsUpdate)
    this.$eventBus.on('canTakeShift', this.allowTakeShift)
    this.$eventBus.on('assignUsersObject', this.assignUsers)
    this.$eventBus.on('scheduleUsers', this.setScheduleUsers)
    this.$eventBus.on('showStat', this.showStatistic)
    this.$eventBus.on('addShiftShow', this.addShiftShow)
    this.$eventBus.on('requestCreateShiftShow', this.requestCreateShiftShow)
    this.$eventBus.on('addShiftShowTemplateView', this.addShiftShowTemplateView)
    this.$eventBus.on('addHoliday', this.addHoliday)
    this.$eventBus.on('clickUserRequestModalShow', this.clickUserRequestModalShow)
    this.$eventBus.on('clickEventModalShow', this.clickEventModalShow)
    this.$eventBus.on('clickFreeBoardEventModalShow', this.clickFreeBoardEventModalShow)
    this.$eventBus.on('breakExchangeModalShow', this.clickBreakExchangeModalShow)
    this.$eventBus.on('addShiftModalMounted', this.addShiftModalMounted)
    this.$eventBus.on('requestCreateShiftModalMounted', this.requestCreateShiftModalMounted)
    this.$eventBus.on('changeViewMode', () => {
      this.viewByUserMode = !this.viewByUserMode
      this.$eventBus.emit('reloadAllUsersSwitch')
    })
    this.$eventBus.on('updateTemplate', () => {
      this.tick++
    })

    this.$eventBus.on('reloadPage', this.reloadPage)

    this.checkModal()
    if (this.companyId) {
      this.init()
    }
    if (this.isMobile) {
      this.isOpen = false
    }
    this.setupCurrentProject()
  },
  mounted () {
    //
  },
  beforeUnmount () {
    this.$eventBus.off('doubleAccounts')
    this.$eventBus.off('canTakeShift')
    this.$eventBus.off('assignUsersObject')
    this.$eventBus.off('scheduleUsers')
    this.$eventBus.off('showStat')
    this.$eventBus.off('addShiftShow')
    this.$eventBus.off('requestCreateShiftShow')
    this.$eventBus.off('addHoliday')
    this.$eventBus.off('clickUserRequestModalShow')
    this.$eventBus.off('clickEventModalShow')
    this.$eventBus.off('clickFreeBoardEventModalShow')
    this.$eventBus.off('breakExchangeModalShow')
    this.$eventBus.off('changeViewMode')
    this.$eventBus.off('updateTemplate')
    this.$eventBus.off('addShiftModalMounted')
    this.$eventBus.off('requestCreateShiftModalMounted')
    this.$eventBus.off('reloadPage')
  },
  watch: {
    companyId () {
      this.init()
      const os = this.getOS
      if (!this.company.view_video && ['MacOS', 'Windows', 'Linux'].includes(os) &&
        this.isEmployeeHasPermission('delete-company') &&
        this.company.work_mode === 'work') {
        this.showOnboardWizard = true
        this.$nextTick(function () {
          document.getElementById('onboardWizardBtn').click()
        })
        this.$store.dispatch('editCompany', [this.companyId, {
          view_video: true,
          work_mode: this.company.work_mode
        }])
      }
    },
    currentProjectId () {
      this.changeProject(this.currentProjectId)
    },
    isMobile (value) {
      this.isOpen = !value
    }
  },

  methods: {
    // ! Нужно для тестов. Закоментить после тестов
    // test () {
    //   this.showOnboardWizard = true
    //   this.$nextTick(function () {
    //     document.getElementById('onboardWizardBtn').click()
    //   })
    //   this.$store.dispatch('editCompany', [this.companyId, {
    //     view_video: true,
    //     work_mode: this.company.work_mode
    //   }])
    // },
    reloadPage () {
      this.setupCurrentProject()
    },
    addShiftModalMounted () {
      this.modalSetup.addShiftShow = true
      const el = document.getElementsByClassName('fc-timeline-bg-harness')[0]
      if (el) {
        el.dataset.toggle = 'modal'
        el.dataset.target = '#AddShiftFreeBoard'
        el.click()
      }
    },
    requestCreateShiftModalMounted () {
      this.modalSetup.requestCreateShiftShow = true
      const el = document.getElementsByClassName('fc-timeline-bg-harness')[0]
      if (el) {
        el.dataset.toggle = 'modal'
        el.dataset.target = '#RequestCreateShiftModal'
        el.click()
      }
    },
    init () {
      if (!this.hasProject) {
        this.$router.push(`/c/${this.companyId}/projects`)
      }
      this.$store.dispatch('getPositions', this.$store.getters.companyId).then((response) => {
        this.positions = response.map(item => {
          return {
            id: item.id,
            name: item.title
          }
        }).sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
      })

      const employeEs = this.$store.dispatch('getEmployeesByCompany', this.companyId)
      const projectsByCompany = this.$store.dispatch('getProjectsByCompany', this.companyId)
      const schedules = this.$store.dispatch('getSchedules', this.companyId)
      Promise.all([employeEs, projectsByCompany, schedules]).then(() => {
        let projectId
        if (this.$store.getters.projectsCount > 0) {
          if (!(localStorage.getItem('currentProject'))) {
            projectId = this.projects[0].id
            console.log('project switched to ', projectId)
            localStorage.setItem('currentProject', projectId)
          } else {
            projectId = localStorage.getItem('currentProject')
          }
          this.$store.dispatch('updateCurrentProjectId', +projectId)
        }
      })
    },
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    viewDateChange (viewStart, viewEnd) {
      this.viewStart = viewStart
      this.viewEnd = viewEnd
    },
    showStatistic (data) {
      this.statistic = data
    },
    clickFreeBoardEventModalShow (value) {
      this.actionShift = {
        shiftId: value.event,
        isInPast: value.isInPast,
        freeBoardStackedEvents: value.freeBoardStackedEvents
      }
      this.modalSetup.clickFreeBoardEventModalShow = true
    },
    clickBreakExchangeModalShow (value) {
      this.actionBreak = {
        projectId: this.currentProject.id,
        break: value.breakEvent,
        shift: value.shift
      }
      this.modalSetup.breakExchangeModalShow = true
    },
    clickEventModalShow (value) {
      this.actionShift = {}
      this.actionShift = value.actionShift
      this.schedulesById = value.schedulesById
      this.actionShift.isInPast = value.isInPast
      this.modalSetup.clickEventModalShow = true
    },
    addShiftShow (value) {
      this.modalSetup.shiftModalType = 'employeesView'
      this.addShiftModal.templateId = null
      this.addShiftModal.addShiftUserId = value.addShiftUserId
      if (value.shiftAddDate) {
        this.addShiftModal.shiftAddDate = moment(value.shiftAddDate)
        this.addShiftModal.shiftAddEndDate = moment(value.shiftAddDate)
      } else {
        this.addShiftModal.shiftAddDate = this.viewStart.clone()
        this.addShiftModal.shiftAddEndDate = this.viewEnd.clone().add(-1, 'day')
      }
      this.userMarks = value.userMarks
      this.addShiftModalMounted()
    },
    addShiftShowTemplateView (value) {
      this.modalSetup.shiftModalType = 'templateView'
      this.addShiftModal.addShiftUserId = null
      this.addShiftModal.templateId = value.templateId ? value.templateId : 0
      if (value.shiftAddDate) {
        this.addShiftModal.shiftAddDate = moment(value.shiftAddDate)
        this.addShiftModal.shiftAddEndDate = moment(value.shiftAddDate)
      } else {
        this.addShiftModal.shiftAddDate = this.viewStart.clone()
        this.addShiftModal.shiftAddEndDate = this.viewEnd.clone().add(-1, 'day')
      }
      this.userMarks = value.userMarks
      this.addShiftModalMounted()
    },
    requestCreateShiftShow (value) {
      const schedules = this.schedules.filter((item) => item.allow_shift_request && item.project_id === this.currentProjectId)
      if (schedules.length > 0) {
        this.addShiftModal.addShiftUserId = value.addShiftUserId
        if (value.shiftAddDate) {
          this.addShiftModal.shiftAddDate = moment(value.shiftAddDate)
        } else {
          this.addShiftModal.shiftAddDate = this.viewStart
        }
        this.userMarks = value.userMarks
        this.requestCreateShiftModalMounted()
      } else {
        this.toastError(this.$t('CREATE_SHIFT_REQUEST_NOT_ALLOWED'))
      }
    },
    addHoliday (value) {
      this.holiday = value
      this.modalSetup.addHoliday = true
      this.toggleModalHoliday = !this.toggleModalHoliday
      const el = document.getElementsByClassName('fc-timeline-bg-harness')[0]
      if (el) {
        el.dataset.toggle = 'modal'
        el.dataset.target = '#AddHoliday'
        el.click()
      }
    },
    clickUserRequestModalShow (value) {
      this.userRequest = value
      this.modalSetup.userRequestModal = true
    },
    setScheduleUsers (usersBySchedule) {
      this.usersBySchedule = usersBySchedule
    },

    allowTakeShift (value) {
      this.canTakeShift = value
    },
    assignUsers (value) {
      this.assignUsersObject = value
    },
    doubleAccountsUpdate (doubleAccounts) {
      this.doubleAccounts = doubleAccounts
    },
    changeProject (id) {
      this.isProjectUpdated = true
      localStorage.setItem('currentProject', id)
      this.statistic = {
        data: {},
        showStat: false
      }
      this.setupCurrentProject()
    },

    // собираем объект текущего проекта
    setupCurrentProject () {
      const id = +this.currentProjectId
      let currentProject = {
        id: 0,
        projectTz: '',
        projectName: '',
        projectUsers: [],
        templatesByProject: {},
        schedulesByProject: [],
        selectedTemplates: []
      }

      if (this.projectsIndexed[id]) {
        this.$store.dispatch('getMarksByProject', [id])

        const project = this.projectsIndexed[id]
        const templates = this.setupTemplatesByCurrentProject(id)

        currentProject = {
          id: id,
          projectTz: project.timezone,
          projectName: project.name,
          projectUsers: project.users,
          templatesByProject: templates.templatesByProject,
          schedulesByProject: templates.schedulesByProject,
          schedulesInfo: templates.schedulesInfo,
          shifts_visible_everyone: project.shifts_visible_everyone,
          shifts_exchange: project.shifts_exchange,
          shifts_exchange_confirmation: project.shifts_exchange_confirmation,
          shifts_take_freeboard: project.shifts_take_freeboard,
          shifts_take_freeboard_confirmation: project.shifts_take_freeboard_confirmation,
          block_change_in_past_for_manager: project.block_change_in_past_for_manager
        }
        this.isProjectUpdated = false
        this.$store.dispatch('updateProjectData', templates)
      }
      this.currentProject = currentProject
    },

    // собираем шедули и темплейты для текущего проекта
    setupTemplatesByCurrentProject (projectId) {
      const schedulesByProject = []
      const templatesByProject = {}
      const schedulesInfo = {}
      const schedulesExpiring = {}
      this.schedules.forEach((schedule) => {
        if (schedule.company_id === +this.$store.getters.companyId && schedule.project_id === projectId) {
          // объект где по id проекта можно получить schedule id которые в него входят
          schedulesByProject.push(schedule.id)
          schedulesInfo[schedule.id] = schedule.name
          if (this.fromZoneToZone(schedule.date_to, 'UTC', this.$store.getters.actualTZ).isBefore(moment().add(1, 'days'))) {
            schedulesExpiring[schedule.id] = schedule.date_to
          }
          const scheduleTemplates = this.$store.getters.templatesByScheduleId(schedule.id)
          if (scheduleTemplates && scheduleTemplates.length > 0) {
            scheduleTemplates.forEach((template) => {
              const scheduleTemplateId = [schedule.id, template.id].join('__sp__')
              const isDeleted = !template.is_active // !!template.pivot.deleted_at
              // Объект в котором можно для конкретнго проекта найти имя template(если они есть) или schedule по id
              templatesByProject[scheduleTemplateId] = [schedule.name, template.name, template.color, isDeleted]
            })
          } else {
            // непонятно нахера тут было это условие, но это место ломает расписания по нагрузке без темплейтов
            // пусть пока остается закоменченымм, на случай если окажется что это нужный код
            // if (schedule.schedule_type !== 'manually') templatesByProject[schedule.id] = schedule.name
          }

          const customShiftId = [schedule.id, 0].join('__sp__')
          templatesByProject[customShiftId] = [schedule.name, this.$t('Custom shift')]
        }
      })

      return {
        schedulesByProject: schedulesByProject,
        templatesByProject: templatesByProject,
        schedulesInfo: schedulesInfo,
        schedulesExpiring: schedulesExpiring
      }
    },

    // проверка для кого доступны плюсики
    showAddButton (templateId) {
      const templateIdArray = templateId.split('__sp__')
      return (this.isEmployeeHasPermission('create-schedule-user') &&
        templateIdArray.length > 1 &&
        +templateIdArray[1] !== 0)
    },
    // подготовка данных для модального окна AddShiftByTemplateModal
    addShiftByTemplate (scheduleTemplateStr) {
      this.actionScheduleTemplateStr = scheduleTemplateStr
      const templateScheduleIdArray = scheduleTemplateStr.split('__sp__')
      this.actionScheduleId = templateScheduleIdArray[0]
      this.actionSchedules = this.currentProject.schedulesByProject
      this.modalSetup.addShiftByTemplateShow = true
      // todo может можно обойтись без запроса, проверить
      // this.$store.dispatch('getProjectTemplates', [this.currentProject.id, null]).then((templates) => {
      //   templates.forEach((item) => {
      //     // console.log(item)
      //     if (item.id === templateScheduleIdArray[1]) {
      //       this.color = item.color
      //     }
      //   })
      // })
    },

    doubleAccountsSetup () {
      this.modalSetup.doubleAccountsSetupShow = true
    },
    checkModal () {
      // закрытие модальных окон
      // this.$on('closeClickEventModal', function () {
      //   this.modalSetup.clickEventModalShow = false
      // })
      // this.$on('closeClickFreeBoardEventModal', function () {
      //   this.modalSetup.clickFreeBoardEventModalShow = false
      // })
      // this.$on('closeAddShiftModal', function () {
      //   this.modalSetup.addShiftShow = false
      // })
      // this.$on('closeRequestCreateShiftModal', function () {
      //   this.modalSetup.requestCreateShiftShow = false
      // })
      // this.$on('closeAddShiftModalTemplateView', function () {
      //   this.modalSetup.addShiftShowTemplateView = false
      // })
      // this.$on('closeAddShiftByTemplateModal', function () {
      //   this.modalSetup.addShiftByTemplateShow = false
      // })
      // this.$on('closeDoubleAccountsSetupModal', function () {
      //   this.modalSetup.doubleAccountsSetupShow = false
      // })
      // this.$on('closeAddHolidayModal', () => {
      //   this.holiday = {}
      // })
      // this.$on('closeUserRequestModal', function () {
      //   this.userRequest = {}
      //   this.modalSetup.userRequestModal = false
      // })
    }
  }
}
</script>

<style scoped lang="scss">
  .video-frame {
    width: 630px!important;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
</style>
