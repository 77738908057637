<template>
  <div>
    <div class="d-flex justify-content-start p-2 border-bottom">
      <span class="text-primary" @click="setupPushNotifications" style="cursor: pointer">
        {{ $t('Setup Push Notifications') }} <i class="icon-zap text-warning"></i>
      </span>
    </div>
    <div style="max-height: 400px;" class="overflow-auto" :style="{'min-width': minWidth}">
      <div v-if="alertsToShow.length === 0" class="d-flex justify-content-center dropdown-item">
        {{ $t('No alerts available') }}
      </div>
      <div v-for="(alert, index) in alertsToShow" :key="index" class="dropdown-item border-bottom text-wrap" >
        <!-- Праздники -->
        <template v-if="alert.type === 'App\\Notifications\\HolidaysUpdate'">
          <div class="text-success">{{$t('Notification')}}</div>
          <template v-if="alert.status === 'created'">
            <div class="d-flex justify-content-between">
              {{ $t('New holiday added. Starts:') }} {{ getDate(alert.holiday_start) }} {{ getTime(alert.holiday_start) }}. {{ $t('Ends:') }} {{ getDate(alert.holiday_end) }} {{ getTime(alert.holiday_end) }}. {{ $t('Check your schedule') }} <span class="font-weight-bold">{{ alert.data }}</span>
              <router-link :to="`/c/${companyId}/p/${currentProject}/schedule`">
                <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
              </router-link>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <!-- Добавление/удаление пользователя из разписания -->
        <template v-if="alert.type === 'App\\Notifications\\ScheduleUserUpdated'">
          <div class="text-success">{{$t('Notification')}}</div>
          <template v-if="alert.status === 'added'">
            <div class="d-flex justify-content-between">
              <span>
                {{ $t('You have been added to a schedule') }} <span class="font-weight-bold">{{ alert.schedule_name }}</span>
                <router-link :to="`/c/${companyId}/p/${currentProject}/schedule`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <template v-if="alert.status === 'deleted'">
            <div class="d-flex justify-content-between">
              <span>
                {{ $t('You have been removed from a schedule') }} <span class="font-weight-bold">{{ alert.schedule_name }}</span>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <!-- Создание/удаление/ресайз расписания -->
        <template v-if="alert.type === 'App\\Notifications\\ScheduleUpdated'">
          <div class="text-success">{{$t('Notification')}}</div>
          <template v-if="alert.status === 'updated'">
            <div class="d-flex justify-content-between">
              <span>
                {{ $t('Your schedule') }} <span class="font-weight-bold">{{ alert.schedule_name }}</span> {{ $t('was updated.') }}
                <router-link :to="`/c/${companyId}/p/${currentProject}/schedule`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <template v-if="alert.status === 'created'">
            <div class="d-flex justify-content-between">
              <span>
                {{ $t('A new schedule') }} <span class="font-weight-bold">{{ alert.schedule_name }}</span> {{ $t('was created for you.') }}
                <router-link :to="`/c/${companyId}/p/${currentProject}/schedule`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <template v-if="alert.status === 'deleted'">
            <div class="d-flex justify-content-between">
              <span>
                {{ $t('Your schedule') }} <span class="font-weight-bold">{{ alert.schedule_name }}</span> {{ $t('was deleted.') }}
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <!-- Таймофф создан -->
        <template v-if="alert.type === 'App\\Notifications\\TimeOffRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_from }} {{ $t('requested Time-Off.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- Таймофф подтвержден -->
        <template v-if="alert.type === 'App\\Notifications\\TimeOffRequestConfirmed'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.manager }} {{ $t('approved your “Time-Off“ request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- Таймофф отклонен -->
        <template v-if="alert.type === 'App\\Notifications\\TimeOffRequestDeclined'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.name }} {{ $t('declined “Time-Off“ request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- take shift from free board -->
        <template v-if="alert.type === 'App\\Notifications\\TakeShiftFromFreeboard'">
          <div class="text-success">{{$t('Notification')}}</div>
          <!-- create -->
          <template v-if="alert.status === 'new'">
            <div class="d-flex justify-content-between">
              <span>
              {{ alert.user }} {{ $t('requested a shift from the Freeboard in a schedule') }} {{ alert.schedule_name }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <!-- confirm -->
          <template v-if="alert.status === 'confirmed'">
            <div class="d-flex justify-content-between">
              <span>
                {{ alert.user }} {{ $t('approved your  “Take a shift from the Freeboard“ request in a schedule') }} {{ alert.schedule_name }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <!-- decline -->
          <template v-if="alert.status === 'declined'">
            <div class="d-flex justify-content-between">
              <span>
                {{ alert.user }} {{ $t('declined “Take a shift from the Freeboard“ request in a schedule') }} {{ alert.schedule_name }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\MoveShiftToFreeboard'">
          <div class="text-success">{{$t('Notification')}}</div>
          <!-- create -->
          <template v-if="alert.status === 'new'">
            <div class="d-flex justify-content-between">
              <span>
              {{ alert.user }} {{ $t('key_alert_move_to_freeboard_new') }} {{ alert.schedule_name }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <!-- confirm -->
          <template v-if="alert.status === 'confirmed'">
            <div class="d-flex justify-content-between">
              <span>
                {{ alert.user }} {{ $t('key_alert_move_to_freeboard_confirmed') }} {{ alert.schedule_name }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <!-- decline -->
          <template v-if="alert.status === 'declined'">
            <div class="d-flex justify-content-between">
              <span>
                {{ alert.user }} {{ $t('key_alert_move_to_freeboard_declined') }} {{ alert.schedule_name.name }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_from }} {{ $t('offers shift to take.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestDeclinedByUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.username === alert.user_from ? alert.user_to : alert.user_from }} {{ $t('declined your shift dropping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestDeclinedByManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ $t('A manager canceled your shift dropping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestAcceptedToUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_to }} {{ $t('accepted your shift dropping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestAcceptedToManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_from }} {{ $t('offers to drop shift to') }} {{ alert.user_to }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestConfirmedByUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_to }} {{ $t('confirmed your shift swapping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\DropToEmployeeRequestConfirmedByManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ $t('A manager confirmed the shift dropping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_from }} {{ $t('offers shifts swapping.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestAcceptedToManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_from }} {{ $t('offers to swap shifts with.') }} {{ alert.user_to }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestDeclinedByUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span v-if="alert.username !== alert.user_to && alert.username !== alert.user_from">
              {{$t('REQUEST_DECLINED_BY_USER', { user_from: alert.user_from, user_to: alert.user_to })}}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <span v-else>
              {{ alert.username === alert.user_from ? alert.user_to : alert.user_from }} {{ $t('declined your shift swapping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestAcceptedToUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.user_to }} {{ $t('confirmed your shift swapping request. Awaiting manager approval.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestConfirmedByUser'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span v-if="alert.username !== alert.user_to && alert.username !== alert.user_from">
              {{$t('REQUEST_CONFIRMED_BY_USER', { user_from: alert.user_from, user_to: alert.user_to })}}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <span v-else>
              {{ alert.user_to }} {{ $t('confirmed your shift swapping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestConfirmedByManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ $t('A manager confirmed the shift swapping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ExchangeRequestDeclinedByManager'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ $t('A manager canceled your shift swapping request.') }}
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- change shift created -->
        <template v-if="alert.type === 'App\\Notifications\\ShiftChangeRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
              <span>{{ alert.user_from }} {{ $t('SHIFT_CHANGE_REQUEST_CREATED_BELL') }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- change shift declined -->
        <template v-if="alert.type === 'App\\Notifications\\ShiftChangeRequestDeclined'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
              <span>{{ alert.user_from }} {{ $t('SHIFT_CHANGE_REQUEST_DECLINED_BELL') }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- change shift confirmed -->
        <template v-if="alert.type === 'App\\Notifications\\ShiftChangeRequestConfirmed'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
              <span>{{ alert.user_from }} {{ $t('SHIFT_CHANGE_REQUEST_CONFIRMED_BELL') }}
                <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
                </router-link>
              </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- beak exchange made -->
        <template v-if="alert.type === 'App\\Notifications\\BreakExchangeDoneNotification'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
<!--            {{alert}}-->
            <div>
              <div>{{$t('BREAK_EXCHANGE_WAS_MADE_1')}}</div>
              <div>{{$t('BREAK_EXCHANGE_WAS_MADE_2')}}</div>
              <div>{{ alert.user_from }}</div>
              <div>
                {{ getDate(alert.break_from.time_from) }} {{ getTime(alert.break_from.time_from) }}
                -
                {{ getDate(alert.break_from.time_to) }} {{ getTime(alert.break_from.time_to) }}
              </div>
              <div>{{$t('BREAK_EXCHANGE_WAS_MADE_3')}}</div>
              <div>{{ alert.user_to }}</div>
              <div>
                {{ getDate(alert.break_to.time_from) }} {{ getTime(alert.break_to.time_from) }}
                -
                {{ getDate(alert.break_to.time_to) }} {{ getTime(alert.break_to.time_to) }}
              </div>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakUpdateDoneNotification'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('BREAK_WAS_UPDATED_1') }}</div>
              <div>{{ $t('BREAK_WAS_UPDATED_2') }}</div>
              <div>
                {{ getDate(alert.break_from.time_from) }} {{ getTime(alert.break_from.time_from) }}
                -
                {{ getDate(alert.break_from.time_to) }} {{ getTime(alert.break_from.time_to) }}
              </div>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ShiftCreateRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
              <!-- {{alert}} -->
            <div>
              <div>{{ alert.user_from }} {{ $t('MAKE_REQUEST_FOR_CREATE_SHIFT') }} </div>
              <div>{{ $t('Shift time') }}: {{ getDateTime(alert.date_from) }} - {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ShiftCreateRequestConfirmed'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <div>
              <div>{{ $t('REQUEST_FOR_CREATING_SHIFT_HAS_BEEN_CONFIRMED') }} </div>
              <div>{{ $t('Shift time') }}: {{ getDateTime(alert.date_from) }} -  {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ShiftCreateRequestDeclined'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <div>
              <div>{{ $t('REQUEST_FOR_CREATING_SHIFT_HAS_BEEN_DECLINED') }} </div>
              <div>{{ $t('Shift time') }}: {{ getDateTime(alert.date_from) }} -  {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakCreateRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <!-- {{alert}} -->
            <div>
              <div>{{ alert.user_from }} {{ $t('MAKE_REQUEST_FOR_CREATE_BREAK') }} </div>
              <div>{{ $t('Break time') }}: {{ getDateTime(alert.date_from) }} - {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakCreateRequestConfirmed'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <div>
              <div>{{ $t('REQUEST_FOR_CREATING_BREAK_HAS_BEEN_CONFIRMED') }} </div>
              <div>{{ $t('Shift time') }}: {{ getDateTime(alert.date_from) }} -  {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakCreateRequestDeclined'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <div>
              <div>{{ $t('REQUEST_FOR_CREATING_BREAK_HAS_BEEN_DECLINED') }} </div>
              <div>{{ $t('Shift time') }}: {{ getDateTime(alert.date_from) }} -  {{ getDateTime(alert.date_to) }}</div>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakExchangeRequestCreated'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ alert.user_from }} {{ $t('BREAK_EXCHANGE_REQUEST_WAS_CREATED_1') }} </div>
              <div>{{ $t('BREAK_EXCHANGE_REQUEST_WAS_CREATED_2') }}</div>
              <div>
                {{ getDate(alert.break_from.time_from) }} {{ getTime(alert.break_from.time_from) }}
                -
                {{ getDate(alert.break_from.time_to) }} {{ getTime(alert.break_from.time_to) }}
              </div>
              <div>{{ $t('BREAK_EXCHANGE_REQUEST_WAS_CREATED_3') }}</div>
              <div>
                {{ getDate(alert.break_to.time_from) }} {{ getTime(alert.break_to.time_from) }}
                -
                {{ getDate(alert.break_to.time_to) }} {{ getTime(alert.break_to.time_to) }}
              </div>
              <span>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakExchangeRequestConfirmed'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ alert.user_to }} {{ $t('BREAK_EXCHANGE_REQUEST_WAS_CONFIRMED_1') }} </div>
              <div>{{ $t('BREAK_EXCHANGE_REQUEST_WAS_CONFIRMED_2') }}</div>
              <div>
                {{ getDate(alert.break_to.time_from) }} {{ getTime(alert.break_to.time_from) }}
                -
                {{ getDate(alert.break_to.time_to) }} {{ getTime(alert.break_to.time_to) }}
              </div>
              <span>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakExchangeRequestDeclined'">
          <div class="text-primary">{{$t('Request')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ alert.user_to }} {{ $t('BREAK_EXCHANGE_REQUEST_WAS_DECLINED_1') }}</div>
              <div>{{ $t('BREAK_EXCHANGE_REQUEST_WAS_DECLINED_2') }}</div>
              <div>
                {{ getDate(alert.break_from.time_from) }} {{ getTime(alert.break_from.time_from) }}
                -
                {{ getDate(alert.break_from.time_to) }} {{ getTime(alert.break_from.time_to) }}
              </div>
              <div>{{ $t('BREAK_EXCHANGE_REQUEST_WAS_DECLINED_3') }}</div>
              <div>
                {{ getDate(alert.break_to.time_from) }} {{ getTime(alert.break_to.time_from) }}
                -
                {{ getDate(alert.break_to.time_to) }} {{ getTime(alert.break_to.time_to) }}
              </div>
              <span>
              <router-link :to="`/c/${companyId}/request/${alert.request_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('View request') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\AttendanceClockOut'">
          <div class="text-success">{{$t('Notification')}}</div>
          <template v-if="alert.action_type === 'shift'">
            <div class="d-flex justify-content-between">
              <div v-if="employeeId === alert.employee_id">
                <span>{{ $t('ATTENDANCE_SHIFT_CLOCK_OUT_ALER_EMPLOYEE') }}</span>
              </div>
              <div v-else>
                <span>{{ alert.employee_name }} {{ $t('ATTENDANCE_SHIFT_CLOCK_OUT_ALER_MANAGER') }}</span>
              </div>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-content-between">
              <div v-if="employeeId === alert.employee_id">
                <span>{{ $t('ATTENDANCE_BREAK_CLOCK_OUT_ALER_EMPLOYEE') }}</span>
              </div>
              <div v-else>
                <span>{{ alert.employee_name }} {{ $t('ATTENDANCE_BREAK_CLOCK_OUT_ALER_MANAGER') }}</span>
              </div>
              <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
            </div>
          </template>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\ShiftAddAbsence'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!-- {{ employeeId }} {{ alert }} -->
            <span>{{ alert.employee_name }} {{ $t('SHIFT_ABSENCE_ALERT_MANAGER') }}</span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\BreakAddAbsence'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!-- {{ employeeId }} {{ alert }} -->
            <span>{{ alert.employee_name }} {{ $t('BREAK_ABSENCE_ALERT_MANAGER') }}</span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- beak reminder -->
        <template v-if="alert.type === 'App\\Notifications\\BreakTimeSoonNotification'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('BREAK_REMINDER_ALERT_TITLE') }}</div>
              <div>
                {{alert.break_title}} {{alert.break_start}} - {{alert.break_end}} ({{alert.break_duration}}) {{$t('STARTS_IN')}} {{alert.notify_in}} {{$t('BREAK_MINUTES')}}
              </div>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- alert to manager when employee late on shift -->
        <template v-if="alert.type === 'App\\Notifications\\AttendanceLateEmployees'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('EMPLOYEE_LATE_ON_SHIFT_ALERT_TITLE') }}</div>
              <div>
                {{alert.employee_name}}
              </div>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\AttendanceEarlyLeavingEmployees'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('EMPLOYEE_EARLY_LEAVING_SHIFT_ALERT_TITLE') }}</div>
              <div>
                {{alert.employee_name}}
              </div>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\LeftLocation'">
          <div class="text-primary">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <span>
              {{ alert.employee_name }} {{ $t('USER_LEFT_FROM_LOCATION') }} {{ alert.location_name }} {{ $t('at') }} {{ getDate(alert.time_start) }} {{ getTime(alert.time_start) }}
            </span>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <!-- alert when task attached -->
        <template v-if="alert.type === 'App\\Notifications\\TaskAttach'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('TASK_ATTACHED_NOTIFICATION') }}</div>
              <span>
              <router-link :to="`/c/${companyId}/user-tasks/${alert.task_id}`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\TodoAttach'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('TODO_ATTACHED_NOTIFICATION') }}</div>
              <span>
              <router-link :to="`/c/${companyId}/todos-list`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\TodoReminderEmployee'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('TODO_REMINDER_EMPLOYEE_NOTIFICATION') }}</div>
              <span>
              <router-link :to="`/c/${companyId}/todos-list`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <template v-if="alert.type === 'App\\Notifications\\TodoReminderAdmin'">
          <div class="text-success">{{$t('Notification')}}</div>
          <div class="d-flex justify-content-between">
            <!--            {{alert}}-->
            <div>
              <div>{{ $t('TODO_REMINDER_ADMIN_NOTIFICATION') }}</div>
              <span>
              <router-link :to="`/c/${companyId}/todos-list`">
                  <span class="ml-1" data-dismiss="modal">{{ $t('Check it') }}</span>
              </router-link>
            </span>
            </div>
            <i class="icon-x btn-icon text-danger mx-1" @click="deleteAlert(alert.id)"></i>
          </div>
        </template>
        <div v-if="alert.type !== 'App\\Notifications\\ModuleStatusUpdated'" class="d-flex justify-content-start">
          <span class="small text-secondary">{{ getDate(alert.created_at) }} {{ getTime(alert.created_at) }}</span>
        </div>
      </div>
      <VisibilityObserver @visible="handleAlertsToShow" />
    </div>
    <div class="border-top py-2 px-3 d-flex justify-content-between">
      <span class="text-danger">{{$t('ALERT_CLEAR')}}:</span>
      <span class="text-primary" style="cursor:pointer;" @click="deleteAlertType('all')">{{$t('All')}}</span>|
      <span class="text-primary" style="cursor:pointer;" @click="deleteAlertType('notifications')">{{$t('Notifications')}}</span>|
      <span class="text-primary" style="cursor:pointer;" @click="deleteAlertType('requests')">{{$t('Requests')}}</span>
    </div>
  </div>
</template>

<script>
import momentMixin from '../mixins/mixinMoment'
import { HTTP } from '@/api/http_utils'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import setup from '@/modules/setup'
import { getToken } from 'firebase/messaging'
import VisibilityObserver from '@/components/CommonComponents/VisibilityObserver.vue'

export default {
  // TODO Добавить еще для типов Move to freeboard and move to user. Согласовать дизайн с викой или мариной
  name: 'Alerts',
  components: { VisibilityObserver },
  mixins: [momentMixin, mixinWindowWidthWatch],
  data () {
    return {
      companyName: this.$store.getters.company.name,
      alertList: [],
      alertsToShow: [],
      preloadUsers: [],
      type: 'new',
      hasNext: false,
      page: 1,
      alertsLoading: true,
      limit: 25,
      newAlertClass: 'new-alert',
      seenAlertClass: 'seen-alert',
      loadingAlerts: 'loading-alerts',
      loadedAlerts: 'loaded-alerts',
      needActionAlert: 'need-action',
      alerts: [],
      loaded: false,
      minWidth: '450px'
    }
  },
  created () {
    this.$eventBus.on('updateAlerts', this.preload)
  },
  beforeMount () {
    if (this.isMobile) this.minWidth = '350px'
  },
  mounted () {
    // this.$eventBus.on('alertClick', this.preload)
    this.preload()
  },
  beforeUnmount () {
    this.$eventBus.off('updateAlerts')
  },
  computed: {
    employeeId () {
      return this.$store.getters.company.employee_id
    },
    currentProject () {
      let id = this.$store.getters.currentProjectId
      if (!id) {
        id = localStorage.getItem('currentProject')
      }
      if (!id && this.$store.getters.projectsCount > 0) {
        id = this.$store.getters.projects[0].id
      }
      return id
    }
  },
  watch: {
    '$store.getters.companyId' (val) {
      if (val) {
        this.preload()
      }
      // console.log(123, val)
    }
  },
  methods: {
    setupPushNotifications () {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission ' + permission)
        if (permission === 'granted') {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then((swReg) => {
              // Get Token
              const getTokenParams = {
                vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
                serviceWorkerRegistration: swReg
              }
              getToken(this.$messaging, getTokenParams).then((currentToken) => {
                // console.log(currentToken)
                this.sendTokenToServer(currentToken).then((response) => {
                  this.toastSuccess(this.$t('Push Notifications setup successful'))
                }).catch((error) => {
                  this.toastError(this.$t(error.response.data.message))
                })
              }).catch((error) => {
                this.toastError(this.$t(error.response.data.message))
              })
            })
          }
        } else {
          this.toastError(this.$t('Please allow notifications'))
        }
      }).catch((err) => {
        console.log('Unable to get permission to notify.', err)
        this.toastError(this.$t('Please allow notifications'))
      })
    },
    sendTokenToServer (token) {
      return HTTP.post('profile/firebase_tokens', { token, useragent: navigator.userAgent })
    },
    deleteAlert (id) {
      this.$store.dispatch('markAsRead', [this.$store.getters.companyId, id]).then(() => {
        this.$eventBus.emit('updateAlertCount')
        this.alertsPreload()
      }).catch(() => {})
    },
    deleteAlertType (type) {
      const payload = {
        type: type,
        work_mode: this.workMode
      }

      this.$store.dispatch('markAsReadType', [this.$store.getters.companyId, payload]).then(() => {
        this.$eventBus.emit('updateAlertCount')
        this.alertsPreload()
      }).catch(() => {})
    },
    preload () {
      if (this.$store.getters.companyId) {
        this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then((preloadUsers) => {
          this.preloadUsers = preloadUsers
          this.alertsPreload()
        })
      }
    },
    alertsPreload () {
      this.$store.dispatch('getUnreadAlerts', [this.$store.getters.companyId, this.workMode]).then((alerts) => {
        const sorted = alerts.sort((a, b) => {
          if (a.created_at > b.created_at) return 1
          if (a.created_at < b.created_at) return -1
          return 0
        })
        this.loadAlerts(sorted)
      }).catch(() => {})
    },
    loadAlerts (alerts) {
      this.alertList = []
      alerts.forEach(alert => {
        if (alert.type === 'App\\Notifications\\ModuleStatusUpdated') {
          const fn = setup[alert.data.module_permission_id]
          if (alert.data.action === 'enable') {
            if (fn) {
              fn.install()
            }
          }
          if (alert.data.action === 'disable') {
            if (fn) {
              fn.uninstall()
            }
          }
          this.deleteAlert(alert.id)
        } else { // todo если нужно отображать изменение статуса модулей в колокольчике - убрать блок else
          alert.data.company_name = this.companyName
          alert.data.type = alert.type
          alert.data.created_at = alert.created_at
          alert.data.id = alert.id
          this.alertList.push(alert.data)
        }
        this.alertList = this.alertList.sort((a, b) => {
          if (a.created_at > b.created_at) return -1
          if (a.created_at < b.created_at) return 1
          return 0
        })
      })
      this.alertsToShow = []
      this.handleAlertsToShow()
    },
    handleAlertsToShow () {
      if (this.alertList.length > 0) {
        const showed = this.alertList.splice(0, 10)
        this.alertsToShow = this.alertsToShow.concat(showed)
      }
    },
    getDate (date) {
      return this.toTimeZone(date).format(this.localeDateFormat)
    },
    getDateTime (date) {
      return this.toTimeZone(date).format(this.localeDateTimeFormat)
    },
    getTime (time) {
      return this.toTimeZone(time).format(this.localeTimeFormat)
    },
    getFirstLastName (id) {
      return this.preloadUsers[id].full_name
    }
  }
}
</script>

<style lang="scss">
  /* ----- BELL MODAL ----- */
  #bellModal .modal-dialog {
    position: fixed!important;
    margin: auto;
    width: 420px!important;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  #bellModal .modal-content {
    height: 100%;
    border-radius: 0!important;
    border: none;
  }

  #bellModal .modal-body {
    overflow-y: auto;
    padding: 15px 15px 80px;
  }

  #bellModal.fade .modal-dialog {
    left: -420px!important;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }

  #bellModal.fade.show .modal-dialog {
    left: 0!important;
  }

  #bellModal .modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
  }
</style>
