<template>
  <div>
    <div class="row pb-2">
      <div class="col-lg-12">
        <div class="text-primary text-uppercase font-weight-bold">{{ $t('Modules') }}</div>
        <div class="font-weight-bold">{{ $t('A list of all free and paid modules with free trial period.') }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7">
        <template v-if="enable">
          <template v-for="(module, key) in modulesByWorkMode">
            <ModulesListItem
              v-if="module.is_active"
              v-bind:key="module.id"
              :module="module"
              :position="key"
              class="mb-3"
            />
          </template>
        </template>
      </div>
      <div class="col-lg-5">
        <div class="card p-2">
          <div class="card-header border-0 bg-transparent p-0 text-secondary font-weight-bold text-center">
            {{ $t('Selected modules').toUpperCase() }}
          </div>
          <div class="card-body bg-transparent p-0 border-bottom pt-2">
            <div v-for="(module, key) in companyModules" v-bind:key="module.id" class="row pb-2">
              <template v-if="module.is_active && (module.work_mode === moduleWorkMode || !module.work_mode)">
                <div class="col-lg-7">
                  <div class="font-weight-bold text-secondary">
                    <span>{{ language in module.locales ? module.locales[language].name : module.name }}</span>
                  </div>
                  <div v-if="module.trial_period > 0" class="text-primary small">({{module.trial_period}} {{$t('month free trial')}})</div>
                </div>
                <div class="col-lg-3 text-left text-secondary">
                  <div class="text-right"><span v-if="module.count > 1">{{ module.count - module.free_count}} * </span>${{module.price * 1}}</div>
                  <div v-if="module.user_price * 1 > 0" class="text-right text-nowrap">+${{module.user_price * 1}} {{$t('per user')}}</div>
                </div>
                <div class="col-lg-2 text-center">
                  <div v-if="!module.always_on" @click="disableModule(key)">
                    <i class="icon-x font-weight-bold" color="grey" size="sm" style="cursor: pointer"></i>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="card-footer border-0 bg-transparent p-1 text-right text-secondary">
            <div class="font-weight-bold text-primary pb-2 pt-2">{{$t('Total')}}: ${{total.total}}</div>
            <div>{{$t('After the trial period ends the monthly payment')}}</div>
            <div>{{$t('is going to be')}} ${{total.total}}/{{$t('month')}}</div>
            <div v-if="total.perUser">+${{total.perUser}} {{$t('per user')}}</div>
            <div v-if="workMode === 'work' && total.total === 0 && companyModules.length < 3 && employeesCount < 100" class="mt-2 font-weight-bold text-break">
              {{$t('FREE_BASE_FUNCTIONALITY_TOKEN')}}
            </div>
            <button type="submit" name="submit" class="btn btn-success rounded-pill shadow-sm mt-2" @click="saveModules">
              {{ $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModulesListItem from '@/components/Company/ModulesPage/ModulesListItem'
import analyticsMixin from '@/mixins/analyticsMixin'

export default {
  name: 'ModulesList',
  components: { ModulesListItem },
  mixins: [analyticsMixin],
  data () {
    return {
      availableModules: [],
      modulesByWorkMode: [],
      unchangedModules: [],
      companyModules: [],
      enable: false,
      language: this.$store.getters.locale
    }
  },
  props: ['company'],
  created () {
    this.$eventBus.on('changeStatus', this.changeStatus)
    this.$eventBus.on('changeCount', this.changeCount)
    this.$store.dispatch('getModulesAllAvailable', this.$store.getters.companyId).then(response => {
      if (response) {
        this.availableModules = response
      }
      this.availableModules.forEach(module => {
        module.enableForCompany = this.modulesByCompany.some(companyModule => {
          return module.id === companyModule.module_id && companyModule.deleted_at === null
        })
        const index = this.modulesByCompany.findIndex(companyModule => {
          return module.id === companyModule.module_id
        })
        module.count = 1
        module.priceTotal = module.price
        if (index > -1) {
          module.count = this.modulesByCompany[index].count
          module.priceTotal = this.modulesByCompany[index].price
        }
      })
      this.modulesByWorkMode = this.availableModules.filter(item => item.work_mode === this.moduleWorkMode || !item.work_mode)

      this.companyModules = []
      this.modulesByWorkMode.forEach(module => {
        if (module.enableForCompany) this.companyModules.push(module)
      })

      this.unchangedModules = this.availableModules.filter(item => item.work_mode !== this.moduleWorkMode || !item.work_mode)
      this.enable = true
    })
  },
  beforeUnmount () {
    this.$eventBus.off('changeStatus')
  },
  computed: {
    modulesByCompany () {
      return this.$store.getters.ModulesByCompany
    },
    moduleWorkMode () {
      return this.workMode === 'field_service' ? 'field_service' : 'office_work'
    },
    total () {
      let total = 0
      let perUser = 0
      this.companyModules.forEach(module => {
        if (Number.isInteger(+module.count)) {
          let moduleCount = +module.count - module.free_count
          if (moduleCount < 0) {
            moduleCount = 0
          }
          total += +module.price * moduleCount
          perUser += module.user_price ? module.user_price * 1 : 0
        }
      })
      return {
        total: Math.round(total * 100) / 100,
        perUser: Math.round(perUser * 100) / 100
      }
    },
    employeesCount () {
      return this.$store.getters.employees.length
    }
  },
  methods: {
    disableModule (key) {
      if (!this.companyModules[key].always_on || (this.companyModules[key].always_on && this.companyModules[key].multi_purchase)) {
        const deleted = this.companyModules.splice(key, 1)
        this.availableModules.forEach(module => {
          if (module.id === deleted[0].id) {
            module.enableForCompany = false
            this.$eventBus.emit('disableModule', module.id)
          }
        })
      }
    },
    changeStatus (data) {
      this.modulesByWorkMode[data.position].enableForCompany = data.status
      this.reloadCompanyModules()
    },
    changeCount (data) {
      this.modulesByWorkMode[data.position].count = data.count
      this.reloadCompanyModules()
    },
    reloadCompanyModules () {
      this.companyModules = []
      this.modulesByWorkMode.forEach(module => {
        if (module.enableForCompany) {
          module.actual_price = module.price
          module.actual_user_price = module.user_price * 1
          this.companyModules.push(module)
        }
      })
    },
    getModulesNames (ids) {
      return this.availableModules.filter(item => ids.includes(item.id)).map(item => {
        return item.name
      }).join(', ')
    },
    findDifferences (arr1, arr2) {
      const set1 = new Set(arr1)
      const set2 = new Set(arr2)

      const added = Array.from(set2).filter(x => !set1.has(x))

      const removed = Array.from(set1).filter(x => !set2.has(x))

      return { added, removed }
    },
    trackModulesEvents (ids) {
      const currentCompaniesModules = this.modulesByCompany.map(item => {
        return item.module_id
      })

      const differences = this.findDifferences(currentCompaniesModules, ids)
      if (differences.added.length) {
        const added = this.getModulesNames(differences.added)

        this.trackEvent({
          gtm: {
            event: 'module_enabled',
            category: 'Modules',
            action: 'click',
            label: added
          },
          crisp: {
            eventName: 'module:enable',
            eventData: {
              enable: added
            }
          }
        })
      }

      if (differences.removed.length) {
        const removed = this.getModulesNames(differences.removed)

        this.trackEvent({
          gtm: {
            event: 'module_disabled',
            category: 'Modules',
            action: 'click',
            label: removed
          },
          crisp: {
            eventName: 'module:disable',
            eventData: {
              disable: removed
            }
          }
        })
      }
    },
    saveModules () {
      const currentModules = this.modulesByCompany.map(m => ({ id: m.module_id, count: m.count }))
      const newModules = this.companyModules.map(m => ({ id: m.id, count: m.count }))

      const unchangedSet = new Set(this.unchangedModules.map(m => m.id))

      const currentMap = new Map(currentModules.map(m => [m.id, m.count]))
      const newMap = new Map(newModules.map(m => [m.id, m.count]))

      const add = []
      const update = []
      const remove = []

      newMap.forEach((count, id) => {
        if (unchangedSet.has(id)) return // Skip unscheduled modules

        if (!currentMap.has(id)) {
          add.push({ id, count }) // Added
        } else if (currentMap.get(id) !== count) {
          update.push({ id, count }) // Changed
        }
      })

      currentMap.forEach((count, id) => {
        if (!newMap.has(id) && !unchangedSet.has(id)) {
          remove.push(id) // Removed
        }
      })

      const data = { add, update, remove }

      this.trackModulesEvents(newModules.map(m => m.id))

      this.$store.dispatch('updateIsLoading', true)
      this.$store.dispatch('setModulesByCompany', data).then(response => {
        this.$store.dispatch('getModulesByCompany').catch(() => {
          this.$store.dispatch('updateIsLoading', false)
        })
        this.toastSuccess(this.$i18n?.t('Modules successfully edited'))

        // mark company as passed whole wizard
        this.$store.dispatch('editCompany', [this.companyId, { wizard_passed: true, work_mode: this.company.work_mode }]).then(() => {
          this.$store.dispatch('updateIsLoading', false)
        }).catch(() => {
          this.$store.dispatch('updateIsLoading', false)
        })
      }).catch(() => {
        this.$store.dispatch('updateIsLoading', false)
      })
    }
  }
}
</script>

<style scoped>
  .bg-color-green {
    background-color: #56a71b;
  }
</style>
