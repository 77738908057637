<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>

    <TaskModal
      v-if="showEditTaskModal"
      :taskModalData="taskData"
      :taskId="taskData.id"
      :disableClient="true"
      @closeTaskModal="closeTaskModal"
      @reloadTasks="reloadTasks"
      :title="'TASK_EVENT_MODAL'"
    />

    <div v-if='loaded'>
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="`/c/${companyId}/clients`">
                <span class="breadcrumb-item">{{ $t('Clients')}}</span>
              </router-link>
            </li>
            <li v-if="this.$route.params['clientId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_CLIENT")}}
              <ui-hint :content="$t('CLIENT_HINTS')" />
            </li>
            <li v-if="!this.$route.params['clientId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_CLIENT")}}
              <ui-hint :content="$t('CLIENT_HINTS')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <div class="row">
            <Form ref="observer" as="div" class="col-lg-6">
              <div class="col-lg-12 text-primary text-uppercase font-weight-bold mb-2">
                {{ $t("Main information") }}
              </div>
              <div class="row pr-2 pl-2">
                <div class="col-lg-6 pb-2">
                  <ui-text-input
                      id="first_name"
                      name="first_name"
                      :label="$t('First name')"
                      v-model="first_name"
                      :validation="{required: true, min: 1}"
                  />
                </div>
                <div class="col-lg-6 pb-2">
                  <ui-text-input
                      id="last_name"
                      name="last_name"
                      :label="$t('Last name')"
                      v-model="last_name"
                      :validation="{required: true, min: 1}"
                  />
                </div>
              </div>
              <div class="col-lg-12 pb-2">
                <ui-text-input
                    id="name"
                    name="name"
                    :label="$t('Display name')"
                    :hint="$t('CLIENT_NAME_HINT')"
                    v-model="name"
                    :validation="{required: true, min: 1}"
                />
              </div>
              <div class="row pr-2 pl-2">
                <div class="col-lg-6 pb-2">
                  <ui-text-input
                      id="phone"
                      name="phone"
                      :label="$t('Phone')"
                      v-model="phone"
                      :validation="{required: false, min: 9}"
                  />
                </div>
                <div class="col-lg-6 pb-2">
                  <ui-text-input
                      id="email"
                      name="email"
                      :label="$t('Email')"
                      v-model="email"
                      :validation="{required: false, email: true}"
                  />
                </div>
              </div>
              <div class="col-lg-12 pb-2">
                <ui-textarea-input
                    name="note"
                    :label="$t('Notes')"
                    v-model="notes"
                    :validation="{required: false, max: 256}"
                    :rows="2"
                />
              </div>
              <div class="col-lg-12">
                <client-info-component
                    :deleted-items="deletedItems"
                    :disabled="false"
                    :items="items"
                    @proceedSave="save"
                >
                </client-info-component>
              </div>
              <div class="col-12 py-2 text-right">
                <button @click="validate" class="btn btn-success btn-circle shadow-sm m-1" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </Form>
            <div class="col-lg-6">
              <client-addresses />
            </div>
          </div>

          <div class="row">
            <div v-if="clientId" class="col-lg-12 mt-2 p-3">
              <div class="row mb-2">
                <div class="col d-flex align-items-center">
                  <div class="text-primary text-uppercase font-weight-bold">
                    {{ $t("CLIENT_TASKS") }}
                  </div>
                </div>
                <div class="col-auto pl-1">
                  <button
                    class="btn btn-primary btn-sm shadow-sm"
                    data-toggle="modal"
                    data-target="#ClickTaskModal"
                    @click="createTask"
                    type="submit">
                    {{ $t('ATTACH_NEW_TASK') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <template v-if="tasks.length > 0">
                <el-table
                  class="table"
                  header-row-class-name="thead-light"
                  :data="tasks"
                  style="width: 100%">
                  <el-table-column
                    :label="$t('title')"
                    prop="title">
                  </el-table-column>
                  <el-table-column
                    :label="$t('description')"
                    prop="description">
                    <template #default="scope">
                      <div class="text-break">
                        {{scope.row.description}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('Employee')"
                    cell-class-name="text-break"
                    label-class-name="text-break"
                  >
                    <template #default="scope">
                      <div v-if="scope.row.employee">
                        {{ scope.row.employee.first_name }} {{ scope.row.employee.last_name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('PLANNED_TIME')"
                    cell-class-name="text-break"
                    label-class-name="text-break"
                    prop="lead_time">
                    <template #default="scope">
                      {{ scope.row.scheduled_time ? toTimeZone(scope.row.scheduled_time).format(localeDateTimeFormat) : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('Status')"
                  >
                    <template #default="scope">
                      <div v-if="scope.row.logs.length > 0">
                        <span class="project-color mx-1" v-bind:style="{ backgroundColor: scope.row.logs[scope.row.logs.length - 1].status.color }"></span>
                        {{ scope.row.logs[scope.row.logs.length - 1].status.title }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="150"
                    align="right">
                    <template #default="scope">
                      <i
                        class="icon-pencil text-primary btn-icon mx-1"
                        data-toggle="modal"
                        data-target="#ClickTaskModal"
                        @click="editTask(scope.row)"
                      />
                      <i class="icon-trash text-danger btn-icon mx-1" @click="handleDelete(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else>
                <div class="card mb-1">
                  <div class="card-body text-center p-4">
                    {{$t('NO_TASKS_ATTACHED')}}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import ClientInfoComponent from '@/components/Tasks/CommonComponents/AdditionalFieldsComponent.vue'
import ClientAddresses from '@/components/Tasks/Client/ClientAddresses.vue'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import mixinMoment from '@/mixins/mixinMoment'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import { utilities } from '@gmap-vue/v3'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'Client',
  components: {
    TaskModal,
    ClientInfoComponent,
    DummyPaymentRequired,
    ClientAddresses
  },
  mixins: [errorMixin, TasksAccessMixin, mixinMoment, mixinDummy, ToastMixin],
  data () {
    return {
      name: null,
      first_name: '',
      last_name: '',
      email: null,
      phone: null,
      notes: null,
      address: null,
      longitude: 0.0,
      latitude: 0.0,
      items: [],
      deletedItems: [],
      links: [],
      mapUrl: '',
      timeout: null,
      gApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      geoCodingApiKey: process.env.VUE_APP_GOOGLE_GEOCODING_API_KEY,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      tasks: [],
      showExistingTasksAttachModal: false,
      showNewTasksAttachModal: false,
      showEditTaskModal: false,
      clientId: this.$route.params.clientId,
      taskData: {
        id: null,
        client: {
          name: ''
        },
        children: []
      },
      taskModalData: null,
      taskId: null,
      nameChanged: false
    }
  },
  created () {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    if (this.companyId) {
      this.checkAccess(this.companyId)
    }
  },
  mounted () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      companyId: 'companyId',
      locale: 'locale'
    }),
    position () {
      return {
        lat: this.latitude,
        lng: this.longitude
      }
    }
  },
  watch: {
    '$refs.observer' (observer) {
      if (observer) {
        observer.resetForm()
      }
    },
    first_name: function (newVal, oldVal) {
      if (!this.nameChanged && !this.clientId) {
        this.name = `${newVal} ${this.last_name}`
      }
    },
    last_name: function (newVal, oldVal) {
      if (!this.nameChanged && !this.clientId) {
        this.name = `${this.first_name} ${newVal}`
      }
    },
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
    },
    longitude: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.mapUrl = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|label:S|${this.latitude},${this.longitude}&center=${parseFloat(this.latitude).toFixed(3)},${parseFloat(this.longitude).toFixed(3)}&zoom=15&size=900x600&maptype=roadmap&key=${this.gApiKey}`
      }
    },
    items: {
      handler () {
        // this.errors.clear()
      },
      deep: true
    }
  },
  methods: {
    changeName () {
      if (this.name) {
        this.nameChanged = true
      } else {
        this.nameChanged = false
      }
    },
    createTask () {
      this.taskData = {
        id: null,
        title: null,
        description: null,
        client_id: +this.$route.params.clientId,
        client: {
          name: this.name
        },
        children: [],
        client_name: this.name,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        address_data: null,
        longitude: 0.0,
        latitude: 0.0
      }
      this.showEditTaskModal = true
    },
    editTask (task) {
      this.taskData = task
      this.taskData.client_name = this.name
      this.showEditTaskModal = true
    },
    reloadTasks () {
      // this.showEditTaskModal = false
      // this.taskData = null
      this.init()
    },
    closeTaskModal () {
      this.showEditTaskModal = false
      this.taskData = null
    },
    setPlace (place) {
      this.longitude = place.geometry.location.lng()
      this.latitude = place.geometry.location.lat()
      this.address = place.formatted_address
      this.address_data = {
        name: place.name,
        address_components: place.address_components,
        formatted_address: place.formatted_address,
        vicinity: place.vicinity
      }
    },
    createFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (item) {
      this.longitude = item.lng
      this.latitude = item.lat
    },
    setDescription (description) {
      this.description = description
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.$store.dispatch('getTasksSettings', this.$store.getters.companyId).then((response) => {
        if (response) {
          this.address = response.default_city
          const google = getGoogleMapsAPI()
          if (google) {
            const geocoder = new google.maps.Geocoder()
            if (geocoder) {
              geocoder.geocode({ address: this.address }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                  this.setPlace(results[0])
                }
              })
            }
          }
        }
      })
      this.$store.dispatch('getCompanyClientFields').then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        this.items = response
        if (this.$route.params.clientId) {
          const tmpItems = {}
          const companyClientFieldsIds = []
          response.forEach(item => {
            tmpItems[item.id] = item
            companyClientFieldsIds.push(item.id)
          })
          this.$store.dispatch('getClient', [this.companyId, this.$route.params.clientId]).then((response) => {
            this.loaded = true
            this.dummy = false
            this.$eventBus.emit('dummy', false)

            this.name = response.name
            this.first_name = response.first_name
            this.last_name = response.last_name
            this.email = response.email
            this.phone = response.phone
            this.notes = response.notes
            this.address = response.address
            this.latitude = response.latitude
            this.longitude = response.longitude
            this.items = []
            this.deletedItems = []
            this.tasks = response.tasks
            const selectedClientFieldsIds = []
            response.fields.forEach((field) => {
              selectedClientFieldsIds.push(field.id)
              if (field.field_type === 'checkbox') {
                field.value = Boolean(Number(field.pivot.value))
              } else {
                field.value = field.pivot.value
              }

              field.options = field.pivot.options
              if (companyClientFieldsIds.includes(field.id)) {
                this.items.push(field)
              } else {
                this.deletedItems.push(field)
              }
            })
            companyClientFieldsIds.forEach(id => {
              if (!selectedClientFieldsIds.includes(id)) {
                this.items.push(tmpItems[id])
              }
            })
          }).catch((error) => {
            if (error.response.data.module) {
              this.dummy = true
              this.$eventBus.emit('dummy', true)
              this.dummyInfo = this.setupDummyData(error.response.data)
            }
          })
        }
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    validate () {
      this.$refs.observer.validate().then(result => {
        this.$eventBus.emit('validateClientFields')
      })
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          console.log(123, this.items)
          const items = this.items.map(item => {
            const clearItem = {}
            clearItem.value = item.value
            clearItem.options = item.options
            clearItem.id = item.id
            return clearItem
          })

          const payload = {
            name: this.name,
            address: this.address,
            address_data: this.address_data,
            longitude: this.longitude,
            latitude: this.latitude,
            items: items,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
            notes: this.notes
          }
          if (this.$route.params.clientId) {
            this.$store.dispatch('updateClient', [this.companyId, this.$route.params.clientId, payload]).then((response) => {
              this.toastSuccess(this.$t('Client updated'))
            }).catch(() => {})
          } else {
            this.$store.dispatch('createClient', [this.companyId, payload]).then((response) => {
              this.toastSuccess(this.$t('Client created'))
              this.$router.push(`/c/${this.companyId}/clients`)
            }).catch(() => {})
          }
        }
      })
    },
    handleDelete (taskId) {
      this.$confirm(this.$t('This will permanently delete the task. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTask', [this.companyId, taskId]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          this.init()
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    detachTask (taskId) {
      const payload = this.tasks.filter(item => +item.id === +taskId)[0]
      if (payload) {
        this.$confirm(this.$t('DETACH_TASK_TITLE'), this.$t('DETACH_TASK_BODY'), {
          confirmButtonText: this.$t('DETACH_TASK_BUTTON'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          payload.client_id = null
          this.$store.dispatch('updateTask', [this.companyId, taskId, payload])
            .then((response) => {
              this.init()
            })
            .catch(() => {})
        }).catch(() => {})
      }
    }
  }
}
</script>

<style>
</style>
