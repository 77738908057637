<template>
  <div>
    <div>
      <span class="text-danger text-uppercase font-weight-bold">{{ $t('DANGER_ZONE') }}</span>
    </div>
    <el-divider />
    <div>
      <span class="text-danger text-uppercase font-weight-bold">{{ $t('DELETE_PROFILE') }}</span>
    </div>
    <div v-if="!isOwner" class="my-2">
      <span class="text-primary">{{ $t('DELETE_PROFILE_HINT') }}</span>
    </div>
    <div v-if="isOwner" class="my-2">
      <span class="text-primary">{{ $t('DELETE_PROFILE_OWNER_HINT') }}</span>
    </div>
    <template v-if="!isOwner">
      <div v-if="!isDeletePress" class="my-2">
        <el-button type="danger" @click="pressDelete" style="min-width: 200px">
          {{ $t('DELETE_PROFILE_BUTTON') }}
        </el-button>
      </div>
      <div v-if="isDeletePress" class="my-2">
        <el-button type="danger" @click="pressDeleteConfirm" :disabled="this.timeout > 0" style="min-width: 200px">
          {{ this.timeout > 0 ? this.timeout : $t('DELETE_PROFILE_BUTTON') }}
        </el-button>
        <div>
          <small class="text-danger">
            {{ $t('DELETE_PROFILE_WARNING') }}
          </small>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { Crisp } from 'crisp-sdk-web'

export default {
  name: 'UserDangerZone',
  props: ['profile'],
  data () {
    return {
      isDeletePress: false,
      timeout: 30,
      intervalID: null
    }
  },
  computed: {
    isOwner () {
      return this.profile.owner.length > 0
    }
  },
  methods: {
    pressDelete () {
      this.isDeletePress = true
      this.intervalID = setInterval(() => {
        this.timeout = this.timeout - 1
        if (this.timeout === 0) {
          clearInterval(this.intervalID)
        }
      }, 1000)
    },
    pressDeleteConfirm () {
      this.$store.dispatch('deleteProfile').then(_ => {
        Crisp.setTokenId()
        Crisp.session.reset()
        this.$store.dispatch('logout', this.$store.getters.locale).then(() => {
        }).catch(() => {
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>

</style>
