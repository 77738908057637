<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="isModuleActive && loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'InventoryItems', params: { companyId: companyId} }">
                <span class="breadcrumb-item">{{ $t('Items')}}</span>
              </router-link>
            </li>
            <li v-if="itemId" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_INVENTORY_ITEM")}}
              <ui-hint :content="$t('EDIT_INVENTORY_ITEM_HINT')" />
            </li>
            <li v-else class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_INVENTORY_ITEM")}}
              <ui-hint :content="$t('CREATE_INVENTORY_ITEM_HINT')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <form @submit.prevent="handleSave">
            <Form ref="observer" as="div" class="row">
              <div class="col-lg-6">
                <div class="pb-2">
                  <el-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :multiple="true"
                    :limit="10"
                    :on-change="onFileChange"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-upload>
                </div>
                <div class="pb-2">
                  <ui-text-input
                      id="item_name"
                      name="item_name"
                      :label="$t('Title')"
                      v-model="item.name"
                      :validation="{required: true, min: 3}"
                  />
                </div>
                <div class="pb-2">
                  <ui-textarea-input
                    :rows="3"
                    name="item_description"
                    v-model="item.description"
                    :placeholder="$t('description')"
                    :label="$t('Description')"
                  />
                </div>
                <div class="pb-2">
                  <ui-multi-select
                      name="item_categories"
                      :label="$t('ITEM_CATEGORIES')"
                      :hint="$t('ITEM_CATEGORIES_HINT')"
                      v-model="itemCategoriesIds"
                      :validation="{required: true}"
                      :filterable="true"
                      :options="categoriesChoices"
                      :key-name="'id'"
                      :label-name="'name'"
                      :value-name="'id'"
                  />
                </div>
                <div class="pb-2">
                  <ui-select
                    name="item_unit_of_measurement"
                    :label="$t('UNIT_OF_MEASUREMENT')"
                    :hint="$t('UNIT_OF_MEASUREMENT_HINT')"
                    v-model="item.unit_of_measurement_id"
                    :validation="{required: true}"
                    :filterable="true"
                    :options="unitOfMeasurementChoices"
                    :key-name="'id'"
                    :label-name="'name'"
                    :value-name="'id'"
                  />
                </div>
                <div class="pb-2">
                  <ui-number-input
                    name="item_purchase_price"
                    :label="$t('ITEM_PURCHASE_PRICE')"
                    :hint="$t('ITEM_PURCHASE_PRICE_HINT')"
                    v-model="item.purchase_price"
                    :validation="{required: true, integer: false, min_value: 0}"
                    :step="0.01"
                    :min="0"
                  />
                </div>
                <div class="pb-2">
                  <ui-number-input
                    name="item_sale_price"
                    :label="$t('ITEM_SALE_PRICE')"
                    :hint="$t('ITEM_SALE_PRICE_HINT')"
                    v-model="item.sale_price"
                    :validation="{required: true, integer: false, min_value: 0}"
                    :step="0.01"
                    :min="0"
                  />
                </div>
              </div>
            </Form>
            <div class="row">
              <div class="col py-2 text-left">
                <button class="btn btn-success btn-circle shadow-sm" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import colors from '@/mixins/mixinColors'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'InventoryCategory',
  mixins: [errorMixin, momentMixin, colors, mixinDummy, ToastMixin],
  components: { DummyPaymentRequired },
  data () {
    return {
      item: {
        name: '',
        description: '',
        purchase_price: 0,
        sale_price: 0,
        unit_of_measurement_id: null,
        images: []
      },
      fileList: [],
      isModuleActive: false,
      itemCategoriesIds: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      taskStatusesChoices: []
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    unitOfMeasurementChoices () {
      return this.$store.getters.unitsOfMeasurement
    },
    categoriesChoices () {
      return this.$store.getters.categories
    },
    itemId () {
      return this.$route.params.itemId
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    onFileChange (file) {
      if (['image/jpeg', 'image/png'].indexOf(file.raw.type) === -1) {
        this.toastError(this.$i18n?.t('Item image must be JPG/PNG format!'))
        this.fileList = this.fileList.filter(item => item.url !== file.url)
        return false
      } else if (file.raw.size / 1024 / 1024 > 2) {
        this.toastError(this.$i18n?.t('Item image size can not exceed 2MB!'))
        this.fileList = this.fileList.filter(item => item.url !== file.url)
        return false
      }
      return true
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.checkActiveModule()

      this.$store.dispatch('getCategories', this.companyId)
      this.$store.dispatch('getUnitsOfMeasurement', this.companyId)

      if (this.itemId) {
        this.$store.dispatch('getItem', [this.companyId, this.itemId]).then((response) => {
          this.item = response
          this.loaded = true
          if (response.categories) {
            this.itemCategoriesIds = response.categories.map(item => item.id)
          }
          if (response.images) {
            this.setItemImages(response.images)
          }
        }).catch(() => {})
      } else {
        this.loaded = true
      }
    },
    setItemImages (images) {
      this.fileList = images.map((image) => {
        return {
          url: image.url,
          id: image.id,
          name: image.name,
          status: 'success'
        }
      })
    },
    checkActiveModule () {
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 32]).then(response => {
        if (response.active === true) {
          this.dummy = false
          this.isModuleActive = true
        }
      }).catch(err => {
        if (!err.response.data.module) {
          this.dummy = true
          this.dummyInfo = {}
        } else {
          this.dummy = true
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        // Files to add
        const toAdd = this.fileList.filter((file) => file.status === 'ready')

        // Files to delete
        const toDelete = this.item.images.filter(
          (file) => !this.fileList.some((f) => f.url === file.url)
        ).map((file) => file.id)

        if (result.valid) {
          const formData = new FormData()

          if (toAdd) {
            toAdd.forEach((item, index) => {
              formData.append(`to_add[${index}]`, item.raw)
            })
          }

          if (toDelete) {
            toDelete.forEach((id, index) => {
              formData.append(`to_delete[${index}]`, id)
            })
          }

          this.itemCategoriesIds.forEach((id, index) => {
            formData.append(`categories[${index}]`, id)
          })

          formData.append('name', this.item.name)
          formData.append('description', this.item.description)
          formData.append('purchase_price', this.item.purchase_price)
          formData.append('sale_price', this.item.sale_price)
          formData.append('unit_of_measurement_id', this.item.unit_of_measurement_id)

          if (this.itemId) {
            formData.append('_method', 'PATCH')

            this.$store.dispatch('updateItem', [this.companyId, this.itemId, formData]).then((response) => {
              this.item = response
              if (response.images) {
                this.setItemImages(response.images)
              }
              this.toastSuccess(this.$t('Item updated'))
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status && errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          } else {
            this.$store.dispatch('createItem', [this.companyId, formData]).then((response) => {
              this.toastSuccess(this.$t('Item created'))
              this.item = response
              this.$router.push(`/c/${this.companyId}/inventory/items/${response.id}`)
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status && errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>
</style>
