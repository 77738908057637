import { createStore } from 'vuex'
import alerts from './modules/alerts'
import app from './modules/app'
import bonus from '@/store/modules/bonus'
import breaks from './modules/breaks'
import checklists from './modules/checklists'
import clients from './modules/clients'
import clientAddress from './modules/clientAddress'
import company from './modules/companies'
import companySchedule from '@/store/modules/companySchedule'
import dashboard from './modules/dashboard'
import employees from '@/store/modules/employees'
import holidays from './modules/holidays'
import locations from '@/store/modules/locations'
import modules from './modules/modules'
import notes from '@/store/modules/notes'
import payment from './modules/payment'
import payments from './modules/payments'
import positions from './modules/positions'
import profile from './modules/profile'
import projectDas from './modules/project_das'
import projectMarks from './modules/project_marks'
import projects from './modules/projects'
import reports from './modules/reports'
import requestReasons from './modules/request_reasons'
import requests from './modules/requests'
import router from '../router/index'
import schedule from './modules/schedule'
import shifts from './modules/shifts'
import stats from './modules/stats'
import task from './modules/task'
import templates from './modules/templates'
import user from './modules/users'
import utils from './modules/utils'
import developers from './modules/developers'
import permissions from './modules/permissions'
import shiftsNotifications from './modules/shifts_notifications'
import availability from './modules/availability'
import geolocation from './modules/geolocation'
import vacations from '@/store/modules/vacations'
import shiftActivities from '@/store/modules/shift_activities'
import skills from '@/store/modules/skills'
import loadDemand from '@/store/modules/loadDemand'
import forecasting from './modules/forecasting'
import serviceArea from './modules/serviceArea'
import todos from './modules/todos'
import inventory from './modules/inventory'

import {
  AUTH,
  AUTH_LOGOUT,
  API_ERRORS,
  CHANGE_AVATAR
} from './mutation-types.js'
import { axiosBasic, axiosCachedImage, HTTP } from '@/api/http_utils'
import axios from 'axios'

// Состояние
const state = {
  apiKey: localStorage.getItem('api_key') || '',
  useImpersonalization: localStorage.getItem('useImpersonalization') === 'true',
  webSiteUrls: [
    '/login', '/login/', '/registration', '/registration/', '/add-phone',
    '/add-phone/', '/confirm-phone', '/confirm-phone/', '/profile/general',
    '/password/recovery', '/password/recovery/'
  ],
  apiErrors: {},
  modal: false
}

// Геттеры
const getters = {
  isAuthenticated: state => !!state.apiKey,
  useImpersonalization: state => !!state.useImpersonalization,
  apiKey: state => state.apiKey,
  webSiteUrls: state => state.webSiteUrls,
  modal: state => state.modal,
  apiErrors: state => state.apiErrors
}

// Мутации
const mutations = {
  [AUTH] (state, authData) {
    if (authData.apiKey) {
      state.apiKey = authData.apiKey
      localStorage.setItem('api_key', authData.apiKey)
      state.tokenType = authData.tokenType
      localStorage.setItem('token_type', authData.tokenType)
      state.useImpersonalization = authData.useImpersonalization
      localStorage.setItem('useImpersonalization', authData.useImpersonalization)
    } else {
      state.apiKey = authData
      localStorage.setItem('api_key', authData)
    }
  },
  [AUTH_LOGOUT] (state, isAuthenticated) {
    localStorage.removeItem('api_key')
    localStorage.removeItem('token_type')
    localStorage.removeItem('useImpersonalization')
    state.isAuthenticated = isAuthenticated
  },
  [API_ERRORS] (state, error) {
    state.apiErrors = {}
    state.apiErrors = error
  },
  [CHANGE_AVATAR] (state, payload) {
    state.utils.avatar.full = payload.full
    state.utils.avatar.mini = payload.mini
  }
}

// Действия
const actions = {
  getAuth ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const { useImpersonalization, ...AuthData } = data
      const clientData = {
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        grant_type: AuthData.grant_type,
        scope: '*'
      }

      let authUrl = 'work/1.0.0/auth/token'

      if (clientData.grant_type === 'social') {
        authUrl = AuthData.isRegistration === true ? 'work/1.0.0/auth/register' : 'oauth/token'
      }

      HTTP.post(process.env.VUE_APP_API_AUTH + authUrl, { ...AuthData, ...clientData }).then(response => {
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        if (response) {
          axios.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
          axiosBasic.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
          axiosCachedImage.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
        }

        commit(AUTH, {
          apiKey: response.access_token,
          tokenType: response.token_type,
          useImpersonalization: useImpersonalization
        })
        resolve(response)
      }).catch(error => {
        const err = {
          errors: {
            apiErrors: [error.response.data]
          }
        }
        this.dispatch('getErrors', err).then()
        commit(AUTH_LOGOUT, false)
        reject(error)
      })
    })
  },
  register ({ commit }, registrationData) {
    registrationData.provider = registrationData.provider === 'live' ? 'microsoft' : registrationData.provider

    return new Promise((resolve, reject) => {
      HTTP.post('/register', registrationData).then(response => {
        const clientData = {
          client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
          grant_type: 'password',
          scope: '*'
        }
        let payload = {}
        if (registrationData.access_token) {
          payload = {
            client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
            client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
            scope: '*',
            access_token: registrationData.access_token,
            provider: registrationData.provider,
            grant_type: 'social'
          }
        } else {
          payload = {
            username: registrationData.email ? registrationData.email : registrationData.phone,
            password: registrationData.password,
            ...clientData
          }
        }

        if (registrationData.recaptcha_key) {
          payload.recaptcha_key = registrationData.recaptcha_key
        }

        HTTP.post(process.env.VUE_APP_API_AUTH + 'oauth/token', payload).then(response => {
          axios.defaults.headers.common['Content-Type'] = 'application/json'
          if (response) {
            axios.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
            axiosBasic.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
            axiosCachedImage.defaults.headers.common.Authorization = response.token_type + ' ' + response.access_token
          }
          commit(AUTH, { apiKey: response.access_token, tokenType: response.token_type })
          resolve(response)
        }).catch(error => {
          this.dispatch('getErrors', error.response.data).then()
          commit(AUTH_LOGOUT, false)
          reject(error)
        })
      }).catch(error => {
        this.dispatch('getErrors', error.response.data).then()
        commit(AUTH_LOGOUT, false)
        reject(error)
      })
    })
  },
  getErrors ({ commit }, apiErrors) {
    commit(API_ERRORS, apiErrors)
  },
  unsubscribe ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.patch('/profile/unsubscribe/' + data).then(resp => {
        resolve(resp)
      })
    })
  },
  confirmEmail ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.patch('/profile/email/' + data.hash, { recaptcha_key: data.recaptcha_key }).then(resp => {
        resolve(resp)
      })
    })
  },
  confirmInviteAuth ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/invites/confirm_action/auth/' + data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getInvitedUser ({ commit }, companyUserId) {
    return new Promise((resolve, reject) => {
      HTTP.get('invites/company_user/' + companyUserId + '/data').then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  declineInviteAuth ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/invites/confirm_action/auth/' + data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  passwordRecovery ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/password/email', data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  passwordRecoveryConfirm ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/password/reset', data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  passwordRecoveryPhone ({ commit }, [phone, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/password/phone/${phone}`, data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  passwordRecoveryPhoneSms ({ commit }, [phone, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/password/phone/${phone}`, data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  passwordRecoveryPhoneConfirm ({ commit }, [recoveryHash, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/password/recovery/phone/${recoveryHash}/confirm`, data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  logout ({ commit }, locale) {
    const invite = localStorage.getItem('add_to_company')
    const dayDisplayType = localStorage.getItem('dayDisplayType')
    const monthDisplayType = localStorage.getItem('monthDisplayType')
    const presenceStatMode = localStorage.getItem('presenceStatMode')
    const minTimeCalendar = localStorage.getItem('minTimeCalendar')
    const maxTimeCalendar = localStorage.getItem('maxTimeCalendar')
    const showPosition = localStorage.getItem('showPosition')
    const showIdentifier = localStorage.getItem('showIdentifier')
    const primarySortOrder = localStorage.getItem('primarySortOrder')
    const nameFormat = localStorage.getItem('nameFormat')
    const TERMLY_API_CACHE = localStorage.getItem('TERMLY_API_CACHE')
    localStorage.clear()
    if (invite) {
      localStorage.setItem('add_to_company', invite)
    }
    localStorage.setItem('monthDisplayType', monthDisplayType)
    localStorage.setItem('presenceStatMode', presenceStatMode)
    localStorage.setItem('minTimeCalendar', minTimeCalendar)
    localStorage.setItem('maxTimeCalendar', maxTimeCalendar)
    localStorage.setItem('dayDisplayType', dayDisplayType)
    localStorage.setItem('showPosition', showPosition)
    localStorage.setItem('showIdentifier', showIdentifier)
    localStorage.setItem('primarySortOrder', primarySortOrder)
    localStorage.setItem('nameFormat', nameFormat)
    localStorage.setItem('TERMLY_API_CACHE', TERMLY_API_CACHE)
    commit(AUTH_LOGOUT, false)
    router.push(`/login?lang=${locale}`)
    location.reload()
  }
}

export default createStore({
  modules: {
    alerts,
    app,
    bonus,
    breaks,
    checklists,
    clients,
    clientAddress,
    company,
    companySchedule,
    dashboard,
    employees,
    holidays,
    locations,
    modules,
    notes,
    payment,
    payments,
    positions,
    profile,
    projectDas,
    projectMarks,
    projects,
    reports,
    requestReasons,
    requests,
    schedule,
    shifts,
    stats,
    task,
    templates,
    user,
    utils,
    developers,
    permissions,
    shiftsNotifications,
    availability,
    geolocation,
    vacations,
    shiftActivities,
    skills,
    loadDemand,
    forecasting,
    todos,
    inventory,
    serviceArea
  },
  state,
  getters,
  actions,
  mutations
})
