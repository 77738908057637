<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="isModuleActive && loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'InventoryFacilities', params: { companyId: companyId} }">
                <span class="breadcrumb-item">{{ $t('Facilities')}}</span>
              </router-link>
            </li>
            <li v-if="facilityId" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_FACILITY")}}
              <ui-hint :content="$t('EDIT_FACILITY_HINT')" />
            </li>
            <li v-else class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_FACILITY")}}
              <ui-hint :content="$t('CREATE_FACILITY_HINT')" />
            </li>
          </ol>
        </nav>
      </div>

      <div class="card card-small">
        <div class="card-body">
          <form @submit.prevent="handleSave">
            <Form ref="observer" as="div" class="row">
              <div class="col-lg-6">
                <div class="pb-2">
                  <ui-text-input
                    id="name"
                    name="name"
                    :label="$t('Title')"
                    v-model="facility.name"
                    :validation="{required: true, min: 1}"
                  />
                </div>
              </div>
            </Form>
            <div class="row">
              <div class="col py-2 text-left">
                <button class="btn btn-success btn-circle shadow-sm" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import colors from '@/mixins/mixinColors'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'InventoryFacility',
  mixins: [errorMixin, momentMixin, colors, mixinDummy, ToastMixin],
  components: { DummyPaymentRequired },
  data () {
    return {
      facility: {
        name: null
      },
      loaded: false,
      isModuleActive: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    facilityId () {
      return this.$route.params.facilityId
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    async init () {
      await this.checkActiveModule()

      if (this.facilityId) {
        this.$store.dispatch('getFacility', [this.companyId, this.facilityId]).then((response) => {
          this.facility = response
          this.loaded = true
        }).catch(() => {})
      } else {
        this.loaded = true
      }
    },
    async checkActiveModule () {
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 32]).then(response => {
        if (response.active === true) {
          this.dummy = false
          this.isModuleActive = true
        }
      }).catch(err => {
        if (!err.response.data.module) {
          this.dummyInfo = {}
          this.dummy = true
        } else {
          this.dummy = true
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const payload = Object.assign({}, this.facility)
          if (this.facilityId) {
            this.$store.dispatch('updateFacility', [this.companyId, this.facilityId, payload]).then((response) => {
              this.facility = response
              this.toastSuccess(this.$t('Facility updated'))
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status === 400 && errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          } else {
            this.$store.dispatch('createFacility', [this.companyId, payload]).then((response) => {
              this.toastSuccess(this.$t('Facility created'))
              this.facility = response
              this.$router.push(`/c/${this.companyId}/inventory/facilities/${response.id}`)
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status === 400 && errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>
</style>
