<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <span class="text-primary text-uppercase font-weight-bold mr-1">{{ $t('General Information') }}</span>
        <small>
          {{ $t("Please fill out ALL the fields in order to continue.") }}
        </small>
      </div>
    </div>
    <div class="mt-3">
      <Form ref="observe" as="div">
        <div class="row">
          <div class="col-lg-3">
            <div id="avatar_image_container" class="mt-5">
              <avatar
                v-if="showAvatar"
                :width="180"
                :borderRadius="50"
                :userId="userId"
                :placeholder="true"
                :placeholderUrl="'../../../static/images/App/placeholder.png'"
                :cache="cacheAvatar">
              </avatar>
              <div class="fullname-text font-weight-bold mt-4" align="center">{{profileState.first_name}} {{profileState.last_name}}</div>
              <div align="center" class="mt-2">
                <ui-file-upload
                  ref="upload"
                  :name="'file'"
                  :upload="{
                    url: url,
                    headers: headers
                  }"
                  :accept="'image/*'"
                  @onSuccess="getAvatar"
                  :show-file-list="false"
                  :auto-upload="true"
                />
              </div>
              <div align="center" class="mt-2">
                <button @click="downloadProfile" class="btn btn-outline-primary rounded-pill shadow-sm">
                  {{ $t('Download profile data') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="row mb-2">
              <div class="col-md-6">
                <ui-text-input
                  id="first_name"
                  :name="'first_name'"
                  v-model="profileState.first_name"
                  :validation="{required: true}"
                  :label="$t('First name')"
                />
              </div>
              <div class="col-md-6">
                <ui-text-input
                  id="last_name"
                  :name="'last_name'"
                  v-model="profileState.last_name"
                  :validation="{required: true}"
                  :label="$t('Last name')"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6">
                <label class="control-label font-weight-bold">{{ $t("Phone number") }}</label>
                <div v-for="(phone_data, phone_number) in profileState.phones" :key="phone_number">
                  <div v-if="!editPhoneCheck && !phoneEnterConfirm" class="row">
                    <div class="col-8 d-flex align-items-center">
                      <div v-if="phone_data.confirmed === false">
                        <div class="d-flex align-items-center">
                          <i class="icon-check-square text-warning font-weight-bold mr-1"></i>
                          <span>+{{phone_number}}</span>
                          <!-- !!! кнопка подтверждения -->
                          <button
                            class="btn btn-sm btn-warning rounded-pill shadow-sm ml-1"
                            @click="phoneConfirmation(phone_number, 'resend')"
                          >
                            {{ $t("CONFIRM") }}
                          </button>
                        </div>
                      </div>
                      <a v-else>
                        <i class="icon-check-circle text-success font-weight-bold"></i> +{{phone_number}}
                      </a>
                    </div>
                    <div class="col-4" align="right">
                      <button class="btn btn-sm btn-outline-secondary rounded-pill shadow-sm" @click="startEditPhone(phone_number)">{{ $t('Change') }}</button>
                    </div>
                  </div>
                  <div v-if="editPhoneCheck && phone_data.editable === true">
                    <div class="row">
                      <div class="col">
                        <Form ref="phone">
                          <ui-tel-input
                            v-model="editPhone.phone"
                            name="phone"
                            id="registerInputPhone"
                            :validation="{required: true, max: 20}"
                            @isPhoneValid="(val) => validPhone = val"
                          />
                        </Form>
                      </div>
                      <div class="col-md-3 pl-md-0 my-auto">
                        <a @click="phoneConfirmation(phone_number, 'edit')">
                          <i class="icon-check btn-icon text-primary mx-1"></i>
                        </a>
                        <a @click="phoneEditFinish(phone_number)">
                          <i class="icon-x btn-icon text-danger mx-1"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- !!! блок подтверждения -->
                  <div v-if="phoneEnterConfirm">
                    <PhoneConfirmationCode
                      :phoneNumber="phone_number"
                      :confirmed="phone_data.confirmed"
                      :isCounting="isCounting"
                      :countDown="countDown"
                      @phoneConfirmation="({phoneNumber, action}) => this.phoneConfirmation(phoneNumber, action)"
                      @confirmPhone="confirmPhone"
                      @confirmationFinish="confirmationFinish"
                    />
                  </div>
                </div>
              </div>
              <div v-if="profileState.register_provider === 'form'" class="col-lg-6 ">
                <div v-if="!editEmail" class="display-email">
                  <label class="control-label font-weight-bold">{{ $t("E-mail") }}</label>
                  <div class="row">
                    <div class="col-8">
                      <a v-if="profileState.email_verified_at === null">
                        <i class="icon-check-circle text-warning font-weight-bold"></i>
                        {{profileState.email}}
                      </a>
                      <a v-if="profileState.email_verified_at !== null">
                        <i class="icon-check-circle text-success font-weight-bold"></i>
                        {{profileState.email}}
                      </a>
                    </div>
                    <div class="col-4" align="right">
                      <button class="btn btn-sm btn-outline-secondary rounded-pill shadow-sm" @click="editEmail=true">{{ $t('Change') }}</button>
                    </div>
                  </div>
                </div>
                <div v-if="editEmail" class="edit-email">
                  <label class="control-label font-weight-bold">{{ $t("E-mail") }}</label>
                  <div class="row">
                    <div class="col-md-9">
                      <Form ref="emailObserve">
                        <ui-text-input
                          id="email"
                          :name="'email'"
                          v-model="profileState.email"
                          :validation="{required: true, email: true}"
                          :placeholder="$t('Enter valid email')"
                        />
                      </Form>
                    </div>
                    <div class="col-md-3 my-auto">
                      <a @click="changeEmail">
                        <i class="icon-check btn-icon text-primary mx-1"></i>
                      </a>
                      <a @click="editEmail = false">
                        <i class="icon-x btn-icon text-danger mx-1"></i>
                      </a>
                      <a @click="resendVerificationEmail()" v-if="profileState.email_verified_at === null">
                        <i class="icon-repeat btn-icon text-warning mx-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6">
                <ui-date-picker
                  name="date_birth"
                  :clearable="false"
                  :label="$t('Date of birth')"
                  v-model="profileState.date_birth"
                />
              </div>
              <div class="col-lg-6">
                <ui-select
                  name="gender"
                  id="gender"
                  :label="$t('Gender')"
                  v-model="profileState.gender"
                  :clearable="true"
                  class="w-100"
                  :options="genderChoices"
                  :key-name="'key'"
                  :value-name="'code'"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6">
                <ui-text-input
                  id="identification"
                  :name="'identification'"
                  v-model="profileState.identification"
                  :validation="{required: requiredField('identification')}"
                  :label="$t('I.D.')"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <location
                  :classes="'col-md-6'"
                  :countryId="profileState.country_code"
                  :cityId="profileState.city_id"
                  :stateId="profileState.subdivision_id"
                  :zipCode="profileState.zip_code"
                  :address="profileState.address"
                  :showAddress="true"
                  :requiredFieldsSetting="requiredFieldsSetting"
                  @addressChange="addressUpdate">
                </location>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12">
                <span class="text-primary text-uppercase font-weight-bold">{{ $t('Settings') }}</span>
                <small>
                  {{ $t("User time zone and interface language") }}
                </small>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mt-2">
                <label class="control-label font-weight-bold">{{ $t('Time zone')}}</label>
                <div class="row">
                  <div class="col-md-6">
                    <ui-select
                      name="time_zone"
                      id="time_zone"
                      v-model="profileState.time_zone"
                      :clearable="false"
                      :filterable="true"
                      class="w-100"
                      :options="allZones"
                      :label-name="'name'"
                      :value-name="'id'"
                      :validation="{required: true}"
                    />
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                <small>{{ $t('By default, time zones are displayed for the selected country')}}</small>
              </div>
              <div class="col-md-12 mt-2">
                <label class="control-label font-weight-bold">{{ $t('Time format')}}</label>
                <div class="row">
                  <div class="col-md-6">
                    <ui-select
                      name="time_format"
                      id="time_format"
                      v-model="profileState.time_format"
                      :clearable="false"
                      :filterable="true"
                      class="w-100"
                      :options="timeFormatChoices"
                      :label-name="'name'"
                      :value-name="'id'"
                      :validation="{required: true}"
                    />
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                <small>{{ $t('time_format_description')}}</small>
              </div>
              <div class="col-md-12 mt-2">
                <label class="control-label font-weight-bold">{{ $t('Date format')}}</label>
                <div class="row">
                  <div class="col-md-6">
                    <ui-select
                      name="date_format"
                      id="date_format"
                      v-model="profileState.date_format"
                      :clearable="false"
                      :filterable="true"
                      class="w-100"
                      :options="dateFormatChoices"
                      :label-name="'name'"
                      :value-name="'id'"
                      :validation="{required: true}"
                    />
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                <small>{{ $t('date_format_description')}}</small>
              </div>
              <div class="col-md-12 mt-2 p-0">
                <div class="col-md-6">
                  <ui-select
                    :label="$t('Choose language')"
                    name="language"
                    id="language"
                    v-model="language"
                    :clearable="false"
                    :filterable="true"
                    class="w-100"
                    :options="languages"
                    :label-name="'name'"
                    :value-name="'code'"
                    :validation="{required: true}"
                    @change="langSwitch"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-12 text-center py-2">
                <button
                  @click="submitForm"
                  class="btn btn-success rounded-pill shadow-sm"
                  :disabled="disabled"
                >
                  {{ $t('SAVE_WEB') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
    <div v-if="false && !(profileState.companies_limit > 0)">
      <div class="mt-5">
        <h6 class="text-primary text-uppercase font-weight-bold">{{ $t('Account Type') }}
          <small>{{ $t("By clicking you gain access to employee invitation, company, project and schedule creation") }}</small>
        </h6>
      </div>
      <div class="mt-2">
        <button
          id="profileUpgradeAccount"
          class="btn btn-success rounded-pill shadow-sm"
          @click.prevent="upgradeAccount"
          :disabled="disabled">
          {{ $t('Create business account') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/CommonComponents/Avatar'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { loadLanguageAsync } from '@/i18n'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import Location from '@/components/CommonComponents/Location'
import PhoneConfirmationCode from '@/components/User/profile/ProfileComponents/PhoneConfirmationCode.vue'

export default {
  name: 'MainProfileSettings',
  components: {
    PhoneConfirmationCode,
    avatar: Avatar,
    Location: Location
  },
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      genderChoices: [
        { code: 'male', name: this.$i18n?.t('Male') },
        { code: 'female', name: this.$i18n?.t('Female') }
      ],
      familyChoices: [
        { code: '', name: '--' },
        { name: this.$i18n?.t('Married'), code: 'married' },
        { name: this.$i18n?.t('Not married'), code: 'not_married' }
      ],
      booleanChoices: [
        { code: '', name: '--' },
        { code: 'yes', name: this.$i18n?.t('Yes') },
        { code: 'no', name: this.$i18n?.t('No') }
      ],
      experienceChoices: [
        { code: '', name: '--' },
        { name: this.$i18n?.t('First job'), code: 'first_job' },
        { name: this.$i18n?.t('Less than a year'), code: 'less_year' },
        { name: this.$i18n?.t('1-2 years'), code: 'one' },
        { name: this.$i18n?.t('2-5 years'), code: 'two_five' },
        { name: this.$i18n?.t('5 years and more'), code: 'more_five' }
      ],
      timeFormatChoices: [
        { name: this.$i18n?.t('12H_FORMAT_KEY'), id: 'hh:mm A' },
        { name: this.$i18n?.t('24H_FORMAT_KEY'), id: 'HH:mm' }
      ],
      dateFormatChoices: [
        { name: this.$i18n?.t('MM-DD-YYYY_FORMAT_KEY'), id: 'MM-DD-YYYY' },
        { name: this.$i18n?.t('DD-MM-YYYY_FORMAT_KEY'), id: 'DD-MM-YYYY' }
      ],
      recaptcha_key: '',
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      oldPhone: '',
      emailConfirmed: '',
      editPhone: {
        status: false,
        phone: ''
      },
      editEmail: false,
      confirmation: {
        status: false,
        code: '',
        phone: ''
      },
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      url: process.env.VUE_APP_ROOT_API + 'users/' + this.$store.getters.profileId + '/avatar',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
      },
      cacheAvatar: true,
      disabled: false,
      language: 'en',
      validPhone: true,
      showAvatar: true,
      countDown: 0,
      timerInterval: null,
      profileState: {}
    }
  },
  created () {
    this.language = this.Profile.locale
    this.profileState = this.Profile
    this.profileState.time_zone_all = true
    if (this.profileState.phones.length === 0) {
      this.profileState.phones = { '': { code: '', number: '', confirmed: true, phone_code: '' } }
    }
  },
  props: [
    'userId',
    'Profile',
    'company'
  ],

  watch: {
    async '$recaptchaInstance.recaptcha' () {
      this.$recaptchaInstance.hideBadge()
    },
    countDown () {
      if (this.countDown < 1) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    }
  },

  computed: {
    isCounting () {
      return this.countDown > 0
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    ...mapGetters(['Country', 'City', 'countries', 'allZones', 'countryZones', 'languages']),
    // checks for data in store
    editPhoneCheck () {
      return this.editPhone.status === true
    },
    phoneEnterConfirm () {
      return this.confirmation.status === true
    },
    requiredFieldsSetting () {
      return {
        country: this.requiredField('country_code'),
        city: this.requiredField('city_id'),
        state: this.requiredField('state'),
        zipCode: this.requiredField('zip_code'),
        address: this.requiredField('address')
      }
    }
  },

  methods: {
    changeOrder (value) {
      const tmp = value.split(' ')
      const x = tmp[0]
      tmp[0] = tmp[1]
      tmp[1] = x
      return tmp.join(' ')
    },

    addressUpdate (newAddress) {
      this.profileState.country_code = newAddress.countryId
      this.profileState.city_id = newAddress.cityId > 0 ? newAddress.cityId : null
      this.profileState.subdivision_id = newAddress.stateId
      this.profileState.zip_code = newAddress.zipCode
      this.profileState.address = newAddress.address
    },

    submitForm (e) {
      e.preventDefault()
      this.$refs.observe.validate().then(result => {
        const anchor = document.querySelector('.is-invalid')
        if (anchor) {
          anchor.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          })
        }
        if (result.valid) {
          const data = {}
          Object.keys(this.profileState).forEach(key => {
            data[key] = this.profileState[key]
          })
          if (this.profileState.date_birth) {
            data.date_birth = moment(this.profileState.date_birth).format(this.backendDateFormat)
          }
          data.locale = this.language
          data.city_id = data.city_id > 0 ? data.city_id : null
          data.subdivision_id = data.subdivision_id > 0 ? data.subdivision_id : null
          delete data.email
          this.$store.dispatch('setProfile', data).then((response) => {
            this.toastSuccess(this.$t('Profile successfully saved'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },

    upgradeAccount () {
      this.$store.dispatch('setProfile', { account_type: 'company' }).then((response) => {
        this.toastSuccess(this.$t('Profile successfully upgraded'))
        this.disabled = false
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },

    // change phone functions
    startEditPhone (phoneNumber) {
      this.editPhone = {
        status: true,
        phone: '+' + phoneNumber
      }
      this.profileState.phones[phoneNumber].editable = true
      this.oldPhone = phoneNumber
    },

    parsePhoneNumber (number) {
      number = number.replace('+', '')
      number = number.replace(/\s/g, '')
      return number
    },
    phoneValidation (val) {
      this.validPhone = val.isValid
    },
    async sendCode (action) {
      if (this.validPhone) {
        await this.getRecaptcha(action)

        const phoneData = {
          action: action,
          old_phone: this.oldPhone,
          phone: this.parsePhoneNumber(this.editPhone.phone),
          recaptcha_key: this.recaptcha_key
        }

        this.$store.dispatch('phone', phoneData).then(profile => {
          this.$store.dispatch('dropProfile').then(() => {
            this.$store.dispatch('getProfile').then(() => {
              localStorage.setItem('currentPhone', phoneData.phone)
              localStorage.setItem('unconfirmedProceed', 'true')
              this.profileState.phones = profile.phones
              this.confirmationStart(this.editPhone.phone)
            }).catch(() => {})
          }).catch(() => {})
        }).catch((err) => {
          clearInterval(this.timerInterval)
          this.timerInterval = null
          this.confirmation.status = false

          if (err.response.data.message === 'sms coldown') {
            this.toastError(this.$i18n?.t('Wait') + ' ' + err.response.data.wait_in_seconds + ' ' + this.$i18n?.t('sec before resending'))
          } else {
            this.toastError(this.$i18n?.t(err.response.data.message))
          }
        })
      }
    },
    async confirmPhone ({ result, code }) {
      await this.getRecaptcha('confirmPhone')
      if (result) {
        const phoneData = {
          action: 'confirm',
          phone: this.parsePhoneNumber(this.confirmation.phone),
          confirm: code,
          recaptcha_key: this.recaptcha_key
        }

        this.$store.dispatch('phone', phoneData).then(profile => {
          this.$store.dispatch('dropProfile').then(() => {
            this.$store.dispatch('getProfile').then(() => {
              this.profileState.phones = profile.phones
              this.confirmationFinish()
            })
          })
        }).catch(error => {
          this.toastError(this.$t(error.response.data.message))
        })
      }
    },
    async getRecaptcha (action) {
      await this.$recaptchaLoaded()

      if (!this.recaptchaShow) {
        this.recaptcha_key = this.sitekey
      } else {
        this.recaptcha_key = await this.$recaptcha(action)
      }
    },
    phoneEditFinish (phoneNumber) {
      this.profileState.phones[phoneNumber].editable = false
      this.editPhone.status = false
      clearInterval(this.timerInterval)
      this.timerInterval = null
    },
    async resendVerificationEmail () {
      this.getRecaptcha('resendVerificationEmail')

      this.$store.dispatch('resendVerificationEmail', { recaptcha_key: this.recaptcha_key }).then(() => {
        this.toastSuccess(this.$t('Email was send'))
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },

    confirmationStart (phoneNumber) {
      this.editPhone = {
        phone: phoneNumber
      }
      this.confirmation = {
        status: true,
        phone: phoneNumber
      }
      this.profileState.phones[phoneNumber].editable = true
      this.oldPhone = phoneNumber
    },

    confirmationFinish () {
      this.editPhone.status = false
      this.confirmation.status = false
      localStorage.removeItem('unconfirmedProceed')
      clearInterval(this.timerInterval)
      this.timerInterval = null
    },

    // change email function
    changeEmail () {
      this.$refs.emailObserve.validate().then(isValid => {
        if (isValid) {
          const emailData = {
            email: this.profileState.email
          }
          this.$store.dispatch('setProfile', emailData).then(() => {
            this.$store.dispatch('dropProfile').then(() => {
              this.$store.dispatch('getProfile').then(() => {
                this.editEmail = false
                this.profileState.email_verified_at = null
              }).catch(e => {})
            }).catch(e => {})
          }).catch(e => {})
        }
      })
    },

    requiredField (fieldName) {
      if (this.company && this.company.user_fields) {
        if (parseInt(localStorage.getItem('companyCount')) > 0) {
          return !!this.company.user_fields.includes(fieldName)
        } else return false
      } else {
        return false
      }
    },

    getAvatar () {
      this.cacheAvatar = false
      this.showAvatar = false
      // возвращаем флаг кэширования в тру, чтобы можно было менять аватар без перезагрузки страницы несколько раз подряд
      const self = this
      setTimeout(function () {
        self.cacheAvatar = true
        self.showAvatar = true
      }, 2000)
      this.$eventBus.emit('updateAvatar')
    },

    langSwitch (lang) {
      if (lang) {
        loadLanguageAsync(lang)
        const data = {
          locale: lang
        }
        this.$store.dispatch('setProfile', data).then(() => {
          this.$store.dispatch('switchLangReset')
          this.$store.dispatch('dropProfile').then(() => {
            this.$store.dispatch('getProfile')
            this.$store.dispatch('getCountryNames', lang)
            this.$store.dispatch('getSubdivisionNames', [lang, this.profileState.country_code])
            this.$store.dispatch('getCityNames', [lang, this.profileState.country_code, this.profileState.subdivision_id])
          })
        })
      }
    },
    phoneConfirmation (phoneNumber, action) {
      this.countDown = 30
      action === 'resend' && this.confirmationStart(phoneNumber)
      this.sendCode(action)
      !this.timerInterval && this.countDownTimer()
    },
    countDownTimer () {
      this.timerInterval = setInterval(() => (this.countDown -= 1), 1000)
    },
    downloadProfile () {
      this.$store.dispatch('downloadProfile').then(profile => {
        const jsonString = JSON.stringify(profile, null, 2)
        const blob = new Blob([jsonString], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'profile.json'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url) // Освободить память, удалив объект URL
      }).catch(error => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  }
}
</script>

<style scoped>
.grey {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
