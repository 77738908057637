<template>
  <div v-if="dummy">
    <dummy-payment-required
      @activate="getTableData"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
  </div>
  <div v-else-if="loaded">
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t('MY_WORKING_HOURS') }}</span>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 d-flex flex-column form-group">
            <div class="mt-auto">
              <ui-input-label>{{ $t('Select a date range') }}</ui-input-label>
              <div class="row py-0">
                <div class="col-lg-6">
                  <ui-date-picker
                      name="from_date"
                      v-model="period[0]"
                      :validation="{required: true}"
                      :clearable="false"
                      @change="changeRange('from')"
                  />
                </div>
                <div class="col-lg-6">
                  <ui-date-picker
                      name="to_date"
                      v-model="period[1]"
                      :validation="{required: true}"
                      :clearable="false"
                      @change="changeRange('to')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column form-group">
          </div>
        </div>
        <div v-if="loadedData && Object.keys(loadedData).length > 0" class="row">
          <div class="col-12 mt-1">
            <div class="card flex-fill shadow-none">
              <template v-for="(data, day) in loadedData" :key="day">
                <div class="card-header font-weight-bold">
                  {{ day.toUpperCase() }}
                </div>
                <div class="card-body p-1 table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th class="text-center">{{$t('regular_day')}}</th>
                      <th class="text-center">{{$t('regular_night')}}</th>
                      <th class="text-center">{{$t('regular_total')}}</th>
                      <th class="text-center">{{$t('holiday_day')}}</th>
                      <th class="text-center">{{$t('holiday_night')}}</th>
                      <th class="text-center">{{$t('holiday_total')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="w-100" style="height: 67px">
                      <td class="text-center">
                        {{ data.regular_day }} <br> ({{ hrsForHuman(data.regular_day + Number.EPSILON) }})
                      </td>
                      <td class="text-center">
                        {{ data.regular_night }} <br> ({{ hrsForHuman(data.regular_night + Number.EPSILON) }})
                      </td>
                      <td class="text-center">
                        {{ data.regular_total }} <br> ({{ hrsForHuman(data.regular_total + Number.EPSILON) }})
                      </td>
                      <td class="text-center">
                        {{ data.holiday_day }} <br> ({{ hrsForHuman(data.holiday_day + Number.EPSILON) }})
                      </td>
                      <td class="text-center">
                        {{ data.holiday_night }} <br> ({{ hrsForHuman(data.holiday_night + Number.EPSILON) }})
                      </td>
                      <td class="text-center">
                        {{ data.holiday_total }} <br> ({{ hrsForHuman(data.holiday_total + Number.EPSILON) }})
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col text-center">
            <div class="font-weight-bold my-5">
              {{ $t('NO_DATA') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import paginationMixin from '@/components/CommonComponents/Pagination/mixinPagination'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  mixins: [errorMixin, momentMixin, paginationMixin, mixinDummy],
  name: 'MyWorkingHours',
  components: { DummyPaymentRequired },
  data () {
    return {
      dummy: false,
      loaded: false,
      period: [moment().add(-7, 'Days').format(this.backendDateFormat), moment().format(this.backendDateFormat)],
      loadedData: {},
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.getTableData()
    }
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      company: 'company'
    }),
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    changeRange (val) {
      // console.log(val)
      if (val === 'from') {
        if (moment(this.period[0]).isAfter(moment(this.period[1])) || moment.utc(this.period[1]).diff(this.period[0], 'days') > 93) {
          this.period[1] = this.period[0]
        }
      }
      if (val === 'to') {
        if (moment(this.period[1]).isBefore(moment(this.period[0]))) {
          this.period[0] = this.period[1]
        }
      }
      this.getTableData()
    },
    getTableData () {
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      const data = '?date_from=' + dateFrom + '&date_to=' + dateTo
      this.$store.dispatch('getMyWorkingHoursStats', [this.$store.getters.companyId, data]).then((response) => {
        this.loadedData = response
        this.dummy = false
        this.loaded = true
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.loaded = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    }
  },
  watch: {
    companyId () {
      this.getTableData()
    }
  }
}
</script>
<style></style>
