<template>
  <span @click="click"
        data-toggle="modal"
        data-target="#AddShiftFreeBoard">
    <i class="icon-plus-circle text-primary font-weight-bold"
       style="font-size: 1.3em; cursor: pointer" /> {{isFreeBoard ? $t('Add shift to freeboard') : $t('Add shift to employee')}}
  </span>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'PlusShiftIcon',
  mixins: [momentMixin],
  props: {
    tooltip: String,
    isFreeBoard: Boolean,
    userId: Number,
    userMarks: Object
  },
  emits: ['clicked'],
  methods: {
    click () {
      this.$emit('clicked')
      this.$eventBus.emit('addShiftShow', {
        addShiftUserId: this.userId,
        userMarks: this.userMarks
      })
    }
  }
}
</script>

<style scoped>

</style>
