<template>
  <div @click="click">
    <i class="icon-minus-circle font-weight-bold text-danger"
       style="font-size: 1.3em; cursor: pointer" /> {{$t('Delete shifts from freeboard')}}
  </div>
</template>

<script>
export default {
  name: 'MinusShiftIcon',
  props: {
    tooltip: String,
    title: String,
    text: String,
    accept: String,
    decline: String,
    warning: String
  },
  emits: ['clicked'],
  methods: {
    click () {
      this.$emit('clicked')
      this.$confirm(this.title, this.warning, {
        confirmButtonText: this.accept,
        cancelButtonText: this.decline,
        type: 'warning',
        center: true
      }).then(() => {
        this.$eventBus.emit('clearFreeBoard')
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
