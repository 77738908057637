<template>
  <span
    @click="click"
    data-toggle="modal"
    data-target="#AddHoliday"
    class="align-content-center">
      <i class="icon-star-f font-weight-bold text-primary"
         style="font-size: 1.3em; cursor: pointer; color: #53a8dd !important;"
      /> {{ $t('Create a holiday') }}
    </span>
</template>

<script>
export default {
  name: 'StarHolidayIcon',
  props: {
    tooltip: String
  },
  emits: ['clicked'],
  methods: {
    click () {
      this.$emit('clicked')
      this.$eventBus.emit('addHoliday')
    }
  }
}
</script>

<style scoped>
.icon-star-f:before {
  content: "\e965";
}
</style>
