<template>
  <div>
    <dummy-payment-required
        v-if="dummy"
        @activate="getTableDataActivated"
        :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <template v-if="!dummy && loaded">
      <div class="container-fluid p-0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th v-for="(title, index) in header" :key="index" class="align-middle text-center table-header">
                  {{title}}
                </th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in tableData" :key="index">
              <template v-for="(value, index2) in row">
                <template v-if="index2 === 0">
                  <td
                    v-if="!(tableData[index -1] && tableData[index -1][0] === row[0])"
                    :rowspan="tableData.filter(row => row[0] === value).length"
                    :key="index2 + value.toString() + '__S__' + index"
                    :class="{
                    'text-right': index2 > 1
                  }"
                    :style="{
                    'font-weight': row[1] === 'total' || index2 === header.length - 1 ? 'bold' : null
                  }"
                  >
                    {{tableData[index -1] && tableData[index -1][0] === row[0] ? '' : value}}
                  </td>
                </template>
                <template v-else>
                  <td
                    :key="index2 + value.toString() + '__S__' + index"
                    :class="{
                    'text-right': index2 > 1
                  }"
                    :style="{
                    'font-weight': row[1] === 'total' || index2 === header.length - 1 ? 'bold' : null
                  }"
                  >
                    {{value === 'total' ? $t(value) : value}}
                  </td>
                </template>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
        <a v-if="loaded" id="download_F" hidden="true">download</a>
      </div>
    </template>
  </div>
</template>
<script>
import moment from 'moment/moment'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'ActivityReport',
  components: { DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy],
  data () {
    return {
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      timeoutId: null,
      dummy: false,
      tableData: [],
      header: [],
      actualIndex: 0,
      loaded: false,
      clickId: false
    }
  },
  props: ['period', 'search'],
  created () {
    this.$store.dispatch('getEmployeesByCompany', this.companyId)
    this.init()
  },
  mounted () {
    this.$eventBus.on('exportShiftActivityReport', () => this.download())
  },
  beforeUnmount () {
    this.$eventBus.off('exportShiftActivityReport')
  },
  watch: {
    period () {
      this.init()
    },
    search () {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.init()
      }, 1000)
    }
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    },
    locale () {
      return this.$store.getters.locale
    }
  },
  methods: {
    download () {
      const data = {
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat),
        export: true
      }
      if (this.search.trim()) {
        data.search = this.search.trim()
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getActivityStats', [this.companyId, payload, true]).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.getElementById('download_F')
        link.href = url
        link.removeAttribute('download')
        link.setAttribute('download', `export_employee_activity_${moment(this.period[0]).format(this.backendDateFormat)}_${moment(this.period[1]).format(this.backendDateFormat)}.xlsx`)
        link.click()
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    init () {
      this.loaded = false
      const data = {
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat),
        export: false
      }
      if (this.search.trim()) {
        data.search = this.search.trim()
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getActivityStats', [this.companyId, payload, false]).then((response) => {
        this.loaded = true
        this.handleTableData(response)
        this.header = [this.$t('Employee'), this.$t('Activity'), this.$t('Project')]
        const from = moment(this.period[0], this.backendDateTimeFormat)
        const to = moment(this.period[1], this.backendDateTimeFormat).add(1, 'days')
        do {
          this.header.push(from.format(this.localeDateFormat))
          from.add(1, 'day')
        } while (from.isBefore(to))
        this.header.push(this.$t('total'))
        this.$eventBus.emit('dummy', false)
        this.dummy = false
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    handleTableData (response) {
      const tableData = []
      Object.keys(response).forEach(employeeId => {
        const userName = response[employeeId]['username']
        Object.keys(response[employeeId]).forEach(activityName => {
          const row = []
          if (activityName === 'username') {
            return
          }
          row.push(userName)
          if (activityName === 'total') {
            row.push(activityName)
            row.push('')
          } else {
            row.push(activityName.split('__sp__')[0])
            row.push(activityName.split('__sp__')[2])
          }
          Object.keys(response[employeeId][activityName]).forEach(key => {
            if (response[employeeId][activityName][key]) {
              const total = response[employeeId][activityName][key]
              const hours = Math.floor(total)
              let minutes = Math.round((total - hours) * 60)
              if (minutes < 10) {
                minutes = '0' + minutes
              }
              row.push(hours + ' : ' + minutes)
            } else {
              row.push(response[employeeId][activityName][key])
            }
          })
          tableData.push(row)
        })
      })
      this.tableData = tableData.sort((a, b) => {
        if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return 1
        }
        if (a[0].toLowerCase() < b[0].toLowerCase()) {
          return -1
        }
        return 0
      })
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    }
  }
}
</script>
<style scoped>

</style>
