<template>
  <Form ref="observe" as="div">
    <div class="row">
      <div class="col-lg-6">
        <ui-text-input
          id="title"
          name="title"
          data-cy="schedule-name"
          v-model="mainInfo.title"
          :label="$t('Enter schedule name')"
          :hint="$t('SCHEDULE_NAME_HINTS')"
          :validation="{required: true, min: 3, max: 128, regex: /[?$<>{}*^=\p{L}\d]/u}"
        />
      </div>
      <div class="col-lg-6">
        <ui-color-picker
          name="color"
          :label="$t('Choose a color:')"
          v-model="mainInfo.color"
          :colors="colorsChoices"
        />
      </div>
      <div class="col-lg-6 mt-3">
        <ui-select
          id="project"
          name="project"
          :label="$t('Select project')"
          :hint="$t('PROJECT_SELECT_HINTS')"
          v-model="mainInfo.project"
          class="w-100"
          :options="projects"
          :validation="{required: true}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
          @change="onProjectChanges"
        />

        <div v-if="getProjectUsersCount() == 1" class="alert alert-warning alert-dismissible fade show mt-1" role="alert">
          <strong>{{ $t('SCHEDULE_MASTER_ADD_MORE_EMPLOYEES_ATTENTION') }}</strong> {{ $t('SCHEDULE_MASTER_ADD_MORE_EMPLOYEES_MESSAGE') }}
          <router-link :to="`/c/${companyId}/project/${mainInfo.project}`">{{ $t('SCHEDULE_MASTER_ADD_MORE_EMPLOYEES_LINK') }}</router-link>
        </div>

      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-6 mt-3">
        <label class="control-label font-weight-bold mr-2">{{ $t('Select dates') }}</label>
        <ui-hint :content="$t('SELECT_DATES_HINTS')" />
        <div class="row">
          <div class="col-lg-6 mb-1">
            <ui-date-picker
              name="from_date"
              v-model="mainInfo.period[0]"
              :validation="{required: true}"
              :clearable="false"
              @change="changeRange('from')"
              :disabled-date="disabledDateFrom"
            />
          </div>
          <div class="col-lg-6 mb-1">
            <ui-date-picker
              name="to_date"
              v-model="mainInfo.period[1]"
              :validation="{required: true}"
              :clearable="false"
              @change="changeRange('to')"
              :disabled-date="disabledDateTo"
            />
          </div>
        </div>
        <div class="text-muted">
          {{ $t('Schedules can be created for a maximum period of three months.') }}
        </div>
      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-12 pt-4">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('Select additional parameters') }}</span>
      </div>
      <div v-if="salaryForManager" class="col-lg-6 py-1">
        <label class="control-label font-weight-bold" for="payrate">
          {{ $t("Basic employee pay rate in this schedule") }}
          <ui-hint :content="$t('BASIC_PAY_RATE_HINTS')" />
        </label>
        <div class="row">
          <div class="col-lg-12 form-inline">
            <ui-number-input
              id="payrate"
              name="payrate"
              :min="0"
              v-model="mainInfo.payRate"
              :validation="{required: true, decimal: 2}"
            />
            <span class="ml-2">{{currency}}/{{ $t("hour") }}</span>
          </div>
        </div>
      </div>
      <div v-if="salaryForManager" class="col-lg-6"></div>
      <div class="col-lg-6 py-1">
        <ui-select
          name="week_overtime_control_type"
          :label="$t('SELECT_WEEK_OVERTIME_CONTROL_TYPE_LABEL')"
          :hint="$t('SELECT_WEEK_OVERTIME_CONTROL_TYPE_TOOLTIP')"
          v-model="mainInfo.shiftsControl.week_overtime_control_type"
          class="w-100"
          :options="weekOvertimeOptions"
          :validation="{required: true}"
          :key-name="'value'"
          :value-name="'value'"
          :label-name="'label'"
          :clearable="false"
        />
      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="shifts_visible_everyone"
          v-if="projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone"
          v-model="projects[mainInfo.project].shifts_visible_everyone"
          :label="$t('allow employees to see all shifts')"
          :hint="$t('key_use_project_settings')"
        />
        <ui-checkbox
          name="visibleEveryone"
          v-if="!(projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone)"
          v-model="mainInfo.shiftsControl.visibleEveryone"
          :label="$t('allow employees to see all shifts')"
          :hint="$t('ALLOW_SEE_ALL_SHIFTS_HINTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-select
          name="visible_everyone_type"
          v-model="mainInfo.shiftsControl.visible_everyone_type"
          class="w-25 mb-auto ml-3"
          :options="showUsersOptions"
          :validation="{required: true}"
          :key-name="'value'"
          :value-name="'value'"
          :label-name="'label'"
          :clearable="false"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="shifts_exchange"
          v-if="projects[mainInfo.project] && projects[mainInfo.project].shifts_exchange"
          v-model="projects[mainInfo.project].shifts_exchange"
          :disabled="true"
          :label="$t('Allow shift swaps between employees')"
          :hint="$t('key_use_project_settings')"
          data-cy="allow-exchange"
        />
        <ui-checkbox
          name="shiftsExchange"
          v-if="!(projects[mainInfo.project] && projects[mainInfo.project].shifts_exchange)"
          v-model="mainInfo.shiftsControl.shiftsExchange"
          :label="$t('Allow shift swaps between employees')"
          :hint="$t('ALLOW_SWAP_SHIFTS_HINTS')"
          data-cy="allow-exchange"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="shifts_exchange_confirmation"
          v-if="projects[mainInfo.project] && projects[mainInfo.project].shifts_exchange"
          class="mb-auto ml-3"
          v-model="projects[mainInfo.project].shifts_exchange_confirmation"
          :disabled="true"
          :label="$t('Shift swapping with manager confirmation')"
          :hint="$t('key_use_project_settings')"
          data-cy="manager-confirmation"
        />
        <ui-checkbox
          name="managerConfirmation"
          v-if="!(projects[mainInfo.project] && projects[mainInfo.project].shifts_exchange)"
          class="mb-auto ml-3"
          v-model="mainInfo.shiftsControl.managerConfirmation"
          :disabled="!mainInfo.shiftsControl.shiftsExchange"
          :label="$t('Shift swapping with manager confirmation')"
          :hint="$t('ALLOW_SWAP_SHIFTS_MANAGER_CONFIRM_HINTS')"
          data-cy="manager-confirmation"
        />
        <ui-checkbox
          name="shift_exchange_same_position_only"
          :disabled="(projects[mainInfo.project] && projects[mainInfo.project].shifts_exchange) || !mainInfo.shiftsControl.shiftsExchange"
          :hint="$t('SHIFT_EXCHANGE_SAME_POSITION_ONLY_HINT')"
          v-model="mainInfo.shiftsControl.shift_exchange_same_position_only"
          data-cy="same-position-exchange"
          class="mb-auto ml-3"
          :label=" $t('SHIFT_EXCHANGE_SAME_POSITION_ONLY')"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="putOnFreeBoard"
          v-model="mainInfo.shiftsControl.putOnFreeBoard"
          :label="$t('ALLOW_PUT_SHIFTS_ON_FREEBOARD')"
          :hint="$t('ALLOW_PUT_SHIFTS_ON_FREEBOARD_HINTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="putOnFreeboardConfirmation"
          class="mb-auto ml-3"
          v-model="mainInfo.shiftsControl.putOnFreeboardConfirmation"
          data-cy="manager-confirmation-put"
          :disabled="!mainInfo.shiftsControl.putOnFreeBoard"
          :label="$t('PUT_ON_FREEBOARD_MANAGER_CONFIRMATION')"
          :hint="$t('PUT_ON_FREEBOARD_MANAGER_CONFIRMATION_HINTS')"
        />
      </div>
      <!--  Shift requests -->
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="allowShiftRequest"
          v-model="mainInfo.shiftsControl.allowShiftRequest"
          :label="$t('ALLOW_SHIFT_REQUESTS')"
          :hint="$t('ALLOW_SHIFT_REQUESTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="allowShiftRequestConfirmation"
          class="mb-auto ml-3"
          data-cy="manager-confirmation-put"
          :disabled="!mainInfo.shiftsControl.allowShiftRequest"
          v-model="mainInfo.shiftsControl.allowShiftRequestConfirmation"
          :label="$t('ALLOW_SHIFT_REQUESTS_CONFIRMATION')"
          :hint="$t('ALLOW_SHIFT_REQUESTS_CONFIRMATION')"
        />
      </div>
      <!--  Break requests -->
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="allowBreakRequest"
          class="mb-auto"
          v-model="mainInfo.shiftsControl.allowBreakRequest"
          :label="$t('ALLOW_BREAK_REQUESTS')"
          :hint="$t('ALLOW_BREAK_REQUESTS_HINTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="allowBreakRequestConfirmation"
          class="mb-auto ml-3"
          data-cy="manager-break-confirmation"
          :disabled="!mainInfo.shiftsControl.allowBreakRequest"
          v-model="mainInfo.shiftsControl.allowBreakRequestConfirmation"
          :label="$t('ALLOW_BREAK_REQUESTS_CONFIRMATION')"
          :hint="$t('ALLOW_BREAK_REQUESTS_CONFIRMATION_HINTS')"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="allow_drop_to_employee"
          class="mb-auto"
          v-model="mainInfo.shiftsControl.allow_drop_to_employee"
          :label="$t('ALLOW_DROP_TO_EMPLOYEE')"
          :hint="$t('ALLOW_DROP_TO_EMPLOYEE_HINTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="allow_drop_to_employee_confirmation"
          class="mb-auto ml-3"
          :disabled="!mainInfo.shiftsControl.allow_drop_to_employee"
          v-model="mainInfo.shiftsControl.allow_drop_to_employee_confirmation"
          :label="$t('ALLOW_DROP_TO_EMPLOYEE_CONFIRMATION')"
          :hint="$t('ALLOW_DROP_TO_EMPLOYEE_CONFIRMATION_HINTS')"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <ui-checkbox
          name="shifts_take_freeboard"
          v-if="projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone && projects[mainInfo.project].shifts_take_freeboard"
          class="mb-auto"
          :disabled="true"
          v-model="projects[mainInfo.project].shifts_take_freeboard"
          :label="$t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD')"
          :hint="$t('key_use_project_settings')"
        />
        <ui-checkbox
          name="takeFromFreeboard"
          v-if="!(projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone && projects[mainInfo.project].shifts_take_freeboard)"
          class="mb-auto"
          v-model="mainInfo.shiftsControl.takeFromFreeboard"
          :label="$t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD')"
          :hint="$t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD_HINTS')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="shifts_take_freeboard_confirmation"
          v-if="projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone  && projects[mainInfo.project].shifts_take_freeboard"
          class="mb-auto ml-3"
          :disabled="true"
          data-cy="manager-confirmation-take"
          v-model="projects[mainInfo.project].shifts_take_freeboard_confirmation"
          :label="$t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION')"
          :hint="$t('key_use_project_settings')"
        />
        <ui-checkbox
          name="takeFromFreeboardConfirmation"
          v-if="!(projects[mainInfo.project] && projects[mainInfo.project].shifts_visible_everyone  && projects[mainInfo.project].shifts_take_freeboard)"
          class="mb-auto ml-3"
          :disabled="!mainInfo.shiftsControl.takeFromFreeboard"
          data-cy="manager-confirmation-take"
          v-model="mainInfo.shiftsControl.takeFromFreeboardConfirmation"
          :label="$t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION')"
          :hint="$t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION_HINTS')"
        />
      </div>
      <div class="col-12 my-1">
        <ui-checkbox
          name="allow_exchange_break_in_diff_templates"
          class="mb-auto"
          v-model="mainInfo.shiftsControl.allow_exchange_break_in_diff_templates"
          :label="$t('allow_exchange_break_in_diff_templates')"
          :hint="$t('key_allow_exchange_break_in_diff_templates')"
        />
      </div>
      <div class="col-12 my-1">
        <ui-checkbox
          name="allow_split_shift_by_employee"
          class="mb-auto"
          v-model="mainInfo.shiftsControl.allow_split_shift_by_employee"
          :label="$t('allow_split_shift_by_employee')"
          :hint="$t('key_allow_split_shift_by_employee')"
        />
      </div>
      <div v-if="$route.params.scheduleId" class="col-lg-12 pt-2">
        <label class="control-label font-weight-bold">{{ $t('OUT_SCHEDULE_SHIFT_ACTION') }}</label>
        <ui-hint :content="$t('OUT_SCHEDULE_SHIFT_ACTION_HINT')" />
        <div>
          <ui-radio
            name="OUT_SCHEDULE_SHIFT_IGNORE"
            :label="$t('OUT_SCHEDULE_SHIFT_IGNORE')"
            v-model="mainInfo.outScheduleShiftsAction"
            :set-value="'ignore'"
          />
        </div>
        <div>
          <ui-radio
            name="OUT_SCHEDULE_SHIFT_DELETE"
            :label="$t('OUT_SCHEDULE_SHIFT_DELETE')"
            v-model="mainInfo.outScheduleShiftsAction"
            :set-value="'delete'"
          />
        </div>
      </div>
      <div class="col-lg-12 d-inline-flex py-2">
        <button
          id="demo_master_next_step"
          data-cy="next-step"
          class="btn btn-primary rounded-pill shadow-sm" @click="next">
          {{ $t('Next step') }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColors from '@/mixins/mixinColors'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'MainInformation',
  mixins: [errorMixin, momentMixin, mixinColors],
  data () {
    return {
      showUsersOptions: [{
        value: 'all',
        label: this.$i18n?.t('KEY_SHOW_ALL')
      }, {
        value: 'with_same_position',
        label: this.$i18n?.t('KEY_SHOW_WITH_SAME_POSITIONS')
      }],
      mainInfo: {
        title: '',
        color: '#4e93e9',
        project: '',
        selectedProject: null,
        period: [
          moment().startOf('month').format(this.backendDateFormat),
          moment().add(1, 'month').add(1, 'days').format(this.backendDateFormat)
        ],
        payRate: 0,
        shiftsControl: {
          shiftsExchange: false,
          managerConfirmation: false,
          shift_exchange_same_position_only: false,
          putOnFreeBoard: false,
          putOnFreeboardConfirmation: false,
          takeFromFreeboard: false,
          takeFromFreeboardConfirmation: false,
          visibleEveryone: false,
          visible_everyone_type: 'all',
          week_overtime_control_type: 'confirm_overtime',
          working_hours: true,
          bind_break: false,
          break_between_shifts: 0,
          minimum_breaks_in_row: 0,
          maximum_breaks_in_row: 0,
          minimum_break_between_shifts: 0,
          minimum_break_between_shifts_for_women: 0,
          minimum_break_in_row_per_week: 0,
          minimum_shift_duration: 1,
          maximum_shift_duration: 8,
          minimum_coverage: 95,
          maximum_coverage: 105,
          create_templates: true,
          scheduleDemand: [],
          allow_exchange_break_in_diff_templates: false,
          allowBreakRequest: false,
          allowBreakRequestConfirmation: false,
          allow_drop_to_employee: false,
          allow_drop_to_employee_confirmation: false
        },
        users: [],
        outScheduleShiftsAction: 'ignore'
      },
      baseDate: moment().format(this.backendDateFormat),
      weekOvertimeOptions: [{
        value: 'confirm_overtime',
        label: this.$i18n?.t('CONFIRM_OVERTIME_LABEL')
      }, {
        value: 'allow_overtime',
        label: this.$i18n?.t('ALLOW_OVERTIME_LABEL')
      }, {
        value: 'block_overtime',
        label: this.$i18n?.t('BLOCK_OVERTIME_LABEL')
      }],
      projects: {},
      currency: this.$store.getters.currency,
      names: []
    }
  },

  props: {
    scheduleMainInfo: Object,
    scheduleId: String,
    isExist: String
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId'
    }),
    salaryForManager () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      return this.$store.state.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.state.company.company.salary.show_salary_to_managers
    },
    allowExchange () {
      return this.mainInfo.shiftsControl.shiftsExchange
    },
    allowPutOnFreeBoard () {
      return this.mainInfo.shiftsControl.putOnFreeBoard
    },
    allowTakeFromFreeBoard () {
      return this.mainInfo.shiftsControl.takeFromFreeboard
    },
    allowShiftRequest () {
      return this.mainInfo.shiftsControl.allowShiftRequest
    },
    companySalary () {
      return this.projects[this.mainInfo.project] ? parseFloat(this.projects[this.mainInfo.project].rate) : this.$store.getters.basicRate
    },
    companySalarySettings () {
      return this.$store.state.company.company.salary
    }
  },

  created () {
    // console.log(123, this.$store.getters.company.salary.week_start)
    this.$store.dispatch('getPositions', this.companyId)
    // получаем проекты по компании
    this.$store.dispatch('getProjectsByCompany', this.companyId).then((projects) => {
      projects.forEach(project => {
        if (this.isEmployeeHasPermission('update-schedule-admin') ||
          this.rolesByProjects[project.id] === 'manager') {
          this.projects[project.id] = {
            id: project.id,
            rate: project.rate,
            name: project.name,
            users: project.users,
            timezone: project.time_zone,
            shifts_visible_everyone: project.shifts_visible_everyone,
            shifts_exchange: project.shifts_exchange,
            shifts_exchange_confirmation: project.shifts_exchange_confirmation,
            shifts_take_freeboard: project.shifts_take_freeboard,
            shifts_take_freeboard_confirmation: project.shifts_take_freeboard_confirmation
          }
        }
      })

      if (Object.keys(this.scheduleMainInfo).length > 0) {
        // console.log(this.scheduleMainInfo)
        this.mainInfo = Object.assign({}, this.scheduleMainInfo)
        this.mainInfo.outScheduleShiftsAction = 'ignore'
        this.mainInfo.shiftsControl.shiftsExchange = this.scheduleMainInfo.shiftsControl.shiftsExchange
        this.mainInfo.shiftsControl.putOnFreeBoard = this.scheduleMainInfo.shiftsControl.putOnFreeBoard
        this.mainInfo.shiftsControl.takeFromFreeboard = this.scheduleMainInfo.shiftsControl.takeFromFreeboard
      } else if (projects.length > 0) {
        if (!this.isEmployeeHasPermission('update-schedule-admin')) {
          projects = projects.filter(project => project.role === 'manager')
        }
        this.mainInfo.project = projects[0].id
        this.mainInfo.projectTZ = this.projects[projects[0].id].timezone
        this.$emit('updateState', {
          data: this.mainInfo
        })
      }
      this.getSchedules()
      // console.log(111, this.scheduleId, this.mainInfo)
      if (!this.scheduleId) {
        this.mainInfo.payRate = Math.round(this.companySalary * 100) / 100
      } else {
        this.mainInfo.payRate = Math.round(this.mainInfo.payRate * 100) / 100
      }
      if (this.isExist) {
        const range = [
          this.toSelectedTimeZone(this.scheduleMainInfo.period[1], this.projects[this.mainInfo.project].timezone).format(this.backendDateFormat),
          moment(this.scheduleMainInfo.period[1], this.backendDateFormat).add(1, 'Month').format(this.backendDateFormat)
        ]
        this.mainInfo.period = range
        this.baseDate = this.mainInfo.period[0]
      }
      this.$store.dispatch('getMarksByProject', [this.mainInfo.project])
      this.$store.dispatch('getProjectUsers', this.mainInfo.project)
      if (this.companySalarySettings) {
        this.mainInfo.shiftsControl.minimum_break_between_shifts = +this.companySalarySettings.minimum_break_between_shifts
        this.mainInfo.shiftsControl.minimum_break_between_shifts_for_women = +this.companySalarySettings.minimum_break_between_shifts_for_women
        this.mainInfo.shiftsControl.minimum_break_in_row_per_week = +this.companySalarySettings.minimum_break_in_row_per_week
      }
    })
  },

  watch: {
    mainInfo (newVal, oldVal) {
      if (newVal.period !== oldVal.period) {
        this.loadDemand()
        this.$emit('updateState', {
          data: this.mainInfo
        })
      }
    },
    allowTakeFromFreeBoard (newValue) {
      if (!newValue && !this.mainInfo.shiftsControl.takeFromFreeboard) {
        this.mainInfo.shiftsControl.takeFromFreeboardConfirmation = false
      }
    },
    allowPutOnFreeBoard (newValue) {
      // console.log(1, newValue)
      if (!newValue && !this.mainInfo.shiftsControl.putOnFreeBoard) {
        // console.log(2)
        this.mainInfo.shiftsControl.putOnFreeboardConfirmation = false
      }
    },
    allowShiftRequest (val) {
      if (!val) {
        // console.log(2)
        this.mainInfo.shiftsControl.allowShiftRequestConfirmation = false
      }
    },
    allowExchange (newValue) {
      if (!newValue && !this.mainInfo.shiftsControl.shiftsExchange) {
        this.mainInfo.shiftsControl.managerConfirmation = false
        this.mainInfo.shiftsControl.shift_exchange_same_position_only = false
      }
    },
    companySalary () {
      if (this.mainInfo.project && !this.scheduleId) {
        this.mainInfo.payRate = parseFloat(this.projects[this.mainInfo.project].rate)
      }
    },
    companySalarySettings (val) {
      if (val) {
        this.mainInfo.shiftsControl.minimum_break_between_shifts = +val.minimum_break_between_shifts
        this.mainInfo.shiftsControl.minimum_break_between_shifts_for_women = +val.minimum_break_between_shifts_for_women
        this.mainInfo.shiftsControl.minimum_break_in_row_per_week = +val.minimum_break_in_row_per_week
      }
    }
  },

  methods: {
    disabledDateFrom (date) {
      if (this.scheduleId) {
        // console.log(666, date, this.mainInfo.period[0])
        return moment.utc(date).isBefore(this.baseDate)
      }
      return false
    },
    disabledDateTo (date) {
      if (this.scheduleId) {
        // console.log(666, date, this.mainInfo.period[0])
        return moment.utc(date).isBefore(this.baseDate) || moment.utc(date).diff(this.mainInfo.period[0], 'days') > 92
      }
      return moment.utc(date).diff(this.mainInfo.period[0], 'days') > 92
    },
    changeRange (val) {
      if (val === 'from') {
        if (moment(this.mainInfo.period[0]).isAfter(moment(this.mainInfo.period[1])) || moment.utc(this.mainInfo.period[1]).diff(this.mainInfo.period[0], 'days') > 93) {
          this.mainInfo.period[1] = this.mainInfo.period[0]
        }
      }
      if (val === 'to') {
        if (moment(this.mainInfo.period[1]).isBefore(moment(this.mainInfo.period[0]))) {
          this.mainInfo.period[0] = this.mainInfo.period[1]
        }
      }
      this.loadDemand()
    },
    loadDemand () {
      if (this.scheduleId) {
        const query = `from=${moment(this.mainInfo.period[0]).format(this.backendDateTimeFormat)}&to=${moment(this.mainInfo.period[1]).format(this.backendDateTimeFormat)}`
        this.$store.dispatch('getLoadStatistic', [this.scheduleId, query]).then((response) => {
          if (response) {
            this.mainInfo.shiftsControl.scheduleDemand = response
          }
        }).catch(() => {
          this.mainInfo.shiftsControl.scheduleDemand = []
        })
      }
    },
    onProjectChanges () {
      this.$store.dispatch('getMarksByProject', [this.mainInfo.project])
      this.$store.dispatch('getProjectUsers', this.mainInfo.project)
      this.getSchedules()
      this.$emit('projectChanged')
    },
    getSchedules () {
      this.$store.dispatch('getSchedulesByProject', [this.mainInfo.project]).then((schedules) => {
        // console.log(schedules)
        this.names = []
        schedules.forEach(schedule => {
          this.names.push(schedule.name.trim().toLowerCase())
        })
        // console.log(this.names)
      })
    },
    next () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          let existTitle = false
          if (this.scheduleId && this.mainInfo.title !== this.scheduleMainInfo.title && this.names.includes(this.mainInfo.title.trim().toLowerCase())) {
            existTitle = true
          } else if (!this.scheduleId && this.names.includes(this.mainInfo.title.trim().toLowerCase())) {
            existTitle = true
          }

          if (existTitle) {
            this.$refs.observe.setErrors({
              title: this.$t('The schedule name is already taken in this project. Enter a different name.')
            })
          } else {
            this.mainInfo.users = this.projects[this.mainInfo.project].users
            this.mainInfo.projectName = this.projects[this.mainInfo.project].name
            this.mainInfo.timezone = this.projects[this.mainInfo.project].timezone
            this.$emit('next', {
              step: 'mainInfo',
              data: this.mainInfo
            })
          }
        }
      })
    },
    getProjectUsersCount () {
      if (this.mainInfo.project && this.projects[this.mainInfo.project]) {
        return this.projects[this.mainInfo.project].users ? this.projects[this.mainInfo.project].users.length : 0
      }
      return 0
    }
  }
}
</script>

<style scoped>
  .invalid-feedback-el-select {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
</style>
