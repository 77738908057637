<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Items") }}
          <ui-hint :content="$t('ITEMS_HINT')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row py-2">
            <div class="col-lg-6">
              <Form ref="observer" as="div" class="form-inline">
                <ui-text-input
                  id="search-task"
                  name="search-task"
                  class="w-50"
                  :placeholder="$t('SEARCH_ITEM_PLACEHOLDER')"
                  v-model='searchQuery'
                  :validation="{required: false, min: searchQuery.length ? 3 : null}"
                  v-on:keyup.enter="searchItems"
                />
                <el-button
                  class="mx-1 mb-1"
                  type="primary"
                  @click="searchItems"
                  icon="Search">
                </el-button>
              </Form>
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill mx-3 m-1 col-auto col-auto" type="button" @click="addItem">
                  {{ $t('Create Item') }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <el-table
              :data="list"
              style="width: 100%">
              <el-table-column
                prop="name"
                :label="$t('Title')"
                width="180">
              </el-table-column>
              <el-table-column
                prop="description"
                :label="$t('Description')">
              </el-table-column>
              <el-table-column
                :label="$t('UNIT_OF_MEASUREMENT')">
                <template #default="scope">
                  {{ scope.row.unit_of_measurement ? scope.row.unit_of_measurement.name : $t('None') }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Categories')">
                <template #default="scope">
                  <span v-for="(category, index) in scope.row.categories" :key="category.id">
                  {{ category.name }}<span v-if="index < scope.row.categories.length - 1">, </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="purchase_price"
                :label="$t('PURCHASE_PRICE')">
              </el-table-column>
              <el-table-column
                prop="sale_price"
                :label="$t('SALE_PRICE')">
              </el-table-column>
              <el-table-column
                width="150"
                :label="$t('Actions')">
                <template #default="scope">
                  <router-link :to="`/c/${companyId}/inventory/items/${scope.row.id}`">
                    <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                  </router-link>
                  <i v-if="!scope.row.is_first" class="icon-trash btn-icon text-danger"
                    @click="deleteItem(scope.row.id)"
                    role="button">
                  </i>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              v-model:current-page="pagination.currentPage"
              :page-size="25"
              :total="pagination.total"
              >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'InventoryItems',
  components: { DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      searchQuery: '',
      loaded: false,
      dummy: false,
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAndInitialize()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAndInitialize()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange (val) {
      this.init()
    },
    checkAndInitialize () {
      this.$store.dispatch('isModuleActive', [this.companyId, 32]).then((e) => {
        this.init()
      }).catch(err => {
        this.$store.dispatch('getModulesByCompany')
        this.dummy = true
        if (!err.response.data.module) {
          this.dummyInfo = {}
        } else {
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    init () {
      const data = {
        page: this.pagination.currentPage
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getItems', [this.companyId, payload]).then((response) => {
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        this.list = response.data
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addItem () {
      this.$router.push(`/c/${this.companyId}/inventory/items/create`)
    },
    searchItems () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            search: this.searchQuery,
            page: this.pagination.currentPage
          }

          const payload = '?' + this.stringifyObject(data)
          this.$store.dispatch('getItems', [this.companyId, payload]).then((response) => {
            this.pagination.currentPage = response.current_page
            this.pagination.total = response.total
            this.list = response.data
            // this.handleCurrentChange()
          }).catch(() => {})
        }
      })
    },
    deleteItem (itemId) {
      this.$confirm(this.$t('This will permanently delete the item. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteItem', [this.companyId, itemId]).then((response) => {
          this.init()
          this.toastSuccess(this.$t('Successfully deleted'))
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
        }).catch((error) => {
          const errorMessage = error.response.data.message
          if (error.response.status === 400 && errorMessage === 'Unable to delete item') {
            this.toastError(this.$i18n?.t(errorMessage))
          }
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    }
  }
}
</script>

<style scoped>
.status-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
