<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div class="container-fluid p-0" v-else-if="loaded">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Username") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Project") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Schedule") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Date") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Clock in time") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Planned time") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Late minutes") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Number of delays") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(employee_data, employee_index) in filteredData">
              <template v-for="(project_data, project_id, project_idex) in employee_data[1]['project_data']">
                <template v-for="(shift, index) in project_data" :key="'employee_' + employee_index + project_idex + index">
                  <tr>
                    <td v-if="project_idex === 0 && index === 0" :rowspan="employee_data[1]['user_data']['total_rows']" class="text-nowrap align-middle">
                      {{ employee_data[1]['user_data']['username'] }}
                    </td>
                    <td v-if="index === 0" :rowspan="employee_data[1]['user_data']['projects_rows'][project_id]" class="text-nowrap align-middle">
                      {{ getProjectName(project_id) }}
                    </td>
                    <td>{{ getScheduleName(shift.schedule_id) }}</td>
                    <td>{{ setDateTimeFormat(shift.time_from) }}</td>
                    <td>{{ setClockInTime(shift) }}</td>
                    <td>{{ setDateTimeFormat(shift.planned_from, false) }}</td>
                    <td>{{ getTimeDiff(shift) }}</td>
                    <td v-if="index === 0" :rowspan="employee_data[1]['user_data']['projects_rows'][project_id]" class="text-nowrap align-middle">
                      {{ employee_data[1]['user_data']['projects_rows'][project_id] }}
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'Budget',
  components: { DummyPaymentRequired },
  mixins: [errorMixin, momentMixin, mixinDummy],
  data () {
    return {
      rows: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  props: ['projects', 'period', 'search'],
  created () {
    this.getTableData()
  },
  beforeUnmount () {
    this.$eventBus.off('exportShiftConfirmationReport')
  },
  mounted () {
    this.$eventBus.on('exportShiftConfirmationReport', this.exportReport)
  },
  methods: {
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    exportReport () {
      this.getTableData(true)
    },
    getTableData (exportReport = false) {
      this.loaded = false
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      let data = '?projects[]=' + this.projects.join('&projects[]=') + '&date_from=' + dateFrom + '&date_to=' + dateTo
      if (exportReport) {
        data += '&export=1'
      }
      this.$store.dispatch('getLateEmployeesReport', [this.$store.getters.companyId, data, exportReport]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (exportReport) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_late_employees_report')}_${moment(this.period[0]).format(this.backendDateFormat)}_${moment(this.period[1]).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    setTable (response) {
      const mappedData = Object.keys(response).map((key) => {
        const projectsRows = {}
        let totalRows = 0

        Object.keys(response[key]).forEach(projectId => {
          projectsRows[projectId] = response[key][projectId].length
          totalRows += projectsRows[projectId]
        })

        const employeeData = {
          user_data: {
            username: this.getEmployeeName(key),
            total_rows: totalRows,
            projects_rows: projectsRows
          }
        }

        employeeData.project_data = response[key]
        return [key, employeeData]
      }).sort(function (a, b) {
        const aName = a[1].user_data.username
        const bName = b[1].user_data.username
        if (aName > bName) return 1
        if (aName < bName) return -1
        return 0
      })

      this.rows = mappedData
    },
    setDateTimeFormat (datetime, date = true) {
      return this.fromZoneToZone(datetime, 'UTC', this.tz).format(date ? this.localeDateFormat : this.localeTimeFormat)
    },
    setClockInTime (shift) {
      if (shift.lateness) {
        return this.fromZoneToZone(shift.planned_from, 'UTC', this.tz).add(shift.lateness, 'minutes').format(this.localeTimeFormat)
      }
      return this.fromZoneToZone(shift.time_from, 'UTC', this.tz).format(this.localeTimeFormat)
    },
    getTimeDiff (shift) {
      if (shift.lateness) {
        return shift.lateness
      }
      return moment(shift.time_from).diff(moment(shift.planned_from), 'minutes')
    },
    getProjectById (projectId) {
      return this.$store.getters.projectById(projectId)
    },
    getEmployeeName (employeeId) {
      const employee = this.$store.getters.employeeById(employeeId)
      if (employee) {
        return employee.full_name
      }
      return this.$i18n?.t('User has been deleted') + ' (' + employeeId + ')'
    },
    getProjectName (projectId) {
      const project = this.$store.getters.projectById(projectId)
      return project.name
    },
    getScheduleName (scheduleId) {
      const schedule = this.$store.getters.scheduleById(scheduleId)
      return schedule.name
    }
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    },
    filteredData () {
      if (this.search === '') {
        return this.rows
      }
      return this.rows.filter((key) => key[1].user_data.username.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1)
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    }
  }
}
</script>

<style scoped>

</style>
