<template>
  <modal-component :id="'clickCompanyActionsModal'" :modalClass="'modal-md'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <el-alert
          center
          :closable="false"
          type="warning">
          <template #title>
            <div class="text-center my-2" v-html="$t(companyActionWarning)">
            </div>
          </template>
        </el-alert>
        <div>
          {{ $t('COMPANY_ACTION_INPUT_INFO_TEXT') }}: <span class="font-weight-bold">{{ inputPhrase }}</span>
        </div>
        <Form ref="observer" as="div">
          <div class="row">
            <div class="col-12">
              <ui-text-input
                id="confirmText"
                :name="'confirmText'"
                v-model="confirmText"
                :validation="{
                  required: true,
                  freeze: this.actionModalType === 'freeze',
                  delete: this.actionModalType === 'delete'
                }"
                :label="$t('COMPANY_ACTION_CONFIRM_TEXT')"
                :placeholder="$t('COMPANY_ACTION_CONFIRM_TEXT')"
              />
            </div>
            <div class="col-12">
              <ui-select
                :id="reason"
                name="reason"
                :label="$t('COMPANY_ACTION_REASON')"
                v-model="reason"
                class="w-100"
                :options="options"
                :value-name="'code'"
                :label-name="'value'"
                :validation="{required: true}"
              />
            </div>
            <div class="col-12">
              <ui-textarea-input
                class="w-100"
                :label="$t('COMPANY_ACTION_REASON_COMMENT')"
                v-model="comment"
                name="comment"
                :rows="2"
                :resize="'none'"
              />
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="mx-auto text-center">
          <button class="btn btn-danger rounded-pill shadow-sm" @click="runAction()">{{ $t(buttonTitle) }}</button>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent.vue'
import momentMixin from '@/mixins/mixinMoment'
import { Crisp } from 'crisp-sdk-web'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyActionsModal',
  mixins: [momentMixin],
  components: { ModalComponent },
  data () {
    return {
      confirmText: '',
      reason: '',
      comment: '',
      options: [],
      title: 'COMPANY_ACTION_MODAL_FREEZE_TITLE',
      buttonTitle: 'COMPANY_ACTION_FREEZE_BUTTON_TITLE',
      companyActionWarning: 'COMPANY_ACTION_FREEZE_WARNING',
      inputPhrase: 'freeze my account'
    }
  },
  props: { actionModalType: String },
  created () {
    if (this.actionModalType === 'freeze') {
      this.options = [
        { code: 'temporary_not_using', value: this.$i18n?.t('reason_temporary_not_using') },
        { code: 'financial_difficulties', value: this.$i18n?.t('reason_financial_difficulties') },
        { code: 'plan_to_return', value: this.$i18n?.t('reason_plan_to_return') },
        { code: 'technical_issues', value: this.$i18n?.t('reason_technical_issues') },
        { code: 'waiting_for_features', value: this.$i18n?.t('reason_waiting_for_features') },
        { code: 'other', value: this.$i18n?.t('reason_other') }
      ]
    } else {
      this.title = 'COMPANY_ACTION_MODAL_DELETE_TITLE'
      this.buttonTitle = 'COMPANY_ACTION_DELETE_BUTTON_TITLE'
      this.companyActionWarning = 'COMPANY_ACTION_DELETE_WARNING'
      this.inputPhrase = 'delete my account'
      this.options = [
        { code: 'not_using_anymore', value: this.$i18n?.t('reason_not_using_anymore') },
        { code: 'too_expensive', value: this.$i18n?.t('reason_too_expensive') },
        { code: 'missing_features', value: this.$i18n?.t('reason_missing_features') },
        { code: 'difficult_to_use', value: this.$i18n?.t('reason_difficult_to_use') },
        { code: 'found_alternative', value: this.$i18n?.t('reason_found_alternative') },
        { code: 'security_privacy_issues', value: this.$i18n?.t('reason_security_privacy_issues') },
        { code: 'bad_support_experience', value: this.$i18n?.t('reason_bad_support_experience') },
        { code: 'other', value: this.$i18n?.t('reason_other') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    logout () {
      Crisp.setTokenId()
      Crisp.session.reset()
      this.$store.dispatch('logout', this.$store.getters.locale).then(() => {
      }).catch(() => {
      })
    },
    runAction () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const payload = {
            action_type: this.actionModalType,
            reason: this.reason,
            comment: this.comment
          }
          this.$store.dispatch('handleCompanyAction', payload).then(response => {
            if (response.status === 'frozen') {
              this.toastSuccess(this.$i18n?.t('The company has been successfully frozen'))
            } else {
              this.toastSuccess(this.$i18n?.t('The company has been successfully deleted'))
            }
            this.closeModal()
            this.logout()
          }).catch(error => {
            this.toastError(this.$i18n?.t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
