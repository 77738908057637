<template>
  <Form ref="observe">
    <div class="row" data-cy="template-component">
      <div :class="customClass" class="mt-2">
        <div class="row">
          <div class="col-lg-12 form-group text-left">
            <ui-text-input
              :name="index + '__title'"
              id="demo_master_template_title"
              data-cy="template-name"
              v-model="templateState.name"
              :label="$t('Schedule name')"
              :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
            />
          </div>
          <div v-if="companySalary && companySalary.is_shift_rate_enable" class="col-lg-12 form-group text-left">
            <ui-text-input
              :name="index + '__rate_per_shift'"
              id="rate_per_shift"
              data-cy="rate-per-shift"
              v-model="templateState.rate_per_shift"
              :label="$t('RATE_PER_SHIFT')"
              :validation="{decimal: true}"
            />
          </div>
          <div class="col-lg-12 text-left">
            <label class="control-label font-weight-bold mx-1">{{ $t("Shift period") }} {{ periodDuration }} {{ getHrs() }} {{ projectTimeZone }} <ui-hint :content="$t('SHIFT_PERIOD_DURATION_HINT')" /></label>
            <label class="control-label font-weight-bold mx-1">{{ $t("Shift duration hrs") }} {{ duration }} {{ durationHrsHuman }} {{ projectTimeZone }} <ui-hint :content="$t('SHIFT_DURATION_HINT')" /></label>
          </div>
          <div class="col-lg-6 text-left">
            <ui-time-picker
              :name="index + '__timeRangeFrom'"
              :placeholder="$t('From')"
              :validation="{required: true}"
              :clearable="false"
              :value-format="'HH:mm'"
              v-model="templateState.time_from"
              :picker-options="pickerOptions"
            />
          </div>
          <div class="col-lg-6 text-left">
            <ui-time-picker
              :name="index+ '__timeRangeTo'"
              :placeholder="$t('To')"
              :validation="{required: true}"
              :clearable="false"
              :value-format="'HH:mm'"
              v-model="templateState.time_to"
              :picker-options="pickerOptions"
            />
          </div>
          <div class="col-lg-6 text-left" v-show="showBreak">
            <ui-number-input
              :name="index + '__break'"
              v-model="templateState.break_time"
              :precision="0"
              :step="1"
              :label="$t('BREAK_MIN')"
              :disabled="templateState.allow_break && templateState.breaks.length > 0"
              :validation="{integer: true, max_value: durationMinutes}"
            />
          </div>
          <div class="col-lg-6 text-left">
            <ui-color-picker
              :name="index + '__Shift_color'"
              v-model="templateState.color"
              :label="$t('Shift color')"
              :colors="colorsChoices"
            />
          </div>
          <div class="col-lg-6 text-left">
            <ui-select
              :name="index + '__location'"
              :label="$t('Location')"
              :hint="$t('LOCATION_TEMPLATE_HINTS')"
              v-model="templateState.location_id"
              class="w-100"
              :options="locations"
              :validation="{required: false}"
              :key-name="'id'"
              :value-name="'id'"
              :label-name="'name'"
              :clearable="true"
            />
          </div>
          <div class="col-lg-6 text-left">
            <ui-multi-select
              :name="index + '__Marks'"
              :label="$t('Marks')"
              :hint="$t('MARKS_TEMPLATE_HINTS')"
              v-model="templateState.marks"
              class="w-100"
              :options="projectMarks"
              :validation="{required: false}"
              :key-name="'id'"
              :value-name="'id'"
              :label-name="'name'"
              :clearable="true"
              :allow-create="true"
            />
          </div>
          <div v-if="showAdvanced" class="col-lg-12 text-left mt-3">
            <div class="row">
              <div class="col-12">
                <ui-checkbox
                  class="custom-control-inline"
                  :name="index + '_checkbox_break'"
                  v-model="templateState.allow_break"
                  :key="index"
                >
                  {{ $t('ALLOW_BREAK_KEY') }}
                  <ui-hint :content="$t('BREAKS_HINTS')" />
                </ui-checkbox>
              </div>
            </div>
            <div class="row" v-if="dummyInfo.active && templateState.allow_break">
              <div class="col-12">
                <div v-for="(breakItem, item_key) in templateState.breaks" :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}" class="p-1 rounded" :key="item_key">
                  <break-item
                    :index="item_key"
                    v-if="templateState"
                    :item="breakItem"
                    :showItemConfig="true"
                    :workplaces="templateState.workplaces"
                    :validate="validate"
                    :startTime="templateState.time_from"
                    @breakChanged="() => {
                    $validator.errors.remove(templateState.id + '__' + item_key + '_breaks')
                  }"
                    :endTime="templateState.time_to"
                    :calculateWorkplaces="true"
                    @deleteItem="deleteItem(item_key)">
                  </break-item>
                </div>
                <button type="button" class="btn btn-primary btn-sm mt-2" @click="addBreak()">{{ $t('Add break') }}</button>
              </div>
            </div>
            <div class="row my-2" v-if="!dummyInfo.active && templateState.allow_break">
              <div class="col-12">
                <dummy-payment-required
                  @activate="activate"
                  :dummyInfo="dummyInfo"
                  :compactMode="true">
                </dummy-payment-required>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showAdvanced && !forDemand" :class="customClass2 ? customClass2 : customClass">
        <div class="row mt-2">
          <div v-if="['advanced', 'by_availability', 'by_pattern'].includes(type) && !templateState.by_days" class="col-lg-12 text-left">
            <ui-number-input
              :name="index + 'workPlacesTotal'"
              data-cy="workplaces-total"
              :key="index + '_'"
              v-model="templateState.workplacesTotal"
              :precision="0"
              :step="1"
              @change="(val) => workplacesTotal(templateState, val)"
              :label="$t('Number of work places')"
              :hint="$t('NUMBER_OF_WORKPLACES_HINT')"
              :validation="{required: true, numeric: true, min_value: 1}"
            />
          </div>
          <div v-if="(['advanced', 'by_availability'].includes(type) || (type === 'by_pattern' && patternType === '5/2')) && templateState.by_days">
            <div class="col-lg-12 text-left">
              <label class="control-label font-weight-bold">{{ $t("Number of work places") }}</label>
            </div>
            <div v-for="(value, key) in templateState.workplaces" :key="key" class="col-lg-12 text-left">
              <div class="row">
                <div class="col">
                  <ui-number-input
                    :name="index + '__' + key + '_workPlaces'"
                    :disabled="!selectedDays[key]['selected']"
                    :key="index + '__' + key + '_workPlaces'"
                    v-model="templateState.workplaces[key]"
                    :label="selectedDays[key]['long']"
                    :precision="0"
                    :step="1"
                    :validation="{
                    required: true,
                     numeric: true,
                     min_value: 0,
                     max_value: type === 'by_pattern' && patternType === '5/2' ? maxVal : false
                  }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="['advanced', 'by_availability'].includes(type) || (type === 'by_pattern' && patternType === '5/2')" class="col-lg-12 text-left">
            <ui-checkbox
              :label="$t('Set workplaces by day')"
              :hint="$t('WORKPLACES_HINTS')"
              :name="index + 'checkbox'"
              :key="index"
              v-model="templateState.by_days"
              @change="workplacesTotal(templateState, templateState.workplacesTotal)"
            />
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import colorMixin from '@/mixins/mixinColors'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import momentMixin from '@/mixins/mixinMoment'
import BreakItem from './BreakItem'
import moment from 'moment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { extendMoment } from 'moment-range'

const extendedMoment = extendMoment(moment)

export default {
  name: 'TemplateComponent',
  components: { 'break-item': BreakItem, DummyPaymentRequired },
  mixins: [errorMixin, colorMixin, momentMixin, mixinColorsMarks],
  data () {
    return {
      templateData: this.template,
      pickerOptions: {
        start: '00:00',
        step: '00:05',
        end: '24:00'
      },
      timeBreak: {
        start: 0,
        end: 120,
        step: 15
      },
      maxVal: 0,
      locations: [],
      marks: [],
      projectId: null,
      templateState: {}
    }
  },
  props: {
    template: Object,
    selectedDays: Array,
    index: Number,
    type: String,
    forDemand: Boolean,
    patternType: String,
    validate: Boolean,
    showAdvanced: Boolean,
    dummyInfo: Object,
    customClass: String,
    customClass2: String,
    project: Number
  },
  created () {
    this.templateState = this.template
    if (this.project) {
      this.projectId = this.project
    } else if (this.templateState.schedule_id) {
      this.changeSchedule()
    }
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.locations = response
        .filter(item => !item.hide)
        .map(location => {
          return { id: location.id, name: location.title }
        })
    })
    let sum = 0
    this.templateState.workplaces.forEach(element => {
      if (element === '') {
        element = 0
      }
      sum += parseInt(element)
    })
    this.maxVal = parseInt(Math.ceil(sum / 5.0))
    if (this.templateState.breaks.length > 0) {
      this.templateState.break_time = 0
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId'
    }),
    projectMarks () {
      return this.$store.getters.projectMarks
    },
    companySalary () {
      return this.$store.state.company.company.salary
    },
    standardBreakIsPaid () {
      return this.companySalary.pay_breaks === undefined ? false : this.companySalary.pay_breaks
    },
    unpaidBreakTime () {
      let breakTime = 0
      // если стандартный перерыв скрыт, то считаем модульные перерывы
      if (this.templateState.allow_break) {
        this.templateState.breaks.forEach(element => {
          if (!element.billable) {
            breakTime += element.duration
          }
        })
      } else if (!this.templateState.allow_break && !this.standardBreakIsPaid) {
        breakTime = this.templateState.break_time
      }
      return breakTime
    },
    projectTimeZone () {
      const projectId = this.projectId ? this.projectId : this.currentProjectId
      if (projectId) {
        const project = this.$store.getters.projectById(projectId)
        if (project) {
          const projectTz = moment().tz(project.time_zone).format('Z')
          return this.$i18n?.t('PROJECT_TIMEZONE') + ' ' + projectTz
        }
      }
      return ''
    },
    durationMinutes () {
      return this.periodDuration * 60
    },
    periodDuration () {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + this.templateState.time_to).diff(moment(moment().format('YYYY-MM-DD') + ' ' + this.templateState.time_from), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }
      return Math.round(value * 100) / 100
    },
    showBreak () {
      return !(this.templateState.allow_break && this.templateState.breaks.length > 0)
    },
    breakInterval () {
      const breakData = []
      let current = this.timeBreak.start
      while (current <= this.timeBreak.end) {
        breakData.push(current)
        current += this.timeBreak.step
      }
      return breakData
    },
    workplaces () {
      return this.templateState.workplaces
    },
    isDisabled () {
      return this.templateState.id !== null
    },
    duration () {
      // Метод возвращает продолжительность дневного и ночного отрезка времени с вычетом перерывов
      const templateStart = moment(this.templateState.time_from, this.localeTimeFormat)
      const templateEnd = moment(this.templateState.time_to, this.localeTimeFormat)

      const { isNightShift, nightTimeDuration, dayTimeDuration } = this.determineNightData(templateStart, templateEnd)

      let value = 0

      if (isNightShift) {
        const nightCoefficientType = this.companySalary.night_coefficient_type !== undefined ? this.companySalary.night_coefficient_type : 'rate_increase'
        if (nightCoefficientType === 'time_recalculation') {
          // Используем множитель продолжительности ночного времени смены
          const nightCoefficient = this.companySalary.night_coefficient ? this.companySalary.night_coefficient : 0
          if (nightCoefficient > 0) {
            value = dayTimeDuration + (nightTimeDuration * nightCoefficient)
          } else {
            value = dayTimeDuration + nightTimeDuration
          }
        } else {
          value = dayTimeDuration + nightTimeDuration
        }
      } else {
        // смена не ночная, значит все время дневное, и без множителя
        value = dayTimeDuration
      }

      value = Math.round(value / 60 * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }

      return Math.round(value * 100) / 100
    },
    durationHrsHuman () {
      const value = this.duration
      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    }
  },
  watch: {
    projectId () {
      this.$store.dispatch('getMarksByProject', [this.projectId, false])
    },
    template () {
      this.templateState = this.template
    },
    'templateState.breaks': {
      handler (val) {
        if (val.length > 0) {
          this.templateState.break_time = 0
        }
      },
      deep: true
    },
    'templateState.schedule_id': {
      handler (val, prevVal) {
        if (prevVal && val !== prevVal) {
          this.templateState.marks = []
        }
        this.changeSchedule()
      },
      deep: true
    },
    'templateState.marks' (items) {
      if (Array.isArray(items) && (this.isEmployeeHasPermission('update-schedule-admin') ||
        this.rolesByProjects[this.currentProjectId] === 'manager')) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            this.$store.dispatch('createMark', [tag, this.projectId]).then((mark) => {
              this.templateState.marks[index] = mark.id
            })
          }
        })
      }
    },
    workplaces (values) {
      let sum = 0
      values.forEach(element => {
        if (element === '') {
          element = 0
        }
        sum += parseInt(element)
      })
      this.maxVal = parseInt(Math.ceil(sum / 5.0))
    },
    validate () {
      if (this.validate) {
        this.$refs.observe.validate().then(result => {
          let errors = 0
          if (!this.dummyInfo.active) {
            this.templateState.allow_break = false
          } else {
            const templateStart = moment(this.templateState.time_from, this.localeTimeFormat)
            const templateEnd = moment(this.templateState.time_to, this.localeTimeFormat)
            let isTwoDays = false
            if (templateStart.isSameOrAfter(templateEnd)) {
              templateEnd.add(1, 'day')
              isTwoDays = true // переходящая смена
            }
            this.templateState.breaks.forEach((breakItem, index) => {
              if (breakItem.title.length < 1 || !breakItem.time_from || !breakItem.time_to) {
                errors++
              } else { // проверяем чтобы брейки не вылазили за пределы темлпейта
                const breakStart = moment(breakItem.time_from, this.localeTimeFormat)
                const breakEnd = moment(breakItem.time_to, this.localeTimeFormat)
                if (isTwoDays) { // если смена переходящая, и брейк приходится на вторую половину смены, добавляем день к началу брейка
                  const clonedStart = templateEnd.clone().startOf('day')
                  const clonedBreakStart = breakStart.clone().add(1, 'day')
                  if (clonedBreakStart.isSameOrAfter(clonedStart) && clonedBreakStart.isSameOrBefore(templateEnd)) {
                    breakStart.add(1, 'day')
                  }
                }
                if (breakStart.isSameOrAfter(breakEnd)) { // если брейк переходящий, добавляем день к окончанию брейка
                  breakEnd.add(1, 'day')
                }
                if (breakStart.isBefore(templateStart)) {
                  errors++
                  this.$validator.errors.add({
                    field: this.templateState.id + '__' + index + '_breaks',
                    msg: 'WRONG_START_TIME'
                  })
                }
                if (breakEnd.isAfter(templateEnd)) {
                  errors++
                  this.$validator.errors.add({
                    field: this.templateState.id + '__' + index + '_breaks',
                    msg: 'WRONG_END_TIME'
                  })
                }
              }
            })
          }
          if (!result.valid || errors > 0) {
            this.$emit('validateTemplates')
          }
          this.$emit('validationResult', result.valid)
          this.$emit('updateTemplateBreaks', this.templateState)
        })
      }
    }
  },

  methods: {
    activate () {
      this.$emit('activateBreaks')
    },
    changeSchedule () {
      if (this.templateState.schedule_id) {
        const schedule = this.$store.getters.schedulesIndexed[this.templateState.schedule_id]
        if (schedule) {
          this.projectId = schedule.project_id
        } else {
          this.$store.dispatch('getScheduleById', this.templateState.schedule_id).then(schedule => {
            this.projectId = schedule.project_id
          })
        }
      }
    },
    addBreak () {
      this.templateState.breaks.push({
        id: null,
        template_id: this.templateState.id,
        title: this.$t('BREAK_TITLE_DEFAULT'),
        duration: 5,
        time_from: this.templateState.time_from,
        time_to: this.templateState.time_to,
        billable: false
      })
    },
    getHrs () {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + this.templateState.time_to).diff(moment(moment().format('YYYY-MM-DD') + ' ' + this.templateState.time_from), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        value = 24
      }

      if (value <= 0) {
        value = (24 + value)
      }

      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    deleteItem (itemKey) {
      this.templateState.breaks.splice(+itemKey, 1)
    },
    workplacesTotal (template, workplaces) {
      template.workplaces = [
        workplaces,
        workplaces,
        workplaces,
        workplaces,
        workplaces,
        workplaces,
        workplaces
      ]
      template.pivot = {}
      template.pivot.by_days = template.by_days
      template.pivot.allow_break = template.allow_break
      template.pivot.workplaces = template.workplaces
      template.pivot.workplacesTotal = template.workplacesTotal
    },
    determineNightData (timeFrom, timeTo) {
      if (timeTo.isBefore(timeFrom)) {
        timeTo = timeTo.add(1, 'days')
      }

      const duration = timeTo.diff(timeFrom, 'minutes', true)
      const breakTime = this.unpaidBreakTime
      const nightTimes = this.getNightTimes(timeFrom, timeTo)

      if (!nightTimes.length) {
        return { isNightShift: false, nightTimeDuration: 0, dayTimeDuration: duration - breakTime }
      }

      const nightShiftDefinitionType = this.companySalary.night_shift_definition_type !== undefined ? this.companySalary.night_shift_definition_type : 'full_entry'
      const nightShiftDefinitionTime = parseInt(this.companySalary.night_shift_definition_time !== undefined ? this.companySalary.night_shift_definition_time : 0)
      const nightTimeTrackingType = this.companySalary.night_time_tracking_type !== undefined ? this.companySalary.night_time_tracking_type : 'all_time'

      let isNight = false
      let nightTimeDuration = 0
      let dayTimeDuration = duration

      if (nightTimes.length) {
        // смена должна полностью входить в отрезок ночного времени
        if (nightShiftDefinitionType === 'full_entry') {
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeTo <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration
              dayTimeDuration = 0
            }
          })
        } else if (nightShiftDefinitionType === 'intersects_beginning_shift') {
          // начало смены должно входить в отрезок ночного времени
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeFrom <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration
              dayTimeDuration = 0
            }
          })
        } else {
          // общее пересечение смены с ночным временем должно составлять N часов, чтобы
          // смена считалась как ночная
          const totalNightTime = this.calculateOverlapping(timeFrom, timeTo, nightTimes)

          // продолжительность пересечения с ночным временем, с вычетом неоплачиваемых перерывов
          if (totalNightTime && totalNightTime / 60 >= nightShiftDefinitionTime) {
            isNight = true
            // смена определена как ночная, теперь нужно учитывать, вся смена идет как ночная,
            // или только пересечение
            if (nightTimeTrackingType === 'all_time') {
              nightTimeDuration = duration
              dayTimeDuration = 0
            } else {
              nightTimeDuration = totalNightTime
              dayTimeDuration = duration - nightTimeDuration
            }
          } else {
            dayTimeDuration = duration
          }
        }
      }

      // модульный перерыв
      if (!this.showBreak) {
        // так же в местах пересечения смены с ночным временем необходимо отнять время
        // пересекающихся неоплачиваемых перерывов
        let overlappingBreaksWithNightTime = 0
        let overlappingBreaksWithDayTime = 0

        this.templateState.breaks.forEach(element => {
          if (!element.billable) {
            const breakFrom = moment(element.time_from, this.backendTimeFormat)
            let breakTo = moment(element.time_to, this.backendTimeFormat)

            if (breakTo < breakFrom) {
              breakTo = breakTo.add(1, 'days')
            }

            const breakDuration = element.duration
            const nightDuration = this.calculateOverlapping(breakFrom, breakTo, nightTimes)

            // время перерыва пересекается с ночным временем
            if (nightDuration > 0) {
              // какой кусок попадет юзеру мы не знаем, поэтому при пересечении
              // перерыва с ночным временем, всю продолжительность перерыва
              // будем считать как ночным
              overlappingBreaksWithNightTime += nightDuration
            } else {
              // время перерыва не пересекается с ночным временем,
              // все время перерыва идет в дневное время
              overlappingBreaksWithDayTime += breakDuration
            }
          }
        })
        dayTimeDuration -= overlappingBreaksWithDayTime
        nightTimeDuration -= overlappingBreaksWithNightTime
      } else if (breakTime) {
        if (nightTimeDuration > dayTimeDuration) {
          nightTimeDuration -= breakTime
        } else {
          dayTimeDuration -= breakTime
        }
      }

      return { isNightShift: isNight, nightTimeDuration: nightTimeDuration, dayTimeDuration: dayTimeDuration }
    },
    getOverlap (fromX, toX, fromY, toY) {
      const range1 = extendedMoment.range(fromX, toX)
      const range2 = extendedMoment.range(fromY, toY)
      const overlap = range1.intersect(range2)
      if (overlap) {
        return overlap.diff('minutes')
      } else {
        return 0
      }
    },
    calculateOverlapping (start, end, ranges) {
      let overlaps = 0

      // order ranges by start time
      ranges = ranges.sort((a, b) => a[0].unix() - b[0].unix())

      for (let i = 0; i < ranges.length; i++) {
        const rangeStart = ranges[i][0]
        const rangeEnd = ranges[i][1]
        overlaps += this.getOverlap(start, end, rangeStart, rangeEnd)
      }
      return overlaps
    },
    getNightTimes (timeFrom, timeTo) {
      // В любом случаи от timeFrom минусуем 1 день так как одна смена
      // может 2-а раза пересекаться с ночным времен и нужно проверить все пересечения.
      // К примеру в настройка компании ночное время с 22:00 до 00:08,
      // смена 25.07.2023 02:00 до 25.07.2023 23:30, в итоге смена 2-а раза пересекается
      // с ночным временем, а именно:
      //  - c 25.07.2023 02:00 до 25.07.2023 08:00,
      //  - c 25.07.2023 22:00 до 25.07.2023 23:30
      const range = moment.range(timeFrom.clone().add(-1, 'days'), timeTo.clone().add(1, 'days'))

      // если не указаны сеттинги возвращаем пустой массив
      if (!this.companySalary || !this.companySalary.night_start || !this.companySalary.night_end) {
        return []
      }

      const nightFrom = moment(this.companySalary.night_start, 'H:mm')
      const nightTo = moment(this.companySalary.night_end, 'H:mm')

      // форматируем время по каждому дню
      const times = Array.from(range.by('days')).map((m, index) => {
        const start = m.clone().set('hour', nightFrom.hour()).set('minute', nightFrom.minute())
        const end = m.clone().set('hour', nightTo.hour()).set('minute', nightTo.minute())

        // переходящая смена, добавляем +1 день к дате окончания
        if (end < start) {
          end.add(1, 'days')
        }
        return [start, end]
      })

      return times
    }
  }
}
</script>

<style scoped>

</style>
