<template>
  <el-popover
    v-if="notes.length > 0"
    :placement="this.$store.getters.locale === 'he' ? 'left': 'right'"
    title=""
    width="200"
    trigger="hover">
    <template #default>
      <div class="text-wrap">
        <div v-for="note in notes" :key="note.id"
             style="max-width: 200px"
             class="text-wrap my-1">
          {{note.content}}
        </div>
      </div>
    </template>
    <template #reference>
      <i class="icon-clipboard mx-1" style="font-size: 1.3em;"></i>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'UserNotesComponent',
  props: {
    userId: Number
  },
  computed: {
    notes () {
      const notes = this.$store.getters.notesByCompany ? this.$store.getters.notesByCompany : []
      return notes.filter(note => note.employee_id === this.userId)
    }
  }
}
</script>

<style scoped>

</style>
