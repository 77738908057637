<template>
  <div data-toggle="modal"
       data-target="#DistributeFreeBoardModal"
       @click="click"
  >
    <i class="icon-link font-weight-bold text-primary"
       style="font-size: 1.3em; cursor: pointer" /> {{$t('DISTRIBUTE_SHIFTS_FROM_FREEBOARD')}}
  </div>
</template>

<script>
export default {
  name: 'DistributeShiftsIcon',
  props: {
    tooltip: String
  },
  emits: ['clicked'],
  methods: {
    click () {
      this.$emit('clicked')
      this.$eventBus.emit('distributeFreeBoard')
    }
  }
}
</script>

<style scoped>

</style>
